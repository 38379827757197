<div *ngFor="let item of auctionsOpened">
    <div class="auction-item profile-error countdown_dashboard_incorso" *ngIf="item.visible">
        <div class="auctions-focus-dashboard auctions-focus-incorso position-relative d-inline-block bg-gradient-active-auctions p-lg-5 p-md-5 p-4 w-100 mb-3">
            <h2><span translate>dashboardPage.aste</span> <span>{{item.codiceAsta}}</span></h2>
            <h1 translate>dashboardPage.un-asta-è-attualmente-in-corso</h1>
            <div class="countdown">
                <a [routerLink]="['/aste-in-corso-box', item.codiceAsta]"><h2 translate>dashboardPage.accedi</h2></a>
            </div>
            <div class="infos_blank">
                <span translate>dashboardPage.l'asta-si-concluderà-il </span> <b>{{item.dataFineAsta}}</b> <span translate> dashboardPage.alle-ore </span>  <b>{{item.oraFineAsta}} <span translate>dashboardPage.ora-italiana</span> </b>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(auctionsOpened == undefined || auctionsOpened.length == 0) && isRoutingAsteInCorso()" class="mb-3">
    <span>
        <span translate>aste-incorso.nessuna-asta-in-corso</span>
    </span>
</div>