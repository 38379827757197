<div class="p-4 bottom80">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>operatori.operatori</h2>
    <div id="operator-list">
        <dx-data-grid id="gridContainerOperators" 
            [dataSource]="operators_list" 
            #datagridOperatori 
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onEditingStart)="onEditingStart($event)"
            (onEditorPreparing)="onEditorPreparing($event)" 
            (onEditorPrepared)="onEditorPrepared($event)"
            (onInitNewRow)="onInitNewRow($event)" 
            (onRowUpdating)="onRowUpdate($event)"
            (onRowInserting)="onRowInsert($event)" 
            (onRowRemoving)="onRowDelete($event)"
            (onRowExpanding)="onRowExpanding($event)"
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" [enabled]="true" mode="dragAndDrop">
            <dxo-search
            [enabled]="true">
            </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"
                placeholder="{{'search'|translate}}">
            </dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true"></dxo-filter-row>

            
            <dxi-column dataField="id" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Id' | translate}}" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nome" caption="{{'operatori.Nome' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true" >
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="cognome" caption="{{'operatori.Cognome' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true" >
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.data-registrazione' | translate}}" dataType="string" format="dd/MM/yyyy"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="role" caption="{{'operatori.Ruolo' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true" >
                <dxo-lookup [dataSource]="getRolesList()" valueExpr="codice" displayExpr="descrizione"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="email" caption="{{'operatori.E-mail' | translate}}" [allowEditing]="false"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="email"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="telefono" caption="{{'operatori.Telefono-fisso' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="mobile" caption="{{'operatori.Telefono-mobile' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="status" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Status' | translate}}" dataType="string" [visible]="true"
                cellTemplate="templateOperatorStatus">
                <div *dxTemplate="let templateOperatorStatus of 'templateOperatorStatus'">
                    <span class="{{templateOperatorStatus.data.cssClass}}">{{templateOperatorStatus.data.status}}</span>
                </div>
            </dxi-column>
            <dxi-column dataField="password" caption="{{'operatori.Password' | translate}}" [allowEditing]="true"
                dataType="password" [visible]="false">
            </dxi-column>
            <dxi-column dataField="confermaPassword" caption="{{'operatori.Conferma-password' | translate}}"
                [allowEditing]="true" dataType="password" [visible]="false">
            </dxi-column>
            <dxi-column dataField="lingua" caption="{{'operatori.Lingua' | translate}}"
                [calculateCellValue]="getLanguageDescr" [allowEditing]="true" dataType="string" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="language_list" valueExpr="codice" displayExpr="descrizione"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="ragioneSociale" [formItem]="{ visible: false }"
                caption="{{'operatori.Ragione-sociale-azienda' | translate}}" [allowEditing]="false" dataType="string"
                [visible]="true" [allowExporting]="true" cellTemplate="templateRagioneSociale">
                <div *dxTemplate="let templateRagioneSociale of 'templateRagioneSociale'">
                    <span class="" (click)="goToCompanyDetail(templateRagioneSociale.data.idOrganization)" (keydown)="handleKeyDown($event)">{{templateRagioneSociale.data.ragioneSociale}}</span>
                </div>
            </dxi-column>
            <dxi-column dataField="ragioneSocialePlain" [calculateCellValue]="getRagioneSociale"
                [formItem]="{ visible: false }" caption="{{'operatori.Ragione-sociale-azienda' | translate}}"
                [allowExporting]="true" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="validated" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Validated' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="disabled" caption="{{'operatori.Disabled' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="deleted" caption="{{'operatori.Deleted' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>

            <dxi-column type="buttons" width="auto">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
                <dxi-button hint="Abilita" icon="check" [visible]="isOperatorEnabled"
                    [onClick]="enableOperator"></dxi-button>
                <dxi-button hint="Disabilita" icon="clearsquare" [visible]="isOperatorDisabled"
                    [onClick]="disableOperator"></dxi-button>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

            <dxo-master-detail [enabled]="false" template="detailOperator"></dxo-master-detail>

            <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="true" mode="form">

                <dxo-texts confirmDeleteMessage="{{'popup-operatori.conferma-elimina-operatore' | translate}}"></dxo-texts>

                <dxo-form [colCount]="20">

                    <dxi-item itemType="group" caption="{{'operatori.dettaglio-modifica' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="nome" [label]="{ text: 'operatori.Nome' | translate }"></dxi-item>

                            <dxi-item dataField="cognome" [label]="{text: 'operatori.Cognome' | translate }"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="email" [label]="{text: 'operatori.E-mail' | translate }"
                                [colSpan]="13"></dxi-item>
                            <dxi-item dataField="role" [label]="{text: 'operatori.Ruolo' | translate }"
                                [colSpan]="7"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="telefono" [label]="{text: 'operatori.Telefono-fisso' | translate }"
                                [colSpan]="10"></dxi-item>
                            <dxi-item dataField="mobile" [label]="{text: 'operatori.Telefono-mobile' | translate }"
                                [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="password" [label]="{text: 'operatori.Password' | translate }"
                                [colSpan]="10"></dxi-item>
                            <dxi-item dataField="confermaPassword"
                                [label]="{text: 'operatori.Conferma-password' | translate }" [colSpan]="10"></dxi-item>
                        </dxi-item>

                    </dxi-item>

                </dxo-form>

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>