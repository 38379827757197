<style>
    ::ng-deep div.dx-datagrid-header-panel {
        position: sticky;
        top: 0;
        z-index: 1000;
        /*z-index is applied so that this element appears in front*/
    }

    ::ng-deep .dx-datagrid-headers.dx-datagrid-nowrap {
        position: sticky;
        top: 60px;
        background-color: white;
        z-index: 1000;
    }
</style>
<div id="asta-in-corso-box" *ngIf="isEnabledToAuction()" class="content p-4 my-3" (scroll)="onScroll()"
    [ngClass]="{'top100': !isAstaSingola()}">

    <app-active-auction-banner
        *ngIf="auctionDetail == undefined && codiceAsta == undefined"></app-active-auction-banner>
    <app-next-auction-countdown
        *ngIf="auctionDetail == undefined && codiceAsta == undefined"></app-next-auction-countdown>

    <div class="pb-5" *ngIf="auctionDetail">
        <h2 title="Tipologia: {{auctionDetail.tipologiaAsta}}"><span translate>aste-incorso.asta</span>
            {{auctionDetail.codiceAsta}}
            <span class="auctions-infos2">
                <span translate>aste-incorso.apertura-asta</span> {{auctionDetail.dataOraInizioAsta}}. <span
                    translate>aste-incorso.chiusura-asta</span> {{auctionDetail.dataOraFineAsta}} <span
                    translate>aste-incorso.ora-italiana</span>
            </span>
            <div class="countdown_refresh2">
                <span translate>aste-incorso.tempo-rimanente-al-prossimo-refresh </span><span
                    id="cdRefresh">{{(secondsToRefresh/1000)}}</span>
            </div>
        </h2>
    </div>

    <!-- filtri header text e pulsantiera-->
    <div class="section-tools" *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="search-incorso mb-2">
            <button><i class="far fa-search"></i></button>
            <div class="row m-0">
                <div class="input">
                    <input type="text" placeholder="{{'search' | translate }}" id="ricerca" [(ngModel)]="searchLotText"
                        name="ricerca" (keyup)="filterByText()">
                </div>
            </div>
        </div>
        <div class="filters">
            <div class="row m-0">
                <div class="input">
                    <div class="reload desktop" id="btnRicarica1">
                        <button class="primary btn btn-success" [disabled]="getDisabledReloadLots()" name="btnRicarica1"
                            (click)="getDisabledReloadLots()==''?getAuctionDetailWithLots():undefined">
                            <span class="icon"><i class="fal fa-sync"
                                    [ngClass]="{'disabled-reload': getDisabledReloadLots() == 'disabled'}"></i></span>
                            <span class="text" translate>aste-incorso.ricarica</span>
                        </button>
                        <button class="primary btn btn-success" id="zoomMinus" title="Zoom -" (click)="decreaseFont()">
                            <span class="icon"><i class="fas fa-search-minus"></i></span>
                            <span class="text">-</span>
                        </button>
                        <button class="primary btn btn-success" id="zoomPlus" title="Zoom +" (click)="increaseFont()">
                            <span class="icon"><i class="fas fa-search-plus"></i></span>
                            <span class="text">+</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- motore asta multipla - TIPO1 - DEFAULT -->
    <div *ngIf="auctionDetail != undefined && codiceAsta != undefined && !isAstaSingola()">
        <!--isAstaMultipla-->
        <div class="d-xl-flex d-md-grid d-grid m-1 align-items-xl-center gap-xl-4 gap-md-4 gap-2 mt-1">
            <p class="m-0 fs14 dark-gray regular inter" translate>aste-incorso.filtra-per-tipologia-materiale</p>
            <div class="d-xl-flex d-md-grid d-grid align-items-center mt-3">
                <div *ngFor="let material of material_list; let i = index" class="input full me-4 mb-2">
                    <input type="checkbox" id="{{material.type_material}}" [(ngModel)]="material.checked" class="filter"
                        checked="" (click)="filterByMaterial(material)" (keydown)="handleKeyDown($event)">
                    <label for="{{material.type_material}}" translate>aste-incorso.{{material.type_material}}</label>
                </div>
            </div>
            <div id="fav" class="d-xl-flex d-md-grid d-grid m-0 align-items-xl-center gap-xl-4 gap-md-4 gap-2">
                <div class="d-xl-flex d-md-grid d-grid align-items-center mt-1">
                    <i *ngIf="toggleFilterFavourite" class="pointer fa fa-star star-filter-full mt-1"
                        (click)="filterFavouriteLots($event)" (keydown)="handleKeyDown($event)"
                        title="{{'common.rimuovi-filtro-lotti-preferiti' | translate}}"></i>
                    <i *ngIf="!toggleFilterFavourite" class="pointer star star-filter-empty mt-1"
                        (click)="filterFavouriteLots($event)" (keydown)="handleKeyDown($event)"
                        title="{{'common.filtra-lotti-preferiti' | translate}}"></i>
                </div>
                <span id="fav-label" class="mt-2 inter fs16 regular gray-dark" translate>aste-incorso.preferiti</span>
            </div>
        </div>

        <div id="area-filter"
            class="d-xl-flex d-md-grid d-grid m-1 pb-2 align-items-xl-center gap-xl-4 gap-md-4 gap-2 mt-1">
            <p class="m-0 fs14 dark-gray regular inter" translate>aste-incorso.filtra-per-area-geografica</p>
            <div class="d-xl-flex d-md-grid d-grid align-items-center mt-3">
                <div *ngFor="let areaGeo of area_list; let i = index" class="input full me-4 mb-2">
                    <input type="checkbox" id="{{areaGeo.area}}" class="filter" checked=""
                        (click)="filterByArea(areaGeo)" (keydown)="handleKeyDown($event)">
                    <label for="{{areaGeo.area}}" translate>aste-incorso.{{areaGeo.area}}</label>
                </div>
            </div>
        </div>
    </div>
    <!-- motore asta singola -> TIPO2 -->
    <div *ngIf="auctionDetail != undefined && codiceAsta != undefined && isAstaSingola()"
        class="d-xl-flex d-md-grid d-grid m-0 pb-2 align-items-xl-center gap-xl-4 gap-md-4 gap-2 mt-3">
        <!--isAstaSingola-->
        <p class="m-0 fs14 dark-gray regular inter" translate>aste-incorso.filtra-per-area-geografica</p>
        <div class="d-xl-flex d-md-grid d-grid align-items-center mt-3">
            <div *ngFor="let areaGeo of area_list; let i = index" class="input full me-4 mb-2">
                <input type="checkbox" id="{{areaGeo.area}}" class="filter" checked="" (click)="filterByArea(areaGeo)" (keydown)="handleKeyDown($event)">
                <label for="{{areaGeo.area}}" translate>aste-incorso.{{areaGeo.area}}</label>
            </div>
        </div>
        <div class="d-xl-flex d-md-flex d-flex align-items-center gap-2 mt-2">
            <div>
                <i *ngIf="toggleFilterFavourite" class="pointer fa fa-star star-filter-full"
                    (click)="filterFavouriteLots($event)" (keydown)="handleKeyDown($event)"
                    title="{{'common.rimuovi-filtro-lotti-preferiti' | translate}}"></i>
                <i *ngIf="!toggleFilterFavourite" class="pointer star star-filter-empty"
                    (click)="filterFavouriteLots($event)" (keydown)="handleKeyDown($event)" title="{{'common.filtra-lotti-preferiti' | translate}}"></i>
            </div>
            <span class="inter fs16 regular gray-dark" translate>aste-incorso.preferiti</span>
        </div>
    </div>
    <div *ngIf="isAstaSingola()" class="costo-materiale-tipo2" translate>
        aste-incorso.costo-materiale-tipo2
    </div>

    <div *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="d-block">
            <ul class="view-actions">
                <li class="view1 active" id="view1" title="{{'common.visualizzazione-a-blocchi' | translate}}"></li>
                <a [routerLink]="getRouterLinkAuctionList(codiceAsta)">
                    <li class="view2" id="view2" title="{{'common.visualizzazione-a-lista' | translate}}"></li>
                </a>
            </ul>
        </div>
        <div class="row m-0">&nbsp;</div>
        <div class="cards-lots-list bottom100" id="cards-lots-list">
            <ngx-masonry [options]="masonryOptions">
                <div ngxMasonryItem *ngFor="let lot of sortByFavourite(auctionLots); trackBy: trackByFn"
                    [@listAnimation] class="lot masonry-item">
                    <ul class="card-top-actions">
                        <ul *ngIf="lot.isExtratime && !lot.isClosed">
                            <li class="fas auction_extended" [title]="ConstantsModule.FLAG_EXTRATIME_LOT | translate">
                            </li>
                        </ul>
                        <ul *ngIf="!lot.isExtratime && !lot.isClosed">
                            <li name="riempispazio"></li>
                        </ul>
                        <ul *ngIf="lot.isClosed">
                            <li class="fas auction_closed" [title]="ConstantsModule.FLAG_AUC_CLOSED | translate"></li>
                        </ul>
                        <ul *ngIf="lot.isDisabled && !lot.isClosed && !lot.isWinning ">
                            <li class="fas auction_closed" [title]="ConstantsModule.FLAG_AUC_DISABLED | translate"></li>
                        </ul>
                        <ul *ngIf="lot.isWinning">
                            <li class="fas company_winning" [title]="ConstantsModule.FLAG_WINNING_LOT | translate"></li>
                        </ul>
                        <ul *ngIf="!lot.favourite">
                            <li class="star pointer" (click)="addFavouriteLot(lot.codiceLotto)" (keydown)="handleKeyDown($event)"
                                title="{{'common.aggiungi-a-preferiti' | translate}}"></li>
                        </ul>
                        <ul *ngIf="lot.favourite">
                            <li class="fa fa-star pointer" (click)="removeFavouriteLot(lot.codiceLotto)" (keydown)="handleKeyDown($event)"
                                title="{{'common.rimuovi-da-preferiti' | translate}}"></li>
                        </ul>
                        <ul>
                            <li class="card-lightbox-toggler" (click)="lotDetail(lot)" (keydown)="handleKeyDown($event)"></li>
                        </ul>
                    </ul>
                    <article>
                        <div class="card-body {{getProdottoClass(lot.tipoProdotto)}}"
                            [ngClass]="{'verde': lot.isWinning}">
                            <ul class="lot-main-infos">
                                <li class="lot-number">{{lot.codiceLotto}}</li>
                                <li class="quantita">
                                    <span translate>aste-incorso.Quantità</span>
                                    <b>{{lot.quantitaTon}} t</b>
                                </li>
                                <!--li>Numero rilanci: {{numeroRilanci}}</li-->
                                <li class="prezzo">
                                    <b>
                                        <span translate>{{lot.numeroRilanci > 0 ? "aste-incorso.Prezzo":
                                            "aste-incorso.base-d-asta"}}</span>
                                        <span
                                            *ngIf="lot.prezzoUltimoRilancio">{{LocalUtil.formatNumber2(lot.prezzoUltimoRilancio)}}</span>
                                        <span
                                            *ngIf="!lot.prezzoUltimoRilancio">{{LocalUtil.formatNumber2(lot.prezzoBase)}}</span>
                                        <span> &euro;/t</span>
                                    </b>
                                </li>
                            </ul>
                            <div class="error-text" style="margin:0; display: inline-flex; font-weight: bold;">
                                <div *ngIf="lot.isExtratime"><span
                                        translate>aste-incorso.chiusura-asta</span>{{getTimeOnly(lot.dataOraFineAstaEffettiva)}}
                                </div>
                                <div id="{{lot.id}}"></div>
                            </div>
                            <app-timer (monitorEvent)="timerEventRaised($event)" [name]="lot.codiceLotto"
                                [dateOfficialEnd]="auctionDetail.dataOraFineAsta"
                                [dateEndCountdown]="getDataFineAstaEffettiva(lot.dataOraFineAstaEffettiva)"
                                [hourEndCountdown]="getOraFineAstaEffettiva(lot.dataOraFineAstaEffettiva)"
                                [serverTime]="auctionDetail.serverTime"
                                [showSign]="false" [visible]="false"></app-timer>
                        </div>

                        <div class="action">
                            <div class="action-wrapper"><!--class={{actionClass}}-->
                                <div class="select-wrapper" *ngIf="lot.isClosed || lot.isDisabled || lot.isWinning">
                                    <div *ngIf="!isTaglioRilancioLibero"><!--{{#notEnabledTaglioLibero}}-->
                                        <select *ngIf="!enabledDdlStyle" name="rilancioTagli" #selectElement
                                            (change)="changeBetSlot($event, lot.codiceLotto)">
                                            <option class="select-selected" *ngFor="let bet of bet_list"
                                                [value]="bet.taglioRilancio">+{{bet.taglioRilancio}}€</option>
                                        </select>
                                        <dx-select-box *ngIf="enabledDdlStyle" id="lot-ddl-{{lot.id}}" [disabled]="true" [value]="getValueByCodiceLotto(lot.codiceLotto)"
                                            [dataSource]="bet_list" [displayExpr]="formatBet"
                                            valueExpr="taglioRilancio">
                                        </dx-select-box>
                                    </div>
                                    <div *ngIf="isTaglioRilancioLibero"
                                        class="{{ConstantsModule.AUC_VIEW_BOX}} rilancioTaglioLibero-wrapper">
                                        <!--{{#enabledTaglioLibero}}-->
                                        <input type="number" name="rilancioTagli" disabled class="rilancioTaglioLibero"
                                            pattern="\d*" maxlength="4">
                                    </div>
                                </div>
                                <button class="btn success btn-make-offer" disabled
                                    *ngIf="lot.isClosed || lot.isDisabled || lot.isWinning"
                                    translate>aste-incorso.rilancia</button>

                                <div class="select-wrapper" *ngIf="!lot.isClosed && !lot.isDisabled && !lot.isWinning">
                                    <div *ngIf="!isTaglioRilancioLibero"><!--{{#notEnabledTaglioLibero}}-->
                                        <select *ngIf="!enabledDdlStyle" name="rilancioTagli" class="tom-select {{ConstantsModule.AUC_VIEW_BOX}}"
                                            (change)="changeBetSlot($event, lot.codiceLotto)" #selectElement>
                                            <option class="select-selected" *ngFor="let bet of bet_list"
                                                [value]="bet.taglioRilancio">+{{bet.taglioRilancio}}€</option>
                                        </select>
                                        <dx-select-box *ngIf="enabledDdlStyle" [value]="getValueByCodiceLotto(lot.codiceLotto)"
                                            (onItemClick)="changeBetSlot($event, lot.codiceLotto)"
                                            [dataSource]="bet_list" [displayExpr]="formatBet"
                                            valueExpr="taglioRilancio">
                                        </dx-select-box>
                                    </div>
                                    <div *ngIf="isTaglioRilancioLibero"
                                        class="{{ConstantsModule.AUC_VIEW_BOX}} rilancioTaglioLibero-wrapper">
                                        <!--{{#enabledTaglioLibero}}-->
                                        <input type="number" name="rilancioTagli" class="rilancioTaglioLibero"
                                            [(ngModel)]="lot.bindModel" pattern="\d*" maxlength="4">
                                    </div>
                                </div>
                                <button class="btn success btn-make-offer"
                                    (click)="makeOffer(lot.codiceLotto, lot.prezzoUltimoRilancio, lot)"
                                    *ngIf="!lot.isClosed && !lot.isDisabled && !lot.isWinning"
                                    translate>aste-incorso.rilancia</button>
                            </div>
                        </div>
                    </article>
                </div>
            </ngx-masonry>
        </div>

    </div>

</div>
<div class="top100 fs28 mt-3" *ngIf="!isEnabledToAuction()">
    <span class="warning">
        <span translate>aste-incorso.azienda-non-abilitata</span>
    </span>
</div>