import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../services/APIService';
import { LocalUtilService } from '../services/local-util.service';

@Component({
  selector: 'app-commons',
  templateUrl: './commons.component.html',
  styleUrls: ['./commons.component.css']
})
export class CommonsComponent extends APIService {

  constructor(public localService: LocalUtilService,
    location: Location, 
    router: Router) { 
    super(localService, location, router);    
  }
  
}