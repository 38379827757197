import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { LocalUtilService } from './services/local-util.service';
import { DatashareService, Locale } from './services/datashare.service';
import { TranslateService } from '@ngx-translate/core';
import itMessages from "devextreme/localization/messages/it.json";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import config from "devextreme/core/config";
import { RouteListenerService } from './services/router.listener.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'coripet-angular';

  pageUrl: string = 'dashboard';

  constructor(public localUtil: LocalUtilService,
    public http: HttpClient,
    public route: ActivatedRoute, 
    private dataShare: DatashareService, 
    private translate: TranslateService,
    private routeListenerService: RouteListenerService) {

    config({
      licenseKey: "ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjYwMzMwZmMzLWQ0N2EtNDIxNS1iNjg0LWNhN2U0YWJkM2FiYyIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.C8R/XtGz7Qhxk88rWrfMMs3uYNXusl21KguIWSZvuOr8U7X1qmNZ8Fbyo0wz+J6EVMKv1vU97eGhDC/h11CYsBd06eOpaJvS+x/Kwyu4NXREv6np5JUN0QLLyGcEsi/jHg1pIg=="
    });
    
    translate.addLangs(['it_IT', 'en_US']);
    console.log('dataShare.getLanguage(): ' + dataShare.getLanguage());
    if (!dataShare.getLanguage()) {
      console.log('setting language default ' + Locale.IT);
      dataShare.setLanguage(Locale.IT);
    }
    console.log('setting translate language  ' + dataShare.getLanguage());
    translate.use(dataShare.getLanguage()!.toString());
    translate.setDefaultLang(dataShare.getLanguage()!.toString());
    
    //set devextreme dictionary language
    if (dataShare.getLanguage()!.toString().indexOf('en') == 0) {
      console.log('load enMessages');
      loadMessages(enMessages);  
    } else {
      console.log('load itMessages');
      loadMessages(itMessages);  
    }
    console .log('navigator.language: ' + navigator.language);
    locale(navigator.language);

    this.http.get('/assets/appConfig.json')
      .toPromise()
      .then(data => {
        Object.keys(data).forEach(function (key) {
          /** Need to fix this */
          console.log(key + ':' + data[key]);
          environment[key] = data[key];
        });
      });
  }

  showSpalla() {
    console.log(this.route.routeConfig);
    return false;
  }

  get islogged() {
    let output = false;
    try {
      let userDetail = sessionStorage.getItem('user');
      //console.log('islogged() userDetail1: ' + userDetail);
      userDetail = JSON.parse(userDetail);
      //console.log('islogged() userDetail2: ' + userDetail);
      if (userDetail != null) {
        output = true;
      }
    } catch (err) { }
    return output;
  }

  isUser() {
    let output = true;
    output = !this.localUtil.isAdmin() && !this.localUtil.isCoripet();
    return output;
  }

  isCoripet() {
    return this.localUtil.isCoripet();
  }

  isAdmin() {
    return this.localUtil.isAdmin();
  }

}
