<div class="p-3">
    <div class="add-item-section add-item-section-user border-0 mb-auto">
        <div class="add-operator-panel pb-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 class="fs24 gray-dark bold inter pb-2" translate>aste.aggiungi-asta</h3>
            <form id="form_createOperator" [formGroup]="asteForm" class="form-group">
                <section class="step-section focused d-flex pb-4">
                    <div class="card flex-fill me-4">
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('dataOraInizioAsta')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataOraInizioAsta" type="datetime-local"
                                    id="dataOraInizioAsta" maxlength="75">
                                <label for="newOperator--dataOraInizioAsta"
                                    translate>aste.data-e-ora-inizio-asta</label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('dataOraFineAsta')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataOraFineAsta" type="datetime-local"
                                    id="dataOraFineAsta" maxlength="75">
                                <label for="newOperator--dataOraFineAsta" translate>aste.data-e-ora-fine-asta</label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('dataDisponibilita')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataDisponibilita" type="datetime-local"
                                    id="dataDisponibilita" maxlength="75">
                                <label for="newOperator--dataDisponibilita"
                                    translate>aste.data-e-ora-disponibilità</label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('dataPubblicazione')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataPubblicazione" type="datetime-local"
                                    id="dataPubblicazione" maxlength="75">
                                <label for="newOperator--dataPubblicazione"
                                    translate>aste.data-e-ora-pubblicazione</label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('tipologiaAsta')}">
                            <div class="input-wrapper full">
                                <select name="tipologiaAsta" id="tipologiaAsta" required formControlName="tipologiaAsta"
                                    class="form-control">
                                    <option *ngFor="let tipology of tipology_list; let i = index" [value]="tipology.codice">
                                        {{tipology.descrizione}} ({{tipology.motore}})</option>
                                </select>
                                <label for="newOperator--tipologiaAsta" translate>aste.tipologia <span>*</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark inter">01</div>
                            <div class="label">
                                <span translate>aste.dati-asta</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="container add-operator-actions mt-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-lg-end text-center">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="salvaAuctionDettaglio()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler d-flex w-100 pointer mb-3 blue-dark" (click)="toggleNuovaAsta()">
            <article class="d-flex align-items-center w-100">
                <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                <div class="text fs18"><span translate>aste.aggiungi-asta</span></div>
            </article>
        </button>
    </div>
</div>
<div class="px-3">
    <hr>
</div>
<div class="p-4 bottom80">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>aste.aste</h2>
    <div id="auction-list">
        <dx-data-grid #dataGridAste 
            id="gridContainer" 
            [dataSource]="auctions_list" 
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowPrepared)="onRowPreparedAuction($event)"
            (onContentReady)="onContentReady($event)" 
            (onRowInserting)="onRowInsert($event)"
            (onRowExpanding)="onRowExpanding($event)" 
            (onRowClick)="onRowClick($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" [formItem]="{ visible: false }"
                caption="{{ 'aste.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{ 'aste.userId' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{ 'aste.data-creazione' | translate}}"
                [allowEditing]="false" [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{ 'aste.data-modifica' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" [width]="180" caption="{{ 'aste.codice-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataDisponibilita" caption="{{ 'aste.data-visibilità-a-coripet' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{ 'aste.data-pubblicazione-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{ 'aste.data-inizio-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{ 'aste.data-fine-asta' | translate}}" dataType="datetime"
                format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{ 'aste.stato-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="statusTemplate">
                <dxo-lookup [dataSource]="status_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{ 'aste.tipologia' | translate}}" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="tipology_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="daValidare" [width]="120" caption="{{ 'aste.da-validare' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="daValidareTemplate">
            </dxi-column>
            
            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

            <div *dxTemplate="let daValidareTemplate of 'daValidareTemplate'">
                <div class="status-alert-new" data-label="Lotti"
                    *ngIf="daValidareTemplate.data.sum_lots_to_publish > 0">
                    <b>{{daValidareTemplate.data.sum_lots_to_publish}}</b>&nbsp;lotti
                </div>
                <div class="" data-label="Lotti" *ngIf="daValidareTemplate.data.sum_lots_to_publish == 0">
                    -
                </div>
            </div>

            <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" mode="form">

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>