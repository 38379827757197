import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatashareService {
  test: any;

  public _localSource = new BehaviorSubject<MyData>({});
  public local = this._localSource.asObservable();

  getLocal(key: string) {
    let jsonData = null;
    if (localStorage.getItem('data')) {
      jsonData = JSON.parse(localStorage.getItem('data')!.toString());
    } else {
      jsonData = JSON.parse("{}");
    }
    this._localSource.next(jsonData);
    return jsonData[key];
  }
  setLocal(key: string, value: any) {

    let jsonData = null;
    if (localStorage.getItem('data')) {
      jsonData = JSON.parse(localStorage.getItem('data')!.toString());
    } else {
      jsonData = JSON.parse("{}");
    }
    jsonData[key] = value;
    localStorage.setItem('data', JSON.stringify(jsonData));
    this._localSource.next(jsonData);

  }
  async isLoading() {
    return this.getLocal('isLoading') === true;
  }

  getSession(key: string) {
    return sessionStorage.getItem(key);
  }
  setSession(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  removeSession(key: string) {
    return sessionStorage.removeItem(key);
  }

  getToken() {
    return this.getLocal('token');
  }

  setToken(value: any) {
    return this.setLocal('token', value);
  }

  cleanSession() {
    localStorage.removeItem('token');
    localStorage.removeItem("data");
    localStorage.removeItem("storage");
    localStorage.removeItem("lastLocationPath");
  }

  getLanguage() {
    return this.getLocal('locale');
  }

  setLanguage(value: any) {
    return this.setLocal('locale', value);
  }

  destroySession() {
    this.setToken(null);
    this.setLocal("showCpl", false);
    sessionStorage.clear();
  }


}
export enum Locale {
  IT = 'it_IT',
  EN = 'en_US',
}

export namespace Locale {
  export function keys(): Array<string> {
    let keys = Object.keys(Locale);
    return keys.slice(0, keys.length - 2);
  }
  export function values(): Array<any> {
    let vals = Object.values(Locale);
    return vals.slice(0, values.length - 2);
  }
}

interface MyData {
}
