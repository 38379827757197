<div id="aste-chiuse" class="content p-4 my-3">           
    <h2 class="mb-5" translate>aste-chiuse.aste-chiuse</h2>
    <div id="auctions-list">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="auctions_list"
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"
            (onRowClick)="openDettaglio($event)"
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'aste-chiuse.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'aste-chiuse.userId' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-chiuse.dataCreazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-chiuse.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-chiuse.codiceAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'aste-chiuse.pubblicazione' | translate}}" dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'aste-chiuse.inizioAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'aste-chiuse.conclusioneAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-end">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'aste-chiuse.tipologia' | translate}}" [width]="130" dataType="string" [visible]="true" cssClass="tipologia">
            </dxi-column>
            <dxi-column dataField="statoAsta" [width]="130" caption="{{'aste-chiuse.statoAsta' | translate}}" dataType="string" [visible]="true" cellTemplate="statusTemplate">                    
                <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                    <div class="stato-asta status Chiusa">{{statusTemplate.data.statoAsta}}</div>
                </div>
            </dxi-column>
            <dxi-column dataField="report" [allowExporting]="false" [width]="130" caption="{{'aste-chiuse.report' | translate}}" dataType="string" [visible]="true" cellTemplate="reportTemplate" cssClass="report">
                <div *dxTemplate="let reportTemplate of 'reportTemplate'">                    
                    <div class="loader_mask_column" [ngClass]="{'hidden': reportTemplate.data.uuid != undefined}">
                        <div class="loader_column"></div>
                    </div>
                    <article class="card-with-actions" [ngClass]="{'hidden': reportTemplate.data.uuid == undefined}">
                        <ul>
                            <li>
                                <div class="download-report-big download-file" (click)="downloadFile(reportTemplate.data.uuid, 'report-asta-' + reportTemplate.data.codiceAsta + '.pdf')" (keydown)="handleKeyDown($event)" id="{{reportTemplate.data.codiceAsta}}" title="Report asta {{reportTemplate.data.codiceAsta}}" [attr.data-asta]="reportTemplate.data.codiceAsta" [attr.data-uuid]="reportTemplate.data.uuid"><i class="fal fa-file-pdf right10"></i><div class="filename-report hidden">report-asta-{{reportTemplate.data.codiceAsta}}.pdf</div></div>
                            </li>
                        </ul>
                    </article>
                </div>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>


            
        </dx-data-grid>
    </div>
</div>