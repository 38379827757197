import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';

@Injectable({
    providedIn: 'root'
})
export class RouteListenerService {

    private excluded_paths: string[] = ['/login', 
        '/registrazione/step1', 
        '/registrazione/step2', 
        'regolamenti/CORIPET_portale aste_Informativa Privacy_20180107.pdf',
        'regolamenti/Aucircle-documentazione-generale.pdf',
        'regolamenti/04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf',
        'regolamenti/cookie-policy.pdf',
    ];

    constructor(
        private router: Router,
        private localUtil: LocalUtilService
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.modifyPath(event.url);
            }
        });
    }

    private modifyPath(url: string): void {
        let pathWithPrefix = url;
        if (this.excluded_paths.indexOf(url) === -1 &&
            !url.startsWith(ConstantsModule.MENU_USER_PREFIX) &&
            !url.startsWith(ConstantsModule.MENU_CORIPET_PREFIX) &&
            !url.startsWith(ConstantsModule.MENU_ADMIN_PREFIX)
        ) {
            if (this.localUtil.isUser()) {
                pathWithPrefix = ConstantsModule.MENU_USER_PREFIX + url.substring(1);
                //console.log('modifyPath(user) url: ' + pathWithPrefix);
            } else if (this.localUtil.isCoripet()) {
                pathWithPrefix = ConstantsModule.MENU_CORIPET_PREFIX + url.substring(1);
                //console.log('modifyPath(coripet) url: ' + pathWithPrefix);
            } else if (this.localUtil.isAdmin()) {
                pathWithPrefix = ConstantsModule.MENU_ADMIN_PREFIX + url.substring(1);
                //console.log('modifyPath(admin) url: ' + pathWithPrefix);
            }
        } else {
            //console.log('modifyPath() no modification to path: ' + pathWithPrefix);
        }

        // Naviga solo se il percorso è cambiato
        if (pathWithPrefix !== url) {
            this.router.navigate([pathWithPrefix]);
        }
    }
}