import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuctionService } from 'src/app/services/auction.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { AuctionItemOpened } from 'src/app/models/dataModels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-active-auction-banner',
  templateUrl: './active-auction-banner.component.html',
  styleUrls: ['./active-auction-banner.component.css']
})
export class ActiveAuctionBannerComponent extends APIService implements OnInit, OnDestroy {

  constructor(localUtil: LocalUtilService,
    location: Location,
    router: Router,
    public dashboardService: DashboardService,
    public auctionService: AuctionService,
    public messageService: MessagesService) {
        super(localUtil, location, router);
  }

  dataFineAstaInCorso: string;
  oraFineAstaInCorso: string;
  visible: boolean = true;
  auctionsOpened: AuctionItemOpened[] = [];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.searchOpenedAuction();
  }

  searchOpenedAuction() {
    const service = this.auctionService.listaAste(ConstantsModule.AUCTION_STATUS_INPROGRESS).subscribe(
      res => {
        if(res.list!=undefined && res.list.length >0){
          res.list.forEach(item => {
            let aucItem: AuctionItemOpened = new AuctionItemOpened();
            aucItem.codiceAsta = item.codiceAsta;
            aucItem.dataFineAsta = item.dataOraFineAsta.split(" ")[0];
            aucItem.oraFineAsta = item.dataOraFineAsta.split(" ")[1];    
            aucItem.visible = true;
            this.auctionsOpened.push(aucItem);
          });
        }
      }, this.handleError(true));
      this.subscriptions.push(service);
      setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);
  }

  isRoutingAsteInCorso() {
    return this.router.url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO__NO_DASHB) > -1;
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
