<div class="p-4 auction-details" *ngIf="auction">
    <h2 class="fs28 gray-dark inter bold mb-5"><span class="fs25 bold w-100 text-center normalSize status {{auction.statoAsta}}"></span>
        <span class="fs25 bold w-100 text-center normalSize status {{auction.statoAsta}}" translate>dashboardPage.asta
        </span>
        <span class="fs25 bold w-100 text-center normalSize status {{auction.statoAsta}}" translate>dashboardPage.{{auction.statoAsta}}</span>&nbsp;
        <span>{{auction.codiceAsta}} - {{auction.tipologiaAsta}}</span>
    </h2>

    <div class="auctions-infos {{auction.statoAsta}}">
        <span translate>admin-aste-dettaglio.L'asta-inizier</span><b> {{auction.dataOraInizioAsta}}</b><br />
        <b>{{auction.numLotti}}</b>&nbsp;<span translate>admin-aste-dettaglio.lotti-disponibili</span>
    </div>

    <div class="top50noPadLeft bottom50" id='lot-list' [ngClass]="{'coripet-lots-list': isCoripet()}">

        <dx-data-grid id="gridContainer" 
            [dataSource]="lot_list" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onExporting)="onExporting($event)"
            >
            
            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <dxo-master-detail [enabled]="true" template="detailLot"></dxo-master-detail>

            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceLotto" caption="{{'aste-vinte-dettaglio.codice-lotto' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-vinte-dettaglio.codice-asta' | translate}}" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-vinte-dettaglio.data-creazione' | translate}}" [width]="150" dataType="date"
                format="dd/MM/yyyy"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-vinte-dettaglio.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy"
                 [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" caption="{{'aste-vinte-dettaglio.tipo-prodotto' | translate}}" dataType="string" [visible]="true">
                <dxo-lookup [dataSource]="tipoProdotto_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
              </dxi-column>
            <dxi-column dataField="quantitaTon" [width]="120" caption="{{'aste-vinte-dettaglio.quantità' | translate}}" cellTemplate="quantityTemplate" dataType="number" [visible]="true">
            </dxi-column>
            <dxi-column dataField="numeroRilanci" caption="{{'aste-vinte-dettaglio.numero-rilanci' | translate}}" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="{{'aste-vinte-dettaglio.area-geografica' | translate}}" dataType="string" [visible]="true">
                <dxo-lookup [dataSource]="geoArea_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="note" caption="{{'aste-vinte-dettaglio.note' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" caption="{{'aste-vinte-dettaglio.chiusura-asta' | translate}}" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" caption="{{'aste-vinte-dettaglio.data-disponibilità-lotto' | translate}}" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="statoLotto" caption="{{'aste-vinte-dettaglio.stato-lotto' | translate}}" [allowExporting]="false" dataType="string" cellTemplate="statusTemplate" [visible]="true">
                <dxo-lookup [dataSource]="lot_statuses" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="prezzoBase" [width]="120" caption="{{'aste-vinte-dettaglio.base-d-asta' | translate}}" cellTemplate="priceTemplate" dataType="number" [visible]="true">
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" caption="{{'aste-vinte-dettaglio.prezzo-finale' | translate}}" dataType="number" [visible]="true" cellTemplate="finalPriceTemplate">
            </dxi-column>
            <dxi-column dataField="pagato" caption="{{'aste-vinte-dettaglio.pagato' | translate}}" [allowEditing]="true" dataType="boolean" [visible]="false">
            </dxi-column>
            <dxi-column dataField="spedito" caption="{{'aste-vinte-dettaglio.ritirato' | translate}}" [allowEditing]="true" dataType="boolean" [visible]="false">
            </dxi-column>
            <dxi-column dataField="idOrganization" caption="{{ 'dettaglio-lotto.idOrgVincitore-header' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nomeOrganization" [width]="200" caption="{{ 'dettaglio-lotto.vincitore-header' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>

                <dxi-item location="after">
                    <div class="toolbar-lots-list" *ngIf="auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE || auction.statoAsta == ConstantsModule.AUCTION_STATUS_DRAFT">
                        <dx-button
                          icon="clear"
                          hint="{{ 'dettaglio.annulla-asta' | translate }}"
                          (onClick)="annullaAsta()"
                          >
                        </dx-button>
                    </div>
                </dxi-item>

                <dxi-item location="after">                    
                    <div class="toolbar-lots-list" *ngIf="auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
                        <dx-button
                          icon="clearsquare"
                          hint="{{ 'dettaglio.annulla-approvazione-lotti' | translate }}"
                          (onClick)="annullaApprovaLotti()"
                          >
                        </dx-button>
                    </div>
                </dxi-item>

                <dxi-item location="after">
                    <div class="toolbar-lots-list" *ngIf="auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
                        <dx-button
                          icon="revert"
                          hint="{{ 'dettaglio.rifiuta-approvazione-lotti' | translate }}"
                          (onClick)="rigettaLotti()"
                          >
                        </dx-button>
                    </div>
                </dxi-item>

                <dxi-item location="after">
                    <div class="toolbar-lots-list" *ngIf="auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
                        <dx-button
                          icon="fieldchooser"
                          hint="{{ 'dettaglio.approva-lotti-in-pubblicazione' | translate }}"
                          (onClick)="approvaLotti()"
                          >
                        </dx-button>
                    </div>
                </dxi-item>

            </dxo-toolbar>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="{{statusTemplate.data.statoLotto}} lot-status">{{statusTemplate.data.statoLotto}}</div>
            </div>          

            <div *dxTemplate="let quantityTemplate of 'quantityTemplate'">
                <div>{{quantityTemplate.data.quantitaTon}} t</div>
            </div>

            <div *dxTemplate="let priceTemplate of 'priceTemplate'">
                <div>{{priceTemplate.data.prezzoBase}} &euro;</div>
            </div>

            <div *dxTemplate="let finalPriceTemplate of 'finalPriceTemplate'">
                <div>{{finalPriceTemplate.data.prezzoUltimoRilancio}} &euro;</div>
            </div>

            <div *dxTemplate="let detailLot of 'detailLot'">
                <div class="lots-list new-lots-list won">
                    <app-dettaglio-lotto [lotDetail]="detailLot.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
                </div>
            </div>

        </dx-data-grid>

    </div>
</div>