import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { AuthService } from 'src/app/services/auth.service';
import { APIService } from 'src/app/services/APIService';
import { ConstantsModule } from 'src/app/services/constants.module';
import { NewMenuListItem } from 'src/app/models/dataModels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spalla',
  templateUrl: './spalla.component.html',
  styleUrls: ['./spalla.component.css']
})
export class SpallaComponent extends APIService implements OnInit, OnDestroy {

  isMenuOpen = false;

  constructor(localUtil: LocalUtilService,
    location: Location,
    router: Router,
    public authService: AuthService) {
    super(localUtil, location, router);
  }

  menuList: NewMenuListItem[];
  subMenuList: NewMenuListItem[];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {

    const service1 = this.authService.getMenuList().subscribe(
      (data: any) => {
        //  console.log(data.list)
        let landingAvailable = false;
        if (data.list != undefined) {
          // console.log(this.menuList)
          this.menuList = data.list;
          this.openChildItems();

          this.checkLanding();
        }
      }, this.handleError(true)
    );
    
    const service2 = this.localUtil.subscribeLogout().subscribe(
      data => {
        if (data && data == true) {
          this.doLogout();
        }
      }
    );

    this.subscriptions.push(service1, service2);

  }

  toggleMenus() {
    this.isMenuOpen = !this.isMenuOpen; // Toggle the menu state
  }

  checkLanding() {
    let currentUrl = this.router.url;
    if (this.menuList != undefined && this.menuList.length > 0) {
      if (currentUrl != undefined && currentUrl.length > 0) {
        currentUrl = currentUrl.substring(1);
        if (currentUrl == '') {
          //path inesistente, vado alla pagina islanding = true
          this.menuList.forEach(item => {
            if (item.isLanding) {
              this.router.navigate([item.key, {}]);
            }
          });
        }
      }
    }
  }

  openChildItems() {
    //console.log('openChildItems()');
    const itemsToOpen = this.menuList.filter(item => item.openChild);

    itemsToOpen.forEach(item => {
      this.openChilds(item);
    });
  }



  openChilds(itemMenu: NewMenuListItem) {
    //console.log('openChilds: ' + itemMenu.page);
    const service = this.authService.getMenuListByPage(itemMenu.page).subscribe(
      (data: any) => {
        //console.log('openChilds listSubmenu: ' + data.list);
        if (data.list != undefined) {
          this.subMenuList = data.list;
          //console.log(data.list)
        }
      }
    );
    this.subscriptions.push(service);
  }

  isActiveSubMenu(menuItem: NewMenuListItem) {
    let output = false;
    if (menuItem != undefined) {
      //console.log('isActiveSubMenu() menuItem: ' + menuItem.key);
      let currentUrl = this.router.url;
      /*if (!menuItem.key.startsWith('/')) {
        if (currentUrl != undefined && currentUrl.length > 1) {
          //tolgo lo slash iniziale
          currentUrl = currentUrl.substring(1);
        }
      }*/
      //const urlforActiveSubMenu = menuItem.page.replace('.html', ''); // Remove the '.html' extension
      const urlforActiveSubMenu = this.getPrefixUrl() + menuItem.page;
      //console.log('isActiveSubMenu() currentUrl: ' + currentUrl + ' urlforActiveSubMenu: ' + urlforActiveSubMenu);
      if (currentUrl == urlforActiveSubMenu) {
        output = true;
      }
    }
    return output;
  }

  gotoUrl(url: string) {
    //this.localUtil.showCompanySubHeader();
    //this.localUtil.hideCompanyCapacityHeader(); 
    //console.log('gotoUrl: ' + url);
    // const urlWithoutExtension = url.replace('.html', ''); // Remove the '.html' extension
    this.router.navigate([url, {}]);
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  isActiveFather(fatherItem: NewMenuListItem) {
    let output = false;
    
    if (this.subMenuList != undefined && this.subMenuList.length > 0) {
      this.subMenuList.forEach(subMenuItem => {
        //console.log('this.isActiveSubMenu(subMenuItem): ' + this.isActiveSubMenu(subMenuItem));
        /*if (fatherItem != undefined && subMenuItem.parent == fatherItem.key) {
          console.log('fatherItem.key: ' + fatherItem.key + ' subMenuItem.parent: ' + subMenuItem.parent);
        }*/
        if (fatherItem != undefined) {
          if (this.isActiveSubMenu(subMenuItem) && subMenuItem.parent == fatherItem.key) {
            //console.log('isFatherActive: this.subMenuList: ' + this.subMenuList);
            output = true;
          }
        }
      });
    } else {
      let currentUrl = this.router.url;
      /*if (!fatherItem.page.startsWith('/')) {
        if (currentUrl != undefined && currentUrl.length > 1) {
          //tolgo lo slash iniziale
          currentUrl = currentUrl.substring(1);
        }
      }*/
      //  const urlforActive = fatherItem.page.replace('.html', ''); // Remove the '.html' extension
      //console.log('fatherItem(): ' + fatherItem);
      if (fatherItem != undefined) {
        const urlforActive = this.getPrefixUrl() + fatherItem.page; // Remove the '.html' extension

        if (currentUrl == urlforActive) {
          output = true;
          //console.log('currentUrl: ' + currentUrl + ' urlforActive: ' + urlforActive);
          //console.log('currentUrl: ' + currentUrl);
        }
      }
    }
    return output;
  }

  doLogout() {

    setTimeout(() => {
      sessionStorage.removeItem("user");
      sessionStorage.clear();
      localStorage.clear();
      this.localUtil.removeAuthParams();
      this.router.navigate([ConstantsModule.REF_AZ_LOGIN_PATH, {}]);
    }, 500);

    setTimeout(() => {
      this.localUtil.hideLoader();
    }, 1500);

    let data: any;
    const service = this.authService.logout().subscribe(
      data => {
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);    

  }

  showUl: boolean = true;
  toggleMenu() {
    $("#container").toggleClass("sidebar_closed");
    $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
    setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 100);
    this.setHeaderSticky();
  }


  setHeaderSticky() {
    let togVisibility = $('#main-menu-items').css('visibility');
    if (togVisibility == 'visible') {
      this.showUl = true;
      togVisibility = 'hidden';
      //togVisibility = 'visible';
    } else {
      this.showUl = false;
      togVisibility = 'visible';
    }
    $('#main-menu-items').css('visibility', togVisibility);
    $('#main-menu-items2').css('visibility', togVisibility);
    $('#logo').css('visibility', togVisibility);

    if ($('#cards-lots-list').length > 0) {
      $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
    }
    
    if ($("#container").hasClass("sidebar_closed")) {

      $('header.fixed-header').addClass('full');
      $('.overview-tool.fixed-parameters').addClass('full');
      if ($('#cards-lots-list').length > 0) {
        //$('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10);
      }

    } else {

      $('header.fixed-header').removeClass('full');
      $('.overview-tool.fixed-parameters').removeClass('full');
      if ($('#cards-lots-list').length > 0) {
        //$('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 - 230 + 'px');
        $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
      }
    }

  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
