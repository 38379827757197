import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/*COMMON COMPONENTS*/
import { LoginComponent } from './commons/components/login/login.component';

/*PUBLIC COMPONENTS*/
import { RegistrazioneStep1Component } from './public/components/registrazione-step1/registrazione-step1.component';
import { RegistrazioneStep2Component } from './public/components/registrazione-step2/registrazione-step2.component';
import { CommonsModule } from './commons/commons.module';
import { PageNotFoundComponent } from './commons/components/page-not-found/page-not-found.component';
import { PdfResolverComponent } from './commons/components/pdf-resolver/pdf-resolver.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'user/',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'coripet/',
    loadChildren: () => import('./coripet/coripet.module').then(m => m.CoripetModule)
  },
  {
    path: 'admin/',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'login', pathMatch: 'full', component: LoginComponent,
    data: {
      title: 'Login'
    }, canActivate: []
  },   
  {
    path: 'registrazione/step1', pathMatch: 'full', component: RegistrazioneStep1Component,
    data: {
      title: 'Registrazione step1'
    }, canActivate: []
  },
  {
    path: 'registrazione/step2', pathMatch: 'full', component: RegistrazioneStep2Component,
    data: {
      title: 'Registrazione step2'
    }, canActivate: []
  },
  { 
    path: 'regolamenti/:fileName',
    pathMatch: 'full',
    component: PdfResolverComponent
  },
  { 
    path: '**', 
    component: PageNotFoundComponent 
  },
];

@NgModule({
  imports: [
    CommonsModule,
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
