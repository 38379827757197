import { Location } from '@angular/common';
import { Component, HostListener, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.css']
})
export class FileBoxComponent extends APIService implements OnInit, OnChanges, OnDestroy {

  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public organizationService: OrganizationService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
  }
  @Input() docname: string;
  @Input() uuid: string;
  @Input() showError: boolean;
  @Input() status: string = undefined;
  @Input() statusClass: string = undefined;
  @Input() externalLink: string = undefined;
  @Input() showCoripetButtons: boolean = true;
  @Input() vatnumber: string;
  @Input() type: string = 'document';
  @Input() frameworkId: number;
  @Output() file = new EventEmitter<File>();

  statusLabel: string = '';
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    if (this.status != undefined &&
      (
        this.status == '' ||
        this.status == 'Y' ||
        this.status == 'N'
      )
    ) {

      if (this.status == '' && this.uuid != '') {
        //console.log('DOC DA APPROVARE: ' + this.docname);
        this.statusLabel = this.messageService.getLabelFromCode("DA_APPROVARE");
        this.statusClass = 'da-approvare';
      } else if (this.status == 'Y') {
        //console.log('DOC APPROVATO: ' + this.docname);
        this.statusLabel = this.messageService.getLabelFromCode("APPROVATO");
        this.statusClass = 'approvato';
      } else if (this.status == 'N') {
        //console.log('DOC RIFIUTATO: ' + this.docname);
        this.statusLabel = this.messageService.getLabelFromCode("RIFIUTATO");
        this.statusClass = 'rifiutato';
      } else if (this.status == '' && this.uuid == '') {
        //console.log('DOC ASSENTE: ' + this.docname);
        this.statusLabel = this.messageService.getLabelFromCode("NON_CARICATO");
        this.statusClass = 'non-caricato';
      }
    } else {
      this.statusClass = 'nascondi';
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  goToExternalLink() {
    console.log('goToExternalLink() : ' + this.externalLink);
    this.router.navigate([this.externalLink, {}]);
  }

  isError() {
    //console.log('isError() ' + this.showError);
    return this.showError;
  }

  //*start drag n drop
  dragAreaClass: string = 'dragarea';
  draggedFiles: any;

  openPopupFile() {
    console.log(this.fileInput);
  }

  cancelFiles() {
    this.draggedFiles = undefined;
    //this.filename.emit(undefined);
    this.file.emit(undefined);
  }

  onFileChange(event: any) {
    console.log('onFileChange() event.target.files: ' + event.target.files);
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    console.log(event);
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  saveFiles(files: FileList) {
    //console.log(files[0].size,files[0].name,files[0].type);
    this.draggedFiles = files;
    this.setOutputFields();
    //console.log(files);
  }

  setOutputFields() {
    if (this.draggedFiles && this.draggedFiles.length > 0) {
      this.file.emit(this.draggedFiles[0]);
    }
  }
  //*end drag n drop

  approveFile() {
    if (this.type == 'document') {
      this.approveDocument();
    } else if (this.type == 'framework') {
      this.approveFrameworkDocument();
    }
  }

  rejectFile() {
    if (this.type == 'document') {
      this.rejectDocument();
    } else if (this.type == 'framework') {
      this.rejectFramework();
    }
  }

  approveDocument() {
    console.log('approveDocument() ' + this.docname);
    const service = this.organizationService.approveDocument(this.vatnumber, this.docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.reloadComponent();
        });
        this.subscriptions.push(service);

      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  approveFrameworkDocument() {
    console.log('approveFramework() this.docname: ' + this.docname + ' vatnumber: ' + this.vatnumber);
    const service = this.organizationService.approveDocumentFramework(this.vatnumber, this.docname, this.frameworkId).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.reloadComponent();
        });
        this.subscriptions.push(service);

      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  rejectFramework() {
    console.log('rejectFramework() this.docname: ' + this.docname);
    const service = this.organizationService.rejectDocumentFramework(this.vatnumber, this.docname, this.frameworkId).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.reloadComponent();
        });
        this.subscriptions.push(service);

      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  rejectDocument() {
    console.log('rejectDocument() ' + this.docname);
    const service = this.organizationService.rejectDocument(this.vatnumber, this.docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.reloadComponent();
        });
        this.subscriptions.push(service);

      }, this.handleError(true)
    );
    this.subscriptions.push(service);

  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {

        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
