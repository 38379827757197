import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { LanguageItem, OrganizationOperatorCreateItem, OrganizationOperatorItem, RoleItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridComponent, DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-azienda-operatori', 
  templateUrl: './azienda-operatori.component.html',
  styleUrls: ['./azienda-operatori.component.css'], 
})
export class AziendaOperatoriComponent extends APIService implements OnInit, OnDestroy {
 
  attivi: boolean = true;
  disabilitati: boolean = true;

  operators_list: OrganizationOperatorItem[] = [];
  operator: OrganizationOperatorItem = new OrganizationOperatorItem();
  operatorForm: UntypedFormGroup;
  submitted: boolean = false;
  password_fields: string[] = ['password', 'confermaPassword'];
  role_list: RoleItem[] = this.localUtil.getRoleList();
  lang_list: LanguageItem[] = this.localUtil.getLanguageList();
  private subscriptions: Subscription[] = [];

  @ViewChild("gridOperators", { static: false }) gridOperators: DxDataGridComponent;
  
  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private translate: TranslateService) {
    super(localService, location, router);

    this.operator.lingua = 'IT';

    this.operatorForm = new UntypedFormGroup({
      nome: new UntypedFormControl(this.operator.nome, [Validators.required]),
      cognome: new UntypedFormControl(this.operator.cognome, [Validators.required]),
      email: new UntypedFormControl(this.operator.email, [Validators.required, Validators.email]),
      password: new UntypedFormControl(this.operator.password, [Validators.required]),
      confermaPassword: new UntypedFormControl(this.operator.confermaPassword, [Validators.required]),
      telefono: new UntypedFormControl(this.operator.telefono, [Validators.required]),
      mobile: new UntypedFormControl(this.operator.mobile, [Validators.required]),
      lingua: new UntypedFormControl(this.operator.lingua, [Validators.required]),
    });
    
    this.disableCompanyOperator = this.disableCompanyOperator.bind(this);
    this.enableCompanyOperator = this.enableCompanyOperator.bind(this);
    this.eliminaOperatore = this.eliminaOperatore.bind(this);
  }

  ngOnInit(): void {
    this.listOperators();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  attiviCheckBox() {
    this.listOperators();
  }

  disabilitatiCheckBox() {
    this.listOperators();
  }

  isNewEditing() {
    return (this.operator.newEditing == true);
  }

  listOperators() {
    this.operators_list = [];
    let list_to_add: OrganizationOperatorItem[] = [];
    const opService = this.organizationService.getOrganizationOperators(this.localUtil.authParams.organization_detail.orgname).subscribe(
      (data: any) => {
        if (data.list) {
          data.list.forEach((item, index) => {
            if (item.disabled == "Y") {
              item.statusClass = "disabled";
              item.status = "Disabilitato";
              item.stato = "disabilitato";
            } else if (item.validated == "N") {
              item.statusClass = "pending";
              item.status = "In attesa di verifica";
            } else if (item.disabled == "N") {
              item.statusClass = "active";
              item.status = "Attivo";
              item.stato = "abilitato";
            }

            if (item.role == "OPERATORE") {
              item.roleDescr = "Operatore Coripet";
            } else if (item.role == "OPER_PLASTICFINDER") {
              item.roleDescr = "Admin";
            } else if (item.role == "OPER_AZIENDALE") {
              item.roleDescr = "Operatore Aziendale";
            } else if (item.role == "REF_AZIENDALE") {
              item.roleDescr = "Referente Aziendale";
            }

            if (item.deleted != "Y") {
              list_to_add.push(item);
            }
          });

        }
        //this.operators_list = list_to_add;

        if (this.attivi == true) {
          for (let val of list_to_add) {
            if (val.status === 'Attivo') {
              this.operators_list.push(val);
            }
          }
        } 
        
        if (this.disabilitati == true) {
          for (let val of list_to_add) {
            if (val.status === 'Disabilitato') {
              this.operators_list.push(val); 
            }
          }
        } 
      });
      this.subscriptions.push(opService);
  }

  toggleNuovoOperatore() {
    this.operator.newEditing = !this.operator.newEditing;
    if (this.operator.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.operatorForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.operator = new OrganizationOperatorItem();
    this.operator.newEditing = false;
  }

  salvaNuovoOperatore() {
    if (this.validate()) {
      console.log('salvaNuovoOperatore() form valido');
      let newOp: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
      newOp = {
        nome: this.operatorForm.get('nome').value,
        cognome: this.operatorForm.get('cognome').value,
        email: this.operatorForm.get('email').value,
        lingua: this.operatorForm.get('lingua').value,
        telefono: this.operatorForm.get('telefono').value,
        mobile: this.operatorForm.get('mobile').value,
        password: this.operatorForm.get('password').value,
        confermaPassword: '',
        role: '',
      };

      const createUserService = this.organizationService.createUser(this.localUtil.authParams.organization_detail.orgname, newOp).subscribe(
        (res) => {
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
          this.annullaNuovoOperatore();
          this.listOperators();
        },
        this.handleError(true)
      );
      this.subscriptions.push(createUserService);

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  validate() {
    console.log('validate()');
    this.submitted = true;
    let output = true;
    console.log(this.operatorForm);
    Object.keys(this.operatorForm.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.operatorForm.get(key).valid) {
        output = false;
      }
    });
    console.log('password check this.operator.password: ' + this.operatorForm.get('password').value + ' valid: ' + this.operatorForm.get('password').valid);
    console.log('password check this.operator.confermaPassword: ' + this.operatorForm.get('confermaPassword').value + ' valid: ' + this.operatorForm.get('confermaPassword').valid);
    if (this.operatorForm.get('password').valid && this.operatorForm.get('confermaPassword').valid) {
      if (this.operatorForm.get('password').value != this.operatorForm.get('confermaPassword').value) {
        console.log('password check');
        output = false;
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.operatorForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
        this.operatorForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
      } else if (this.operatorForm.get('password').value.trim().length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.operatorForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        this.operatorForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  }

  getControlErrorMessage(name) {
    let output = '';
    //console.log(this.operatorForm.controls[name].errors);
    let errors = this.operatorForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validateField(nome) {
    return this.operatorForm.get(nome).valid;
  }

  controlHasError(nome) {
    return !this.operatorForm.get(nome).valid && this.submitted;
  }

  controlPassHasError(nome1, nome2) {
    let ctl1 = this.operatorForm.get('passwords.' + nome1);
    let ctl2 = this.operatorForm.get('passwords.' + nome2);
    console.log('ctl1: ');
    console.log(this.operator[nome1]);
    console.log('ctl2: ');
    console.log(this.operator[nome2]);
    return false;
  }
  
  toggleDettaglio(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms();
      this.operator = data;
      data.formOpened = true;
    }
  }

  openDettaglio(data) {
    data.formOpened = true;
    this.operator = data;
  }

  closeDettaglio(data) {
    console.log(data);
    data.formOpened = false;
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  toggleMenu(data) {
    console.log(data);
    data.menuOpened = !data.menuOpened;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  modificaOperatore(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms();
      data.editing = true;
    }
    this.openDettaglio(data);
    this.closeMenu(data);
  }

  disableAllEditForms() {
    if (this.operators_list != undefined) {
      this.operators_list.forEach((operator: OrganizationOperatorItem) => {
        operator.editing = false;
        operator.formOpened = false;
        operator.menuOpened = false;
      });
    }
  }

  annullaModificaOperatore(data) {
    data.editing = !data.editing;
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  isEditing(data) {
    return (data.editing == true);
  }

  aggiornaOperatore(data) {

    const updateService = this.organizationService.updateUser(data).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );
    this.subscriptions.push(updateService);

  }

  disabilitaOperatore(data) {

    console.log(data);

    const userService = this.organizationService.disableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );
    this.subscriptions.push(userService);

  }

  abilitaOperatore(data) {

    console.log(data);

    const userService = this.organizationService.enableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );
    this.subscriptions.push(userService);

  }

  eliminaOperatore(e) {
    console.log('eliminaOperatore()');
    let data = e.row.data;
    let params: any[] = [data.nome, data.cognome, data.email];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_OPERATOR_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("DELETE_OPERATOR_MESSAGE", params));
    const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        const userService = this.organizationService.deleteUser(data.email).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_OPERATOR_DELETE"), true);
            this.listOperators();
          },
          this.handleError(true)
        );
        this.subscriptions.push(userService);

      }
    });
    this.subscriptions.push(modalService);

  }

  isCompanyOperatorEnabled(e) {
    if (e.row.data.stato == ConstantsModule.COMPANY_OPERATOR_STATUS_ENABLED) {
      return true;
    } else {
      return false;
    }
  }

  isCompanyOperatorDisabled(e) {
    if (e.row.data.stato == ConstantsModule.COMPANY_OPERATOR_STATUS_DISABLED) {
      return true;
    } else {
      return false;
    }
  }

  enableCompanyOperator(e) {
    this.abilitaOperatore(e.row.data);
  }

  disableCompanyOperator(e) {
    this.disabilitaOperatore(e.row.data);
  }

  onRowUpdating(e) {
    let data = $.extend({}, e.oldData, e.newData);
    this.aggiornaOperatore(data);
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      })
    }
  }

  onEditingStart(e) {
    this.gridOperators.instance.collapseAll(-1);
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }
  
}
