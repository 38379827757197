import { AfterViewInit, Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ActivityItem, AuctionItem, AuctionLotItem, AuctionStatusItem, AuctionTipologyItem, OrganizationOperatorCreateItem, PivotLotItem } from 'src/app/models/dataModels';
import { DxChartComponent, DxPivotGridComponent } from 'devextreme-angular';
import { OrganizationService } from 'src/app/services/organization.service';
import { AuctionService } from 'src/app/services/auction.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConstantsModule } from 'src/app/services/constants.module';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboardComponent extends APIService implements OnInit, AfterViewInit, OnDestroy {

  addUserForm: UntypedFormGroup;
  addUser: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
  submitted: boolean = false;

  activities_list: ActivityItem[] = [];
  auctions_list: AuctionItem[] = [];
  tipology_list: AuctionTipologyItem[] = [];
  status_list: AuctionStatusItem[] = this.localUtil.getStatoAstaList();
  private subscriptions: Subscription[] = [];

  constructor(localUtil: LocalUtilService, public organizationService: OrganizationService,
    location: Location, public auctionService: AuctionService, public messageService: MessagesService,
    router: Router,
    public dashboardService: DashboardService,
    private renderer: Renderer2,
    private translate: TranslateService) {
    super(localUtil, location, router);
    this.customizeTooltip = this.customizeTooltip.bind(this);

    this.addUserForm = new UntypedFormGroup({
      nome: new UntypedFormControl(this.addUser.nome, [Validators.required]),
      cognome: new UntypedFormControl(this.addUser.cognome, [Validators.required]),
      email: new UntypedFormControl(this.addUser.email, [Validators.required]),
      lingua: new UntypedFormControl(this.addUser.lingua, [Validators.required]),
      telefono: new UntypedFormControl(this.addUser.telefono, [Validators.required]),
      mobile: new UntypedFormControl(this.addUser.mobile, [Validators.required]),
      password: new UntypedFormControl(this.addUser.password, [Validators.required]),
      confermaPassword: new UntypedFormControl(this.addUser.confermaPassword, [Validators.required])
    })
  }

  //@ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;
  @ViewChild("pivotGrid", { static: false }) pivotGrid: DxPivotGridComponent;

  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

  pivotGridDataSource: any;

  dashBoardList: any[] = [];

  lots_pivot: PivotLotItem[] = [];

  pivot_accordion: any[];

  customizeTooltip(args) {
    return {
      html: `${args.seriesName} | Total<div class='currency'>${args.valueText}</div>`,
    };
  }

  loadLotPivotData() {
    console.log('loadLotPivotData()');
    const service = this.dashboardService.getPivotLotsList().subscribe(
      data => {
        console.log(data);
        this.dashBoardList = data.list;
        data.list.forEach(element => {
          element.data = new Date(element.data);
        });

        this.lots_pivot = data.list;
        this.pivotGridDataSource = {
          fields: [{
            caption: this.checkAndReplaceTranslation(this.translate, 'pivot.num-rilanci'),
            width: 120,
            dataField: 'numeroRilanci',
            area: 'row'
          }, {
            caption: this.checkAndReplaceTranslation(this.translate, 'pivot.area-geografica'),
            width: 120,
            dataField: 'areaGeografica',
            area: 'row',
            sortBySummaryField: 'tipoProdotto',
          }, {
            caption: this.checkAndReplaceTranslation(this.translate, 'pivot.asta'),
            dataField: 'codiceAsta',
            width: 150,
            area: 'row',
          }, {
            dataField: 'data',
            dataType: 'date',
            area: 'column',
          }, {
            groupName: 'date',
            groupInterval: 'month',
            visible: false,
          }, {
            caption: this.checkAndReplaceTranslation(this.translate, 'pivot.tipo-prodotto'),
            dataField: 'tipoProdotto',
            dataType: 'string',
            area: 'column',
          }, {
            caption: this.checkAndReplaceTranslation(this.translate, 'pivot.prezzo-finale'),
            dataField: 'prezzoUltimoRilancio',
            dataType: 'number',
            summaryType: 'sum',
            format: {
              currency: 'EUR',
              precision: 0,
              type: 'currency',
            },
            area: 'data',
          }],
          store: this.lots_pivot,
        };

        this.pivot_accordion = [];
        this.pivot_accordion.push('Pivot');
        this.initializePivotDataSource();

      });
      this.subscriptions.push(service);
  }
  
  initializePivotDataSource() {
    console.log('initializePivotDataSource()');
    this.pivotGridDataSource = {
      fields: [{
        caption: this.checkAndReplaceTranslation(this.translate, 'pivot.area-geografica'),
        width: 120,
        dataField: 'areaGeografica',
        area: 'row',
        sortBySummaryField: 'tipoProdotto',
      }, {
        caption: this.checkAndReplaceTranslation(this.translate, 'pivot.asta'),
        dataField: 'codiceAsta',
        width: 150,
        area: 'row',
      }, {
        dataField: 'data',
        dataType: 'date',
        area: 'column',
      }, {
        groupName: 'date',
        groupInterval: 'month',
        visible: false,
      }, {
        caption: this.checkAndReplaceTranslation(this.translate, 'pivot.tipo-prodotto'),
        dataField: 'tipoProdotto',
        dataType: 'string',
        area: 'column',
      }, {
        caption: this.checkAndReplaceTranslation(this.translate, 'pivot.prezzo-finale'),
        dataField: 'prezzoUltimoRilancio',
        dataType: 'number',
        summaryType: 'sum',
        format: {
          currency:'EUR',
          precision: 0,
          type: 'currency',
        },
        area: 'data',
      }],
      store: this.lots_pivot,
    };
  }

  ngOnInit(): void {
    this.adminInfo();
    this.operatorInfo();
    this.loadLotPivotData();
    this.listActivities();
    this.listAuctionNotClosed();
    this.listAuctionTipology();
  }

  vatNumber: string;
  adminInfo() {
    const service = this.organizationService.getAdminInfo().subscribe(dt => {
      console.log(dt['vatnumber']);
      this.vatNumber = dt['vatnumber'];
    });
    this.subscriptions.push(service);
  }

  operatorVatNumber: string;
  operatorInfo() {
    const service = this.organizationService.getCoripetInfo().subscribe(dt => {
      console.log(dt['vatnumber']);
      this.operatorVatNumber = dt['vatnumber'];
    });
    this.subscriptions.push(service);
  }

  bindPivotChart() {
    if (this.pivotGrid && this.pivotGrid.instance) {
        this.pivotGrid.instance.bindChart(this.chart.instance, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: true,
        });
    } else {
      console.log('pivotGrid instance is not accessible');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.pivotGrid && this.pivotGrid.instance) {
        this.pivotGrid.instance.bindChart(this.chart.instance, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: true,
        });

        const dataSource = this.pivotGrid.instance.getDataSource();
        dataSource.expandHeaderItem('row', ['NORD']);
        dataSource.expandHeaderItem('column', [ (new Date()).getFullYear()]);
      } else {
        console.log('pivotGrid instance is not accessible');
      }
    }, 3000);
  }

  isNewEditing() {
    return (this.addUser.newEditing == true);
  }

  firstButton: string;
  secondButton: string;
  firstToogleButton() {
    this.firstButton = 'admin';
    this.secondButton = '';
    this.addUser.newEditing = !this.addUser.newEditing;
    if (this.addUser.newEditing) {
      this.cleanValidation();
    }
  }

  secondToogleButton() {
    this.secondButton = 'operator';
    this.firstButton = '';
    this.addUser.newEditing = !this.addUser.newEditing;
    if (this.addUser.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.addUserForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.addUser = new OrganizationOperatorCreateItem();
    this.addUser.newEditing = false;
  }

  controlHasError(nome) {
    return !this.addUserForm.get(nome).valid && this.submitted;
  }

  salva() {
    if (this.firstButton === 'admin') {
      if (this.validate()) {
        let request: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
        request = {
          nome: this.addUserForm.get('nome').value,
          cognome: this.addUserForm.get('cognome').value,
          email: this.addUserForm.get('email').value,
          lingua: this.addUserForm.get('lingua').value,
          telefono: this.addUserForm.get('telefono').value,
          mobile: this.addUserForm.get('mobile').value,
          password: this.addUserForm.get('password').value,
          confermaPassword: this.addUserForm.get('confermaPassword').value,
          role: ''
        };

        const service = this.organizationService.createAdminUser(this.vatNumber, request).subscribe(dt => {
          this.localUtil.showMessage("", this.ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("ADMIN_CREATE_SUCCESS")), true);
          console.log(dt);
          this.handleError(true);
        }, err => {
          this.handleError(true);
        });
        this.subscriptions.push(service);
      }
    } else if (this.secondButton === 'operator') {
      this.salvaOperator();
    }
  }

  salvaOperator() {
    if (this.validate()) {
      let request: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
      request = {
        nome: this.addUserForm.get('nome').value,
        cognome: this.addUserForm.get('cognome').value,
        email: this.addUserForm.get('email').value,
        lingua: this.addUserForm.get('lingua').value,
        telefono: this.addUserForm.get('telefono').value,
        mobile: this.addUserForm.get('mobile').value,
        password: this.addUserForm.get('password').value,
        confermaPassword: this.addUserForm.get('confermaPassword').value,
        role: ''
      };

      const service = this.organizationService.createCoripetUser(this.vatNumber, request).subscribe(dt => {
        this.localUtil.showMessage("", this.ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("CORIPET_CREATE_SUCCESS")), true);
        console.log(dt);
        this.handleError(true);
      }, err => {
        this.handleError(true);
      });
      this.subscriptions.push(service);
    }
  }

  getControlErrorMessage(name) {
    let output = '';
    console.log(this.addUserForm.controls[name].errors);
    let errors = this.addUserForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validate() {
    console.log('validate()');
    this.submitted = true;
    let output = true;
    console.log(this.addUserForm);
    Object.keys(this.addUserForm.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.addUserForm.get(key).valid) {
        output = false;
      }
    });

    if (this.addUserForm.get('password').valid && this.addUserForm.get('confermaPassword').valid) {
      if (this.addUserForm.get('password').value != this.addUserForm.get('confermaPassword').value) {
        output = false;
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.addUserForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
        this.addUserForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
      } else if (this.addUserForm.get('password').value.trim().length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.addUserForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        this.addUserForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  }

  onRowClick(e) {
    console.log(e.data.codiceAsta);
    this.router.navigate(['admin-aste-dettaglio.html/', e.data.codiceAsta]);
  }

  listActivities() {

    const service = this.organizationService.getActivities().subscribe(
      (data: any) => {
        let conta = 0;
        data.list.forEach(item => {
          conta++;
          if (item.operation == 'INSERT') {
            item.operazione = "aggiunto";
            item.desinenza = "alla";
          } else if (item.operation == 'DELETE') {
            item.operazione = "rimosso";
            item.desinenza = "dalla";
          } else if (item.operation == 'UPDATE') {
            item.operazione = "aggiornato";
            item.desinenza = "nella";
          }
          
          if (conta < 10) {
            this.activities_list.push(item);
          }
        });

      }, this.handleError(true));
      this.subscriptions.push(service);
  }

  bozza_List: any[] = [];
  listAuctionNotClosed() {

    const service = this.auctionService.listaAste(undefined).subscribe(
      (data) => {
               
        data.list.forEach((auction: AuctionItem) => {
          //if (auction.statoAsta != 'Chiusa') {
          auction.cssClass = auction.statoAsta.replace(' ', '');
          //this.auctions_list.push(auction);
          if (auction.statoAsta == this.ConstantsModule.AUCTION_STATUS_AVAILABLE) {
            this.loadDaValidareField(auction);
            this.auctions_list.push(auction);
          }
          if (auction.statoAsta === this.ConstantsModule.AUCTION_STATUS_DRAFT) {
            this.bozza_List.push(auction);
          }
          //}
        });
      },
      this.handleError(true));
      this.subscriptions.push(service);

  }

  loadDaValidareField(auction) {

          const service = this.auctionService.getAuctionLots(auction.codiceAsta, '').subscribe(
            data => {
              let somma = 0;
              if (data.lotList != undefined) {
                data.lotList.forEach(itemLot => {
                  if (itemLot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                    somma++;
                  }
                });
              }
              auction.lots = data.lotList;
              auction.sum_lots_to_publish = somma;
              auction.daValidare = somma + ' lotti';
              // set fields
              this.setLotsFields(auction);
            }
          );
          this.subscriptions.push(service);
  }

  setLotsFields(auction) {
    let lotList: AuctionLotItem[] = auction.lots;
    
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });
      
      
    }

  }

  listAuctionTipology() {

    const service = this.auctionService.listAuctionTipology().subscribe(
      (data) => {
        
        this.tipology_list = data.list;
    });
    this.subscriptions.push(service);

  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
