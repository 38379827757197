<dx-popup [width]="600" [height]="600" [showTitle]="true" title="{{'setup.tagli-rilancio'|translate}}"
    [dragEnabled]="false" [showCloseButton]="true" [(visible)]="this.showPopup">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <div class="row">
                <p class="col-6 ps-4" translate>setup.taglio</p>
                <p class="col-6" translate>setup.azioni</p>
            </div>
            <hr class="my-3">
            <div *ngFor="let item of betList; let i = index;">
                <div class="row mb-4">
                    <p class="col-6 ps-4">{{item.taglioRilancio}}&nbsp;€</p>
                    <span class="col-6"><i class="fa fa-close" (click)="remove(i)" (keydown)="handleKeyDown($event)"
                            style="font-size:20px;color:skyblue; cursor: pointer;"></i></span>
                </div>
                <hr class="mb-4">
            </div>
            <div class="row mb-4">
                <div class="input col-6 ps-4 mb-0">
                    <div class="input-wrapper full">
                        <input type="text" name="nuovoTaglio" id="nuovoTaglio" [(ngModel)]="nuovoTaglio">
                        <label for="newOperator--nuovoTaglio" translate>setup.nuovo-taglio</label>
                    </div>
                </div>
                <span class="col-6"><i class="fa fa-plus" (click)="add()" (keydown)="handleKeyDown($event)"
                        style="font-size:20px;color:skyblue; cursor: pointer;"></i>
                    <p *ngIf="errorMsg" class="text-danger" translate>setup.il-taglio-di-rilancio-obbligatorio</p>
                </span>
            </div>
            <hr>
            <p class="my-4" translate>setup.per-confermare-il-salvataggio-dei-tagli</p>
            <hr class="mb-4">
        </dx-scroll-view>
    </div>
    <dxi-toolbar-item class="px-5" widget="dxButton" toolbar="bottom" location="after" [options]="saveButtonOptions">
    </dxi-toolbar-item>
</dx-popup>
<div class="p-3">
    <div class="add-item-section add-item-section-user">
        <div class="add-operator-panel pb-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 translate>setup.tipologia-asta</h3>
            <form id="form_createOperator" [formGroup]="tipologiaForm" class="form-group">
                <section class="step-section focused d-flex pb-4">
                    <div class="card flex-fill me-4">
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('codice')}">
                            <div class="input-wrapper full">
                                <input required formControlName="codice" type="text" id="codice" maxlength="75">
                                <label for="newOperator--codice" translate>setup.codice <span>*</span></label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('descrizione')}">
                            <div class="input-wrapper full">
                                <input required formControlName="descrizione" type="text" id="descrizione"
                                    maxlength="75">
                                <label for="newOperator--descrizione" translate>setup.descrizione <span>*</span></label>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('motore')}">
                            <div class="input-wrapper full">
                                <select name="motore" id="motore" required formControlName="motore"
                                    class="form-control">
                                    <option *ngFor="let lot of motore; let i = index" [value]="lot.motore">
                                        {{lot.motore}}</option>
                                </select>
                                <label for="newOperator--tipologiaAsta" translate>setup.motore <span>*</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark pb-2">01</div>
                            <div class="label">
                                <span translate>setup.dati-tipologia-asta</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="container add-operator-actions mt-5" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-lg-end text-center">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="saveTipologia()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler d-flex mb-4 blue-dark pointer w-100" (click)="toggleNuovoOperatore()">
            <article class="d-flex align-items-center w-100">
                <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                <div class="text fs18"><span translate>setup.aggiungi-tipologia-asta</span></div>
            </article>
        </button>
    </div>
</div>
<div class="px-3">
    <hr>
</div>

<div class="p-4 bottom80">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>setup.setup</h2>
    <div id="operator-list">
        <dx-data-grid #dataGridTipology 
            id="gridContainer" 
            [dataSource]="tipology_list" 
            keyExpr="codice"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowExpanding)="onRowExpanding($event)" 
            (onRowRemoving)="onRowDelete($event)"
            (onRowInserting)="onRowInsert($event)" 
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-search [enabled]="true"></dxo-search>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [formItem]="{ visible: false }" caption="Id"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codice" caption="Codice" [allowEditing]="true" dataType="string" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="descrizione" caption="Descrizione" [allowEditing]="true" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="motore" caption="Motore" [allowEditing]="true" dataType="string" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="motore_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="setup" caption="" [allowEditing]="true" [allowExporting]="false" [allowEditing]="false" [showInColumnChooser]="false" [formItem]="{ visible: false }"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <div *dxTemplate="let operatorDetail of 'lotRowTemplate'">
                <div class="operator operator-active">
                    <article id="{{operatorDetail.data.id}}">
                        <ul class="operator-header card-header">
                            <li name="codice" class="codice">{{operatorDetail.data.codice}}</li>
                            <li name="descrizione" class="descrizione">{{operatorDetail.data.descrizione}}</li>
                            <li name="motore" class="motore">{{operatorDetail.data.motore}}</li>
                            <li class="actions" [ngClass]="{'menu-open': isMenuOpened(operatorDetail.data)}">
                                <div class="actions-icons">
                                    <div class="card-body-toggler"
                                        [ngClass]="{'open': isFormOpened(operatorDetail.data)}"
                                        (click)="toggleDettaglio(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="operator-body card-body">
                            <div class="body-wrapper" style="padding-bottom: 40px;"
                                [ngClass]="{'form-visible': isFormOpened(operatorDetail.data)}">

                                <div class="add-item-section add-item-section-user">
                                    <div class="add-operator-panel mb-0 pb-0"
                                        [ngClass]="{'hidden': !isNewEditingSetup(), 'show': isNewEditingSetup()}">
                                        <h3 translate>setup.setup-asta</h3>
                                        <form id="form_createOperator" [formGroup]="addAuctionForm" class="form-group">
                                            <section class="step-section focused">
                                                <div class="card">
                                                    <div class="input"
                                                        [ngClass]="{'error': controlHasError2('dataInizio')}">
                                                        <div class="input-wrapper full">
                                                            <input required formControlName="dataInizio" type="date"
                                                                id="dataInizio" maxlength="75">
                                                            <label for="newOperator--dataInizio"
                                                                translate>setup.data-inizio
                                                                <span>*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="input"
                                                        [ngClass]="{'error': controlHasError2('dataFine')}">
                                                        <div class="input-wrapper full">
                                                            <input required formControlName="dataFine" type="date"
                                                                id="dataFine" maxlength="75">
                                                            <label for="newOperator--dataFine" translate>setup.data-fine
                                                                <span>*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="input"
                                                        [ngClass]="{'error': controlHasError2('numeroAsteAnno')}">
                                                        <div class="input-wrapper full">
                                                            <input class="m-0" required formControlName="numeroAsteAnno"
                                                                type="number" id="numeroAsteAnno" maxlength="75">
                                                            <label for="newOperator--numeroAsteAnno"
                                                                translate>setup.numero-aste-anno
                                                                <span>*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="input"
                                                        [ngClass]="{'error': controlHasError2('percRule2')}">
                                                        <div class="input-wrapper full">
                                                            <input required formControlName="percRule2" type="number"
                                                                id="percRule2" maxlength="75">
                                                            <label for="newOperator--percRule2" translate>setup.regola-2
                                                                <span>*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="input"
                                                        [ngClass]="{'error': controlHasError2('percRule3')}">
                                                        <div class="input-wrapper full">
                                                            <input required formControlName="percRule3" type="number"
                                                                id="percRule3" maxlength="75">
                                                            <label for="newOperator--percRule3" translate>setup.regola-3
                                                                <span>*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="infos">
                                                    <div class="number-wrapper">
                                                        <div class="number">01</div>
                                                        <div class="label">
                                                            <span translate>setup.dati-setup-asta</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </form>
                                    </div>
                                    <div class="container add-operator-actions"
                                        [ngClass]="{'hidden': !isNewEditingSetup(), 'show': isNewEditingSetup()}">
                                        <div class="pb-5 text-end">
                                            <button class="btn" id="btn_cancelCreate"
                                                (click)="annullaNuovoSetup()"><span
                                                    translate>azienda-operatori.annulla</span></button>
                                            <button class=" btn" style="border-color: orange;color: orange;"
                                                type="submit" id="btn_createOperator" (click)="saveAddAuction()"><i
                                                    class="fal fa-save"></i> <span
                                                    translate>azienda-operatori.salva</span></button>
                                        </div>
                                    </div>
                                    <button class="add add-operator new-operator-toggler"
                                        (click)="toggleNuovoSetup()">
                                        <article>
                                            <div class="icon"><i class="fal fa-plus-circle"></i></div>
                                            <div class="text"><span translate>setup.aggiungi-setup-asta</span></div>
                                        </article>
                                    </button>
                                </div>
                                <div *ngIf="updateForm">
                                    <div class="d-flex">
                                        <div class="input me-2">
                                            <div class="input-wrapper full">
                                                <!--dx-date-box type="date" 
                                                    [value]="dataInizioUpdate" 
                                                    name="dataInizioUpdate" 
                                                    id="dataInizioUpdate"
                                                    [inputAttr]="{ 'aria-label': 'Data inizio' }"
                                                ></dx-date-box-->
                                                <!--input type="date" name="dataInizioUpdate" id="dataInizioUpdate"
                                                    [(ngModel)]="dataInizioUpdate">
                                                <label for="newOperator--dataInizioUpdate"
                                                    translate>setup.data-inizio</label-->
                                            </div>
                                        </div>
                                        <div class="input me-2">
                                            <div class="input-wrapper full">
                                                <!--input type="date" name="dataFineUpdate" id="dataFineUpdate"
                                                    [(ngModel)]="dataFineUpdate">
                                                <label for="newOperator--dataFineUpdate"
                                                    translate>setup.data-fine</label-->
                                            </div>
                                        </div>
                                        <div class="input me-2">
                                            <div class="input-wrapper full">
                                                <input type="text" name="numeroAsteAnnoUpdate" id="numeroAsteAnnoUpdate"
                                                    [(ngModel)]="numeroAsteAnnoUpdate">
                                                <label for="newOperator--numeroAsteAnnoUpdate"
                                                    translate>setup.numero-aste-anno</label>
                                            </div>
                                        </div>
                                        <div class="input me-2">
                                            <div class="input-wrapper full">
                                                <input type="text" name="percRule2Update" id="percRule2Update"
                                                    [(ngModel)]="percRule2Update">
                                                <label for="newOperator--percRule2Update"
                                                    translate>setup.regola-2</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <input type="text" name="percRule3Update" id="percRule3Update"
                                                    [(ngModel)]="percRule3Update">
                                                <label for="newOperator--percRule3Update"
                                                    translate>setup.regola-3</label>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 translate>setup.properties</h2>
                                    <div class="input" *ngFor="let item of properties; let i = index">
                                        <div class="input-wrapper full">
                                            <input type="text" name="properties" id="properties"
                                                [(ngModel)]="propertiesValues[i]">
                                            <label for="newOperator--properties" translate>{{item.code}}</label>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <button class="btn btn-primary me-5" (click)="saveUpdateAuction()"><span
                                                translate>azienda-operatori.salva</span></button>
                                        <button class="btn btn-primary" (click)="gestione()"><span
                                                translate>setup.gestione-tagli-rilancio</span></button>
                                    </div>
                                </div>

                                <div *ngIf="grid">
                                    <dx-data-grid #dataGridSetup id="gridContainer"
                                        [dataSource]="operatorDetail.data.setup" 
                                        keyExpr="id"
                                        [allowColumnReordering]="true"
                                        [allowColumnResizing]="true" 
                                        [showBorders]="true" 
                                        [columnHidingEnabled]="false" 
                                        [columnAutoWidth]="true"
                                        (onInitNewRow)="onInitNewRowSetup($event, tipologyDetail.data.codice)"
                                        (onRowInserting)="onRowInsertSetup($event)"
                                        (onEditingStart)="onEditingStart($event)"
                                        (onRowRemoving)="onRowDeleteSetup($event)"
                                        (onRowUpdating)="onRowUpdateSetup($event)"
                                        (onRowPrepared)="onRowPrepared($event)"
                                        (onExporting)="onExporting($event)">

                                        <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                                            <dxo-search
                                            [enabled]="true">
                                            </dxo-search>
                                        </dxo-column-chooser>

                                        <dxo-load-panel [enabled]="true"></dxo-load-panel>
                                        <dxo-filter-row [visible]="false"></dxo-filter-row>
                                        <dxo-header-filter [visible]="false"></dxo-header-filter>
                                        <dxo-scrolling mode="default"></dxo-scrolling>

                                        <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false"
                                            placeholder="Cerca...">
                                        </dxo-search-panel>
                                        <dxo-export [enabled]="true" 
                                                    [allowExportSelectedData]="false" 
                                                    fileName="datagrid_list"
                                                    [formats]="['xlsx', 'csv', 'pdf']"
                                        ></dxo-export>

                                        <dxi-column dataField="dataInizio" dataType="date"
                                            caption="{{ 'setup.dataInizio' | translate }}" format="dd/MM/yyyy"
                                            [visible]="true">
                                        </dxi-column>

                                        <dxi-column dataField="dataFine"  dataType="date"
                                            caption="{{ 'setup.dataFine' | translate }}" format="dd/MM/yyyy" 
                                            [visible]="true">
                                        </dxi-column>

                                        <dxi-column dataField="numeroAsteAnno"
                                            caption="{{ 'setup.numeroAsteAnno' | translate }}" [allowEditing]="true"
                                            dataType="number" [visible]="true">
                                            <dxi-validation-rule type="custom"
                                                [validationCallback]="ruleNumberZeroPositive"
                                                message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dxi-column>

                                        <dxi-column dataField="percRule2" caption="{{ 'setup.percRule2' | translate }}"
                                            [allowEditing]="true" dataType="number" [visible]="true">
                                            <dxi-validation-rule type="custom"
                                                [validationCallback]="ruleNumberZeroPositive"
                                                message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dxi-column>

                                        <dxi-column dataField="percRule3" caption="{{ 'setup.percRule3' | translate }}"
                                            [allowEditing]="true" dataType="number" [visible]="true">
                                            <dxi-validation-rule type="custom"
                                                [validationCallback]="ruleNumberZeroPositive"
                                                message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dxi-column>

                                        <dxi-column dataField="id" [allowEditing]="false"
                                            [formItem]="{ visible: false }" [visible]="false"
                                            caption="{{ 'setup.id' | translate }}">
                                        </dxi-column>

                                        <dxi-column dataField="dataModifica"
                                            caption="{{ 'setup.dataModifica' | translate }}" dataType="date"
                                            [allowEditing]="false" [visible]="false">
                                        </dxi-column>

                                        <dxi-column dataField="tipologiaAsta"
                                            caption="{{ 'setup.tipologiaAsta' | translate }}" [allowEditing]="false"
                                            dataType="string" [visible]="false">
                                        </dxi-column>

                                        <dxi-column dataField="active" caption="{{ 'setup.active' | translate }}"
                                            [formItem]="{ visible: false }" [allowEditing]="false" dataType="boolean"
                                            [visible]="false">
                                        </dxi-column>

                                        <dxi-column dataField="properties"
                                            caption="{{ 'setup.properties' | translate }}"
                                            [formItem]="{ visible: false }" [allowEditing]="false" [visible]="false">
                                        </dxi-column>

                                        <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="true"
                                            mode="form">

                                            <dxo-texts
                                                confirmDeleteMessage="{{'setup.conferma-cancel-setup' | translate}}"></dxo-texts>

                                            <dxo-form>
                                                <dxi-item itemType="tabbed"
                                                    [tabPanelOptions]="{ deferRendering: false, onSelectionChanged: onSelectionChangedTab }">

                                                    <dxi-tab title="Dati setup">
                                                        <dxi-item itemType="group" caption="Dati setup"
                                                            cssClass="top20 detail-form">
                                                            <dxi-item itemType="group" [colCount]="3"
                                                                cssClass="detail-form-section">
                                                                <dxi-item dataField="dataInizio"></dxi-item>
                                                                <dxi-item dataField="dataFine"></dxi-item>
                                                                <dxi-item dataField="numeroAsteAnno"></dxi-item>
                                                            </dxi-item>
                                                            <dxi-item itemType="group" [colCount]="2"
                                                                cssClass="detail-form-section">
                                                                <dxi-item dataField="percRule2"
                                                                    [colSpan]="10"></dxi-item>
                                                                <dxi-item dataField="percRule3"
                                                                    [colSpan]="10"></dxi-item>
                                                            </dxi-item>
                                                        </dxi-item>
                                                    </dxi-tab>

                                                    <dxi-tab title="Properties">
                                                        <dxi-item itemType="group" caption="Properties" [colSpan]="20"
                                                            cssClass="top20 detail-form">
                                                            <dxi-item itemType="properties" [colCount]="2"
                                                                cssClass="detail-form-section"
                                                                itemTemplate="setupProperties">
                                                                <div
                                                                    *dxTemplate="let setupProperties of 'setupProperties'">
                                                                    <div *ngIf="modelSetup" class="card"
                                                                        id="properties">

                                                                        <app-setup-properties
                                                                            [properties]="modelSetup.properties"
                                                                            (updated)="updatedProperties($event)"></app-setup-properties>

                                                                    </div>
                                                                </div>
                                                            </dxi-item>
                                                        </dxi-item>
                                                    </dxi-tab>

                                                    <dxi-tab title="Tagli rilancio">
                                                        <dxi-item itemType="group" caption="Tagli rilancio"
                                                            [colSpan]="20" cssClass="top20 detail-form">
                                                            <dxi-item itemType="tagli" [colCount]="2"
                                                                cssClass="detail-form-section" itemTemplate="setupBet">
                                                                <div *dxTemplate="let setupBet of 'setupBet'">
                                                                    <div *ngIf="modelTipology" class="card" id="tagli">

                                                                        <app-setup-bet [bet]="modelTipology.bet"
                                                                            [tipologiaAsta]="modelTipology.codice"
                                                                            (updated)="updatedBet($event)"></app-setup-bet>

                                                                    </div>
                                                                </div>
                                                            </dxi-item>
                                                        </dxi-item>
                                                    </dxi-tab>

                                                </dxi-item>

                                            </dxo-form>

                                        </dxo-editing>

                                    </dx-data-grid>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            <dxo-master-detail [enabled]="true" template="tipologyDetail"></dxo-master-detail>

            <div *dxTemplate="let tipologyDetail of 'tipologyDetail'">

                <dx-form [(formData)]="tipologyDetail.data" id="dettaglio-tipologia-asta">

                    <dxi-item itemType="group" caption="{{'setup.dettaglio-tipologia-asta' | translate}}" [colSpan]="20"
                        cssClass="top20 detail-form">

                        <dxi-item dataField="setup" [colSpan]="20" [label]="{text: '    '}" cssClass="detail-form"
                            itemTemplate="templateSetup">
                            <div *dxTemplate="let templateSetup of 'templateSetup'">

                                <dx-data-grid #dataGridSetup 
                                    id="setupGridContainer" 
                                    [dataSource]="tipologyDetail.data.setup"
                                    keyExpr="id" 
                                    [allowColumnReordering]="true"
                                    [allowColumnResizing]="true" 
                                    [showBorders]="true" 
                                    [columnHidingEnabled]="false" 
                                    [columnAutoWidth]="true"
                                    (onInitNewRow)="onInitNewRowSetup($event, tipologyDetail.data.codice)"
                                    (onRowInserting)="onRowInsertSetup($event)"
                                    (onEditingStart)="onEditingStart($event)" 
                                    (onRowRemoving)="onRowDeleteSetup($event)"
                                    (onRowUpdating)="onRowUpdateSetup($event)"
                                    (onExporting)="onExporting($event)"
                                    >

                                    <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                                        <dxo-search
                                        [enabled]="true">
                                        </dxo-search>
                                    </dxo-column-chooser>
                                    <dxo-export [enabled]="true" 
                                                [allowExportSelectedData]="false" 
                                                fileName="datagrid_list"
                                                [formats]="['xlsx', 'csv', 'pdf']"
                                    ></dxo-export>
                                    <dxo-load-panel [enabled]="true"></dxo-load-panel>
                                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                                    <dxo-scrolling mode="default"></dxo-scrolling>

                                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" placeholder="Cerca...">
                                    </dxo-search-panel>

                                    <dxi-column dataField="id" [allowEditing]="false" [formItem]="{ visible: false }"
                                        [visible]="false" caption="{{ 'setup.id' | translate }}">
                                    </dxi-column>

                                    <dxi-column dataField="dataModifica"
                                        caption="{{ 'setup.dataModifica' | translate }}" dataType="date"
                                        [allowEditing]="false" [visible]="false">
                                    </dxi-column>

                                    <dxi-column dataField="dataInizio"
                                        caption="{{ 'setup.dataInizio' | translate }}" [allowEditing]="true"
                                        dataType="date" [visible]="true">                        
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dxi-column>

                                    <dxi-column dataField="dataFine"
                                        caption="{{ 'setup.dataFine' | translate }}" [allowEditing]="true"
                                        dataType="date" [visible]="true">                                   
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dxi-column>

                                    <dxi-column dataField="numeroAsteAnno"
                                        caption="{{ 'setup.numeroAsteAnno' | translate }}" [allowEditing]="true"
                                        dataType="number" [visible]="true">
                                        <dxi-validation-rule type="custom" [validationCallback]="ruleNumberZeroPositive"
                                            message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dxi-column>

                                    <dxi-column dataField="percRule2" caption="{{ 'setup.percRule2' | translate }}"
                                        [allowEditing]="true" dataType="number" [visible]="true">
                                        <dxi-validation-rule type="custom" [validationCallback]="ruleNumberZeroPositive"
                                            message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dxi-column>

                                    <dxi-column dataField="percRule3" caption="{{ 'setup.percRule3' | translate }}"
                                        [allowEditing]="true" dataType="number" [visible]="true">
                                        <dxi-validation-rule type="custom" [validationCallback]="ruleNumberZeroPositive"
                                            message="{{ 'Il valore deve essere maggiore o uguale a zero' | translate }}"></dxi-validation-rule>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dxi-column>

                                    <dxi-column dataField="tipologiaAsta"
                                        caption="{{ 'setup.tipologiaAsta' | translate }}" [allowEditing]="false"
                                        dataType="string" [visible]="true">
                                    </dxi-column>

                                    <dxi-column dataField="active" caption="{{ 'setup.active' | translate }}"
                                        [formItem]="{ visible: false }" [allowEditing]="false" dataType="boolean"
                                        [visible]="true">
                                    </dxi-column>

                                    <dxi-column dataField="properties" caption="{{ 'setup.properties' | translate }}"
                                        [formItem]="{ visible: false }" [allowEditing]="false" [visible]="false">
                                    </dxi-column>

                                    <dxo-toolbar>
                                        <dxi-item location="after" name="searchPanel"></dxi-item>
                                        <dxi-item location="after" name="exportButton"></dxi-item>
                                        <dxi-item location="after" name="columnChooserButton"></dxi-item>
                                        <dxi-item location="after" name="addRowButton"></dxi-item>
                                    </dxo-toolbar>

                                    <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                                        mode="form">

                                        <dxo-texts
                                            confirmDeleteMessage="{{'setup.conferma-cancel-setup' | translate}}"></dxo-texts>

                                        <dxo-form>
                                            <dxi-item itemType="tabbed"
                                                [tabPanelOptions]="{ deferRendering: false, onSelectionChanged: onSelectionChangedTab }">

                                                <dxi-tab title="Dati setup">
                                                    <dxi-item itemType="group" caption="Dati setup"
                                                        cssClass="top20 detail-form">
                                                        <dxi-item itemType="group" [colCount]="3"
                                                            cssClass="detail-form-section">
                                                            <dxi-item dataField="dataInizio"></dxi-item>
                                                            <dxi-item dataField="dataFine"></dxi-item>
                                                            <dxi-item dataField="numeroAsteAnno"></dxi-item>
                                                        </dxi-item>
                                                        <dxi-item itemType="group" [colCount]="2"
                                                            cssClass="detail-form-section">
                                                            <dxi-item dataField="percRule2" [colSpan]="10"></dxi-item>
                                                            <dxi-item dataField="percRule3" [colSpan]="10"></dxi-item>
                                                        </dxi-item>
                                                    </dxi-item>
                                                </dxi-tab>

                                                <dxi-tab title="Properties">
                                                    <dxi-item itemType="group" caption="Properties" [colSpan]="20"
                                                        cssClass="top20 detail-form">
                                                        <dxi-item itemType="properties" [colCount]="2"
                                                            cssClass="detail-form-section"
                                                            itemTemplate="setupProperties">
                                                            <div *dxTemplate="let setupProperties of 'setupProperties'">
                                                                <div *ngIf="modelSetup" class="card" id="properties">

                                                                    <app-setup-properties
                                                                        [properties]="modelSetup.properties"
                                                                        (updated)="updatedProperties($event)"></app-setup-properties>

                                                                </div>
                                                            </div>
                                                        </dxi-item>
                                                    </dxi-item>
                                                </dxi-tab>

                                                <dxi-tab title="Tagli rilancio">
                                                    <dxi-item itemType="group" caption="Tagli rilancio" [colSpan]="20"
                                                        cssClass="top20 detail-form">
                                                        <dxi-item itemType="tagli" [colCount]="2"
                                                            cssClass="detail-form-section" itemTemplate="setupBet">
                                                            <div *dxTemplate="let setupBet of 'setupBet'">
                                                                <div *ngIf="modelTipology" class="card" id="tagli">

                                                                    <app-setup-bet [bet]="modelTipology.bet"
                                                                        [tipologiaAsta]="modelTipology.codice"
                                                                        (updated)="updatedBet($event)"></app-setup-bet>

                                                                </div>
                                                            </div>
                                                        </dxi-item>
                                                    </dxi-item>
                                                </dxi-tab>

                                            </dxi-item>

                                        </dxo-form>

                                    </dxo-editing>

                                </dx-data-grid>

                            </div>
                        </dxi-item>
                    </dxi-item>
                </dx-form>

            </div>

            <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="true" mode="form">
                <dxo-texts confirmDeleteMessage="{{'setup.conferma-cancel-tipologia-asta' | translate}}"></dxo-texts>
            </dxo-editing>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

        </dx-data-grid>
    </div>
</div>