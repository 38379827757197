import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AreaGeograficaItem, CentroDiSelezioneItem, CentroSelezioneInsertItem, CentroSelezioneUpdateItem, NazioneItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CentroSelezioneService } from 'src/app/services/centroSelezione.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-centri-di-selezione',
  templateUrl: './centri-di-selezione.component.html',
  styleUrls: ['./centri-di-selezione.component.css']
})
export class CentriDiSelezioneComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("dataGridCs", { static: false }) dataGridCs: DxDataGridComponent;
  cs_list: CentroDiSelezioneItem[] = [];
  cs: CentroDiSelezioneItem;
  csItem: CentroSelezioneInsertItem = new CentroSelezioneInsertItem();
  csForm: UntypedFormGroup;
  submitted: boolean = false;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  geo_list: AreaGeograficaItem[] = [];
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public centroSelezioneService: CentroSelezioneService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public commonService: CommonService,
    location: Location,
    router: Router,
    private translate: TranslateService) {
    super(localService, location, router);
    this.cs = new CentroDiSelezioneItem();
    this.getNazioneDescr = this.getNazioneDescr.bind(this);
    this.onRowDelete = this.onRowDelete.bind(this);

    this.csForm = new UntypedFormGroup({
      codice: new UntypedFormControl(this.csItem.codice, [Validators.required]),
      nome: new UntypedFormControl(this.csItem.nome, [Validators.required]),
      indirizzo: new UntypedFormControl(this.csItem.indirizzo, [Validators.required]),
      cap: new UntypedFormControl(this.csItem.cap, [Validators.required]),
      citta: new UntypedFormControl(this.csItem.citta, [Validators.required]),
      provincia: new UntypedFormControl(this.csItem.provincia, [Validators.required]),
      nazione: new UntypedFormControl(this.csItem.nazione, [Validators.required]),
      areaGeografica: new UntypedFormControl(this.csItem.areaGeografica, [Validators.required]),
      pesoMedioCarico: new UntypedFormControl(this.csItem.pesoMedioCarico, [Validators.required]),
      CER: new UntypedFormControl(this.csItem.CER, [Validators.required]),
      capacitaTotale: new UntypedFormControl(this.csItem.capacitaTotale, [Validators.required]),
      note: new UntypedFormControl(this.csItem.note)
    })
  }

  ngOnInit(): void {
    console.log('init()');
    this.loadNazioni();
    this.loadProvince();
    this.loadAreaGeografica();
    this.listSelectionCenter();
  }

  loadNazioni() {
    const service = this.commonService.getNationsList().subscribe(
      data => {
        let nazioneVuota = new NazioneItem();
        nazioneVuota.codice = "";
        nazioneVuota.descrizione = " - ";
        this.nation_list.push(nazioneVuota);
        this.nation_list.push.apply(this.nation_list, data.list);
        console.log('pushed nation_list: ' + this.nation_list.length);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  loadProvince() {
    this.province_list = [];
    const service = this.commonService.getProvinceItalianeList().subscribe(
      data => {
        let provinciaVuota = new ProvinciaItem();
        provinciaVuota.codice = "";
        provinciaVuota.descrizione = " - ";
        this.province_list.push(provinciaVuota);
        this.province_list.push.apply(this.province_list, data.list);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  listSelectionCenter() {

    const service = this.commonService.getSelectionCenterList().subscribe(
      (data: any) => {
        if (data.list) {
          this.cs_list = data.list;
        }
      },
      this.handleError(true));
    this.subscriptions.push(service);
  }

  cleanValidation() {
    this.csForm.markAllAsTouched();
  }

  annullaCs() {
    this.csItem = new CentroSelezioneInsertItem();
    this.csItem.newEditing = false;
    this.submitted = false;
  }

  annullaNuovoOperatore() {
    this.csItem = new CentroSelezioneInsertItem();
    this.csItem.newEditing = false;
  }

  toggleNuovoOperatore() {
    this.csItem.newEditing = !this.csItem.newEditing;
    if (this.csItem.newEditing) {
      this.cleanValidation();
    }
  }

  salvaCs() {
    if (this.validate()) {
      console.log('salvaCs() form valido');
      let newCs: CentroSelezioneInsertItem = new CentroSelezioneInsertItem();
      newCs = {
        codice: this.csForm.get('codice').value,
        nome: this.csForm.get('nome').value,
        indirizzo: this.csForm.get('indirizzo').value,
        cap: this.csForm.get('cap').value,
        citta: this.csForm.get('citta').value,
        nazione: this.csForm.get('nazione').value,
        provincia: this.csForm.get('provincia').value,
        areaGeografica: this.csForm.get('areaGeografica').value,
        pesoMedioCarico: this.csForm.get('pesoMedioCarico').value,
        CER: this.csForm.get('CER').value,
        capacitaTotale: this.csForm.get('capacitaTotale').value,
        note: this.csForm.get('note').value,
        codiceAzienda: this.csForm.get('codiceAzienda').value,
        codiceEsterno: this.csForm.get('codiceEsterno').value,
      };

      const service = this.centroSelezioneService.createCentroSelezione(newCs).subscribe(
        (res) => {
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
          this.annullaCs();
          this.listSelectionCenter();
        },
        this.handleError(true)
      );
      this.subscriptions.push(service);

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  validate() {
    console.log('validate()');
    this.submitted = true;
    let output = true;
    console.log(this.csForm);
    Object.keys(this.csForm.controls).forEach(key => {
      console.log(key + ' ' + this.csForm.get(key).value + ' valid?: ' + this.csForm.get(key).valid);
      if (!this.csForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  getControlErrorMessage(name) {
    let output = '';
    console.log(this.csForm.controls[name].errors);
    let errors = this.csForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validateField(nome) {
    return this.csForm.get(nome).valid;
  }

  controlHasError(nome) {
    return !this.csForm.get(nome).valid && this.submitted;
  }

  getNazioneDescr(data): string {
    if (this.nation_list != undefined) {
      this.nation_list.forEach(nation => {
        if (nation.codice == data.nazione || nation.descrizione == data.nazione) {
          data.nazioneDescr = nation.descrizione;
        }
      });
    }
    return data.nazione;
  }

  onInitNewRow(e) {
    console.log('onInitNewRow()');
    this.dataGridCs.instance.collapseAll(-1);
    this.dataGridCs.instance.columnOption("codice", "allowEditing", true);
  }

  onRowUpdate(e) {
    console.log('onRowUpdate()');
    console.log(e);
    let cs: CentroSelezioneUpdateItem = $.extend({}, e.oldData, e.newData);
    const service = this.centroSelezioneService.updateCentroSelezione(cs).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.listSelectionCenter();
      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  onRowDelete(e) {
    console.log('onRowDelete()');
    console.log(e);
    let params: any[] = [];
    if (e.data != undefined) {
      params = [e.data.codice, e.data.nome];
    } else {
      params = [e.row.data.codice, e.row.data.nome];
    }
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("CANCEL_CS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.centroSelezioneService.deleteCentroSelezione(params[0]).subscribe(
          {next: (data) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.listSelectionCenter();
          },
          error: this.handleError(true)
        });
        this.subscriptions.push(service);

      }
    }, this.handleError(true));
    this.subscriptions.push(service);
  }

  onRowInsert(e) {
    let cs: CentroSelezioneInsertItem = e.data;
    const service = this.centroSelezioneService.createCentroSelezione(cs).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.listSelectionCenter();
      }, this.handleError(true)
    );
    this.subscriptions.push(service);

  }

  onRowExpanding(e) {
    this.dataGridCs.instance.cancelEditData();
    this.dataGridCs.instance.collapseAll(-1);
  }

  onEditingStart(e) {
    this.dataGridCs.instance.columnOption("codice", "allowEditing", false);
  }

  aggiornaCentroDiSelezione() {

    if (this.validate()) {

      let cs: CentroSelezioneUpdateItem = new CentroSelezioneUpdateItem();
      cs = {
        codice: this.csForm.get('codice').value,
        nome: this.csForm.get('nome').value,
        indirizzo: this.csForm.get('indirizzo').value,
        cap: this.csForm.get('cap').value,
        citta: this.csForm.get('citta').value,
        nazione: this.csForm.get('nazione').value,
        provincia: this.csForm.get('provincia').value,
        areaGeografica: this.csForm.get('areaGeografica').value,
        pesoMedioCarico: this.csForm.get('pesoMedioCarico').value,
        CER: this.csForm.get('CER').value,
        capacitaTotale: this.csForm.get('capacitaTotale').value,
        note: this.csForm.get('note').value,
        codiceAzienda: this.csForm.get('codiceAzienda').value,
        codiceEsterno: this.csForm.get('codiceEsterno').value,    
      };

      const service = this.centroSelezioneService.updateCentroSelezione(cs).subscribe(
        (res) => {
          const modalRef = this.modalService.open(PopupComponent);
          modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
          modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE");
          modalRef.componentInstance.tipo = 'message';
          const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
            this.listSelectionCenter();
          });
          this.subscriptions.push(service);

        },
        this.handleError(true)
      );
      this.subscriptions.push(service);
      
    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }

  }

  eliminaCs(data) {

    let params: any[] = [data.codice, data.nome, data.indirizzo];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_CS_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_CS_MESSAGE", params);
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {
        const service = this.centroSelezioneService.deleteCentroSelezione(data.codice).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CS_DELETE"), true);
            this.listSelectionCenter();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    });
    this.subscriptions.push(service);

  }

  loadAreaGeografica() {
    this.geo_list = [];
    const service = this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.geo_list = data.list;
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  isNewEditing() {
    return (this.csItem.newEditing == true);
  }

  toggleNuovoCs() {
    this.csItem.newEditing = !this.csItem.newEditing;
    if (this.csItem.newEditing) {
      this.localUtil.scrollTop();
    }
  }

  formatNumber(num: number) {
    return this.localUtil.formatNumber2(num);
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      info.rowElement.addClass('auction');
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

  handleClick(e) {
    this.onRowDelete(e);
  }      

}

