import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { APIService } from './APIService';
import { Location } from '@angular/common';
import { ClientService } from './client.service';
import { Observable, of } from 'rxjs';
import { AuctionBetSetupCreateItem, AuctionBetSetupItem, AuctionCreateItem, AuctionDettaglioItem, AuctionLotCreateItem, AuctionLotItem, AuctionLotUpdateItem, AuctionSetupCreateItem, AuctionSetupUpdateItem, AuctionTipologyCreateItem, FileWithContentUpload, GetAuctionLotUserFavouriteResponse, MakeOfferRequest } from '../models/dataModels';

@Injectable({
    providedIn: 'root'
})
export class AuctionService extends ClientService {

    constructor(protected localUtilService: LocalUtilService,
        protected location: Location,
        private router: Router,
        httpClient: HttpClient) {
        super(localUtilService, router, httpClient);
    }

    genericObservable: Observable<{}>;

    listAuctionTipology(): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/auction/tipology/list', this.httpOptions);
    }

    listFavouriteLots(codiceAsta: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lots/favourite/list', this.httpOptions);
    }

    addFavouriteLot(codiceAsta: string, codiceLotto: string) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lot/' + codiceLotto + '/fav', undefined, this.httpOptions);
    }

    removeFavouriteLot(codiceAsta: string, codiceLotto: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lot/' + codiceLotto + '/rmfav', this.httpOptions);
    }

    getProductionCapacity(): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/production_capacity', this.httpOptions);
    }

    listaAste(statoAsta: string): Observable<any> {
        let statoParam = '';
        if (statoAsta != undefined) {
            statoParam = "?statoAsta=" + statoAsta;
        }
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/list' + statoParam, this.httpOptions);
    }

    listaAsteVinte(statoAsta: string): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/won/list?statoAsta=' + statoAsta, this.httpOptions);
    }

    getAuctionLots(codiceAsta: string, onlywon: string): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lots/list' + onlywon, this.httpOptions);
    }

    getAuctionReport(codiceAsta: string): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/report-new/' + codiceAsta, this.httpOptions);
    }

    getAuctionReportWon(codiceAsta: string): Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/report-new-won/' + codiceAsta + '/', this.httpOptions);
    }

    getSelectionCenterList() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_CS_PREFIX + ConstantsModule.API_PREFIX + '/list', this.httpOptions);
    }

    cancelAuction(auctionCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/annullata', undefined, this.httpOptions);
    }

    approveLotList(auctionCode: string, lotList:string[]) {
        let bean = {
            lotList: lotList
        };
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/approve-list', bean, this.httpOptions);
    }

    undoApproveLotList(auctionCode: string, lot_list: AuctionLotItem[]) {

        let bean = {
            lotList: []
        };

        if (lot_list) {
            lot_list.forEach(lot => {
                bean.lotList.push(lot.codiceLotto);
            });
        }

        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/undo-approve-list', bean, this.httpOptions);
    }

    rejectLotList(auctionCode: string, lot_list: AuctionLotItem[]) {

        let bean = {
            lotList: []
        };

        if (lot_list) {
            lot_list.forEach(lot => {
                if (lot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                    bean.lotList.push(lot.codiceLotto);
                }
            });
        }

        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/reject-list', bean, this.httpOptions);
    }

    approveLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/' + auctionLotCode + '/approve', undefined, this.httpOptions);
    }

    undoApproveLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionLotCode + '/' + auctionCode + '/undo_approve_request', undefined, this.httpOptions);
    }

    rejectLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/' + auctionLotCode + '/reject', undefined, this.httpOptions);
    }

    undoRejectLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionLotCode + '/' + auctionCode + '/undo_reject_request', undefined, this.httpOptions);
    }

    requestApproveLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/' + auctionLotCode + '/publish_request', undefined, this.httpOptions);
    }

    undoRequestApproveLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/' + auctionLotCode + '/unpublish_request', undefined, this.httpOptions);
    }

    deleteLot(auctionCode: string, auctionLotCode: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/lot/' + auctionLotCode + '/delete', this.httpOptions);
    }

    requestApproveLotList(auctionCode: string, lot_list: AuctionLotItem[]) {

        let bean = {
            lotList: []
        };

        if (lot_list) {
            lot_list.forEach(lot => {
                if (lot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_DRAFT) {
                    bean.lotList.push(lot.codiceLotto);
                }
            });
        }

        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/publish-request-list', bean, this.httpOptions);
    }

    undoRequestApproveLotList(auctionCode: string, lot_list: AuctionLotItem[]) {

        let bean = {
            lotList: []
        };

        if (lot_list) {
            lot_list.forEach(lot => {
                if (lot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                    bean.lotList.push(lot.codiceLotto);
                }
            });
        }

        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/lot/' + auctionCode + '/undo-publish-request-list', bean, this.httpOptions);
    }

    downloadTemplateExcelLots() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UPLOAD_PREFIX + ConstantsModule.API_PREFIX + '/document/template_xlsx', this.httpDownloadB64Options);
    }

    uploadExcelLots(doc: FileWithContentUpload) {
        const formData = new FormData();
        formData.append('file', doc.file);
        formData.append('codiceAsta', doc.codiceAsta);
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + doc.codiceAsta + '/lot/excel/create', formData, this.httpUploadOptions);
    }

    setLottoPagato(auctionCode: string, codiceLotto: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/lot/' + codiceLotto + '/set_pagato', undefined, this.httpOptions);
    }

    setLottoNonPagato(auctionCode: string, codiceLotto: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/lot/' + codiceLotto + '/set_non_pagato', undefined, this.httpOptions);
    }

    setLottoRitirato(auctionCode: string, codiceLotto: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/lot/' + codiceLotto + '/set_spedito', undefined, this.httpOptions);
    }

    setLottoNonRitirato(auctionCode: string, codiceLotto: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionCode + '/lot/' + codiceLotto + '/set_non_spedito', undefined, this.httpOptions);
    }

    createAuction(auction: AuctionCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/create', auction, this.httpOptions);
    }

    createAuctionTipology(tipology: AuctionTipologyCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/auction/tipology/create', tipology, this.httpOptions);
    }

    deleteAuctionTipology(id: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/auction/tipology/' + id + '/delete', this.httpOptions);
    }

    getAuctionSetupListByTipology(tipology: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/auction/' + tipology + '/setup', this.httpOptions);
    }

    deleteAuctionSetup(id: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/auction/setup/' + id + '/delete', this.httpOptions);
    }

    createAuctionSetup(auction_setup: AuctionSetupCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/setup/create', auction_setup, this.httpOptions);
    }

    updateAuctionSetup(auction_setup: any) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/setup/update', auction_setup, this.httpOptions);
    }

    getAuctionBetSetupByTipology(tipology: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/bet/' + tipology + '/list', this.httpOptions);
    }

    deleteBetSlot(id: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/bet/' + id + '/delete', this.httpOptions);
    }

    insertBetSlot(tipology: string, auction_bet: AuctionBetSetupCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + tipology + '/bet/create', auction_bet, this.httpOptions);
    }

    updateLot(auctionLot: AuctionLotUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionLot.codiceAsta + '/lot/' + auctionLot.codiceLotto + '/update', auctionLot, this.httpOptions);
    }

    insertLot(auctionLot: AuctionLotCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + auctionLot.codiceAsta + '/lot/create', auctionLot, this.httpOptions);
    }

    /*  insertLotAste(auctionLot: AuctionDettaglioItem,codiceAsta: string) {
         return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lot/create', auctionLot, this.httpOptions);
     } */


    insertLotAste(codiceAsta: string, auctionLot: AuctionDettaglioItem) {
        const apiUrl = `${ConstantsModule.API_HOST}${ConstantsModule.API_AUCTION_PREFIX}${ConstantsModule.API_PREFIX}/${codiceAsta}/lot/create`;
        return this.httpClient.post<any>(apiUrl, auctionLot, this.httpOptions);
    }

    updateLotAste(codiceAsta: string, codiceLotto: any, auctionLot) {
        const apiUrl = `${ConstantsModule.API_HOST}${ConstantsModule.API_AUCTION_PREFIX}${ConstantsModule.API_PREFIX}/${codiceAsta}/lot/${codiceLotto}/update`;
        return this.httpClient.put<any>(apiUrl, auctionLot, this.httpOptions);
    }

    makeOffer(codiceAsta: string, codiceLotto: string, actualPrice: number, myOffer: number): Observable<any> {

        let offer: MakeOfferRequest = {
            actualPrice: actualPrice,
            myOffer: myOffer
        };

        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lots/' + codiceLotto + '/offer', offer, this.httpOptions);
    }

    getAuctionRules(codiceAsta: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/rules/' + codiceAsta, this.httpOptions);
    }

    getServerTime() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/server_time', this.httpOptions);
    }

    getLotDetail(codiceAsta: string, codiceLotto: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lot/' + codiceLotto + '/details', this.httpOptions);
    }

    getAuctionLotsHistory(codiceAsta: string, lotId: number) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/' + codiceAsta + '/lot/' + lotId, this.httpOptions);
    }

}