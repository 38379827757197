<div class="content bottom80 auction-details p-4 my-3" *ngIf="auction">           
    <h2><span class="status completed" style="font-size: 32px;"><span translate>aste-vinte-dettaglio.asta</span>&nbsp;<span translate>aste-vinte-dettaglio.{{auction.statoAsta}}</span></span> {{auction.codiceAsta}} - {{auction.tipologiaAsta}}</h2>

    <div class="auctions-infos">
        <span translate>aste-vinte-dettaglio.l-asta-si-e-conclusa-il</span>&nbsp;<b>{{auction.dataOraFineAsta}}</b><br/>
        <b>{{auction.numLotti}}</b> lotti disponibili.
    </div>

    <div class="mt50" id='lots-list'>
        
        <dx-data-grid #dataGridLots
            id="gridUserChiuseContainer"
            [dataSource]="lot_list"
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="auction_lot_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowExporting]="true" [allowEditing]="false" [width]="100" caption="{{'aste-vinte-dettaglio.id' | translate}}" alignment="left" [visible]="false">                
            </dxi-column>
            <dxi-column dataField="codiceLotto" [width]="auto" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.codice-lotto' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="codiceAsta" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.codice-asta' | translate}}" dataType="string"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.data-creazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.tipo-prodotto' | translate}}" dataType="string" [visible]="true">
                <dxo-lookup [dataSource]="tipoProdotto_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="quantitaTon" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.quantità-ton' | translate}}" cellTemplate="quantityTemplate" dataType="number" [visible]="true">                    
            </dxi-column>
            <dxi-column dataField="numeroRilanci" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.numero-rilanci' | translate}}" dataType="number" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="areaGeografica" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.area-geografica' | translate}}" dataType="string" [visible]="true">
                <dxo-lookup [dataSource]="geoArea_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="note" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.note' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.chiusura-asta' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" [allowExporting]="true" caption="{{'aste-vinte-dettaglio.data-disponibilità-lotto' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="prezzoBase" [allowExporting]="true" cellTemplate="basePriceTemplate" caption="{{'aste-vinte-dettaglio.base-d-asta' | translate}}" dataType="number" [visible]="true">
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" [allowExporting]="true" cellTemplate="finalPriceTemplate" caption="{{'aste-vinte-dettaglio.prezzo-finale' | translate}}" dataType="number" [visible]="true">
            </dxi-column>

            <dxo-master-detail [enabled]="true" template="lotDetail"></dxo-master-detail>

            <div *dxTemplate="let lotDetail of 'lotDetail'">
                <div class="lots-list new-lots-list won" name='lot-item'>
                    <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
                </div>
            </div>

            <div *dxTemplate="let basePriceTemplate of 'basePriceTemplate'">
                <div>{{basePriceTemplate.data.prezzoBase}} &euro;</div>
            </div>
            <div *dxTemplate="let finalPriceTemplate of 'finalPriceTemplate'">
                <div>{{finalPriceTemplate.data.prezzoUltimoRilancio}} &euro;</div>
            </div>
            <div *dxTemplate="let quantityTemplate of 'quantityTemplate'">
                <div>{{quantityTemplate.data.quantitaTon}} t</div>
            </div>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>
            
        </dx-data-grid>

    </div>
</div>