<div class="p-3">
    <div class="add-item-section add-item-section-user border-0 mb-auto">
        <div class="add-operator-panel pb-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 class="fs24 gray-dark bold inter pb-2" *ngIf="firstButton === 'admin'" translate>dashboard-admin.Nuovo Admin</h3>
            <h3 class="fs24 gray-dark bold inter pb-2" *ngIf="secondButton === 'operator'" translate>dashboard-admin.Nuovo operatore Coripet</h3>
            <form id="form_createOperator" [formGroup]="addUserForm" class="form-group">
                <section class="step-section focused d-flex pb-4">
                    <div class="card flex-fill me-4">
                        <div class="d-flex">
                            <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('nome')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="nome" type="text" name="nome" id="nome"
                                        maxlength="75">
                                    <label translate>dashboard-admin.nome</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('cognome')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="cognome" type="text" name="cognome" id="cognome"
                                        maxlength="75">
                                    <label for="" translate>dashboard-admin.cognome</label>
                                </div>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('email')}">
                            <div class="input-wrapper full">
                                <input required formControlName="email" type="email" name="email" id="email"
                                    maxlength="75">
                                <label for="" translate>dashboard-admin.email</label>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('password')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="password" type="password" name="password"
                                        id="password" maxlength="75">
                                    <label for="" translate>dashboard-admin.password</label>
                                </div>
                                <span class="error"
                                    *ngIf="controlHasError('password')">{{getControlErrorMessage('password')}}</span>
                            </div>
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('confermaPassword')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="confermaPassword" type="password"
                                        name="confermaPassword" id="confermaPassword" maxlength="75">
                                    <label for="" translate>dashboard-admin.conferma-password</label>
                                </div>
                                <span class="error"
                                    *ngIf="controlHasError('confermaPassword')">{{getControlErrorMessage('confermaPassword')}}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill me-2 mb-4" [ngClass]="{'error': controlHasError('telefono')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="telefono" type="text" name="telefono" id="telefono"
                                        maxlength="75">
                                    <label for="" translate>dashboard-admin.telefono</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('mobile')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="mobile" type="text" name="mobile" id="mobile"
                                        maxlength="75">
                                    <label for="" translate>dashboard-admin.mobile</label>
                                </div>
                            </div>
                        </div>
                        <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('lingua')}">
                            <div class="input-wrapper full">
                                <select name="lingua" id="lingua" required formControlName="lingua"
                                    class="form-control">
                                    <option value="IT">Italy</option>
                                    <option value="EN">English</option>
                                </select>
                                <label for="" translate>dashboard-admin.lingua</label>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark inter">01</div>
                            <div class="label">
                                <span translate>dashboard-admin.Dati operatore</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="container add-operator-actions mt-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-lg-end text-center">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="salva()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <button class="add add-operator new-operator-toggler d-flex w-100 pointer mb-3 blue-dark" (click)="firstToogleButton()">
                    <article class="d-flex align-items-center w-100">
                        <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                        <div class="text fs18"><span translate>dashboard-admin.Aggiuni admin</span></div>
                    </article>
                </button>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
                <button class="add add-operator new-operator-toggler d-flex w-100 pointer mb-3 blue-dark" (click)="secondToogleButton()">
                    <article class="d-flex align-items-center w-100">
                        <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                        <div class="display">
                            <div class="text fs18 "><span translate>dashboard-admin.Aggiungi operatore</span></div>
                            <span><img src="assets/img/coripet.png" alt="" style="width: 90px; height: 25px;"></span>
                        </div>
                    </article>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="px-3">
    <hr>
</div>

<div class="p-4"> 
    <h2 class="fs28 gray-dark inter bold mb-4" translate>dashboardPage.dashboard</h2>
    <dx-accordion
        #accordion
        [dataSource]="pivot_accordion"
        [collapsible]="true"
        [multiple]="false"
        [animationDuration]="300"
        [selectedIndex]="undefined"
        (onItemClick)="bindPivotChart()"
        id="accordion-container">

        <div *dxTemplate="let item of 'title'">
            <div class="header">{{ item }}</div>
        </div>
        <div *dxTemplate="let item of 'item'">

            <div id="pivotgrid">
                <dx-chart id="sales-chart">
                    <dxo-common-series-settings type="bar"></dxo-common-series-settings>
                    <dxo-size [height]="200"></dxo-size>
                    <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
                    <dxo-tooltip
                        [enabled]="true"
                        format="currency"
                        [customizeTooltip]="customizeTooltip"
                    ></dxo-tooltip>
                </dx-chart>
        
                <dx-pivot-grid
                    id="pivotgrid"
                    [allowSortingBySummary]="true"
                    [allowFiltering]="true"
                    [showBorders]="true"
                    [allowSortingBySummary]="true"
                    [showColumnGrandTotals]="false"
                    [showRowGrandTotals]="false"
                    [showRowTotals]="false"
                    [showColumnTotals]="false"
                    [dataSource]="pivotGridDataSource"
                    #pivotGrid
                >
                    <dxo-field-chooser [enabled]="true" [height]="400"></dxo-field-chooser>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                </dx-pivot-grid>
            </div>

        </div>
    </dx-accordion>

    <h2 class="fs28 gray-dark inter bold mt-5" translate>dashboardPage.aste-disponibili</h2>
    <div class="mt-5" id="aste-disponibili">
        <dx-data-grid id="gridContainer" 
            [dataSource]="auctions_list" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowClick)="onRowClick($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}"
                dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string"
                [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}"
                dataType="string" [visible]="true">
                <dxo-lookup [dataSource]="tipology_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string"
                [visible]="true" cellTemplate="statusTemplate">
                <dxo-lookup [dataSource]="status_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="daValidare" [width]="120" caption="{{ 'aste.da-validare' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="daValidareTemplate">
            </dxi-column>

            <div *dxTemplate="let daValidareTemplate of 'daValidareTemplate'">
                <div class="status-alert-new" data-label="Lotti"
                    *ngIf="daValidareTemplate.data.sum_lots_to_publish > 0">
                    <b>{{daValidareTemplate.data.sum_lots_to_publish}}</b>&nbsp;lotti
                </div>
                <div class="" data-label="Lotti" *ngIf="daValidareTemplate.data.sum_lots_to_publish == 0">
                    -
                </div>
            </div>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="stato-asta status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>
            
            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

        </dx-data-grid>
    </div>

    <h2 class="fs28 gray-dark inter bold" translate>dashboardPage.aste-in-bozza</h2>
    <div class="mt-5" id="auctions-list">
        <dx-data-grid id="gridContainer" 
            [dataSource]="bozza_List" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowClick)="onRowClick($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}"
                dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string"
                [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}"
                dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-end-pubblica">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="tipologia-pubblica">
                <dxo-lookup [dataSource]="tipology_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string"
                [visible]="true" cellTemplate="statusTemplate">
                <dxo-lookup [dataSource]="status_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

        </dx-data-grid>
    </div>

    <div class="col-12">
        <div class="mt40 activities bottom50">
            <article>
                <h2 translate>dashboardPage.attivita</h2>
                <ul class="activities-list" id="activities-list">
                    <li *ngFor="let activity of activities_list; let i = index">
                        <div class="date">{{activity.createDate}}</div>
                        <div class="description font-a-size" *ngIf="isAdmin()">
                            <a
                                [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                                &nbsp;<span translate>activity.e-stato</span>&nbsp;<span translate>activity.{{activity.operazione}}</span>&nbsp;<span translate>activity.{{activity.desinenza}}</span>&nbsp;<a
                                [routerLink]="['/admin-aziende.html/', activity.idOrganization]"><span translate>activity.lista-operatori</span></a>&nbsp;<span translate>activity.dell-azienda</span>&nbsp;<a
                                [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                        </div>
                    </li>
                </ul>
            </article>
        </div>
    </div>

</div>