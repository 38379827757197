import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionDettaglioItem, AuctionItem, AuctionLotGeoArea, AuctionLotItem, AuctionLotStatus, AuctionLotTipoProdotto, CentroSelezioneItem, FileWithContentUpload, NazioneItem, OrganizationItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DxDataGridComponent } from 'devextreme-angular';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-coripet-asta-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})
export class CoripetAstaDettaglioComponent extends APIService implements OnInit, OnDestroy {

  showPopup: boolean = false;
  saveButtonOptions: any;
  closeButtonOptions: any;
  backButtonOptions: any;
  documentoAzienda: FileWithContentUpload;
  lot_statuses: AuctionLotStatus[] = [];
  geoArea_list: AuctionLotGeoArea[] = [];
  tipoProdotto_list: AuctionLotTipoProdotto[] = [];
  private subscriptions: Subscription[] = [];
  full_organizations: OrganizationItem[] = [];

  @ViewChild("inputFile", { static: false }) inputFile: ElementRef;
  @ViewChild("dataGridLots", { static: false }) dataGridLots: DxDataGridComponent;
  @ViewChild("dataGridLotsForExport", { static: false }) dataGridLotsForExport: DxDataGridComponent;  
  
  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public organizationService: OrganizationService) {
    super(localUtil, location, router);
    console.log('CoripetAstaDettaglioComponent()');
    
    this.lot_statuses = this.localUtil.getStatoLottoList();
    this.geoArea_list = this.localUtil.getGeoAreaList();
    this.tipoProdotto_list = this.localUtil.getTipoProdottoList();

    this.openPopup = this.openPopup.bind(this);
    const that = this;
    this.saveButtonOptions = {
      text: 'Aggiungi',
      stylingMode: 'contained',
      width: '200',
      type: 'default',
      onClick() {
        that.save();
      }
    },
      this.closeButtonOptions = {
        text: 'Annulla',
        width: '200',
        onClick() {
          that.showPopup = false;
        }
      },
      this.backButtonOptions = {
        text: 'Indietro',
        width: '200',
        onClick() {
          that.indietro();
        }
      }

    this.auctionDettaglioForm = new UntypedFormGroup({
      codiceLotto: new UntypedFormControl(this.auctionDettaglio.codiceLotto, [Validators.required]),
      codiceProdotto: new UntypedFormControl(this.auctionDettaglio.codiceProdotto, [Validators.required]),
      tipoProdotto: new UntypedFormControl(this.auctionDettaglio.tipoProdotto, [Validators.required]),
      areaGeografica: new UntypedFormControl(this.auctionDettaglio.areaGeografica, [Validators.required]),
      quantitaTon: new UntypedFormControl(this.auctionDettaglio.quantitaTon, [Validators.required]),
      dataDisponibilitaLotto: new UntypedFormControl(this.auctionDettaglio.dataDisponibilitaLotto, [Validators.required]),
      note: new UntypedFormControl(this.auctionDettaglio.note),
      pesoMedia: new UntypedFormControl(this.auctionDettaglio.pesoMedio),
      prezzoBase: new UntypedFormControl(this.auctionDettaglio.prezzoBase, [Validators.required]),
      schedaTecnica: new UntypedFormControl(this.auctionDettaglio.schedaTecnica)

    })
  }

  public Editor = ClassicEditor;
  auctionDettaglio: AuctionDettaglioItem = new AuctionDettaglioItem();
  auctionDettaglioForm: UntypedFormGroup;
  submitted: boolean = false;
  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  lot_list_for_export: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];

  excelLotti: FileWithContentUpload;
  flagExcelLotti: boolean;

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  asteDetails: any[] = [];
  ngOnInit(): void {
    this.asteDetails = this.centroSelezione;
    console.log(this.asteDetails);
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' + this.codiceAsta);
    this.listOrganizations();
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, false);
    this.loadMaterialType();
    this.loadAreaGeo();
  }

  editDetails: string;
  grid: boolean = true;
  form: boolean = false;
  button: boolean = false;
  quantita: any = '';
  mandatory: boolean = false;
  centroSelezione: any[] = [];
  codice: any;

  click(e) {
    this.grid = false;
    this.form = true;
    this.button = true;
    console.log(e);
    this.editDetails = e.data.codice + e.data.nome + e.data.indirizzo;
    this.codice = e.data.codice;
  }

  indietro() {
    this.grid = true;
    this.form = false;
    this.button = false;
    this.mandatory = false;
  }

  save() {
    if (this.quantita === '') {
      this.mandatory = true;
    } else {
      console.log("API Calling");
      this.mandatory = false;
      this.centroSelezione.push({
        "codiceCentroSelezione": this.codice,
        "quantitaTon": this.quantita
      });
      console.log(this.centroSelezione);
      this.form = false;
      this.grid = true;
      this.button = false;
      this.showPopup = false;
      this.quantita = '';
    }
  }

  rimuovi(index: number) {
    if (index >= 0 && index < this.asteDetails.length) {
      this.asteDetails.splice(index, 1);
      //sessionStorage.setItem('aste-details', JSON.stringify(this.asteDetails));
    }
  }

  type_material: any[] = [];
  loadMaterialType() {
    const service = this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.type_material = data.list;
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  geo_area: any[] = [];
  loadAreaGeo() {
    const service = this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.geo_area = data.list;
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  loadNazioni() {
    const service = this.commonService.getNationsList().subscribe(
      data => {
        this.nation_list = data.list;
      });
    this.subscriptions.push(service);
  }

  loadCentriSelezione() {
    const service = this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
      });
    this.subscriptions.push(service);
  }

  getLotByCode(codiceLotto: string) {
    let output = undefined;
    if (this.lot_list != undefined) {
      this.lot_list.forEach((lotElem: any) => {
        if (lotElem.codiceLotto == codiceLotto) {
          output = lotElem;
        }
      });
    }
    return output;
  }

  gotoBack() {
    this.location.back();
  }

  loadAuctionDetails(textFilter, onlywon) {

    let qparam = '';

    const service = this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
      (data: any) => {
        this.auction = data.auctionDetail;
        
        if (data.lotList != undefined) {
          this.auction.numLotti = data.lotList.length;
          

          if (this.auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE) {
            this.manageAllOtherAuction(data.lotList);
          } else {
            this.manageAllOtherAuction(data.lotList);
          }


          console.log('lot_list: ' + this.lot_list.length);
        }
      });
      this.subscriptions.push(service);
  }

  manageAvailableAuction(lotList) {
    console.log('manageAvailableAuction()');
    this.manageAllOtherAuction(lotList);
  }

  manageAllOtherAuction(lotList) {
    this.lot_list = [];
    console.log('manageAllOtherAuction()');
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

        if (
          (this.daapprovare && item.statoLotto == "In Pubblicazione") ||
          (this.approvati && item.statoLotto == "Pubblicato") ||
          (this.rifiutati && item.statoLotto == "Rifiutato") ||
          (this.bozza && item.statoLotto == "Bozza")
        ) {
          this.lot_list.push(item);
        }

      });      
    }

  }

  daapprovare: boolean = true;
  approvati: boolean = true;
  rifiutati: boolean = true;
  bozza: boolean = true;
  filterCheckBox() {
    this.loadAuctionDetails(undefined, false);
  }

  onStartEditing(e) {
    console.log('onStartEditing()');
  }

  gotoListaAsteChiuse() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_CHIUSE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
    return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note: string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;
  }

  chiudi() {
    //this.passBack(undefined);
    //this.activeModal.close();
    //this.modalService.dismissAll();
  }

  openPopup() {
    this.showPopup = true;
    //const modalRef = this.modalService.open(PopupComponent);
    //modalRef.componentInstance.tipo = 'centri-di-selezione';
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
      lotElem.centroSelezione.forEach(csLottoElem => {
        let item = new CentroSelezioneItem();
        item.quantitaTon = lotElem.quantitaTon;
        item.indirizzo = '';
        item.pesoMedioCarico = '';

        let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
        if (cs != undefined) {

          let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
          console.log('nazione: ' + JSON.stringify(nazione));
          let nazioneDescr = '';
          if (nazione != null && nazione != undefined && nazione != '') {
            nazioneDescr = nazione.descrizione;
            if (nazione.codice == 'IT') {
              nazioneDescr = 'Italia';
            }
            item.nazioneDescr = nazioneDescr;
          }
        }
        item.CER = cs.CER;
        item.cap = cs.cap;
        item.citta = cs.citta;
        item.provincia = cs.provincia;
        item.pesoMedioCarico = cs.pesoMedioCarico;
        item.nome = cs.nome;
        item.indirizzo = cs.indirizzo;
        csListPopup.push(item);
      });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  requestApproveAllLots() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.requestApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);
  }

  undoRequestApproveAllLots() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.undoRequestApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);
  }

  downloadTemplateExcelLotti() {

    const service = this.auctionService.downloadTemplateExcelLots().subscribe(
      b64 => {
        this.auctionService.downloadFileB64('template-excel-lots.xlsx', 'application/vnd.ms-excel', b64);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  //need to check this because i have changed the documentoAzienda to excelLotti
  UploadExcelLottiFile(event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
    if (file != undefined) {
      console.log(file);
      this.excelLotti = new FileWithContentUpload();
      this.excelLotti.file = file;
      console.log('getExcelLottiFile() loaded file: ' + file.name);
      this.caricaExcelLotti();
    } else {
      this.excelLotti = undefined;
    }
  }


  getExcelLottiFile(file) {
    if (file != undefined) {
      console.log(file);
      this.documentoAzienda = new FileWithContentUpload();
      this.documentoAzienda.file = file;
      console.log('getExcelLottiFile() loaded file: ' + file.name);
    } else {
      this.excelLotti = undefined;
    }
  }


  caricaExcelLotti() {
    console.log('caricaExcelLotti()');
    console.log(this.excelLotti);

    if (this.excelLotti != undefined && this.excelLotti != undefined) {

      this.excelLotti.contentType = 'FWK';
      this.excelLotti.codiceAsta = this.auction.codiceAsta;

      const service = this.auctionService.uploadExcelLots(this.excelLotti).subscribe(
        data => {
          console.log(data);
          data = JSON.parse(data);
          console.log('excelLotti uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.ngOnInit();
        }, this.handleError(true));
      this.subscriptions.push(service);

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);
    }

  }




  uploadDocument() {
    this.documentoAzienda.contentType = 'LOT';
    this.documentoAzienda.codiceLotto = this.auctionDettaglioForm.get('codiceLotto').value;

    const service = this.commonService.documentUpload(this.documentoAzienda).subscribe(dt => {
      this.createResponseDetails.forEach(data => {
        let updateRequest = {
          "areaGeografica": data.areaGeografica,
          "centroSelezione": data.centroSelezione,
          "codiceAsta": data.codiceAsta,
          "codiceLotto": data.codiceLotto,
          "codiceProdotto": data.codiceProdotto,
          "codiceRifiutoCER": data.codiceRifiutoCER,
          "createDate": data.createDate,
          "dataDisponibilitaLotto": data.dataDisponibilitaLotto,
          "dataOraFineAstaEffettiva": data.dataOraFineAstaEffettiva,
          "id": data.id,
          "idOrganization": data.idOrganization,
          "modifiedDate": data.modifiedDate,
          "note": data.note,
          "numeroRilanci": data.numeroRilanci,
          "pagato": data.pagato,
          "prezzoBase": data.prezzoBase,
          "prezzoUltimoRilancio": data.prezzoUltimoRilancio,
          "quantitaTon": data.quantitaTon,
          "schedaTecnica": JSON.parse(dt).file_uuid,
          "spedito": data.spedito,
          "statoLotto": data.statoLotto,
          "tipoProdotto": data.tipoProdotto
        }
        const service = this.auctionService.updateLotAste(this.auction.codiceAsta, data.codiceLotto, updateRequest).subscribe(dt => {
        }, this.handleError(true));
        this.subscriptions.push(service);
      });
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("LOT_CREATE"), true);
      this.loadAuctionDetails(undefined, false);
      this.auctionDettaglio.newEditing = true;
    }, this.handleError(true));
    this.subscriptions.push(service);
  }

  loadExcelLotti() {

    console.log('caricaExcelLotti()');
    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("EXCEL_LOTS_TITLE");
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'excelLotti';
    modalRef.componentInstance.codiceAsta = this.auction.codiceAsta;
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        console.log('carica da popup return');

      }
    }, this.handleError(true));
    this.subscriptions.push(service);
  }

  isNewEditing() {
    return (this.auctionDettaglio.newEditing == true);
  }

  toggleNuovoOperatore() {
    this.auctionDettaglio.newEditing = !this.auctionDettaglio.newEditing;
    if (this.auctionDettaglio.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.auctionDettaglioForm.markAllAsTouched();
  }

  date = new Date();
  pipe = new DatePipe('en-US');
  firstSection: boolean = true;
  secondSection: boolean = false;
  createResponseDetails: any[] = [];
  salvaAuctionDettaglio() {
    if (this.validate()) {
      let newOp: AuctionDettaglioItem = new AuctionDettaglioItem();
      let auctionDettaglioForm = this.pipe.transform(this.auctionDettaglioForm.get('dataDisponibilitaLotto').value, 'dd/MM/yyyy');
      console.log(auctionDettaglioForm);
      newOp = {
        codiceRifiutoCER: "",
        prezzoBase: this.auctionDettaglioForm.get('prezzoBase').value,
        pesoMedio: "",
        schedaTecnica: "",
        codiceLotto: this.auctionDettaglioForm.get('codiceLotto').value,
        codiceProdotto: this.auctionDettaglioForm.get('codiceProdotto').value,
        tipoProdotto: this.auctionDettaglioForm.get('tipoProdotto').value,
        areaGeografica: this.auctionDettaglioForm.get('areaGeografica').value,
        quantitaTon: this.auctionDettaglioForm.get('quantitaTon').value,
        dataDisponibilitaLotto: auctionDettaglioForm,
        note: this.auctionDettaglioForm.get('note').value,
        centroSelezione: this.centroSelezione,
        // codiceAsta:this.auction.codiceAsta,
      }
      const service = this.auctionService.insertLotAste(this.auction.codiceAsta, newOp).subscribe(dt => {
        console.log(dt.detail);
        this.createResponseDetails.push(dt.detail);
        console.log(this.createResponseDetails);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("LOT_CREATE"), true);
        this.firstSection = false;
        this.secondSection = true;
        this.handleError(true);
      }, this.handleError(true));
      this.subscriptions.push(service);
    }
  }

  annullaNuovoOperatore() {
    this.auctionDettaglio = new AuctionDettaglioItem();
    this.auctionDettaglio.newEditing = false;
  }

  controlHasError(nome) {
    return !this.auctionDettaglioForm.get(nome).valid && this.submitted;
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.auctionDettaglioForm);
    Object.keys(this.auctionDettaglioForm.controls).forEach(key => {
      if (!this.auctionDettaglioForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  selezionaExcelLotti() {
    this.inputFile.nativeElement.click();
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    console.log('onExporting()');
    this.exportData(e);    
  }

  exportData(e) {
    console.log('exportData()');
    try {
      this.dataGridLotsForExport.visible=true;
      if (e.format === 'xlsx') {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datagrid');
        
        exportDataGrid({
          component: this.dataGridLotsForExport.instance,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datagrid.xlsx');
            this.dataGridLotsForExport.visible=false;
          });
        });
      } else if (e.format === 'csv') {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(e.fileName);
        exportDataGrid({
            component: this.dataGridLotsForExport.instance,
            worksheet: worksheet
        }).then(function() {
            workbook.csv.writeBuffer().then(function(buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
                this.dataGridLotsForExport.visible=false;
            });
        });
      } else  if (e.format === 'pdf') {
        const doc = new jsPDF();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            component: this.dataGridLotsForExport.instance
        }).then(() => {
            doc.save(e.fileName + '.pdf');
            this.dataGridLotsForExport.visible=false;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  onExportingHideColumn(e) {
    console.log('onExportingHideColumn()');
    e.component.beginUpdate();
    for (let i=0; i< this.dataGridLotsForExport.instance.columnCount(); i++) {
      if (this.dataGridLotsForExport.instance.columnOption(i).type != 'buttons' && this.dataGridLotsForExport.instance.columnOption(i).dataField != undefined) {
        this.dataGridLotsForExport.instance.columnOption(i, 'visible', true);
      }
    }
    //this.dataGridLotsForExport.instance.cellValue(0, "ragioneSociale", 'prova indirizzo cs');
  }

  onExportedHideColumn(e) {
    console.log('onExportedHideColumn()');
    window.location.reload();
  }

  onExported(e) {
    //window.location.reload();
  }

  prepareExportGrid(e) {
    console.log('prepareExportGrid()');
    this.lot_list_for_export = [];
    let lot: AuctionLotItem;
    for (lot of this.lot_list) {
      if (lot.centroSelezione != undefined && lot.centroSelezione.length > 0) {
        let riga: number = 0;
        for (const csLot of lot.centroSelezione) {
          let newAuctionLot: AuctionLotItem = JSON.parse(JSON.stringify(lot));
          riga++;
          let csAnag = this.localUtil.getSelectionCenterByCodice(csLot.codiceCentroSelezione, this.cs_list);
          let winnerAnag = this.localUtil.getWinnerOrganizationById(newAuctionLot.idOrganization, this.full_organizations);
          
          if (csAnag != undefined && winnerAnag != undefined) {
            newAuctionLot.riga=riga;
            newAuctionLot.codiceAzienda = winnerAnag.codiceAzienda;
            newAuctionLot.centroSelezione = [csLot];
            newAuctionLot.centroSelezione[0].codiceEsterno = csAnag.codiceEsterno;
            this.lot_list_for_export.push(newAuctionLot);
          }
        }
      }
    }
    setTimeout( () => {
      this.exportHideGrid(e);
    }, 1500);
  }

  exportHideGrid(e) {
    console.log('exportHideGrid()');
    console.log(e);
    this.exportData(e);
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

  listOrganizations(orgname ? : string, isInEditMode ? : boolean) {
    const listService = this.organizationService.getOrganizationList().subscribe(
        data => {
            if (data?.list) {
                this.full_organizations = data.list;                
            }
        },
        this.handleError(true)
    );
    this.subscriptions.push(listService);
  }

}
