import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CommonsComponent } from "./commons.component";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

/*COMMON COMPONENTS*/
import { UploadFideiussioneComponent } from './components/upload-fideiussione/upload-fideiussione.component';
import { UploadLegaleRappresentanteComponent } from './components/upload-legale/upload-legale.component';
import { UploadModOrgComponent } from './components/upload-mod-org/upload-mod-org.component';
import { UploadVisuraComponent } from './components/upload-visura/upload-visura.component';
import { FooterComponent } from "./components/footer/footer.component";
import { HeadComponent } from "./components/head/head.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoginComponent } from "./components/login/login.component";
import { PopupComponent } from "./components/popup/popup.component";
import { ConfirmComponent } from "./components/confirm/confirm.component";
import { SpallaComponent } from "./components/spalla/spalla.component";
import { DettaglioLottoComponent } from "./components/dettaglio-lotto/dettaglio-lotto.component";
import { DettaglioAziendaImpiantoComponent } from "./components/dettaglio-azienda-impianto/dettaglio-azienda-impianto.component";
import { DettaglioAziendaOperatoreComponent } from "./components/dettaglio-azienda-operatore/dettaglio-azienda-operatore.component";
import { FileBoxComponent } from "./components/file-box/file-box.component";
import { AnagraficaAziendaComponent } from "./components/anagrafica-azienda/anagrafica-azienda.component";
import { CentriDiSelezioneComponent } from "./components/centri-di-selezione/centri-di-selezione.component";
import { SetupPropertiesComponent } from "./components/setup-properties/setup-properties.component";
import { SetupBetComponent } from "./components/setup-bet/setup-bet.component";
import { ElencoOperatoriComponent } from "./components/elenco-operatori/elenco-operatori.component";
import { ElencoImpiantiComponent } from "./components/elenco-impianti/elenco-impianti.component";
import { ElencoOperatoriFullComponent } from "./components/elenco-operatori-full/elenco-operatori-full.component";
import { AziendeComponent } from "./components/aziende/aziende.component";
import { ElencoLottiComponent } from "./components/elenco-lotti/elenco-lotti.component";
import { ElencoCsLottoComponent } from "./components/elenco-cs-lotto/elenco-cs-lotto.component";
import { NextAuctionCountdownComponent } from "./components/next-auction-countdown/next-auction-countdown.component";
import { ActiveAuctionBannerComponent } from "./components/active-auction-banner/active-auction-banner.component";
import { TimerComponent } from "./components/timer/timer.component";
import { CommonModule } from "@angular/common";
import { HttpLoaderFactory } from "../app.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxPivotGridModule, DxChartModule, DxTemplateHost, NestedOptionHost, DxPopupModule } from 'devextreme-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FlatpickrModule } from "angularx-flatpickr";


const COMMONS_ROUTES: Routes = []

@NgModule({
  declarations: [
    FooterComponent,
    HeadComponent,
    HeaderComponent,
    LoaderComponent,
    LoginComponent,
    PopupComponent,
    ConfirmComponent,
    SpallaComponent,
    DettaglioLottoComponent,
    DettaglioAziendaImpiantoComponent,
    DettaglioAziendaOperatoreComponent,
    FileBoxComponent,
    AnagraficaAziendaComponent,
    UploadVisuraComponent,
    UploadLegaleRappresentanteComponent,
    UploadModOrgComponent,
    UploadFideiussioneComponent,
    CentriDiSelezioneComponent,
    SetupPropertiesComponent,
    SetupBetComponent,
    ElencoOperatoriComponent,
    ElencoImpiantiComponent,
    ElencoOperatoriFullComponent,
    AziendeComponent,
    ElencoLottiComponent,
    ElencoCsLottoComponent,
    NextAuctionCountdownComponent,
    ActiveAuctionBannerComponent,
    TimerComponent
  ],
  exports: [
    FooterComponent,
    HeadComponent,
    HeaderComponent,
    LoaderComponent,
    LoginComponent,
    PopupComponent,
    ConfirmComponent,
    SpallaComponent,
    DettaglioLottoComponent,
    DettaglioAziendaImpiantoComponent,
    DettaglioAziendaOperatoreComponent,
    FileBoxComponent,
    AnagraficaAziendaComponent,
    UploadVisuraComponent,
    UploadLegaleRappresentanteComponent,
    UploadModOrgComponent,
    UploadFideiussioneComponent,
    CentriDiSelezioneComponent,
    SetupPropertiesComponent,
    SetupBetComponent,
    ElencoOperatoriComponent,
    ElencoImpiantiComponent,
    ElencoOperatoriFullComponent,
    AziendeComponent,
    ElencoLottiComponent,
    ElencoCsLottoComponent,
    NextAuctionCountdownComponent,
    ActiveAuctionBannerComponent,
    TimerComponent,
    CentriDiSelezioneComponent,
  ],
  bootstrap: [CommonsComponent], 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxTemplateModule,
    DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxPivotGridModule, DxChartModule,
    DxPopupModule,
    CKEditorModule,
    RouterModule.forChild(COMMONS_ROUTES), 
    FlatpickrModule.forRoot(),   
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ], 
  providers: [provideHttpClient(withInterceptorsFromDi()), DxTemplateHost, NestedOptionHost],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],  
})
export class CommonsModule { }