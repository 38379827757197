<div id="upload-legale" class="content top100 p-4 my-3">
    
    <section class="step-section focused d-flex pb-4">
        <div class="card flex-fill me-3">
            <h2 class="fs28 inter bold gray-dark"><span translate>upload-documents.document</span>&nbsp;<span translate>upload-documents.rappresentante-legale</span></h2>
            <div class="doc-datas doc-form-upload d-flex justify-content-center p-3 ">
                <app-file-box [uuid]="existsDocument != undefined ? existsDocument.uuid : undefined" [status]="existsDocument != undefined ? existsDocument.status : ''" docname="rappresentante-legale.pdf" (file)="getLegaleRappresentanteFile($event)" [showError]="false" [showCoripetButtons]="false"></app-file-box>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper lh-1">
                <div class="number fs32 bold gray-dark inter">01</div>
                <div class="label">
                    Rappresentante legale
                </div>
            </div>
        </div>
    </section>
    <div class="main-actions pb-5 text-lg-end text-center">
        <a (click)="back()" class="btn"><span translate>upload-documents.annulla</span></a>
        <button class="btn primary btn-primary" (click)="uploadLegale()" id="btn_fileUpload" data-sector="legale"><span translate>upload-documents.invia</span></button>
    </div>
</div>