import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';

@Component({
  selector: 'app-admin-centri-di-selezione',
  templateUrl: './centri-di-selezione.component.html',
  styleUrls: ['./centri-di-selezione.component.css']
})
export class AdminCentriDiSelezioneComponent extends APIService {

  constructor(public localService: LocalUtilService,
    location: Location, 
    router: Router) {
    super(localService, location, router);
  }

}

