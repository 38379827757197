<div class="p-4">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>aste.aste</h2>
    <div id="auctions-list">
        <dx-data-grid #dataGridAste 
            id="gridContainer" 
            [dataSource]="auctions_list" 
            keyExpr="codiceAsta"
            [allowColumnReordering]="true" 
            [allowColumnResizing]="true" 
            [showBorders]="true"
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowExpanding)="onRowExpanding($event)"
            (onRowInserting)="onRowInsert($event)"
            (onContentReady)="onContentReady($event)" 
            (onRowClick)="onRowClick($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"
                placeholder="{{'search'|translate}}">
            </dxo-search-panel>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            
            
            <dxi-column dataField="id" [allowEditing]="false" [formItem]="{ visible: false }"
                caption="{{'aste.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'aste.userId' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste.data-creazione' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste.data-modifica' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste.codice-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" [width]="180" dataType="string" [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataDisponibilita" caption="{{'aste.data-visibilità-a-coripet' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'aste.data-pubblicazione-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'aste.data-inizio-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'aste.data-fine-asta' | translate}}" dataType="datetime"
                format="dd/MM/yyyy HH:mm" [visible]="true" >
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'aste.stato-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="statusTemplate" cssClass="stato-asta">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'aste.tipologia' | translate}}" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="tipology_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="daValidare" [width]="120" caption="{{'aste.da-validare' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="daValidareTemplate">
            </dxi-column>

            <!--dxo-toolbar>
                <dxi-item location="before">
                    <div *dxTemplate>
                        <div class="mt-2">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="disponibile" class="filter"
                                    value="disponibile" [(ngModel)]="disponibile" (click)="clickCheckBox()">
                                <label class="form-check-label" for="disponibile" translate>Disponibile</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="pubblica" class="filter"
                                    value="pubblica" [(ngModel)]="pubblica" (click)="clickCheckBox()">
                                <label class="form-check-label" for="pubblica" translate>Pubblica</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="incorso" class="filter"
                                    value="incorso" [(ngModel)]="incorso" (click)="clickCheckBox()">
                                <label class="form-check-label" for="incorso" translate>In corso</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="conclusa" class="filter"
                                    value="conclusa" [(ngModel)]="conclusa" (click)="clickCheckBox()">
                                <label class="form-check-label" for="conclusa" translate>Conclusa</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="annullate" class="filter"
                                    value="annullate" [(ngModel)]="annullate" (click)="clickCheckBox()">
                                <label class="form-check-label" for="annullate" translate>Annullate</label>
                            </div>
                        </div>
                    </div>
                </dxi-item>
                <dxi-item location="before" name="searchPanel"></dxi-item>
                <dxi-item name="exportButton"></dxi-item>
            </dxo-toolbar-->

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let codiceAstaTemplate of 'codiceAstaTemplate'">
                <div class="codice-asta">{{codiceAstaTemplate.data.codiceAsta}}</div>
            </div>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="stato-asta status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

            <div *dxTemplate="let daValidareTemplate of 'daValidareTemplate'">
                <div class="da-validare status-alert-new" data-label="Lotti"
                    *ngIf="daValidareTemplate.data.sum_lots_to_publish > 0">
                    <b>{{daValidareTemplate.data.sum_lots_to_publish}}</b>&nbsp;lotti
                </div>
                <div class="" data-label="Lotti" *ngIf="daValidareTemplate.data.sum_lots_to_publish == 0">
                    -
                </div>
            </div>

            <dxo-master-detail [enabled]="false" template="detailAuction"></dxo-master-detail>

            <div *dxTemplate="let detailAuction of 'detailAuction'">
                <dx-form [(formData)]="detailAuction.data">
                    <dxi-item itemType="group" caption="Lista lotti" [colSpan]="20" cssClass="detail-form">
                        <app-elenco-lotti [lots]="detailAuction.data.lots" [codiceAsta]="detailAuction.data.codiceAsta"
                            (monitorEvent)="raisedEvents($event, detailAuction.data)"></app-elenco-lotti>
                    </dxi-item>
                </dx-form>
            </div>

            <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="false" mode="form">

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>