import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.css'
})
export class PageNotFoundComponent {

  constructor(private localUtil: LocalUtilService,
              private router: Router
  ) {
        console.log('route not found: ' + router.getCurrentNavigation().finalUrl);
        if (this.localUtil.isUser()) {
          this.router.navigate([ConstantsModule.REF_AZ_DASHBOARD_PATH, {}]);
        } else if (this.localUtil.isCoripet()) {
          this.router.navigate([ConstantsModule.REF_CORIPET_DASHBOARD_PATH, {}]);
        } else if (this.localUtil.isAdmin()) {
          this.router.navigate([ConstantsModule.REF_ADMIN_DASHBOARD_PATH, {}]);
        }
  }


}
