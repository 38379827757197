
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AreaGeograficaItem, AuctionBetSetupItem, AuctionBoxTimer, AuctionItem, AuctionLotFavElem, AuctionLotItem, AuctionLotTimerItem, AuctionSetupItem, CentroSelezioneItem, GetAuctionLotsResponse, GetAuctionLotUserFavouriteResponse, GetSelectionCenterListResponse, HeaderDetail, MaterialTypeItem, MatRule, SchedaTecnicaItem, SliderValue } from 'src/app/models/dataModels';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { PageVisibilityService } from 'src/app/services/PageVisibility.service';
import { WindowFocusService } from 'src/app/services/WindowFocus.service';

@Component({
  selector: 'app-aste-in-corso-box',
  templateUrl: './aste-in-corso-box.component.html',
  styleUrls: ['./aste-in-corso-box.component.css'],
  encapsulation: ViewEncapsulation.None, // Add this line

  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AsteInCorsoBoxComponent extends APIService implements OnInit, OnDestroy {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    private organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
    public httpClient: HttpClient,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public pageVisibilityService: PageVisibilityService,
    public windowFocusService: WindowFocusService) {
    super(localService, location, router);

  }

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 30,
    columnWidth: 200,
    resize: true
  };

  trackByFn(index, item) {
    return item.codiceLotto;
  }

  auctionReloadLotsMonitor: Subscription;
  timerCallbackMonitor: Subscription;

  area_list: AreaGeograficaItem[] = [];
  material_list: MaterialTypeItem[] = [];
  bet_list: AuctionBetSetupItem[] = [];

  codiceAsta: string;
  auctionDetail: AuctionItem;
  auctionLots: AuctionLotItem[] = [];
  auctionLotsFull: AuctionLotItem[] = [];
  favouriteLots: AuctionLotFavElem[] = [];
  scheda_list: SchedaTecnicaItem[] = [];
  auction_configuration: AuctionSetupItem;

  minimumBetSlot: number;
  toggleFilterFavourite: boolean = false;
  betSlotLotto = new Map();

  //reload button vars
  reloadEnabled: boolean = true;

  //refresh 15 sec var
  secondsToRefresh: number;

  isTaglioRilancioLibero: boolean = false;

  //search header
  searchLotText: string;

  //navbar sticky
  navbarfixed: boolean = false;

  //headerDetail
  headerDetail: HeaderDetail = new HeaderDetail();

  enabled_materials: string[] = ['PET-Azzurrato', 'PET-Colorato', 'PET-Trasparente', 'PET-Misto'];
  enabledDdlStyle: boolean = false;
  refreshLotMillis: string = ConstantsModule.AUC_REFRESH_LOT_MILLIS.toString();
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {

    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    this.localUtil.activeAuctionCode = this.codiceAsta;
    this.subscribeChromeFocus();
    this.subscribePageVisibility();
    this.bindRefreshLotsForRefreshErr();
    this.simulateScroll();
    this.setHeaderSticky();
    this.loadAreaGeo();
    this.loadMaterialType();
    this.loadSchedaTecnicaList();
    this.loadRules();
    this.loadAuctionConfiguration();

    if (this.codiceAsta != undefined) {
      this.getAuctionDetailWithLots();
      this.initHeaderMonitor();
      this.initAuctionHeader();
    }
  }

  subscribePageVisibility() {
    const pageVisiblityService = this.pageVisibilityService.visibilityChange$.subscribe(isVisible => {
      if (isVisible) {
        setTimeout(() => this.getAuctionDetailWithLots(),0);
      }
    });
    this.subscriptions.push(pageVisiblityService);
  }

  subscribeChromeFocus() {
      const windowFocusService = this.windowFocusService.focusChange$.subscribe(isFocused => {
        if (isFocused) {
          setTimeout(() => this.getAuctionDetailWithLots(),0);
        }
      });
      this.subscriptions.push(windowFocusService);
  }

  bindRefreshLotsForRefreshErr() {
    try {
      const refreshLotsService = this.refreshAuctionLots.subscribe(() => {
        setTimeout(() => this.getAuctionDetailWithLots(),0);
      });
      this.subscriptions.push(refreshLotsService);
    } catch (err) {
      //
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

  initAuctionHeader() {
    setTimeout(() => {
      this.headerDetail = new HeaderDetail();
      this.headerDetail.dashboardHeader = false;
      this.headerDetail.stickyHeader = false;
      this.headerDetail.capacityHeader = this.auctionDetail && this.auctionDetail.tipologiaAsta == ConstantsModule.TIPOLOGIA_ASTA_DEFAULT ? true : false;
      this.headerDetail.view = ConstantsModule.AUC_VIEW_BOX;
      this.localUtil.setHeaderMonitor(this.headerDetail);
      if (this.auctionDetail == undefined) this.initAuctionHeader();
    }, 500);
  }

  simulateScroll() {
    window.scrollTo(0, window.scrollY + 1);
    window.scrollTo(0, window.scrollY - 1);
  }

  initHeaderMonitor() {

    if (this.codiceAsta != undefined && !this.auctionReloadLotsMonitor) {

      const auctionReloadLotsService = this.auctionReloadLotsMonitor = this.localUtil.getAuctionReloadLotsMonitor().subscribe(
        res => {
          if (res) {
            this.getAuctionDetailWithLots();
          }
        }
      );
      this.subscriptions.push(auctionReloadLotsService);

      const timerCallbackService = this.timerCallbackMonitor = this.localUtil.getTimersCallbackMonitor().subscribe(
        res => {
          if (res) {
            this.getAuctionDetailWithLots();
          }
        }
      );
      this.subscriptions.push(timerCallbackService);

    }

  }

  setTimers(timers: AuctionBoxTimer[]) {
    this.localUtil.setTimers(timers);
  }

  getAuctionDetailWithLots() {
    if (this.reloadEnabled) {
      this.reloadEnabled = false;

      this.localUtil.lotsLoaderCalled();

      setTimeout(() => {
        this.enableReloadButton()
      }, 1000);

      const getAuctionLotsService = this.auctionService.getAuctionLots(this.codiceAsta, "").subscribe(
        (res: GetAuctionLotsResponse) => {
          this.auctionDetail = res.auctionDetail;
          this.auctionDetail.serverTime = res.serverTime;

          if (this.auctionDetail.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED) {

            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = ConstantsModule.INFO_TITLE;
            modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode('AUCTION_CLOSED'));
            modalRef.componentInstance.tipo = 'message';
            ConstantsModule.REFRESH_LOTS_ENABLED = false;
            const modalRefService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
              this.router.navigate([ConstantsModule.REF_AZ_ASTE_VINTE_PATH]);
            });
            this.subscriptions.push(modalRefService);
          }
          let lotList: AuctionLotItem[] = res.lotList;
          this.localUtil.setServerTime(res.serverTime);
          this.prepareTimers(res);
          this.setAttributeToLots(lotList, res.serverTime);
          this.disableItemRule2(lotList);
          this.loadFavouriteLots(lotList, true);
          this.getBetSlotList(this.auctionDetail.tipologiaAsta);
          this.auctionLotsFull = lotList;
          //console.log('lots list call');
        },
        this.handleError(true)
      );
      this.subscriptions.push(getAuctionLotsService);
    }

  }

  sortByFavourite(list: AuctionLotItem[]) {
    return list.sort((a, b) => (a.favourite && b.favourite) ? 1 : (a.favourite ? -1 : 1));
  }

  loadAuctionConfiguration() {
    if (this.codiceAsta != undefined) {
      const auctionUserSetupService = this.organizationService.getAuctionUserSetup(this.codiceAsta).subscribe(
        (res: any) => {
          if (res.list != undefined) {
            this.auction_configuration = res.list;
            res.list.forEach((auctionConfig: AuctionSetupItem) => {
              if (auctionConfig.active) {
                this.auction_configuration = auctionConfig;
              }
            });
            this.isTaglioRilancioLibero = this.getAuctionPropValue(ConstantsModule.AUC_TAGLIO_RILANCIO_KEY) == ConstantsModule.AUC_TAGLIO_RILANCIO_LIBERO ? true : false;
            this.enabledDdlStyle = this.getAuctionPropValue(ConstantsModule.AUC_ENABLE_STYLE_TO_DDL_KEY) == ConstantsModule.AUC_ENABLED_STYLE_TO_DDL ? true : false;
            this.refreshLotMillis = this.getAuctionPropValue(ConstantsModule.AUC_REFRESH_LOT_MILLIS_KEY);
            this.setRefreshTimerStr(this.refreshLotMillis != undefined ? this.refreshLotMillis : ConstantsModule.AUC_REFRESH_LOT_MILLIS.toString());
            this.loadRefreshTimer();

          }
        }, this.handleError(true)
      );
      this.subscriptions.push(auctionUserSetupService);
    }
  }

  getAuctionPropValue(key: string) {
    let output = '';
    if (this.auction_configuration && key != undefined && key != '' && this.auction_configuration.properties != undefined) {
      this.auction_configuration.properties.forEach(config => {
        if (config.code == key) {
          output = config.value;
        }
      });
    }
    return output;
  }

  setRefreshTimerStr(seconds: string) {
    try {
      this.secondsToRefresh = parseInt(seconds);
    } catch (err) {
      this.secondsToRefresh = ConstantsModule.AUC_REFRESH_LOT_MILLIS;
    }
  }

  setRefreshTimer(seconds: number) {
    this.secondsToRefresh = seconds;
  }

  setSliderValues(sliderValue: SliderValue) {
    this.localUtil.setValueSliderCompanySubHeader(sliderValue);
  }

  makeOffer(codiceLotto: string, prezzoUltimoRilancio: number, lot: AuctionLotItem) {
    let taglioRilancio: number = this.betSlotLotto.get(codiceLotto);
    if (taglioRilancio == undefined) {
      taglioRilancio = this.minimumBetSlot;
    }

    let prezzoMioRilancio: number = 0;
    let prezzoPartenza: number = parseInt(prezzoUltimoRilancio.toString());
    if (prezzoPartenza == 0) {
      prezzoPartenza = lot.prezzoBase;
    }
    if (!this.isTaglioRilancioLibero) {
      prezzoMioRilancio = prezzoPartenza + parseInt(taglioRilancio.toString());
    } else {
      if (lot.bindModel != undefined) {
        prezzoMioRilancio = prezzoPartenza + parseInt(lot.bindModel.toString());
      } else {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("BET_SLOT_MANDATORY"), true);
        return;
      }
    }

    let params: any[] = [prezzoMioRilancio, codiceLotto];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("MAKE_OFFER_CONFIRM", params));
    const modalRefService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const makeOfferService = this.auctionService.makeOffer(this.codiceAsta, codiceLotto, prezzoUltimoRilancio, prezzoMioRilancio).subscribe(
          res => {
            this.getAuctionDetailWithLots();
            this.loadRules();
          }, this.handleError(true)
        );
        this.subscriptions.push(makeOfferService);
      }
    }, this.handleError(true));
    this.subscriptions.push(modalRefService);
  }

  loadRules() {
    if (this.codiceAsta != undefined) {
      const auctionRuleService = this.auctionService.getAuctionRules(this.codiceAsta).subscribe(
        capData => {

          this.localUtil.CapData = capData;

          //Barra totale impianto
          let barValues: SliderValue = new SliderValue();
          barValues.type = ConstantsModule.SLIDER_TYPE_CAPACITY;

          barValues.curValue = this.localUtil.CapData.rule1.oldWon;
          barValues.resValue = (this.localUtil.CapData.rule1.totalEligibility - this.localUtil.CapData.rule1.oldWon);
          barValues.maxValue = this.localUtil.CapData.rule1.totalEligibility;
          barValues.curPerc = (100 * barValues.curValue / barValues.maxValue);
          this.setSliderValues(barValues);

          //rule3 per tipoProdotto
          this.localUtil.CapData.rule3.forEach((matRule: MatRule) => {

            barValues = new SliderValue();
            barValues.type = matRule.typeMaterial;
            barValues.curValue = matRule.winning;
            barValues.resValue = (matRule.ruleEligibility - matRule.winning);
            barValues.maxValue = matRule.ruleEligibility;
            barValues.curPerc = (100 * barValues.curValue / barValues.maxValue);
            this.setSliderValues(barValues);

          });

          this.localUtil.setCurrentCost(this.localUtil.numberWithCommas(capData.rule1.cost));

        }, this.handleError(true)
      );
      this.subscriptions.push(auctionRuleService);
    }
  }

  filterByFavourite(favourite: boolean) {
    let lots: AuctionLotItem[] = [];
    if (favourite != undefined) {
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot: AuctionLotItem) => {
      if (this.toggleFilterFavourite) {
        if (lot.favourite) {
          auctionLotFiltered.push(lot);
        }
      } else {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (favourite) {
      this.filterByText();
    }
    this.masonry?.layout();
  }

  filterByMaterial(materialItem: MaterialTypeItem) {
    let lots: AuctionLotItem[] = [];
    if (materialItem) {
      this.setMaterialFilterValue(materialItem);
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot: AuctionLotItem) => {
      let addItem: boolean = true;
      this.material_list.forEach((material: MaterialTypeItem) => {
        if (!material.checked && addItem) { //check non selezionato
          if (lot.tipoProdotto == material.type_material) {
            addItem = false;
          }
        }
      });
      if (addItem) {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (materialItem) {
      this.filterByFavourite(undefined);
      this.filterByArea(undefined);
    }
    this.masonry?.layout();
  }

  filterForEnabledMaterials(materialList: MaterialTypeItem[]) {
    let output: MaterialTypeItem[] = [];
    if (materialList != undefined && materialList.length > 0) {
      for (let material of materialList) {
        if (this.enabled_materials.includes(material.type_material)) {
          output.push(material);
        }
      }
    }
    return output;
  }

  setMaterialFilterValue(materialItem: MaterialTypeItem) {
    this.material_list.forEach((material: MaterialTypeItem) => {
      if (materialItem.id == material.id) {
        material.checked = !material.checked;
      }
    });
  }

  filterByArea(areaGeoItem: AreaGeograficaItem) {
    let lots: AuctionLotItem[] = [];
    if (areaGeoItem) {
      this.setAreaFilterValue(areaGeoItem);
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot: AuctionLotItem) => {
      let addItem: boolean = true;
      this.area_list.forEach((area: AreaGeograficaItem) => {
        if (!area.checked && addItem) { //check non selezionato
          if (lot.areaGeografica == area.area) {
            addItem = false;
          }
        }
      });
      if (addItem) {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (areaGeoItem) {
      this.filterByFavourite(undefined);
      this.filterByMaterial(undefined);
    }
    this.masonry?.layout();
  }

  setAreaFilterValue(areaGeoItem: AreaGeograficaItem) {
    this.area_list.forEach((area: AreaGeograficaItem) => {
      if (areaGeoItem.id == area.id) {
        area.checked = !area.checked;
      }
    });
  }

  loadFavouriteLots(auctionLots: AuctionLotItem[], beautify: boolean) {//(repaintGrid?: boolean) {
    const favLotService = this.auctionService.listFavouriteLots(this.codiceAsta).subscribe(
      (res: GetAuctionLotUserFavouriteResponse) => {
        if (res && res.list) {
          this.favouriteLots = res.list;
          this.updateFavouriteLots(auctionLots, beautify);
        }
      },
      this.handleError(true)
    );
    this.subscriptions.push(favLotService);
  }

  addFavouriteLot(codiceLotto: string) {
    if (codiceLotto) {
      const addFavLotService = this.auctionService.addFavouriteLot(this.codiceAsta, codiceLotto,).subscribe(
        (res) => {
          this.loadFavouriteLots(this.auctionLots, false);
        },
        this.handleError(true)
      );
      this.subscriptions.push(addFavLotService);
    }
  }

  removeFavouriteLot(codiceLotto: string) {
    if (codiceLotto) {
      const removeFavLotService = this.auctionService.removeFavouriteLot(this.codiceAsta, codiceLotto).subscribe(
        (res) => {
          this.loadFavouriteLots(this.auctionLots, false);
        },
        this.handleError(true)
      );
      this.subscriptions.push(removeFavLotService);
    }
  }

  updateFavouriteLots(auctionLots: AuctionLotItem[], beautifySelect: boolean) {
    if (auctionLots && this.favouriteLots) {
      auctionLots.forEach(lot => {
        lot.favourite = false;
        this.favouriteLots.forEach(favLot => {
          if (favLot.lotCode == lot.codiceLotto) {
            lot.favourite = true;
          }
        });
      });

      this.auctionLots = auctionLots;

      this.filterByText();

      /*if (beautifySelect && beautifySelect == true) {
        setTimeout(()=>this.beautifySelect(), 100); //funziona impostando la classe custom-select all'oggetto padre che contiene la select
      }*/

    } else {
      this.auctionLots = this.auctionLotsFull;
      this.filterByText();
    }
    this.masonry?.layout();
  }

  getDefaultTaglioRilancio() {
    let output = '';
    try {
      if (this.bet_list != undefined && this.bet_list.length > 0) {
        output = this.bet_list[0].taglioRilancio.toString();
      }      
    } catch (err) {
      console.log(err);
    }
    return output;
  }

  changeBetSlot(e, codiceLotto: string) {
    try {
      //console.log(e);
      let betVal: number = 0;
      betVal = e.itemData.taglioRilancio;
      //console.log(betVal);
      /* for (let option of e.target) {
        if (option.selected) {
          betVal = option.value;
          console.log(betVal);
        }
      } */
      this.betSlotLotto.set(codiceLotto, betVal);
    } catch (err) {
      console.log(err);
    }
  }

  loadRefreshTimer() {
    if (this.codiceAsta != undefined && (this.localUtil.isListView() || this.localUtil.isBoxView())) {
      setTimeout(() => {
        this.manageRefreshTimer();
        this.loadRefreshTimer();
      }, 1000);
    }
  }

  manageRefreshTimer() {
    this.secondsToRefresh = this.secondsToRefresh - 1000;
    if (this.secondsToRefresh == 0) {
      if (ConstantsModule.REFRESH_LOTS_ENABLED) {
        this.getAuctionDetailWithLots();
      }
      this.setRefreshTimerStr(this.refreshLotMillis != undefined ? this.refreshLotMillis : ConstantsModule.AUC_REFRESH_LOT_MILLIS.toString());
    }
  }

  increaseFont() {
    let style: any = document.body.style;
    let actualZoom = style.zoom;
    if (actualZoom == undefined || actualZoom == "") actualZoom = "100%";
    actualZoom = actualZoom.substr(0, actualZoom.length - 1);
    actualZoom = parseInt(actualZoom) + 5;
    if (actualZoom > 0) {
      style.zoom = actualZoom + "%";
    }
    this.masonry?.layout();
  }

  decreaseFont() {
    let style: any = document.body.style;
    let actualZoom = style.zoom;
    if (actualZoom == undefined || actualZoom == "") actualZoom = "100%";
    actualZoom = actualZoom.substr(0, actualZoom.length - 1);
    actualZoom = parseInt(actualZoom) - 5;
    if (actualZoom > 0) {
      style.zoom = actualZoom + "%";
    }
    this.masonry?.layout();
  }

  enableReloadButton() {
    if (!this.reloadEnabled) {
      //console.log('enabling reload button');
      this.reloadEnabled = true;
    }
  }

  getDisabledReloadLots() {
    return this.reloadEnabled ? '' : 'disabled';
  }

  disableItemRule2(auctionLots: AuctionLotItem[]) {
    if (auctionLots) {
      auctionLots.forEach((item: any) => {
        try {
          if (this.auctionDetail.tipologiaAsta == ConstantsModule.TIPOLOGIA_ASTA_DEFAULT) {
            if ((this.localUtil.CapData.rule1.residualEligibility + this.localUtil.CapData.rule1.ruleEligibility - this.localUtil.CapData.rule1.winning) < item.quantitaTon) {
              item.isDisabled = true;
            } else if ((this.localUtil.CapData.rule2.ruleEligibility - this.localUtil.CapData.rule2.winning) < item.quantitaTon) {
              item.isDisabled = true;
            }

            if ((item.userId != this.localUtil.authParams.user_detail.id) && (item.idOrganization != this.localUtil.authParams.organization_detail.id)) {
              this.localUtil.CapData.rule3.forEach(function (matRule) {
                if (matRule.typeMaterial == item.tipoProdotto) {
                  if ((matRule.ruleEligibility - matRule.winning) < item.quantitaTon) {
                    item.isDisabled = true;
                  }
                }
              });
            }
          }

        } catch (err) {
          console.log(err);
        }
      });
    }
  }

  selectedBet: any;
  getBetSlotList(tipologiaAsta: string) {
    try {
      if (this.bet_list == undefined || this.bet_list.length == 0) {
        const betSetupService = this.auctionService.getAuctionBetSetupByTipology(tipologiaAsta).subscribe(
          (data) => {
            this.bet_list = data.list;
            for (let val of this.bet_list) {
              this.selectedBet = val.taglioRilancio;
            }
            //console.log(this.bet_list);
            this.minimumBetSlot = this.bet_list[0].taglioRilancio;
          }, this.handleError(true));
          this.subscriptions.push(betSetupService);
      }
    } catch (err) {
      console.log(err);
    }
  }

  getValueByCodiceLotto(codiceLotto) {
    try {
      return this.betSlotLotto.get(codiceLotto) != undefined ? this.betSlotLotto.get(codiceLotto) : this.bet_list[0].taglioRilancio;
    } catch (err) {
      return '';
    }
  }

  formatBet(bet: any): string {
    try {
      return `+${bet?.taglioRilancio} €`;
    } catch (err) {
      return `+0 €`;
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      template: "favouritesFilterButton"
    });
  }

  filterFavouriteLots(e) {
    this.toggleFilterFavourite = !this.toggleFilterFavourite;
    this.filterByFavourite(this.toggleFilterFavourite);
  }

  loadAreaGeo() {
    const geoAreaService = this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.area_list = data.list;
        this.area_list.forEach((area: AreaGeograficaItem) => {
          area.checked = true;
        });
      },
      this.handleError(true)
    );
    this.subscriptions.push(geoAreaService);
  }

  loadMaterialType() {
    const materialListService = this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.material_list = this.filterForEnabledMaterials(this.localUtil.filterTipoProdottoWithExclusion(data.list, this.auctionDetail != undefined ? this.auctionDetail.tipologiaAsta : ConstantsModule.TIPOLOGIA_ASTA_DEFAULT));
        this.material_list.forEach((material: MaterialTypeItem) => {
          material.checked = true;
        });
      },
      this.handleError(true)
    );
    this.subscriptions.push(materialListService);
  }

  loadSchedaTecnicaList() {
    let scheda: SchedaTecnicaItem = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Azzurrato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Colorato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Trasparente.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Misto.pdf';
    this.scheda_list.push(scheda);
  }

  getColorePET(tipoProdotto: any) {
    let output = '';
    if (tipoProdotto != undefined) {
      if (tipoProdotto == ConstantsModule.PET_COLORATO) {
        output = ConstantsModule.PET_CSS_COLORATO;
      } else if (tipoProdotto == ConstantsModule.PET_AZZURRATO) {
        output = ConstantsModule.PET_CSS_AZZURRATO;
      } else if (tipoProdotto == ConstantsModule.PET_TRASPARENTE) {
        output = ConstantsModule.PET_CSS_TRASPARENTE;
      } else if (tipoProdotto == ConstantsModule.PET_MISTO) {
        output = ConstantsModule.PET_CSS_MISTO;
      }
    }
    return output;
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  timerEventRaised(outputTimerEmit: AuctionLotTimerItem) {
    this.updateLotStatus(outputTimerEmit);
  }

  updateLotStatus(outputTimerEmit: AuctionLotTimerItem) {
    if (this.auctionLots != undefined && outputTimerEmit != undefined) {
      this.auctionLots.forEach(lot => {
        if (lot.codiceLotto == outputTimerEmit.codiceLotto) {
          lot.isClosed = outputTimerEmit.isClosed;
          lot.isExtratime = outputTimerEmit.isExtratime;
        }
      });
    }
  }

  setAttributeToLots(auctionLots: AuctionLotItem[], serverTime) {

    if (auctionLots != undefined) {
      let index: number = 0;
      auctionLots.forEach(lot => {
        if (lot.idOrganization != undefined && lot.idOrganization == this.localUtil.authParams.organization_detail.id.toString()) {
          lot.isWinning = true;
        }
        if (!lot.isWinning && lot.userId == this.localUtil.authParams.user_detail.id) {
          lot.isWinning = true;
        }
        if (this.auctionDetail.dataOraFineAsta != lot.dataOraFineAstaEffettiva) {
          lot.isExtratime = true;
        }
        lot.isClosed = this.getClosedStatus(lot.dataOraFineAstaEffettiva, serverTime);
        //console.log(lot.codiceLotto + ' isClosed: ' + lot.isClosed);
        index++;
      });
    }
  }

  prepareTimers(res: GetAuctionLotsResponse) {
    let timers: AuctionBoxTimer[] = [];
    let dataScadenzaAsta = this.getMomentDate(this.auctionDetail.dataOraFineAsta, this.auctionDetail.serverTime);
    let dataNow = this.getNowMomentDate(this.auctionDetail.serverTime);

    this.addBaseAuctionTimer(timers, dataScadenzaAsta, dataNow);

    if (res) {
        this.addFirstLotTimer(timers, res, dataScadenzaAsta, dataNow);
        this.addLastLotTimer(timers, res, dataNow);
    }

    this.setTimers(timers);
  }

  addBaseAuctionTimer(timers: AuctionBoxTimer[], dataScadenzaAsta: moment.Moment, dataNow: moment.Moment) {
        if (dataScadenzaAsta > dataNow) {
            let timer = new AuctionBoxTimer();
            timer.label = "header.Tempo rimanente alla fine dell'asta";
            timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
            timer.dataOraFineAstaEffettiva = this.auctionDetail.dataOraFineAsta;
            timer.name = 'scadenza asta base';
            timer.serverTime = this.auctionDetail.serverTime;
            timers.push(timer);
        }
    }

  addFirstLotTimer(timers: AuctionBoxTimer[], res: GetAuctionLotsResponse, dataScadenzaAsta: moment.Moment, dataNow: moment.Moment) {
        if (res.dataPrimoLottoInScadenza) {
            let dataScadenzaPrimoLotto = this.getMomentDate(res.dataPrimoLottoInScadenza, this.auctionDetail.serverTime);
            if (dataScadenzaPrimoLotto > dataNow) {
                let timer = new AuctionBoxTimer();
                timer.label = res.dataPrimoLottoInScadenza === this.auctionDetail.dataOraFineAsta ?
                    "header.Tempo rimanente alla fine dell'asta" :
                    "header.Primo lotto in scadenza";
                timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
                timer.dataOraFineAstaEffettiva = res.dataPrimoLottoInScadenza;
                timer.name = 'primo lotto';
                timer.serverTime = this.auctionDetail.serverTime;
                timers.push(timer);
            }
        }
  }

  addLastLotTimer(timers: AuctionBoxTimer[], res: GetAuctionLotsResponse, dataNow: moment.Moment) {
      if (res.dataUltimoLottoInScadenza && res.dataUltimoLottoInScadenza !== res.dataPrimoLottoInScadenza) {
          let dataUltimoLottoInScadenza = this.getMomentDate(res.dataUltimoLottoInScadenza, this.auctionDetail.serverTime);
          if (dataUltimoLottoInScadenza > dataNow) {
              let timer = new AuctionBoxTimer();
              timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
              timer.dataOraFineAstaEffettiva = res.dataUltimoLottoInScadenza;
              timer.name = 'ultimo lotto';
              timer.label = "header.Ultimo lotto in scadenza";
              timer.serverTime = this.auctionDetail.serverTime;
              timers.push(timer);
          }
      }
  }

  filterByText() {
    if (this.searchLotText) {
      let auctionLotFiltered: AuctionLotItem[] = [];
      this.auctionLotsFull.forEach((lot: AuctionLotItem) => {
        if (JSON.stringify(lot).toLowerCase().indexOf(this.searchLotText.toLowerCase()) > -1) {
          auctionLotFiltered.push(lot);
        }
      });
      this.auctionLots = auctionLotFiltered;
    } else {
      this.auctionLots = this.auctionLotsFull;
    }
    this.filterByFavourite(undefined);
    this.filterByMaterial(undefined);
    this.filterByArea(undefined);
    this.masonry?.layout();
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.manageStickyHeader();
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    this.manageStickyHeader();
  }

  manageStickyHeader() {
    let header = this.getHeaderElement();
    let sticky = header ? header.offsetTop : 0;
    let offset_var = this.getOffsetVar();

    if (window.pageYOffset + offset_var > sticky) {
        this.setStickyHeader(true);
    } else {
        this.setStickyHeader(false);
    }
    this.setHeaderSticky();
  }

  getHeaderElement(): HTMLElement | null {
      let header = document.getElementById("cards-lots-list");
      return header;
  }

  getOffsetVar(): number {
      return $('#cards-lots-list-header').hasClass('header-no-default-auction') ? 75 : 160;
  }

  setStickyHeader(isSticky: boolean) {
      this.navbarfixed = isSticky;
      this.headerDetail.stickyHeader = false;
      this.localUtil.setHeaderMonitor(this.headerDetail);
      if (this.codiceAsta) {
          if (isSticky) {
              this.localUtil.showHeaderSticky(this.isAstaSingola() ? ConstantsModule.AUC_MOTORE_ASTA_SINGOLA : ConstantsModule.AUC_MOTORE_ASTA_MULTIPLA);
          } else {
              this.localUtil.hideHeaderSticky(this.isAstaSingola() ? ConstantsModule.AUC_MOTORE_ASTA_SINGOLA : ConstantsModule.AUC_MOTORE_ASTA_MULTIPLA);
          }
      }
  }

  isAstaSingola() {
    if (this.auction_configuration) {
      if (this.auction_configuration.motore == ConstantsModule.AUC_MOTORE_ASTA_SINGOLA) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setHeaderSticky() {
    try {
      /*$('#lotto').width($('#lottoHeader').width());
      $('#tipologia').width($('#tipologiaHeader').width());
      $('#area').width($('#areaHeader').width());
      $('#quantita').width($('#quantitaHeader').width());
      $('#rilanci').width($('#rilanciHeader').width());
      $('#prezzo').width($('#prezzoHeader').width());
      $('#tempo').width($('#tempoHeader').width());
      $('#stato').width($('#statoHeader').width());
      $('#azione1').width($('#azione1Header').width());
      $('#azione2').width($('#azione2Header').width());*/

      if ($("#container").hasClass("sidebar_closed")) {
        $('header.fixed-header').addClass('full');
        $('.overview-tool.fixed-parameters').addClass('full');
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 + 'px');
      } else {
        $('header.fixed-header').removeClass('full');
        $('.overview-tool.fixed-parameters').removeClass('full');
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 - 230 + 'px');
      }
    } catch (err) {
      //
    }
  }

  lotDetail(lot: AuctionLotItem) {
    try {
      let lotDetailElem: AuctionLotItem = lot;
      let csLots: CentroSelezioneItem[] = [];
      //console.log('lotDetail()');
      //console.log(lot);
      const modalRef = this.modalService.open(PopupComponent);
      modalRef.componentInstance.tipo = 'openLotDetail';
      modalRef.componentInstance.codiceLotto = lot.codiceLotto;

      const selectionCenterService = this.commonService.getSelectionCenterList().subscribe(
        (data: GetSelectionCenterListResponse) => {
          let csList = data.list;
          lot.centroSelezione.forEach(csLot => {
            //console.log('csLot: ' + JSON.stringify(csLot));
            csList.forEach((cs: CentroSelezioneItem) => {
              //console.log('cs: ' + JSON.stringify(cs));
              if (cs.codice == csLot.codiceCentroSelezione) {
                csLot = $.extend(cs, csLot);
                csLots.push(csLot);
                //console.log(csLot);
              }
            });
          });
        }
      );
      this.subscriptions.push(selectionCenterService);

      //console.log(csLots);
      const lotDetailService = this.auctionService.getLotDetail(lot.codiceAsta, lot.codiceLotto).subscribe(
        data => {
          lotDetailElem = data.lot;
          lotDetailElem.centroSelezione = csLots;
          const lotsHistoryService = this.auctionService.getAuctionLotsHistory(lot.codiceAsta, lot.id).subscribe(
            res => {
              lotDetailElem.rilanciLotto = res.list;
              lotDetailElem.numContendentiUnici = res.numContendentiUnici;
              //console.log('lotDetail()');
              //console.log(lotDetailElem);
              if (modalRef && modalRef.componentInstance) {
                modalRef.componentInstance.lot = lotDetailElem;
              }
            }, this.handleError(true)
          );
          this.subscriptions.push(lotsHistoryService);

        }, this.handleError(true)
      );
      this.subscriptions.push(lotDetailService);
    } catch (err) {
      //
    }

  }

  getProdottoClass(tipoProdotto: string) {
    if (tipoProdotto != undefined) {
      return tipoProdotto.toLowerCase();
    } else {
      return tipoProdotto;
    }
  }

  isEnabledToAuction() {
    return this.localUtil.isEnabledToAuction();
  }

}

