import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentData, FileWithContentUpload, OrganizationDocumentsUpdateItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-fideiussione',
  templateUrl: './upload-fideiussione.component.html',
  styleUrls: ['./upload-fideiussione.component.css']
})
export class UploadFideiussioneComponent extends APIService implements OnInit, OnDestroy {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public dashboardService: DashboardService,
    location: Location,
    router: Router,
    public route: ActivatedRoute,
    public commonService: CommonService) {
    super(localService, location, router);
    console.log('UploadFideiussioneComponent()');
  }

  fideiussioneBancaria: FileWithContentUpload;
  totaleFidejussione: number;
  dataScadenzaFidejussione: string;
  vatnumber: string;
  existsDocument: DocumentData;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.checkFideiussioneDoc();
    this.totaleFidejussione = this.localUtil.authParams.organization_detail.totaleFidejussione;
    this.dataScadenzaFidejussione = this.localUtil.authParams.organization_detail.dataScadenzaFidejussione;
    let vatnumber = this.route.snapshot.paramMap.get('vatnumber');
    if (vatnumber != undefined) {
      this.vatnumber = vatnumber;
      console.log('UploadFideiussioneComponent() set vatnumber to ' + this.vatnumber);
    } else {
      if (this.isUser() && vatnumber == undefined) {
        this.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      }
    }
  }

  back() {
    //this.location.back();
    this.router.navigate(['/dashboard.html']);
  }

  getFideiussioneBancariaFile(file) {
    if (file != undefined) {
      console.log('getFideiussioneBancariaFile() loaded file: ' + file.name);
    } else {
      console.log('getFideiussioneBancariaFile() unloaded file!');
    }
    this.fideiussioneBancaria = new FileWithContentUpload();
    this.fideiussioneBancaria.file = file;
    console.log(this.fideiussioneBancaria);
  }

  documentUpload() {
    this.back();
  }

  uploadFideiussione() {
    if (this.validateFideiussione()) {
      if (this.fideiussioneBancaria != undefined && this.fideiussioneBancaria.file != undefined) {
        this.fideiussioneBancaria.contentType = 'ORG';
        this.fideiussioneBancaria.vatnumber = this.vatnumber;

        const service = this.commonService.documentUpload(this.fideiussioneBancaria).subscribe(
          data => {
            data = JSON.parse(data);
            console.log('uploadFideiussione uploaded! file_uuid: ' + data.file_uuid);
            console.log(data);
            this.updateFideiussione(this.fideiussioneBancaria.vatnumber, data.file_uuid);
          }, this.handleError(true));
          this.subscriptions.push(service);
      } else {
        //salvataggio dati fideiussione senza upload file
        this.updateFideiussione(this.vatnumber, undefined);
      }
    }
  }

  validateFideiussione() {
    let output = true;
    /*if (this.fideiussioneBancaria == undefined || this.fideiussioneBancaria.file == undefined) {
      output = false;
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);
    } else*/ if (this.totaleFidejussione == undefined || this.totaleFidejussione <= 0) {
      output = false;
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FIDEIUSSIONE_WRONG_TOTALE"), true);
    } else if (this.dataScadenzaFidejussione == undefined || this.dataScadenzaFidejussione == '') {
      output = false;
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FIDEIUSSIONE_WRONG_DATA"), true);
    }
    return output;
  }

  updateFideiussione(vatnumber: string, uuid: string) {

    let updateBean: OrganizationDocumentsUpdateItem = new OrganizationDocumentsUpdateItem();
    if (uuid != undefined) {
      updateBean.fidejussioneBancariaUuid = uuid;
    }
    updateBean.totaleFidejussione = this.totaleFidejussione;
    updateBean.dataScadenzaFidejussione = this.dataScadenzaFidejussione;

    const service = this.organizationService.updateOrganizationDocuments(vatnumber, updateBean).subscribe(
      (data: any) => {

        if (this.isUser()) {
          let authParam = this.localUtil.authParams;
          authParam.organization_detail.fidejussioneBancaria = data.detail.fidejussioneBancaria;
          authParam.organization_detail.fidejussioneBancariaValidated = "";
          authParam.organization_detail.dataScadenzaFidejussione = data.detail.dataScadenzaFidejussione;
          authParam.organization_detail.totaleFidejussione = data.detail.totaleFidejussione;
          this.localUtil.setAuthParams(authParam);
        }

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.localUtil.reloadUserHeaderParameters();
          this.back();
        });
        this.subscriptions.push(service);
      },
      this.handleError(true));
      this.subscriptions.push(service);
  }

  checkFideiussioneDoc() {
    let organization_detail = this.localUtil.getSessionOrganization();
    if (organization_detail != '' && organization_detail != undefined) {
      if (organization_detail.fidejussioneBancaria != '' &&
          organization_detail.fidejussioneBancariaValidated == '') {
            //EXISTS AND NOT YET VALIDATED
            console.log('fidejussione EXISTS AND NOT YET VALIDATED');            
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = organization_detail.fidejussioneBancaria;
            this.existsDocument.status = '';
      } else if (organization_detail.fidejussioneBancaria != '' &&
                 organization_detail.fidejussioneBancariaValidated == 'Y') {
            //EXISTS AND VALIDATED
            console.log('fidejussione EXISTS AND VALIDATED');
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = organization_detail.fidejussioneBancaria;
            this.existsDocument.status = 'Y';
      } else if (organization_detail.fidejussioneBancaria != '' &&
                 organization_detail.fidejussioneBancariaValidated == 'N') {
            //EXISTS AND NOT VALIDATED
            this.existsDocument = new DocumentData();
            console.log('fidejussione EXISTS AND NOT VALIDATED');
            this.existsDocument.uuid = organization_detail.fidejussioneBancaria;
            this.existsDocument.status = 'N';
      } else {
            //UNKNOWN STATUS
            console.log('fidejussione UNKNOWN STATUS');
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = '';
            this.existsDocument.status = '';
      }  
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}