import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpHeaders, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DxTemplateHost, NestedOptionHost } from 'devextreme-angular';

@NgModule({ declarations: [], imports: [CommonModule], providers: [provideHttpClient(withInterceptorsFromDi()), DxTemplateHost, NestedOptionHost] })
export class ConstantsModule {
    public static SHOW_LOG = true;
    //public static API_HOST_AUTH = 'http://localhost:8091/iam';
    //public static API_HOST_AUTH = 'http://localhost:4200/iam';
    //public static API_HOST_AUTH = 'http://localhost/iam';
    //public static API_HOST_LAB = 'http://localhost/laboratorio';
    //public static API_HOST_AUTH = 'http://localhost:8091/iam';
    //public static API_HOST_LAB = 'http://localhost:8080/laboratorio';
    //STAGING CON PROXY
    /*public static API_HOST_AUTH = '/api/authenticate';
    public static API_HOST = '/api';*/
    
    public static REFRESH_LOTS_ENABLED = true;

    //STAGING
    public static API_HOST_AUTH = environment.apiUrl + '/o/authenticate';
    public static API_HOST = environment.apiUrl + '/o';
    public static S3_URL = environment.s3Url;
    
    public static API_PREFIX = '/62489';
    public static API_MENU_PREFIX = '/Menu';
    public static API_AUCTION_PREFIX = '/Auction';
    public static API_ORGANIZATION_PREFIX = '/Organization';
    public static API_UPLOAD_PREFIX = '/Upload';
    public static API_CS_PREFIX = '/SelectionCenter';
    public static API_UTIL_PREFIX = '/AucircleRestUtil';
    public static API_USER_PREFIX = '/User';
    
    
    public static REF_AZIENDALE: 'REF_AZIENDALE';
    public static OPER_AZIENDALE: 'OPER_AZIENDALE';
    public static OPERATORE: 'OPERATORE';
    public static OPER_PLASTICFINDER: 'OPER_PLASTICFINDER';

    public static REF_AZIENDALE_DESC: 'Referente aziendale';
    public static OPER_AZIENDALE_DESC: 'Operatore aziendale';
    public static OPER_PLASTICFINDER_DESC: 'Admin';
    public static OPERATORE_DESC: 'Operatore Coripet';


    public static INFO_TITLE = "common.info";
    public static ERROR_TITLE = "common.attenzione";

    public static ERROR_MANDATORI_PRE = "Il campo ";

    public static ERROR_MANDATORI_POST = " è obbligatorio";

    public static SUCCESS_MESSAGE_KEY = "OK_OPERATION";

    public static ROLE_ADMIN = "OPER_PLASTICFINDER"
    public static ROLE_CORIPET = "OPERATORE"
    public static ROLE_REF_AZIENDALE = "REF_AZIENDALE"
    public static ROLE_OPER_AZIENDALE = "OPER_AZIENDALE"
    public static FAKE_LOGIN_PATH = 'login';

    public static LOGIN_PATH = 'login';//ConstantsModule.FAKE_LOGIN_PATH; // login path --> '';

    public static REF_AZ_DASHBOARD_PATH = '/user/dashboard.html';
    public static REF_ADMIN_DASHBOARD_PATH = '/admin/admin-dashboard.html';
    public static REF_CORIPET_DASHBOARD_PATH = '/coripet/operatore-dashboard.html';
    public static REF_AZ_LOGIN_PATH = 'login';
    public static REF_AZ_NO_PATH = '';
    public static PUBLIC_REGISTRATION_STEP1 = 'registrazione/step1';
    public static PUBLIC_REGISTRATION_STEP2 = 'registrazione/step2';

    public static REF_AZ_ASTE_CHIUSE_DETTAGLIO_PATH = 'aste-chiuse-dettaglio/';
    public static REF_AZ_ASTE_VINTE_DETTAGLIO_PATH = 'aste-vinte-dettaglio/';
    public static REF_AZ_ASTE_PUBBLICHE_DETTAGLIO_PATH = 'aste-pubbliche-dettaglio/';
    public static REF_AZ_ASTE_CHIUSE_PATH = '/user/aste-chiuse.html';
    public static REF_AZ_ASTE_VINTE_PATH = '/user/aste-vinte.html';
    public static REF_AZ_ASTE_PUBBLICHE_PATH = '/user/aste-pubbliche.html';
    public static REF_AZ_UPLOAD_VISURA_PATH = 'upload-visura.html';
    public static REF_AZ_UPLOAD_LEGALE_PATH = 'upload-documento-rappresentante.html';
    public static REF_AZ_UPLOAD_FIDEIUSSIONE_PATH = 'upload-fideiussione.html';
    public static REF_AZ_UPLOAD_DOC_231_2001_PATH = 'upload-dichiarazione-231.html';
    public static USER_CONSENTS_PATH = 'consensi-utente/';
    public static USER_ASTE_IN_CORSO = 'aste-in-corso/';
    public static USER_ASTE_IN_CORSO_BOX = 'aste-in-corso-box/';
    public static USER_ASTE_IN_CORSO__NO_DASHB = '/aste-in-corso.html';

    public static CORIPET_AZIENDE = 'operatore-aziende.html';
    public static CORIPET_AZIENDE_DETTAGLIO = 'coripet-aziende-dettaglio/';
    public static CORIPET_AZIENDE_DETTAGLIO_NEW = 'operatore-aziende-dettaglio.html/';
    public static CORIPET_UPLOAD_VISURA_PATH = 'coripet-upload-visura/';
    public static CORIPET_UPLOAD_LEGALE_PATH = 'coripet-upload-legale/';
    public static CORIPET_AZ_UPLOAD_FIDEIUSSIONE_PATH = 'coripet-azienda-fideiussione/';
    public static CORIPET_UPLOAD_DOC_231_2001_PATH = 'coripet-upload-dichiarazione-231/';
    public static CORIPET_ASTE_DETTAGLIO_PATH = 'operatore-aste-dettaglio/';
    public static CORIPET_ASTE_PATH = 'operatore-aste-dettaglio.html/';

    public static ADMIN_ASTE_PATH = 'admin-aste/';
    public static ADMIN_ASTE_DETTAGLIO_PATH = 'admin-aste-dettaglio/';
    public static ADMIN_AZIENDE_DETTAGLIO = 'admin-aziende-dettaglio/';
    public static ADMIN_AZIENDE_DETTAGLIO_NEW = 'admin-aziende.html/';

    public static AZIENDA_REGISTRATA = 'REGISTRATA';


    public static DOC_VISURA = 'visura-camerale.pdf';
    public static DOC_VISURA_APPROVE = 'validate_visura'
    public static DOC_VISURA_REJECT = 'reject_visura';
    public static DOC_FIDEIUSSIONE = 'fideiussione-bancaria.pdf';
    public static DOC_FIDEIUSSIONE_APPROVE = 'validate_fidejussione';
    public static DOC_FIDEIUSSIONE_REJECT = 'reject_fidejussione';
    public static DOC_LEGALE = 'rappresentante-legale.pdf';
    public static DOC_LEGALE_APPROVE = 'validate_legale';
    public static DOC_LEGALE_REJECT = 'reject_legale';
    public static DOC_231 = 'dichiarazione-231-2001.pdf';
    public static DOC_231_APPROVE = 'validate_dichiarazione231';
    public static DOC_231_REJECT = 'reject_dichiarazione231';

    public static DOC_FRAMEWORK_9001 = 'certificazione-iso-9001.pdf';
    public static DOC_FRAMEWORK_9001_APPROVE = 'validate_9001';
    public static DOC_FRAMEWORK_9001_REJECT = 'reject_9001';
    public static DOC_FRAMEWORK_14001 = 'certificazione-iso-14001.pdf';
    public static DOC_FRAMEWORK_14001_APPROVE = 'validate_14001';
    public static DOC_FRAMEWORK_14001_REJECT = 'reject_14001';
    public static DOC_FRAMEWORK_DOCAUTH = 'documento-autorizzazione.pdf';
    public static DOC_FRAMEWORK_DOCAUTH_APPROVE = 'validate_doc_auth';
    public static DOC_FRAMEWORK_DOCAUTH_REJECT = 'reject_doc_auth';

    public static ORG_APPROVE = 'validate'
    public static ORG_REJECT = 'reject'
    
    public static AUCTION_STATUS_DRAFT = 'Bozza';
    public static AUCTION_STATUS_AVAILABLE = 'Disponibile';
    public static AUCTION_STATUS_PUBLISHED = 'Pubblica';
    public static AUCTION_STATUS_INPROGRESS = 'In Corso';
    public static AUCTION_STATUS_CLOSED = 'Chiusa';
    public static AUCTION_STATUS_CANCELED = 'Annullata';

    public static AUCTION_LOT_STATUS_DRAFT = 'Bozza';
    public static AUCTION_LOT_STATUS_CANCELED = 'Cancellato';
    public static AUCTION_LOT_STATUS_INPUBLISHING = 'In Pubblicazione';
    public static AUCTION_LOT_STATUS_PUBLISHED = 'Pubblicato';
    public static AUCTION_LOT_STATUS_REJECTED = 'Rifiutato';
    

    public static CORIPET_MENU_ACTIONS_REQ_PUBLISH = 'common.richiedi-pubblicazione';
    public static CORIPET_MENU_ACTIONS_UNDO_REQ_PUBLISH = 'common.annulla-richiesta-pubblicazione';
    public static CORIPET_MENU_ACTIONS_MODIFY = 'common.modifica';
    public static CORIPET_MENU_ACTIONS_DELETE = 'common.cancella';
      
    public static OPERATOR_STATUS_ACTIVE = 'Attivo';
    public static OPERATOR_STATUS_NOT_ACTIVE = 'Disabilitato';
    
    public static ORG_USER_TAB = 'Dati personali referente';
    public static ORG_TAB = 'anagrafica-azienda.informazioni-azienda';

    public static FLAG_APPROVED = 'Y';
    public static FLAG_REJECTED = 'N';
    public static FLAG_PENDING = '';

    public static FLAG_APPROVED_TEXT = 'Attivo';
    public static FLAG_REJECTED_TEXT = 'Non abilitato';
    public static FLAG_PENDING_TEXT = 'In attesa di abilitazione';
    public static FLAG_APPROVED_CSS_CLASS = 'ok';
    public static FLAG_REJECTED_CSS_CLASS = 'ko';
    public static FLAG_PENDING_CSS_CLASS = 'pending';

    public static PET_PREFIX = 'PET-';
    public static PET_COLORATO = 'PET-Colorato';
    public static PET_CSS_COLORATO = 'colorato';
    public static PET_AZZURRATO = 'PET-Azzurrato';
    public static PET_CSS_AZZURRATO = 'azzurrato';
    public static PET_TRASPARENTE = 'PET-Trasparente';
    public static PET_CSS_TRASPARENTE = 'trasparente';
    public static PET_MISTO = 'PET-Misto';
    public static PET_CSS_MISTO = 'misto';

    public static FLAG_AUC_CLOSED = 'common.chiusa-o-capacita-esaurita';
    public static FLAG_WINNING_LOT = 'common.lotto-aggiudicato';
    public static FLAG_EXTRATIME_LOT = 'common.extra-time';
    public static FLAG_AUC_DISABLED = 'common.regole-raggiunte';
    
    public static SLIDER_TYPE_CAPACITY = 'SLIDER_TYPE_CAPACITY';
    public static SLIDER_TYPE_PET_AZZURRATO = 'PET-Azzurrato';
    public static SLIDER_TYPE_PET_COLORATO = 'PET-Colorato';
    public static SLIDER_TYPE_PET_TRASPARENTE = 'PET-Trasparente';

    public static TIPOLOGIA_ASTA_DEFAULT = 'TIPO1';

    public static AUC_REFRESH_LOT_MILLIS: number = 15000;
    public static AUC_REFRESH_LOT_MILLIS_KEY = 'AUC_REFRESH_LOT_MILLIS';

    public static AUC_TAGLIO_RILANCIO_KEY = 'TAGLIO_RILANCIO_LIBERO';
    public static AUC_TAGLIO_RILANCIO_LIBERO = '1';
    public static AUC_TAGLIO_RILANCIO_LIST = '0';

    public static AUC_MOTORE_ASTA_SINGOLA = 'SINGOLA';
    public static AUC_MOTORE_ASTA_MULTIPLA = 'MULTIPLA';

    public static AUC_VIEW_LIST = 'LIST';
    public static AUC_VIEW_BOX = 'BOX';

    public static GEO_AREA_NORD = 'NORD';
    public static GEO_AREA_CENTRO = 'CENTRO';
    public static GEO_AREA_SUD = 'SUD E ISOLE';

    public static LANG_IT_CODE = 'IT';
    public static LANG_EN_CODE = 'EN';

    public static LANG_IT_DESC = 'Italiano';
    public static LANG_EN_DESC = 'Inglese';

    public static COMPANY_OPERATOR_STATUS_ENABLED = 'abilitato';
    public static COMPANY_OPERATOR_STATUS_DISABLED = 'disabilitato';

    public static MENU_USER_PREFIX = '/user/';
    public static MENU_CORIPET_PREFIX = '/coripet/';
    public static MENU_ADMIN_PREFIX = '/admin/';

    public static AUC_ENABLE_STYLE_TO_DDL_KEY = 'ENABLE_STYLE_TO_DDL';
    public static AUC_ENABLED_STYLE_TO_DDL = '1';
    public static AUC_DISABLED_STYLE_TO_DDL = '0';

}
