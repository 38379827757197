<div class="content p-4 my-3">
    <form id="registerConsents" class="content form-group" [formGroup]="consensiForm"
        *ngIf="consensiForm && registrazioneConsensi">
        
        <section class="step-section-black step-section focused d-flex pb-4">
            <div class="card flex-fill me-4">
                <h2 class="fs28 inter bold gray-dark pb-4" translate>registrazione.salvataggio-consensi</h2>
                <div class="fs20 gray-dark">
                    <span translate>registrazione.per-porter</span>
                </div>
            </div>
            <div class="infos">
                <div class="number-wrapper lh-1">
                    <div class="number fs32 bold gray-dark pb-2">01</div>
                    <div class="label">
                        <span translate>registrazione.consensi_utente</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms d-flex pb-4">
            <div class="card flex-fill me-4">
                <h2 class="fs28 inter bold gray-dark pb-4" translate>registrazione.informativa-privacy</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkPrivacy')}"
                        (mouseleave)="validateField('checkPrivacy')">
                        <div class="input-wrapper-black full">
                            <input type="checkbox" id="check-personal-datas" class="big" formControlName="checkPrivacy">
                            <label for="check-personal-datas"><span translate>registrazione.cliccando-qui</span>&nbsp;<a
                                    href="regolamenti/CORIPET_portale aste_Informativa Privacy_20180107.pdf" target="_blank"><span
                                        translate>registrazione.privacy-policy</span></a>&nbsp;<span
                                    translate>registrazione.e-la-nostra</span>&nbsp;<a href="regolamenti/cookie-policy.pdf"
                                    target="_blank" translate>registrazione.cookie-policy</a>.</label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1">
                    <div class="number fs32 bold gray-dark pb-2">04</div>
                    <div class="label">
                        <span translate>registrazione.trattamento_dati</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms d-flex pb-4">
            <div class="card flex-fill me-4">
                <h2 class="fs28 inter bold gray-dark pb-4" translate>registrazione.regolamento-aste-tematiche</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkRules')}"
                        (mouseleave)="validateField('checkRules')">
                        <div class="input-wrapper-black full">
                            <input type="checkbox" id="check-general-conditions" class="big"
                                formControlName="checkRules">
                            <label class="fs20" for="check-general-conditions" translate>
                                <span translate>registrazione.clicca-qui-regolamento-1</span>&nbsp; <a
                                    href="regolamenti/04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf"
                                    target="_blank"><span
                                        translate>registrazione.modulo-regolamento</span></a>&nbsp;<span
                                    translate>registrazione.clicca-qui-regolamento-2</span>
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1">
                    <div class="number fs32 bold gray-dark pb-2">05</div>
                    <div class="label">
                        <span translate>registrazione.condizioni_generali_vendita</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms d-flex pb-4">
            <div class="card flex-fill me-4">
                <h2 class="fs28 inter bold gray-dark pb-4" translate>registrazione.accettazione-espressa</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkAcceptance')}"
                        (mouseleave)="validateField('checkAcceptance')">
                        <div class="input-wrapper-black full">
                            <input type="checkbox" id="check-auction-rules" class="big"
                                formControlName="checkAcceptance">
                            <label for="check-auction-rules" translate>registrazione.testo-accettazione-espressa</label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1">
                    <div class="number fs32 bold gray-dark pb-2">06</div>
                    <div class="label">
                        <span translate>registrazione.regolamento_aste</span>
                    </div>
                </div>
            </div>
        </section>
        <div class="main-actions mt-3">
            <button class="btn primary" id="btn_registrationStep2" (click)="saveUserConsents()"
                translate>registrazione.salva</button>
        </div>
    </form>
</div>