import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuctionService } from 'src/app/services/auction.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { AuctionItemPublished } from 'src/app/models/dataModels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-next-auction-countdown',
  templateUrl: './next-auction-countdown.component.html',
  styleUrls: ['./next-auction-countdown.component.css']
})
export class NextAuctionCountdownComponent extends APIService implements OnInit, OnDestroy {

  constructor(localUtil: LocalUtilService,
    location: Location,
    router: Router,
    public dashboardService: DashboardService,
    public auctionService: AuctionService,
    public messageService: MessagesService) {
        super(localUtil, location, router);
  }

  auctionsPublished: AuctionItemPublished[] = [];
  serverTime: string;
  private subscriptions: Subscription[] = [];
  
  ngOnInit(): void {
    this.getServerTime();
    this.searchPublicAuctions();
  }

  getServerTime() {
    const service = this.auctionService.getServerTime().subscribe(
      res => {
      this.serverTime = res.dateTime;
    }, this.handleError(false));
    this.subscriptions.push(service);
  }

  searchPublicAuctions() {

    const service = this.auctionService.listaAste(ConstantsModule.AUCTION_STATUS_PUBLISHED).subscribe(
      res => {
        if(res.list!=undefined && res.list.length >0){

          res.list.forEach(item => {
            let aucItem: AuctionItemPublished = new AuctionItemPublished();            
            
            aucItem.dataInizioAsta = item.dataOraInizioAsta.split(" ")[0];
            aucItem.oraInizioAsta = item.dataOraInizioAsta.split(" ")[1];
            aucItem.dataFineAsta = item.dataOraFineAsta.split(" ")[0];
            aucItem.oraFineAsta = item.dataOraFineAsta.split(" ")[1];
            aucItem.codiceAsta = item.codiceAsta;
            aucItem.visible = true;
            
            this.auctionsPublished.push(aucItem);
          });
         }
      }, this.handleError(true));
    this.subscriptions.push(service);
    //setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);
  }

  reloadCurrentPage(e) {
    const currentUrl = this.router.url;
    this.router.navigateByUrl(currentUrl);
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
