import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { LocalUtilService } from "./local-util.service";

@Injectable({
  providedIn: 'root'
})
export abstract class RoleGuard implements CanActivate {
  
  constructor(public localUtil: LocalUtilService, public router: Router) {}

  protected abstract hasAccess(): boolean;

  canActivate(): boolean {
    if (this.hasAccess()) {
      return true;
    } else {
      this.router.navigate(['/login']); // Redirect to login
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsUserCanActivate extends RoleGuard {
  
  protected hasAccess(): boolean {
    return this.localUtil.isUser();
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsCoripetCanActivate extends RoleGuard {
  
  protected hasAccess(): boolean {
    return this.localUtil.isCoripet();
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsAdminCanActivate extends RoleGuard {

  protected hasAccess(): boolean {
    console.log("isAdmin");
    return this.localUtil.isAdmin();
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsNotLogged extends RoleGuard {
  
  protected hasAccess(): boolean {
    return this.localUtil.isNotLogged();
  }

  canActivate(): boolean {
    if (this.hasAccess()) {
      return true;
    } else {
      this.router.navigate(['/']); // Redirect to home or another page
      return false;
    }
  }
}
