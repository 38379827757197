import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AuctionBoxTimer, AuctionLotTimerItem, HeaderDetail, HeaderStickyItem, SliderValue } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { TimerComponent } from '../timer/timer.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("timer1", { static: false }) timer1: TimerComponent;
  @ViewChild("timer2", { static: false }) timer2: TimerComponent;
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public registrationService: RegistrationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    public commonService: CommonService,
    private route: ActivatedRoute) {
    super(localService, location, router);

    const service1 = this.localUtil.subscribeUserHeaderParameters().subscribe(
      data => {
        this.localUtil.hideHeaderSticky(undefined);
        this.ngOnInit();
      });

    const service2 = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkConsents();
        this.ngOnInit();
      }
    });

    const service3 = this.headerMonitor = this.localUtil.subscribeHeaderMonitor().subscribe(
      data => {
        this.headerDetail = data;
      });
    this.subscriptions.push(service1, service2, service3);

    this.initBoxTimers();
    this.initServerTimeMonitor();
  }

  //headerMonitor new
  headerDetail: HeaderDetail = new HeaderDetail();

  waitEnableReload: boolean = false;

  //header 1
  nome: string;
  qualifica: string;
  logoUrl: string;

  //header 2
  frameworksCapacity: string;
  totaleFidejussione: string;
  dataScadenzaFidejussione: string;

  //visibilityCompanySubHeader: boolean = true;
  //visibilityCompanyCapacitySubHeader: boolean = false;
  sliderValueCapacity: SliderValue;
  sliderValueAzzurrato: SliderValue;
  sliderValueColorato: SliderValue;
  sliderValueTrasparente: SliderValue;

  //serverTime
  serverTime: string;
  currentCost: string;

  //sticky header auction
  stickyHeaderAuction: boolean = false;

  //sticky header auction boxes view
  stickyHeaderAuctionBox: boolean = false;

  motoreAsta: string;
  loaderLotsEnabled: boolean = false;

  existsAuctionCode: boolean = true;

  timers: AuctionBoxTimer[] = [];

  serverTimeMonitor: Subscription;
  headerMonitor: Subscription;
  timersMonitor: Subscription;
  lotsHeaderMonitor: Subscription;
  currentCostMonitor: Subscription;
  auctionHeaderStickyMonitor: Subscription;
  sliderCompanySubHeaderMonitor: Subscription;

  routerPath: boolean = true;
  headerVisible: boolean = true;
  ngOnInit(): void {
    /*if (this.router.url.includes('upload')) {
      this.headerVisible = false;
    } else {*/
    this.headerVisible = true;
    //}
    if (this.router.url.includes('azienda') || this.router.url.includes('upload') || this.router.url.includes('dettaglio') ||
      this.router.url.includes('operatore-dashboard') || this.router.url.includes('operatore-operatori') || this.router.url.includes('operatore-aziende') || this.router.url.includes('admin-centriselezione') || this.router.url.includes('operatore-aste') ||
      this.router.url.includes('admin-operatori') || this.router.url.includes('admin-aste') || this.router.url.includes('admin-setup') || this.router.url.includes('admin-dashboard') || this.router.url.includes('admin-aziende') || this.router.url.includes('aste-in-corso-box') || this.router.url.includes('aste-in-corso')) {
      this.routerPath = false;
    } else if (this.router.url.includes('dashboard')) {
      this.routerPath = true;
    } else {
      this.routerPath = true;
    }

    this.setHeaderCompanyData();
    this.initActiveAuction();
    this.initDefaultHeader();
    this.showCapsNoAuction();
    this.initSliderMonitor();
    this.initHeaderMonitors();
    this.initLotsLoaderMonitor();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  initBoxTimers() {
    const service = this.timersMonitor = this.localUtil.getTimersMonitor().subscribe(
      (timers: AuctionBoxTimer[]) => {
        this.timers = timers;

      }, this.handleError(false)
    );
    this.subscriptions.push(service);
  }

  timerLotEndRaised(timerItem: AuctionLotTimerItem, timer: AuctionBoxTimer) {
    if (timerItem.isClosed) {
      this.timers = undefined;
      if (this.timer1) {
        this.timer1.stopTimer();
      }
      if (this.timer2) {
        this.timer2.stopTimer();
      }
      this.localUtil.raiseTimersEndCallback();
    }
  }

  initDefaultHeader() {
    let headerDetail: HeaderDetail = new HeaderDetail();
    this.localUtil.setHeaderMonitor(headerDetail);
  }

  initActiveAuction() {
    setInterval(() => {
      this.existsAuctionCode = this.localUtil.activeAuctionCode != undefined;
      if (!this.existsAuctionCode) {
        this.stickyHeaderAuction = false;
        this.stickyHeaderAuctionBox = false;
      }
    }, 500);
  }

  initLotsLoaderMonitor() {
    if (this.existsAuctionCode) {
      const service = this.lotsHeaderMonitor = this.localUtil.getLotsLoaderMonitor().subscribe(
        res => {
          this.loaderLotsEnabled = true;
          setTimeout(() => {
            this.loaderLotsEnabled = false;
          }, 1000);
        }, this.handleError(true)
      );
      this.subscriptions.push(service);
    }
  }

  getShowAuctionHeaders() {
    return this.existsAuctionCode;
  }

  initHeaderMonitors() {

    if (this.existsAuctionCode) {
      const service1 = this.currentCostMonitor = this.localUtil.getCurrentCostMonitor().subscribe(
        res => {
          this.currentCost = res;
        }, this.handleError(true)
      );
      
      const service2 = this.auctionHeaderStickyMonitor = this.localUtil.getAuctionHeaderStickyMonitor().subscribe(
        (res: HeaderStickyItem) => {
          this.stickyHeaderAuction = res.value;
          this.stickyHeaderAuctionBox = res.value;
          this.motoreAsta = res.motoreTipologiaAsta;
          if (this.motoreAsta == 'SINGOLA') {
            this.headerDetail.capacityHeader = false;
          } else {
            this.headerDetail.capacityHeader = true;
          }
          this.adjustStickyHeaderPosition();
        }, this.handleError(true)
      );

      this.subscriptions.push(service1, service2);
    }

  }

  adjustStickyHeaderPosition() {
    try {
      $('#cards-lots-list-header').attr('style', 'margin-left:0px; width:100%;');
    } catch (err) {
      console.error(err);
    }
  }
    


  initServerTimeMonitor() {
    const service = this.serverTimeMonitor = this.localUtil.getServerTimeMonitor().subscribe(
      serverTime => {
        if (serverTime) {
          this.serverTime = moment(serverTime, 'DD/MM/YYYY HH:mm:ss').format('HH:mm');
        }
      }
    );
    this.subscriptions.push(service);
  }


  initSliderMonitor() {
    if (this.existsAuctionCode) {
      const service = this.sliderCompanySubHeaderMonitor = this.localUtil.sliderCompanySubHeaderMonitor().subscribe(
        (sliderValue: SliderValue) => {
          if (sliderValue.type == ConstantsModule.SLIDER_TYPE_CAPACITY) {
            this.sliderValueCapacity = sliderValue;
          } else if (sliderValue.type == ConstantsModule.SLIDER_TYPE_PET_AZZURRATO) {
            this.sliderValueAzzurrato = sliderValue;
          } else if (sliderValue.type == ConstantsModule.SLIDER_TYPE_PET_COLORATO) {
            this.sliderValueColorato = sliderValue;
          } else if (sliderValue.type == ConstantsModule.SLIDER_TYPE_PET_TRASPARENTE) {
            this.sliderValueTrasparente = sliderValue;
          }
        }, this.handleError(true)
      );
      this.subscriptions.push(service);
    }
  }

  checkConsents() {
    if (this.localUtil.isUser()) {
      const service = this.registrationService.checkConsensiUtente(this.localService.authParams.user_detail.id.toString()).subscribe(
        (data: any) => {
          if (!data.consent) {
            this.router.navigate([ConstantsModule.USER_CONSENTS_PATH]);
          }
        }, this.handleError(true)
      );
      this.subscriptions.push(service);
    }
  }

  setHeaderCompanyData() {
    if (sessionStorage.getItem('user')) {
      this.nome = this.localUtil.authParams.user_detail.nome + ' ' + this.localUtil.authParams.user_detail.cognome;
      this.qualifica = this.localUtil.authParams.user_detail.nome + this.localUtil.authParams.user_detail.cognome;
      this.qualifica = this.setQualifica(this.localUtil.ruolo);
    }
  }

  setQualifica(slug_qualifica: string) {
    switch (slug_qualifica) {
      case "REF_AZIENDALE":
        this.logoUrl = "";
        return "Ref. Aziendale";
        break;
      case "OPER_AZIENDALE":
        this.logoUrl = "";
        return "Op. Aziendale";
        break;
      case "OPER_PLASTICFINDER":
        this.logoUrl = "";
        return "Op. Admin";
        break;
      case "OPERATORE":
        this.logoUrl = "public/images/coripet.svg";
        return "Op. Coripet";
        break;
      default:
        return slug_qualifica;
        break;
    }
  }

  showCapsNoAuction() {
    if (sessionStorage.getItem('user')) {
      let orgDetail = this.localUtil.authParams.organization_detail;
      this.totaleFidejussione = this.localUtil.formatCurrency(orgDetail.totaleFidejussione, undefined);
      this.dataScadenzaFidejussione = orgDetail.dataScadenzaFidejussione;

      if (this.isUser()) {
        const service = this.auctionService.getProductionCapacity().subscribe(
          (capData) => {
            this.frameworksCapacity = this.localUtil.formatNumber2(capData.rule1.totalEligibility + " t");
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }

    }

  }

  getDisabledReloadLots() {
    return !this.waitEnableReload ? false : true;
  }

  reloadLots() {
    if (this.existsAuctionCode && !this.getDisabledReloadLots()) {
      if (!this.waitEnableReload) {
        const scrollY = window.scrollY;
        this.waitEnableReload = true;
        this.localUtil.reloadLots();
      
      setTimeout(() => {
        window.scrollTo(0, scrollY);
        this.waitEnableReload = false;
      }, 1000);
    }
    }
  }


  getView() {
    if (this.router.url.indexOf('aste-in-corso-box') > -1) {
      return ConstantsModule.AUC_VIEW_BOX;
    } else {
      return ConstantsModule.AUC_VIEW_LIST;
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
