import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { NazioneItem, OrganizationPlantItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-elenco-impianti',
  templateUrl: './elenco-impianti.component.html',
  styleUrls: ['./elenco-impianti.component.css'],
})
export class ElencoImpiantiComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("dataGridPlants", { static: false }) dataGridPlants: DxDataGridComponent;
  @Input() plants: OrganizationPlantItem[] = [];
  @Input() orgname: string;
  @Output() monitorEvent: EventEmitter<boolean> = new EventEmitter();
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  detailPlant: OrganizationPlantItem;
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    private translate: TranslateService) {
    super(localService, location, router);

  }

  ngOnInit(): void {
    this.loadNazioni();
    this.loadProvince();
  }

  loadNazioni() {
    console.log('loadNazioni');
    const service = this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      },
        this.handleError(true)
      );
      this.subscriptions.push(service);
  }

  loadProvince() {
    this.province_list = [];
    const service = this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
        this.handleError(true)
      );
      this.subscriptions.push(service);
    }

  getDocumentStatusClass(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = "ko";
    } else {
        if (validated == ConstantsModule.FLAG_APPROVED) {
            output = "ok";
        } else if (validated == ConstantsModule.FLAG_REJECTED) {
            output = "ko";
        } else if (validated == ConstantsModule.FLAG_PENDING) {
            output = "pending";
        }
    }
    return output;
  }
  
  getDocumentStatus(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
    } else {
        if (validated == ConstantsModule.FLAG_APPROVED) {
            output = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
        } else if (validated == ConstantsModule.FLAG_REJECTED) {
            output = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
        } else if (validated == ConstantsModule.FLAG_PENDING) {
            output = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
        }
    }
    return output;
  }

  approveDocumentFramework(document: string, frameworkId: number) {
    console.log('approveFramework() this.docname: ' + document);
    const service = this.organizationService.approveDocumentFramework(this.orgname, document, frameworkId).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.updateFrameworkStatus(document, frameworkId, ConstantsModule.FLAG_APPROVED);
        });

      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  rejectDocumentFramework(document: string, frameworkId: number) {
    console.log('rejectFramework() this.docname: ' + document + ' frameworkId: ' + frameworkId);
    const service = this.organizationService.rejectDocumentFramework(this.orgname, document, frameworkId).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.updateFrameworkStatus(document, frameworkId, ConstantsModule.FLAG_REJECTED);
        });

      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  updateFrameworkStatus(document: string, frameworkId: number, status: string) {
    if (this.plants) {
      this.plants.forEach((plant:OrganizationPlantItem) => {
        if (plant.id == frameworkId) {
          console.log('updateFrameworkStatus() document: ' + document + ' frameworkId: ' + frameworkId + ' status: ' + status);
          if (document == ConstantsModule.DOC_FRAMEWORK_9001) {
            plant.certificazioneIso9001Valid = status;
          } else if (document == ConstantsModule.DOC_FRAMEWORK_14001) {
            plant.certificazioneIso14001Valid = status;
          } else if (document == ConstantsModule.DOC_FRAMEWORK_DOCAUTH) {
            plant.docAutorizzazioneValid = status;
          }
        }
      });
    }
  }

  approveFramework(frameworkId: number) {
    const service = this.organizationService.validateOrganizationPlant(this.orgname, frameworkId).subscribe(
      (data) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.updateStatusPlant(frameworkId, ConstantsModule.FLAG_APPROVED, ConstantsModule.FLAG_APPROVED_CSS_CLASS, ConstantsModule.FLAG_APPROVED_TEXT);
        });
        this.subscriptions.push(service);

    },
    this.handleError(true));
    this.subscriptions.push(service);
  }

  rejectFramework(frameworkId: number) {
    const service = this.organizationService.rejectOrganizationPlant(this.orgname, frameworkId).subscribe(
      (data) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.updateStatusPlant(frameworkId, ConstantsModule.FLAG_REJECTED, ConstantsModule.FLAG_REJECTED_CSS_CLASS, ConstantsModule.FLAG_REJECTED_TEXT);
        });
        this.subscriptions.push(service);

    },
    this.handleError(true));
    this.subscriptions.push(service);
  }

  updateStatusPlant(frameworkId: Number, status: string, cssClass: string, statusText: string) {
    if (this.plants && frameworkId) {
      this.plants.forEach((plant:OrganizationPlantItem) => {
        if (plant.id == frameworkId) {
          plant.status = status;
          plant.statusClass = cssClass;          
          plant.status = statusText;
        }
      });
    }
  }

  onRowExpanding(e) {
    console.log('onRowExpanding()');
    //this.dataGridPlants.instance.cancelEditData();
    //this.dataGridPlants.instance.collapseAll(-1);
    //this.dataGridPlants.instance.editRow(this.dataGridPlants.instance.getRowIndexByKey(e.key));
  }

  onEditingStart(e) {
    this.dataGridPlants.instance.cancelEditData();
    this.dataGridPlants.instance.collapseAll(-1);
    this.detailPlant = e.data;
  }

  onRowUpdate(e) {
    console.log('onRowUpdate()');
    let detail: OrganizationPlantItem = $.extend({},e.oldData,e.newData);
    const service = this.organizationService.updateOrganizationPlant(this.orgname, detail).subscribe(
      (data) => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_UPDATE"), true);
    },
    this.handleError(true));
    this.subscriptions.push(service);
  }

  onRowPrepared(info) {
      if (info.rowType === 'data') {
        info.rowElement.addClass('auction');
      }
    }

    onCellPrepared(e) {
      let saveLabelKey = this.messageService.getLabelFromCode("SALVA");
      let plantLabelKey = this.messageService.getLabelFromCode("IMPIANTO");
      if (e.rowType === 'detail' && e.row.isEditing && !e.data.activated) {
        let saveLabel: string = 'SALVA';
        let plantLabel: string = 'IMPIANTO';
        let buttonLinks = e.cellElement.find('.dx-button-text');
        const service1 = this.translate.get(saveLabelKey).subscribe(v => saveLabel = v);
        const service2 = this.translate.get(plantLabelKey).subscribe(v => plantLabel = v);
        for (let buttonLink of buttonLinks) {
          if (buttonLink.innerText == saveLabel) {
            buttonLink.innerText = buttonLink.innerText + ' ' + plantLabel;
            e.component.updateDimensions();
          }
        }
        this.subscriptions.push(service1, service2);
      }
    }

    ngOnDestroy() {
      try {
        this.subscriptions.forEach(sub => {
                  
          sub.unsubscribe()
        });
      } catch (err) {
        //
      }
    }
}
