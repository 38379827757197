import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { IsAdminCanActivate } from '../services/IsCanActivate';
import { AdminDashboardComponent } from "./components/dashboard/dashboard.component";
import { AdminOperatoriComponent } from "./components/operatori/operatori.component";
import { AdminAziendeComponent } from "./components/aziende/aziende.component";
import { AdminAsteComponent } from "./components/aste/aste.component";
import { AdminAstaDettaglioComponent } from "./components/aste/dettaglio/dettaglio.component";
import { AdminSetupComponent } from "./components/setup/setup.component";
import { AdminCentriDiSelezioneComponent } from "./components/centri-di-selezione/centri-di-selezione.component";
import { AdminComponent } from "./admin.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { HttpLoaderFactory } from "../app.module";
import { CommonsModule } from "../commons/commons.module";
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxPivotGridModule, DxChartModule, DxTemplateHost, DxPopupModule, NestedOptionHost } from 'devextreme-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FlatpickrModule } from "angularx-flatpickr";


const ADMIN_ROUTES: Routes = [
  {
    path: 'admin/admin-dashboard.html', pathMatch: 'full', component: AdminDashboardComponent,
    data: {
      title: 'Dashboard'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-operatori.html', pathMatch: 'full', component: AdminOperatoriComponent,
    data: {
      title: 'Operatori'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-aziende.html', pathMatch: 'full', component: AdminAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-aziende.html/:id', pathMatch: 'full', component: AdminAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-aste.html', pathMatch: 'full', component: AdminAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-aste-dettaglio.html/:codiceAsta', pathMatch: 'full', component: AdminAstaDettaglioComponent,
    data: {
      title: 'Dettaglio asta'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-setup.html', pathMatch: 'full', component: AdminSetupComponent,
    data: {
      title: 'Setup'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin/admin-centriselezione.html', pathMatch: 'full', component: AdminCentriDiSelezioneComponent,
    data: {
      title: 'Centri di selezione'
    }, canActivate: [IsAdminCanActivate]
  },
]

@NgModule({
  declarations: [   
    AdminComponent,
    AdminDashboardComponent, AdminOperatoriComponent, AdminAziendeComponent,
    AdminAsteComponent, AdminAstaDettaglioComponent, AdminSetupComponent,
    AdminCentriDiSelezioneComponent,
  ],
  exports: [    
    RouterModule
  ],
  bootstrap: [AdminComponent], 
  imports: [
    CommonsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule, DxBulletModule,
    DxPopupModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    CKEditorModule,
    DxPivotGridModule,
    DxChartModule,
    FlatpickrModule.forRoot(),
    DxDropDownButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    DxButtonModule,
    FlatpickrModule.forRoot(),
    RouterModule.forChild(ADMIN_ROUTES),    
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ], 
  providers: [provideHttpClient(withInterceptorsFromDi()), DxTemplateHost, NestedOptionHost],
  schemas: [],  
})
export class AdminModule { }