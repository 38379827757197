<div class="p-4">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>dashboardPage.aziende-da-validare</h2>
    <div id="auctions-list" style="display: contents;">
        <dx-data-grid 
            id="gridContainer" 
            [dataSource]="organizations_list"
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onEditingStart)="onRowUpdateOrganization($event)" 
            (onRowClick)="openDettaglioOrganization($event)"
            (onCellPrepared)="onCellPrepared($event)" 
            (onRowPrepared)="onRowPrepared($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            


            <dxi-column dataField="id" [allowEditing]="false" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false"></dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="false" [visible]="false"></dxi-column>
            <dxi-column dataField="ragioneSociale" caption="{{'dashboardPage.ragioneSociale' | translate}}"
                dataType="string" [visible]="true"></dxi-column>
            <dxi-column dataField="dataCreazione" [width]="160" caption="{{'dashboardPage.dataCreazione' | translate}}"
                dataType="string" [visible]="true" cellTemplate="regTemplate"></dxi-column>
            <dxi-column dataField="referente" caption="{{'dashboardPage.referente' | translate}}" dataType="string"
                [visible]="true" cellTemplate="referentTemplate"></dxi-column>
            <dxi-column dataField="status" [width]="155" caption="{{'dashboardPage.status' | translate}}" dataType="string"
                [visible]="true" cssClass="azienda registrata"></dxi-column>
            <dxi-column dataField="totaleFidejussione" [width]="150" caption="{{'dashboardPage.totaleFidejussione' | translate}}"
                dataType="string" [visible]="true" cellTemplate="fidTemplate"></dxi-column>
            <dxi-column dataField="capacitaProduttiva" [width]="150" caption="{{'dashboardPage.capacitaProduttiva' | translate}}"
                dataType="string" [visible]="true" cellTemplate="capacityTemplate"></dxi-column>

            <dxo-editing mode="form" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
            </dxo-editing>
            
            <div *dxTemplate="let regTemplate of 'regTemplate'">
                <!--span translate>dashboardPage.registrata-il</span-->{{regTemplate.data.dataCreazione}}
            </div>

            <div *dxTemplate="let referentTemplate of 'referentTemplate'">
                <a>{{referentTemplate.data.referente}}</a>
            </div>

            <div *dxTemplate="let fidTemplate of 'fidTemplate'">
                <a>{{formatNumber(fidTemplate.data.totaleFidejussione)}} &euro;</a>
            </div>

            <div *dxTemplate="let capacityTemplate of 'capacityTemplate'">
                <a>{{formatNumber(capacityTemplate.data.capacitaProduttiva)}} t</a>
            </div>
            
            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

        </dx-data-grid>
    </div>
    <h2 class="fs28 gray-dark inter bold my-5" translate>dashboardPage.aste-disponibili</h2>
    <div id="auctions-list" style="display: contents;">
        <dx-data-grid id="gridContainer" 
            [dataSource]="auctions_list" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowClick)="openDettaglioAuction($event)"
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'dashboardPage.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string" showEditorAlways="false" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-code">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}" dataType="string" [visible]="true" >
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string" [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

        </dx-data-grid>
    </div>

    <div class="activities bottom50">
        <article>
            <h2 translate>dashboardPage.attivita</h2>
            <ul class="activities-list" id="activities-list">
                <li *ngFor="let activity of activities_list; let i = index">
                    <div class="date">{{activity.createDate}}</div>
                    <div class="description font-a-size" *ngIf="isCoripet()">
                        <a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                            &nbsp;<span translate>activity.e-stato</span>&nbsp;<span translate>activity.{{activity.operazione}}</span>&nbsp;<span translate>activity.{{activity.desinenza}}</span>&nbsp;<a
                            [routerLink]="['/operatore-operatori.html/']"><span translate>activity.lista-operatori</span></a>&nbsp;<span translate>activity.dell-azienda</span>&nbsp;<a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                    </div>
                </li>
            </ul>
        </article>
    </div>
</div>