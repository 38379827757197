<dx-popup [width]="1000" [height]="150" [showTitle]="true" title="{{'Attenzione'|translate}}" [dragEnabled]="false"
    [showCloseButton]="true" [(visible)]="this.errorPopup">
    <div *dxTemplate="let data of 'content'">
        <p>{{errorMessage}}</p>
    </div>
</dx-popup>
<dx-popup [width]="1000" [showTitle]="true" title="{{'dettaglio.dettaglio-lotto'|translate}}" [dragEnabled]="false"
    [showCloseButton]="true" [(visible)]="this.updatePopup">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <section *ngIf="firstSection" class="step-section focused">
                <div class="card">
                    <div class="d-flex">
                        <div class="input flex-fill mb-2 me-2">
                            <div class="input-wrapper full">
                                <input required [(ngModel)]="codiceLotto" [readonly]="true" type="text" id="codiceLotto"
                                    maxlength="75">
                                <label for="newOperator--codiceLotto" translate>dettaglio.codice-lotto</label>
                            </div>
                        </div>

                        <div class="input flex-fill mb-2">
                            <div class="input-wrapper full">
                                <input required [(ngModel)]="codiceProdotto" type="text" id="codiceProdotto"
                                    maxlength="75">
                                <label for="newOperator--codiceProdotto" translate>dettaglio.codice-prodotto</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="input flex-fill mb-2 me-2">
                            <div class="input-wrapper full">
                                <select name="tipoProdotto" id="tipoProdotto" maxlength="75" required
                                    [(ngModel)]="tipoProdotto" class="form-control">
                                    <option *ngFor="let lot of type_material; let i = index"
                                        [value]="lot.type_material">
                                        {{lot.type_material}}</option>
                                </select>
                                <label for="tipoProdotto" translate>dettaglio.tipo-prodotto</label>
                            </div>
                        </div>

                        <div class="input flex-fill mb-2">
                            <div class="input-wrapper full">
                                <select name="areaGeografica" maxlength="75" id="areaGeografica" required
                                    [(ngModel)]="areaGeografica" class="form-control">
                                    <option *ngFor="let lot of geo_area; let i = index" [value]="lot.area">
                                        {{lot.area}}</option>
                                </select>
                                <label for="newOperator--areaGeografica" translate>dettaglio.area-geografica</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="input flex-fill mb-2 me-2">
                            <div class="input-wrapper full">
                                <input required [(ngModel)]="quantitaTon" type="number" id="quantitaTon" maxlength="75">
                                <label for="newOperator--quantitaTon" translate>dettaglio.quantita-ton</label>
                            </div>
                        </div>

                        <div class="input flex-fill mb-2">
                            <div class="input-wrapper full">
                                <input required [(ngModel)]="prezzoBase" type="number" id="prezzoBase" maxlength="75">
                                <label for="newOperator--prezzoBase" translate>dettaglio.base-d'asta-in</label>
                            </div>
                        </div>
                    </div>

                    <div class="input flex-fill mb-2">
                        <div class="input-wrapper full">
                            <input required [(ngModel)]="dataDisponibilitaLotto" type="date" id="dataDisponibilitaLotto"
                                maxlength="75">
                            <label for="newOperator--dataDisponibilitaLotto"
                                translate>dettaglio.data-disponibilita-lotto</label>
                        </div>
                    </div>

                    <ckeditor id="noteText" nome="noteText" [editor]="Editor" [(ngModel)]="note"></ckeditor>

                    <div class="row mt-4">
                        <p class="fw-bold col-4" translate>dettaglio.codice-centro-di-selezione</p>
                        <p class="fw-bold col-4" translate>dettaglio.quantita</p>
                        <p class="fw-bold col-4" translate>dettaglio.azioni</p>
                    </div>

                    <div class="row mt-4" *ngFor="let item of this.asteDetails; let i = index">
                        <p class="col-4">{{item.codiceCentroSelezione}}</p>
                        <p class="col-4">{{item.quantitaTon}}</p>
                        <button class="col-4 btn btn-primary" (click)="rimuovi(i)"><span
                                translate>dettaglio.rimuovi</span></button>
                    </div>

                    <div class="pt-5">
                        <button class="btn" style="border-color: orange;color: orange;" type="submit"
                            (click)="openPopup()"><i class="fal fa-save"></i>
                            <span translate>dettaglio.aggiungi-centro-di-selezione</span></button>
                    </div>

                    <div class="mt-4">
                        <div class="text-end">
                            <button class="btn" type="submit" (click)="cancel()">
                                <span translate>azienda-operatori.annulla</span></button>
                            <button class="btn" style="border-color: orange;color: orange;" type="submit"
                                (click)="update()"><i class="fal fa-save"></i>
                                <span translate>azienda-operatori.salva</span></button>
                        </div>
                    </div>

                </div>
            </section>
            <section *ngIf="secondSection" class="step-section focused">
                <div class="card">
                    <div class="container-half">
                        <div class="lots-list new-lots-list admin-buttons">
                            
                            <article class="popup">
                                <div class="buttons ingrid buttons-left">

                                    <div class="doc-datas">
                                        <app-file-box docname="excel-lotti.xlsx" [showCoripetButtons]="false"
                                            (file)="getExcelLottiFile($event)"></app-file-box>
                                    </div>
                                    <br />
                                    <button class="box__file btn btncarica success popup"
                                        style="border-color: green;color: green;" type="submit" id="btn_createOperator"
                                        (click)="uploadDocument()"><i class="fal fa-save"></i>
                                        <span translate>azienda-operatori.salva</span></button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </dx-scroll-view>
    </div>
</dx-popup>

<dx-popup [width]="1000" [showTitle]="true" title="{{'dettaglio.aggiungi-centro-di-selezione'|translate}}"
    [dragEnabled]="false" [showCloseButton]="true" [(visible)]="this.showPopup">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <div class="container">
                <div *ngIf="grid">
                    <dx-data-grid id="gridContainer" [dataSource]="cs_list" keyExpr="id" [showColumnHeaders]="false">
                        <dxi-column dataField="codice"></dxi-column>
                        <dxi-column dataField="nome"></dxi-column>
                        <dxi-column dataField="indirizzo"></dxi-column>
                        <dxi-column dataField="codice" cellTemplate="template"></dxi-column>
                        <div *dxTemplate="let cellInfo of 'template'">
                            <dx-button stylingMode="contained" text="Seleziona" [width]="200" type="default"
                                (onClick)="click(cellInfo)">
                            </dx-button>
                        </div>
                        <dxo-paging [pageSize]="40"> </dxo-paging>
                    </dx-data-grid>
                </div>
                <div *ngIf="form">
                    <div class="input mt-5">
                        <div class="input-wrapper full">
                            <input type="text" id="codiceLotto" [(ngModel)]="quantita">
                            <label for="newOperator--codiceLotto" translate>dettaglio.quantità</label>
                        </div>
                        <p *ngIf="mandatory" class="fw-bold text-danger mt-2 mb-5">
                            dettaglio.inserisci-la-quantita`-di-tonnellate</p>
                    </div>
                    <p class="fw-bold mb-4" translate>dettaglio.centro-di-selezione</p>
                    <p>{{editDetails}}</p>
                </div>
            </div>
        </dx-scroll-view>
    </div>
    <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
        [options]="saveButtonOptions">
    </dxi-toolbar-item>
    <dxi-toolbar-item class="px-5" widget="dxButton" toolbar="bottom" location="after" [options]="closeButtonOptions">
    </dxi-toolbar-item>
    <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
        [options]="backButtonOptions">
    </dxi-toolbar-item>
</dx-popup>

<div class="lot" *ngIf="statoAsta == 'Pubblica'">
    <article>
        <div class="full">
            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell flex-grow-1">
                    <div class="lot-number">
                        <div class="num">{{ lotDetail.codiceLotto }}</div>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1">
                    <div class="type type-public">
                        <span translate>dettaglio-lotto.tipo</span>&nbsp;
                        <b translate>dettaglio-lotto.{{lotDetail.tipoProdotto}}</b>
                        <div class="download-scheda" id="{{lotDetail.codiceAsta}}" title="Scheda tecnica"
                            (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.tipoProdotto))"
                            [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.tipoProdotto)" (keydown)="handleKeyDown($event)">
                        </div>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1 small">
                    <div class="quantity">
                        <span translate>dettaglio-lotto.quantit</span>:&nbsp;
                        <b>{{ lotDetail.quantitaTon }} t</b>
                    </div>
                </div>
            </div>

            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell flex-grow-1">
                    <div class="area">
                        <span translate>dettaglio-lotto.area</span>&nbsp;
                        <b translate>aste-incorso.{{ lotDetail.areaGeografica }}</b>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1">
                    <div class="auction-close">
                        <span translate>dettaglio-lotto.base-d-asta</span>&nbsp;
                        <b>{{ lotDetail.prezzoBase }} &euro;/t</b>
                    </div>
                </div>
            </div>

            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell flex-grow-1">
                    <div class="product-code">
                        <span translate>dettaglio-lotto.codice-prodotto</span>&nbsp;
                        <b>{{ lotDetail.codiceProdotto }}</b>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1">
                    <div class="download-scheda d-flex align-items-center" id="{{ lotDetail.codiceAsta }}"
                        title="Scheda tecnica" (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.tipoProdotto))"
                        [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.tipoProdotto)" (keydown)="handleKeyDown($event)">
                        <ng-container *ngIf="lotDetail.schedaTecnica">
                            <i class="bi bi-file-earmark-pdf me-3 blue-dark"></i>
                            <div class="filename-report">
                                <span translate>dettaglio-lotto.scheda-tecnica</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!lotDetail.schedaTecnica" class="pe-auto">
                            <i class="bi bi-file-earmark-pdf me-3 blue-dark"></i>
                            <span class="error text-decoration-none">Scheda tecnica non presente</span>
                        </ng-container>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1 small">
                    <a name="openCentriSelezione" class="note" (click)="openCentriSelezione()"
                        [attr.data-id-lot]="lotDetail.codiceAsta" translate>dettaglio-lotto.centri-di-selezione</a>
                </div>
                <div class="lot-cell flex-grow-1">
                    <a name="openNote" class="note" (click)="openNote()" [attr.data-id-lot]="lotDetail.codiceAsta"
                        translate>dettaglio-lotto.note</a>
                    <span class="hidden" id="note-{{lotDetail.codiceAsta}}">{{ lotDetail.note }}</span>
                </div>
                <div class="lot-cell flex-grow-1 px-lg-4 px-0">
                    <div class="auction-close">
                        <span translate>dettaglio-lotto.data-disponibilit</span>&nbsp;
                        <span translate>dettaglio-lotto.lotto</span>&nbsp;
                        <b>{{ lotDetail.dataDisponibilitaLotto }}</b>
                    </div>
                </div>
            </div>
        </div>
    </article>
</div>

<div class="lot" *ngIf="statoAsta == 'Chiusa'">
    <article>
        <div class="left">
            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-grow-1">
                    <div class="lot-number d-flex align-items-center">
                        <div class="star"></div>
                        <div class="num d-flex align-items-center">
                            {{lotDetail.codiceLotto}}
                            <div class="download-scheda mleft" data="scheda-tecnica"
                                data-lotto="{{lotDetail.codiceLotto}}" data-uuid="{{lotDetail.schedaTecnica}}">
                                <i class="fal fa-file-pdf"></i>
                                <div class="filename-scheda-tecnica hidden">scheda-tecnica-{{lotDetail.codiceLotto}}.pdf
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lot-cell flex-grow-1 ps-3">
                    <div class="type"><span translate>dettaglio-lotto.tipo</span>&nbsp;<b translate>dettaglio-lotto.{{lotDetail.tipoProdotto}}</b>
                    </div>
                </div>
                <div class="lot-cell small flex-grow-1 ps-3">
                    <div class="quantity"><span
                            translate>dettaglio-lotto.quantit</span>:&nbsp;<b>{{lotDetail.quantitaTon}} t</b></div>
                </div>
            </div>

            <!-- Additional lot-row blocks -->

            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-grow-1">
                    <div class="area"><span
                            translate>dettaglio-lotto.area</span>&nbsp;<b>{{lotDetail.areaGeografica}}</b></div>
                </div>
                <div class="lot-cell small flex-grow-1 ps-3">
                    <div class="relaunch"><span
                            translate>dettaglio-lotto.rilanci</span>&nbsp;<b>{{lotDetail.numeroRilanci}}</b></div>
                </div>
                <div class="lot-cell flex-grow-1 ps-3">
                    <div class="auction-close"><span
                            translate>dettaglio-lotto.chiusura-asta</span>&nbsp;<b>{{lotDetail.dataOraFineAstaEffettiva}}</b>
                    </div>
                </div>
                
            </div>
            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-grow-1" *ngIf="isAdmin() || isCoripet()">
                    <div class="auction-close">
                        <span translate>dettaglio-lotto.vincitore</span>&nbsp;
                        <b *ngIf="lotDetail.idOrganization && lotDetail.nomeOrganization">
                            <a *ngIf="isAdmin()" [routerLink]="['/admin-aziende.html/' + lotDetail.idOrganization]">{{lotDetail.nomeOrganization}}</a>
                            <a *ngIf="isCoripet()" [routerLink]="['/operatore-aziende.html/' + lotDetail.idOrganization]">{{lotDetail.nomeOrganization}}</a>
                        </b>
                        <b *ngIf="!lotDetail.idOrganization">
                            -
                        </b>
                    </div>
                </div>
            </div>

            <!-- More content blocks follow the same pattern... -->

        </div>
        <div class="right ms-3">
            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell">
                    <span translate>dettaglio-lotto.base-d-asta</span>&nbsp;<b>{{lotDetail.prezzoBase}} €/t</b>
                </div>
            </div>
            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell">
                    <span translate>dettaglio-lotto.prezzo-finale</span>&nbsp;<b>{{lotDetail.prezzoUltimoRilancio}}
                        €/t</b>
                </div>
            </div>
            <div class="lot-row d-flex flex-wrap">
                <div class="lot-cell total ok">
                    <span translate>dettaglio-lotto.totale</span>&nbsp;<b>{{lotDetail.prezzoUltimoRilancio}} €</b>
                </div>
            </div>

            <!-- Status Section for Admins/Coripet -->

            <div *ngIf="isAdmin()" class="status">
                <div class="lot-row d-flex">
                    <div *ngIf="lotDetail.pagato == 0" class="lot-cell paid ko">
                        <span translate>dettaglio-lotto.non-pagato</span>
                    </div>
                    <div *ngIf="lotDetail.pagato == 1" class="lot-cell paid ok">
                        <span translate>dettaglio-lotto.pagato</span>
                    </div>
                </div>
                <div class="lot-row d-flex">
                    <div *ngIf="lotDetail.spedito == 0" class="lot-cell paid ko">
                        <span translate>dettaglio-lotto.non-ritirato</span>
                    </div>
                    <div *ngIf="lotDetail.spedito == 1" class="lot-cell paid ok">
                        <span translate>dettaglio-lotto.ritirato</span>
                    </div>
                </div>
            </div>

            <!-- Similar blocks for Coripet -->
        </div>
    </article>
</div>

<div class="lot" *ngIf="statoAsta == 'Disponibile'">
    <article>
        <div class="left">
            <!-- First Row -->
            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-fill">
                    <div class="lot-number d-flex align-items-center">
                        <div class="num">
                            {{lotDetail.codiceLotto}}
                        </div>
                    </div>
                </div>
                <div class="lot-cell flex-fill">
                    <div class="type type-public d-flex align-items-center">
                        <span translate>dettaglio-lotto.tipo</span>&nbsp;<b
                            translate>dettaglio-lotto.{{lotDetail.tipoProdotto}}</b>
                        <div class="download-scheda ms-2" id="{{lotDetail.codiceAsta}}" title="Scheda tecnica"
                            (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.tipoProdotto))"
                            [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.tipoProdotto)" (keydown)="handleKeyDown($event)">
                        </div>
                    </div>
                </div>
                <div class="lot-cell small flex-fill">
                    <div class="quantity">
                        <span translate>dettaglio-lotto.quantit</span>:&nbsp;<b>{{lotDetail.quantitaTon}} t</b>
                    </div>
                </div>
            </div>

            <!-- Second Row -->
            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-fill">
                    <div class="area">
                        <span translate>dettaglio-lotto.area</span>&nbsp;<b
                            translate>aste-incorso.{{lotDetail.areaGeografica}}</b>
                    </div>
                </div>
                <div class="lot-cell flex-fill">
                    <div class="auction-close">
                        <span translate>dettaglio-lotto.base-d-asta</span>&nbsp;<b>{{lotDetail.prezzoBase}} &euro;/t</b>
                    </div>
                </div>
            </div>

            <!-- Third Row -->
            <div class="lot-row d-flex flex-wrap align-items-center">
                <div class="lot-cell flex-fill">
                    <div class="product-code">
                        <span translate>dettaglio-lotto.codice-prodotto</span>&nbsp;<b>{{lotDetail.codiceProdotto}}</b>
                    </div>
                </div>
                <div class="lot-cell flex-fill d-flex align-items-center">
                    <div class="download-scheda d-flex align-items-center" id="{{lotDetail.codiceAsta}}"
                        title="Scheda tecnica" (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.tipoProdotto))"
                        [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.tipoProdotto)" (keydown)="handleKeyDown($event)">
                        <ng-container *ngIf="lotDetail.schedaTecnica">
                            <i class="fal fa-file-pdf right10"></i>
                            <div class="filename-report ms-2">
                                <span translate>dettaglio-lotto.scheda-tecnica</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!lotDetail.schedaTecnica" class="pe-auto">
                            <i class="fal fa-file-pdf right10"></i>
                            <span class="error text-decoration-none">Scheda tecnica non presente</span>
                        </ng-container>
                    </div>
                </div>
                <div class="lot-cell small flex-fill">
                    <a name="openCentriSelezione" class="note" (click)="openCentriSelezione()"
                        [attr.data-id-lot]="lotDetail.codiceAsta" translate>dettaglio-lotto.centri-di-selezione</a>
                </div>
                <div class="lot-cell flex-fill">
                    <a name="openNote" class="note" (click)="openNote()"
                        [attr.data-id-lot]="lotDetail.codiceAsta">Note</a>
                    <span class="hidden" id="note-{{lotDetail.codiceAsta}}">{{lotDetail.note}}</span>
                </div>
                <!--
                <div class="lot-cell">
                    <div class="auction-close">
                        <span translate>dettaglio-lotto.data-disponibilit</span>&agrave; 
                        <span translate>dettaglio-lotto.lotto"></span>&nbsp;<b>{{lotDetail.dataDisponibilitaLotto}}</b>
                    </div>
                </div>
                -->
            </div>
        </div>
        <div class="right d-flex flex-column align-items-start">
            <div class="lot-status {{lotDetail.cssClass}} d-flex align-items-center mb-3">
                <i class="fal fa-check me-2 white"></i>
                <i class="fal fa-times me-2 white"></i>
                <span translate>dettaglio-lotto.{{lotDetail.statoLotto}}</span>
            </div>
            <div *ngIf="isCoripet()">
                <div class="action"
                    *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_DRAFT || lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_REJECTED">
                    <div class="action-wrapper choise action-buttons d-flex">
                        <dx-drop-down-button [dropDownOptions]="menuAzioniOptions" [items]="coripet_menu_actions_draft"
                            (onItemClick)="menuAzioniClick($event)" text="{{'dettaglio-lotto.Azioni' | translate}}">
                        </dx-drop-down-button>
                    </div>
                </div>
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING">
                    <div class="action-wrapper choise action-buttons d-flex">
                        <dx-drop-down-button [dropDownOptions]="menuAzioniOptions"
                            [items]="coripet_menu_actions_inpublishreq" (onItemClick)="menuAzioniClick($event)"
                            text="{{'dettaglio-lotto.Azioni' | translate}}">
                        </dx-drop-down-button>
                    </div>
                </div>
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_PUBLISHED">
                </div>
            </div>
            <!-- PULSANTIERA ADMIN -->
            <div *ngIf="isAdmin()">
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_DRAFT">
                </div>
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING">
                    <div class="action-wrapper choise action-buttons d-flex">
                        <button class="btn error btn-cancelapprove-lot me-2" (click)="rejectLot()" title="Rifiuta"
                            tooltip="Rifiuta">
                            <i class="fal fa-times"></i>
                            <span translate>dettaglio-lotto.rifiuta</span>
                        </button>
                        <button class="btn success btn-cancelapprove-lot" (click)="approveLot()" title="Approva"
                            tooltip="Approva">
                            <i class="fal fa-check"></i>
                            <span translate>dettaglio-lotto.approva</span>
                        </button>
                    </div>
                </div>
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_REJECTED">
                    <div class="action-wrapper choise action-buttons d-flex">
                        <button class="btn error btn-cancelreject-lot" (click)="undoRejectLot()">
                            <i class="fal fa-times"></i>
                            <span translate>dettaglio-lotto.annulla-rifiuto</span>
                        </button>
                    </div>
                </div>
                <div class="action" *ngIf="lotDetail.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_PUBLISHED">
                    <div class="action-wrapper choise action-buttons d-flex">
                        <button class="btn error btn-cancelapprove-lot" (click)="undoApproveLot()">
                            <i class="fal fa-times"></i>
                            <span translate>dettaglio-lotto.annulla-approva</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </article>
</div>