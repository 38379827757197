import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private translate: TranslateService) { 

    this.messages.set("OK_OPERATION","common.ok-operation");
    this.messages.set("LOT_CREATE","common.lot-save-success");
    this.messages.set("E:LOGIN_ERR","Autenticazione fallita");
    this.messages.set("E:AUTHENTICATION","Non autenticato");
    this.messages.set("E:AUTHORIZATION_FAIL","Sessione scaduta");
    
    this.messages.set("E:GENERIC","errore.generico");
    this.messages.set("E:ERR_GENERIC","errore.generico.");
    this.messages.set("E:ERR_GENERIC","errore.generico");
    this.messages.set("E:REQ_LOT_PUBL_LIST_EMPTY", "common.no-lotti-bozza-trovati");
    this.messages.set("E:REQ_LOT_UNPUBL_LIST_EMPTY", "common.no-lotti-publish-trovati");
    this.messages.set("E:INVALID_ORG", "Azienda non valida");
    this.messages.set("E:DOWNLOAD_ERROR", "Impossible scaricare il file ");
    
    this.messages.set("E:AUCLOTS_PAYD_ERR_ORG_NOT_EXISTS", "Non è possibile aggiornare un lotto non aggiudicato");
    this.messages.set("E:AUCLOTS_SENT_ERR_ORG_NOT_EXISTS", "Non è possibile aggiornare un lotto non aggiudicato");
    this.messages.set("E:ORG_FULL_USR_EXISTS", "Esiste già un utente con l'indirizzo email inserito");    
    this.messages.set("E:AUC_CREATE_SETUP_NOT_EXISTS", "Le impostazioni di setup delle aste annuali non sono presenti");
    this.messages.set("E:DEL_AUC_SETUP_USED_ERR", "common.cannot-delete-active-setup");
    this.messages.set("E:AUC_OFFER_TIMEOUT", "common.auction-lot-ended");    
    this.messages.set("E:AUC_OFFER_ALREADY_MINE", "Ti stai già aggiudicando il lotto");
    this.messages.set("E:AUC_OFFER_MUST_BE_UPPER_ERR", "L'offerta deve essere maggiore del prezzo attuale");
    this.messages.set("E:AUC_OFFER_REFRESH_ERR", "common.refresh-offer");
    this.messages.set("E:ERR_UPDATE_AUCTION_SETUP_NO_ACTIVE_ROW_EXISTS", "Il setup asta non ha un elemento attivo.<br/>Crea o modifica una riga esistente per coprire la data odierna");
    this.messages.set("ADMIN_CREATE_SUCCESS", "common.admin-create-success");
    this.messages.set("CORIPET_CREATE_SUCCESS", "common.coripet-create-success");
    this.messages.set("BET_SLOT_MANDATORY", "common.taglio-rilancio-obbligatorio");
    this.messages.set("E:AUC_OFFER_AUC_RULE1_FAIL_ERR", "aste-incorso.rule1-fail");
    this.messages.set("E:AUC_OFFER_AUC_RULE2_FAIL_ERR", "aste-incorso.rule2-fail");
    this.messages.set("E:AUC_OFFER_AUC_RULE3_FAIL_ERR", "aste-incorso.rule3-fail");
    
    //label
    this.labels.set("FORGOT_PASSWORD_TITLE","loginPage.recupera-password");
    this.labels.set("FORGOT_PASSWORD_REQUEST","Inserisci il tuo indirizzo email");
    this.labels.set("FORGOT_PASSWORD_SUCCESS","Richiesta di recupero password inviata, controlla nella tua mail");
    //this.labels.set("GENERIC_SUCCESS_MESSAGE_SAVE","Dati salvati correttamente" );

   
    this.labels.set("GENERIC_SUCCESS_MESSAGE_SAVE",this.translate.instant("GENERIC_SUCCESS_MESSAGE_SAVE"));
    this.labels.set("SUCCESS_CREATE_AUCTION_TIPOLOGY_MESSAGE","Tipologia asta creata correttamente");
    this.labels.set("SUCCESS_DELETE_AUCTION_TIPOLOGY_MESSAGE","Tipologia asta cancellata correttamente");    
    this.labels.set("SUCCESS_CREATE_AUCTION_SETUP_MESSAGE","Setup asta creato correttamente");
    this.labels.set("SUCCESS_UPDATE_AUCTION_SETUP_MESSAGE","Setup asta aggiornato correttamente");
    this.labels.set("SUCCESS_DELETE_AUCTION_SETUP_MESSAGE","Setup asta cancellato correttamente");    
    this.labels.set("SUCCESS_DELETE_AUCTION_BET_SLOT_MESSAGE","Taglio di rilancio cancellato correttamente");
    this.labels.set("SUCCESS_CREATE_AUCTION_BET_SLOT_MESSAGE","Taglio di rilancio creato correttamente");
    this.labels.set("SUCCESS_UPDATE_LOT_MESSAGE","common.lot-update-success");
    this.labels.set("SUCCESS_DELETE_LOT_MESSAGE","common.lot-delete-success");
    this.labels.set("SUCCESS_CREATE_LOT_MESSAGE","common.lot-create-success");    
    
    this.labels.set("DELETE_OPERATOR_TITLE","popup-operatori.elimina-operatore");
    this.labels.set("DELETE_AUCTION_TIPOLOGY_TITLE","Elimina tipologia asta");
    this.labels.set("DELETE_AUCTION_SETUP_TITLE","Elimina setup asta");
    
    //this.labels.set("DELETE_OPERATOR_MESSAGE","Sei sicuro di voler eliminare l'operatore ? (%1 - %2 - %3)");
    this.labels.set("DELETE_OPERATOR_MESSAGE","popup-operatori.conferma-elimina-operatore (%1 - %2 - %3)");

    this.labels.set("DELETE_AUCTION_TIPOLOGY_MESSAGE","Sei sicuro di voler eliminare la tipologia asta ? (%1 - %2)");

    this.labels.set("DELETE_FRAMEWORK_TITLE","Elimina impianto");
    this.labels.set("DELETE_FRAMEWORK_MESSAGE","Sei sicuro di voler cancellare l'impianto %1 ?");
    this.labels.set("DELETE_CS_TITLE","Elimina centro di selezione");
    this.labels.set("DELETE_CS_MESSAGE","Sei sicuro di voler eliminare il centro di selezione ? (%1 - %2 - %3)");
    this.labels.set("CANCEL_AUCTION_MESSAGE","common.cancel-auction %1 ?");
    this.labels.set("AUCTION_APPROVE_ALL_LOTS_MESSAGE","common.approve-all-lots");
    this.labels.set("AUCTION_UNDO_APPROVE_ALL_LOTS_MESSAGE","common.undo-approve-all-lots");
    this.labels.set("AUCTION_REJECT_ALL_LOTS_MESSAGE","common.reject-all-lots");

    this.labels.set("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE","common.request-publish-all-lots");
    this.labels.set("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE","common.undo-request-publish-all-lots");

    this.labels.set("AUCTION_DELETE_LOT_MESSAGE","common.delete-lot %1 ?");

    this.labels.set("CANCEL_CS_MESSAGE","common.delete-cs %1 - %2 ?");

    this.labels.set("SUCCESS_CANCEL_AUCTION_MESSAGE","common.success-cancel-auction");
    this.labels.set("SUCCESS_CREATE_AUCTION_MESSAGE","Asta creata correttamente");
    this.labels.set("SUCCESS_OPERATOR_DISABLE","Operatore disabilitato correttamente");
    this.labels.set("SUCCESS_OPERATOR_ENABLE","Operatore abilitato correttamente");
    this.labels.set("SUCCESS_OPERATOR_DELETE","common.operatore-cancellato");
    this.labels.set("SUCCESS_CS_DELETE","Centro di selezione cancellato correttamente");
    this.labels.set("SUCCESS_FRAMEWORK_INSERT","Impianto creato correttamente");   
    this.labels.set("SUCCESS_FRAMEWORK_DELETE","Impianto cancellato correttamente");
    //this.labels.set("SUCCESS_FRAMEWORK_UPDATE","Impianto aggiornato correttamente");
    this.labels.set("SUCCESS_FRAMEWORK_UPDATE","common.success-message");
    this.labels.set("SUCCESS_OPERATOR_UPDATE","Operatore aggiornato correttamente");
    this.labels.set("ERROR_FORM_VALIDATION_MESSAGE","registrazione.validazione-form");    
    this.labels.set("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH","La password deve essere lunga almeno 6 caratteri");
    this.labels.set("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL","Le password non coincidono");
    this.labels.set("ERROR_FIDEIUSSIONE_WRONG_TOTALE","common.fido-maggiore-zero");
    this.labels.set("ERROR_FIDEIUSSIONE_WRONG_DATA","common.fido-data-obbligatoria");


    this.labels.set("DATI_PERSONALI_UPDATED","Dati personali aggiornati");
    this.labels.set("REGISTRATION_SUCCESS", "common.registration-success");
    this.labels.set("SAVE_CONSENT_SUCCESS", "common.success-save-terms");

    this.labels.set("DESCR_DOC_MANCANTE","Mancante");
    this.labels.set("DESCR_DOC_MANCANTI","Mancanti");
    this.labels.set("DESCR_DOC_DA_APPROVARE","Da approvare");
    this.labels.set("DESCR_DOC_APPROVATO","Approvato");
    this.labels.set("DESCR_DOC_RIFIUTATO","Rifiutato");
    this.labels.set("RIFIUTATO","Non validata");
    this.labels.set("ORG_NOT_VALID_MESSAGE","common.azienda-non-abilita-desc");
    this.labels.set("ORG_NOT_VALID_TITLE","common.azienda-non-abilitata");
    this.labels.set("ORG_REG_STEP1_PIVA_EXISTS", "registrazione.piva-gia-presente");
    this.labels.set("USER_NOT_FOUND","Utente non trovato (%1)");
    this.labels.set("ORG_NOT_FOUND","Azienda non trovata");

    this.labels.set("ATTESA_VERIFICA","In attesa di verifica");
    this.labels.set("VALIDATA","Validata");
    this.labels.set("DOC_DA_APPROVARE","Documenti da approvare");
    this.labels.set("AZIENDA_REGISTRATA","Registrata");
    this.labels.set("VALIDATA","Validata");
    this.labels.set("DA_APPROVARE",this.translate.instant("DA_APPROVARE"));

    //this.labels.set("DA_APPROVARE","Da approvare");
    this.labels.set("APPROVATO","Approvato");
    this.labels.set("RIFIUTATO","Rifiutato");
    this.labels.set("NON_CARICATO","Non caricato");
    this.labels.set("INFO_TITLE","common.info");
    this.labels.set("EXCEL_LOTS_TITLE","Caricamento lotti da file excel");
    this.labels.set("WRONG_ROLE","common.wrong-role");
    this.labels.set("SUCCESS_USER_ENABLED","common.enabled-success");
    this.labels.set("SUCCESS_USER_DISABLED","common.disabled-success");
    this.labels.set("LOAD_ORG_DOCUMENT","common.load-document - %1");
    this.labels.set("MAKE_OFFER_CONFIRM","common.make-offer %1 €/ton common.make-offer-for %2 ?");

    this.labels.set("UPLOAD_FORM_FILE_NOT_LOADED","common.seleziona-o-trascina-il-file");

    this.labels.set("AUCTION_CLOSED", "common.auction-closed");
    this.labels.set("SALVA","common.salva");
    this.labels.set("IMPIANTO","common.impianto");

    this.labels.set("BET_SLOT_NOT_NUMERIC","Ricarica la pagina prima di cancellare il taglio di rilancio selezionato");
    this.labels.set("E:AUC_OFFER_AUC_RULE1_FAIL_ERR", "aste-incorso.rule1-fail");
    this.labels.set("E:AUC_OFFER_AUC_RULE2_FAIL_ERR", "aste-incorso.rule2-fail");
    this.labels.set("E:AUC_OFFER_AUC_RULE3_FAIL_ERR", "aste-incorso.rule3-fail");
    
  }

  messages = new Map<String, String>();

  labels = new Map<String, String>();

  getMessageFromCode(code:string, params?: any[]): string {
    let output = '';
    let message:any = this.messages.get(code);
    if (message != undefined) {
      output = message;
    }
    if (params != undefined) {
      let indexPar:number = 1;
      params.forEach(par => {
        output = output.replace('%'+ indexPar, par);
        indexPar++;
      });
    }
    return output;
  }

  getLabelFromCode(code:string, params?: any[]): string {
    let output = '';
    let label:any = this.labels.get(code);
    if (label != undefined) {
      output = label;
    }
    if (params != undefined) {
      let indexPar:number = 1;
      params.forEach(par => {
        output = output.replace('%'+ indexPar, par);
        indexPar++;
      });
    }
    return output;
  }
  
}
