import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/LoaderInterceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxTemplateHost, DxPopupModule, NestedOptionHost } from 'devextreme-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  DxPivotGridModule,
  DxChartModule,
} from 'devextreme-angular';
import { FlatpickrModule } from 'angularx-flatpickr';
import 'flatpickr/dist/flatpickr.css';

import { RegistrazioneStep1Component } from './public/components/registrazione-step1/registrazione-step1.component';
import { RegistrazioneStep2Component } from './public/components/registrazione-step2/registrazione-step2.component';
import config from "devextreme/core/config";

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommonsModule } from './commons/commons.module';
import { UserModule } from './user/user.module';
import { CoripetModule } from './coripet/coripet.module';
import { AdminModule } from './admin/admin.module';
import { PageNotFoundComponent } from './commons/components/page-not-found/page-not-found.component';
import { NgxMasonryModule } from 'ngx-masonry';

registerLocaleData(localeIt);

export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ];

config({
  editorStylingMode: 'outlined',
  defaultCurrency: 'EUR',
});

@NgModule({ declarations: [
        AppComponent,
        RegistrazioneStep1Component,
        RegistrazioneStep2Component,
        PageNotFoundComponent
    ],
    exports: [],
    bootstrap: [AppComponent],
    imports: [
        NgxMasonryModule,
        CommonsModule,
        UserModule,
        CoripetModule,
        AdminModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        AppRoutingModule,
        DxDataGridModule, DxBulletModule,
        DxPopupModule,
        DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
        DxTextAreaModule,
        DxTextBoxModule,
        DxDropDownBoxModule,
        DxDateBoxModule,
        DxFormModule,
        DxTooltipModule,
        DxAccordionModule,
        DxHtmlEditorModule,
        DxToolbarModule,
        DxTextAreaModule,
        DxNumberBoxModule,
        CKEditorModule,
        DxPivotGridModule,
        DxChartModule,
        FlatpickrModule.forRoot(),
        DxDropDownButtonModule,
        DxLookupModule,
        DxTabPanelModule,
        DxButtonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        DxTemplateHost,
        NestedOptionHost,
        interceptorProviders,
        provideHttpClient(withInterceptorsFromDi()), 
    ] })
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
