<div id="auctions-list" class="">
    <dx-data-grid #dataGridProperties 
        id="gridContainer" 
        [dataSource]="properties" 
        keyExpr="code"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true" 
        [showBorders]="true" 
        [columnHidingEnabled]="false" 
        [columnAutoWidth]="true"
        (onRowClick)="onRowClickProperties($event)" 
        (onRowUpdating)="onRowUpdateProperties($event)"
        (onExporting)="onExporting($event)">

        <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
            <dxo-search
            [enabled]="true">
            </dxo-search>
        </dxo-column-chooser>
        <dxo-export [enabled]="true" 
                    [allowExportSelectedData]="false" 
                    fileName="datagrid_list"
                    [formats]="['xlsx', 'csv', 'pdf']"
        ></dxo-export>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="default"></dxo-scrolling>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
        </dxo-search-panel>

        <dxi-column dataField="code" caption="Chiave" [allowEditing]="false" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="value" caption="Valore" [allowEditing]="true" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false" mode="row">
        </dxo-editing>

        <dxo-toolbar>
            <dxi-item location="after" name="searchPanel"></dxi-item>
            <dxi-item location="after" name="exportButton"></dxi-item>
            <dxi-item location="after" name="columnChooserButton"></dxi-item>
        </dxo-toolbar>

    </dx-data-grid>
</div>