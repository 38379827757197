<dx-data-grid id="gridContainerOperators" [dataSource]="operators" 
    keyExpr="id" 
    [allowColumnReordering]="true"
    [allowColumnResizing]="true" 
    [showBorders]="true" 
    [columnHidingEnabled]="false"
    [columnAutoWidth]="true"
    (onRowPrepared)="onRowPrepared($event)"
    >

    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-filter-row [visible]="false"></dxo-filter-row>

    <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
    </dxo-search-panel>

    <dxi-column dataField="id" [allowEditing]="false" caption="Id" alignment="left"
        [visible]="false">
    </dxi-column>
    <dxi-column dataField="nome" caption="{{'operatore-operatori.nome' | translate}}" [allowEditing]="false" dataType="string" [visible]="true" cellTemplate="templateNomeOperatore">
        <div *dxTemplate="let templateNomeOperatore of 'templateNomeOperatore'">
            {{templateNomeOperatore.data.nome}} {{templateNomeOperatore.data.cognome}}
        </div>
    </dxi-column>
    <dxi-column dataField="cognome" caption="Cognome" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="dataRegistrazione" caption="Data registrazione" [allowEditing]="false" dataType="date"
        format="dd/MM/yyyy" [visible]="false">
    </dxi-column>
    <dxi-column dataField="role" caption="{{'operatore-operatori.ruolo' | translate}}" [allowEditing]="false" dataType="string" [visible]="true" cellTemplate="templateRuoloOperatore">
        <div *dxTemplate="let templateRuoloOperatore of 'templateRuoloOperatore'">
            {{getDescrizioneRuolo(templateRuoloOperatore.data.role)}}
        </div>
    </dxi-column>
    <dxi-column dataField="email" caption="{{'operatore-operatori.email' | translate}}" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="lingua" caption="{{'operatore-operatori.lingua' | translate}}" [allowEditing]="false" dataType="string" [visible]="true">
    </dxi-column>
    <dxi-column dataField="telefono" caption="{{'operatore-operatori.telefono-fisso' | translate}}" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="mobile" caption="{{'operatore-operatori.telefono-mobile' | translate}}" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="validated" caption="{{'operatore-operatori.stato' | translate}}" [allowEditing]="false" dataType="string" [visible]="true" cellTemplate="templateOperatorStatus">
        <div *dxTemplate="let templateOperatorStatus of 'templateOperatorStatus'">
            <span class="{{getOperatorStatusClass(templateOperatorStatus.data)}}">{{getOperatorStatus(templateOperatorStatus.data)}}</span>
        </div>
    </dxi-column>
    <dxi-column dataField="disabled" caption="" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="deleted" caption="" [allowEditing]="false" dataType="string" [visible]="false">
    </dxi-column>
    <dxi-column dataField="ragioneSociale" caption="{{'operatore-operatori.ragione-sociale' | translate}}" [allowEditing]="false" dataType="string" [visible]="true"cellTemplate="templateOperatorRagSoc">
        <div *dxTemplate="let templateOperatorRagSoc of 'templateOperatorRagSoc'">
            <span class="referent">{{templateOperatorRagSoc.data.ragioneSociale}}</span>
        </div>
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detailOperator"></dxo-master-detail>

    <div *dxTemplate="let detailOperator of 'detailOperator'">

        <dx-form [(formData)]="detailOperator.data">
        
            <dxi-item itemType="group" caption="{{'operatore-operatori.dati-operatore' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                    <dxi-item dataField="nome" [label]="{text: 'operatore-operatori.nome' | translate}"></dxi-item>
                    <dxi-item dataField="cognome" [label]="{text: 'operatore-operatori.cognome' | translate}"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                    <dxi-item dataField="email" [label]="{text: 'operatore-operatori.email' | translate}" [colSpan]="14"></dxi-item>
                    <dxi-item dataField="lingua" [label]="{text: 'operatore-operatori.lingua' | translate}" [colSpan]="6"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                    <dxi-item dataField="telefono" [label]="{text: 'operatore-operatori.telefono-fisso' | translate}" [colSpan]="10"></dxi-item>
                    <dxi-item dataField="mobile" [label]="{text: 'operatore-operatori.telefono-mobile' | translate}" [colSpan]="10"></dxi-item>
                </dxi-item>
            </dxi-item>

        </dx-form>
    </div>

</dx-data-grid>