<dx-popup [width]="1000" [showTitle]="true" title="{{'dettaglio.aggiungi-centro-di-selezione'|translate}}"
  [dragEnabled]="false" [showCloseButton]="true" [(visible)]="this.showPopup">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <div class="container">
        <div *ngIf="grid">
          <dx-data-grid id="gridContainer" [dataSource]="cs_list" keyExpr="id" [showColumnHeaders]="false">
            <dxi-column dataField="codice"></dxi-column>
            <dxi-column dataField="nome"></dxi-column>
            <dxi-column dataField="indirizzo"></dxi-column>
            <dxi-column dataField="codice" cellTemplate="template"></dxi-column>
            <div *dxTemplate="let cellInfo of 'template'">
              <dx-button stylingMode="contained" text="Seleziona" [width]="200" type="default"
                (onClick)="click(cellInfo)">
              </dx-button>
            </div>
            <dxo-paging [pageSize]="40"> </dxo-paging>
          </dx-data-grid>
        </div>
        <div *ngIf="form">
          <div class="input mt-5">
            <div class="input-wrapper full">
              <input type="text" id="codiceLotto" [(ngModel)]="quantita">
              <label for="newOperator--codiceLotto" translate>dettaglio.quantità</label>
            </div>
            <p *ngIf="mandatory" class="fw-bold text-danger mt-2 mb-5" translate>
              dettaglio.inserisci-la-quantita`-di-tonnellate</p>
          </div>
          <p class="fw-bold mb-4" translate>dettaglio.centro-di-selezione</p>
          <p>{{editDetails}}</p>
        </div>
      </div>
    </dx-scroll-view>
  </div>
  <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
    [options]="saveButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item class="px-5" widget="dxButton" toolbar="bottom" location="after" [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
    [options]="backButtonOptions">
  </dxi-toolbar-item>
</dx-popup>
<div class="p-3" *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true">
  <div class="add-item-section add-item-section-user"
    *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
    <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
      <h3 translate>dettaglio.dettaglio-lotto</h3>
      <form id="form_createOperator" [formGroup]="auctionDettaglioForm" class="form-group">
        <section *ngIf="firstSection" class="step-section d-flex focused">
          <div class="card flex-fill me-4">
            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('codiceLotto')}">
              <div class="input-wrapper full">
                <input required formControlName="codiceLotto" type="text" id="codiceLotto" maxlength="75">
                <label for="newOperator--codiceLotto" translate>dettaglio.codice-lotto</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('codiceProdotto')}">
              <div class="input-wrapper full">
                <input required formControlName="codiceProdotto" type="text" id="codiceProdotto" maxlength="75">
                <label for="newOperator--codiceProdotto" translate>dettaglio.codice-prodotto</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('tipoProdotto')}">
              <div class="input-wrapper full">
                <select name="tipoProdotto" id="tipoProdotto" required formControlName="tipoProdotto"
                  class="form-control">
                  <option *ngFor="let lot of type_material; let i = index" [value]="lot.type_material">
                    {{lot.type_material}}</option>
                </select>
                <label for="tipoProdotto" translate>dettaglio.tipo-prodotto</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('areaGeografica')}">
              <div class="input-wrapper full">
                <select name="areaGeografica" id="areaGeografica" required formControlName="areaGeografica"
                  class="form-control">
                  <option *ngFor="let lot of geo_area; let i = index" [value]="lot.area">
                    {{lot.area}}</option>
                </select>
                <label for="newOperator--areaGeografica" translate>dettaglio.area-geografica</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('quantitaTon')}">
              <div class="input-wrapper full">
                <input required formControlName="quantitaTon" type="number" id="quantitaTon" maxlength="75">
                <label for="newOperator--quantitaTon" translate>dettaglio.quantita-ton</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('prezzoBase')}">
              <div class="input-wrapper full">
                <input required formControlName="prezzoBase" type="number" id="prezzoBase" maxlength="75">
                <label for="newOperator--prezzoBase" translate>dettaglio.base-d'asta-in</label>
              </div>
            </div>

            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('dataDisponibilitaLotto')}">
              <div class="input-wrapper full">
                <input required formControlName="dataDisponibilitaLotto" type="date" id="dataDisponibilitaLotto"
                  maxlength="75">
                <label for="newOperator--dataDisponibilitaLotto" translate>dettaglio.data-disponibilita-lotto</label>
              </div>
            </div>

            <ckeditor id="noteText" nome="noteText" [editor]="Editor" formControlName="note"></ckeditor>

            <div class="row mt-4">
              <p class="fw-bold col-4" translate>dettaglio.codice-centro-di-selezione</p>
              <p class="fw-bold col-4" translate>dettaglio.quantita</p>
              <p class="fw-bold col-4" translate>dettaglio.azioni</p>
            </div>

            <div class="row mt-4" *ngFor="let item of this.asteDetails; let i = index">
              <p class="col-4">{{item.codiceCentroSelezione}}</p>
              <p class="col-4">{{item.quantitaTon}}</p>
              <button class="col-4 btn btn-primary" (click)="rimuovi(i)"><span
                  translate>dettaglio.rimuovi</span></button>
            </div>

            <div class="pt-5">
              <button class="btn" style="border-color: orange;color: orange;" type="submit" (click)="openPopup()"><i
                  class="fal fa-save"></i>
                <span translate>dettaglio.aggiungi-centro-di-selezione</span></button>
            </div>

          </div>
          <div class="infos">
            <div class="number-wrapper lh-1">
              <div class="number fs32 bold gray-dark pb-2">01</div>
              <div class="label">
                <span translate>dettaglio.dati-lotto</span>
              </div>
            </div>
          </div>
        </section>
      </form>
      <section *ngIf="secondSection" class="step-section focused d-flex pb-4">
        <div class="card flex-fill me-3">
          <div class="container-half">
            <div class="lots-list new-lots-list admin-buttons">
              
              <article class="popup">
                <div class="buttons ingrid buttons-left">

                  <div class="doc-datas">
                    <app-file-box docname="excel-lotti.xlsx" [showCoripetButtons]="false"
                      (file)="getExcelLottiFile($event)"></app-file-box>
                  </div>
                  <br />
                  <button class="box__file btn btncarica success popup" style="border-color: green;color: green;"
                    type="submit" id="btn_createOperator" (click)="uploadDocument()"><i class="fal fa-save"></i>
                    <span translate>azienda-operatori.salva</span></button>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="infos">
          <div class="number-wrapper lh-1">
            <div class="number fs32 bold gray-dark inter">02</div>
            <div class="label">
              <span translate>anagrafica-azienda.dati-personali</span>
            </div>
          </div>
        </div>
      </section>

    </div>
    <div class="container add-operator-actions mt-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
      <div class="pb-5 text-lg-end text-center">
        <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
            translate>azienda-operatori.annulla</span></button>
        <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
          (click)="salvaAuctionDettaglio()"><i class="fal fa-save"></i> <span
            translate>azienda-operatori.salva</span></button>
      </div>
    </div>
    <button class="add add-operator new-operator-toggler d-flex mb-4 blue-dark pointer w-100" (click)="toggleNuovoOperatore()">
      <article class="d-flex align-items-center w-100">
        <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
        <div class="text fs18"><span translate>dettaglio.aggiungi-lotto</span></div>
      </article>
    </button>
  </div>
</div>
<!-- <div class="px-3">
  <hr>
</div> -->

<div class="p-4 auction-details m-0" *ngIf="auction">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-12">
      <h2 class="fs28 gray-dark inter bold mb-5"><span
          class="fs25 bold w-100 text-center status {{auction.statoAsta}}"><span
            [ngClass]="auction.statoAsta == 'Chiusa' ? '':'text-success'"><span translate>dettaglio.asta</span>
            {{auction.statoAsta}}</span></span> {{auction.codiceAsta}} -
        {{auction.tipologiaAsta}}</h2>

      <div class="auctions-infos">
        <span translate>dettaglio.l'asta-inizier</span>&nbsp;<span translate>dettaglio.il</span>&nbsp;
        <!-- <b>{{auction.dataOraFineAsta}}</b> <span translate>dettaglio.e-si-concluder</span>&agrave; <span
          translate>dettaglio.il</span> -->
        <b>{{auction.dataOraFineAsta}}</b><br />
        <b>{{auction.numLotti}}</b>&nbsp;<span translate>dettaglio.lotti-disponibili</span>
      </div>
    </div>
    <!--div class="col-6 text-end" *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true">
      <i class="pointer fa fa-file-excel-o me-3" style="font-size:38px;color:green" (click)="downloadTemplateExcelLotti()"></i>
      <input #inputFile type="file" (change)="UploadExcelLottiFile($event)"
        class="btncarica box__file btn success btn-primary mb-3">
      <div> <button class="btn btn-outline-success btncarica" style="" (click)="caricaExcelLotti()"><span
            translate>dettaglio.carica</span></button>
      </div>
    </div-->
  </div>

  <!-- <i class="fas fa-angle-left back-button" (click)="gotoBack()"><span>Torna all'elenco aste</span></i> -->

  <!--div class="lots-list new-lots-list admin-buttons"
    *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
    <div class="lot">

      <article>
        <div class="buttons ingrid">
          <button class="btn btn-primary richiediApprovazioneBozze" type="button" (click)="requestApproveAllLots()">
            <span translate>dettaglio.richiedi-pubblicazione-di-tutti-i-lotti-bozza</span>
          </button>
          <button class="btn btn-primary annullaRichiestaApprovazione" type="button"
            (click)="undoRequestApproveAllLots()">
            <span translate>dettaglio.annulla-richiesta-pubblicazione-di-tutti-i-lotti-bozza</span>
          </button>
        </div>
      </article>

    </div>
  </div-->

  <div class="top50 bottom50 my-5" id="lots">

    <dx-data-grid id="gridContainer" #dataGridLots [dataSource]="lot_list" keyExpr="id" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false" [columnAutoWidth]="true"
      (onExported)="onExported($event)" (onStartEditing)="onStartEditing($event)"
      (onExporting)="prepareExportGrid($event)">

      <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
        <dxo-search [enabled]="true">
        </dxo-search>
      </dxo-column-chooser>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-scrolling mode="default"></dxo-scrolling>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" placeholder="{{'search'|translate}}">
      </dxo-search-panel>
      <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="datagrid_list"
        [formats]="['xlsx', 'csv', 'pdf']"></dxo-export>

      
      <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" [visible]="false">
      </dxi-column>
      <dxi-column dataField="userId" [allowExporting]="false" [allowEditing]="false" caption="userId" [visible]="false">
      </dxi-column>
      <dxi-column dataField="idOrganization" [allowEditing]="false"
        caption="{{'aste-vinte-dettaglio.id-azienda' | translate}}" [visible]="false">
      </dxi-column>
      <dxi-column dataField="nomeOrganization" [allowEditing]="false"
        caption="{{'aste-vinte-dettaglio.azienda-vincitrice' | translate}}" [visible]="false">
      </dxi-column>
      <dxi-column dataField="codiceLotto" caption="{{'aste-vinte-dettaglio.codice-lotto' | translate}}"
        dataType="string" [visible]="true">
      </dxi-column>
      <dxi-column dataField="tipoProdotto" caption="{{'aste-vinte-dettaglio.tipo-prodotto' | translate}}"
        dataType="string" [visible]="true">
        <dxo-lookup [dataSource]="tipoProdotto_list" valueExpr="codice" displayExpr="valore">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="areaGeografica" [width]="170"
        caption="{{'aste-vinte-dettaglio.area-geografica' | translate}}" dataType="string" [visible]="true">
        <dxo-lookup [dataSource]="geoArea_list" valueExpr="codice" displayExpr="valore">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="quantitaTon" [width]="170" caption="{{'aste-vinte-dettaglio.quantità-ton' | translate}}"
        dataType="number" cellTemplate="quantityTemplate" [visible]="true">
      </dxi-column>
      <dxi-column dataField="prezzoBase" [width]="120" caption="{{'aste-vinte-dettaglio.base-d-asta' | translate}}"
        dataType="number" cellTemplate="priceTemplate" [visible]="true">
      </dxi-column>
      <dxi-column dataField="schedaTecnica" caption="{{'dettaglio-lotto.scheda-tecnica' | translate}}" dataType="string"
        [visible]="false">
      </dxi-column>
      <dxi-column dataField="codiceAsta" caption="{{'aste-vinte-dettaglio.codice-asta' | translate}}" dataType="string"
        [visible]="false" cssClass="number">
      </dxi-column>
      <dxi-column dataField="dataDisponibilitaLotto"
        caption="{{'aste-vinte-dettaglio.data-disponibilità-lotto' | translate}}" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="note" caption="Note" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="codiceProdotto" caption="{{'aste-vinte-dettaglio.codice-prodotto' | translate}}"
        dataType="string" [visible]="true">
      </dxi-column>
      <dxi-column dataField="dataCreazione" [allowExporting]="false"
        caption="{{'aste-vinte-dettaglio.data-creazione' | translate}}" [width]="150" dataType="date"
        format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
      </dxi-column>
      <dxi-column dataField="dataModifica" [allowExporting]="false"
        caption="{{'aste-vinte-dettaglio.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy"
        showEditorAlways="true" [visible]="false">
      </dxi-column>
      <dxi-column dataField="numeroRilanci" [allowExporting]="false"
        caption="{{'aste-vinte-dettaglio.numero-rilanci' | translate}}" dataType="number" [visible]="false">
      </dxi-column>
      <dxi-column dataField="dataOraFineAstaEffettiva" [allowExporting]="false"
        caption="{{'aste-vinte-dettaglio.chiusura-asta' | translate}}" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="statoLotto" caption="{{'aste-vinte-dettaglio.stato-lotto' | translate}}"
        [allowExporting]="false" dataType="string" cellTemplate="statusTemplate" [visible]="true">
        <dxo-lookup [dataSource]="lot_statuses" valueExpr="codice" displayExpr="valore">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="prezzoUltimoRilancio" [allowExporting]="false"
        caption="{{'aste-vinte-dettaglio.prezzo-finale' | translate}}" dataType="number" [visible]="false">
      </dxi-column>

      <dxo-toolbar>
        <dxi-item location="after" name="searchPanel"></dxi-item>
        <dxi-item location="after" name="exportButton"></dxi-item>
        <dxi-item location="after" name="columnChooserButton"></dxi-item>

        <dxi-item location="after">
          <div *dxTemplate>
            <div class="approve-all-lots"
              *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
              <dx-button icon="check" hint="{{ 'dettaglio.richiedi-pubblicazione-di-tutti-i-lotti-bozza' | translate }}"
                (onClick)="requestApproveAllLots()">
              </dx-button>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <div class="undo-approve-all-lots"
              *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
              <dx-button icon="remove"
                hint="{{ 'dettaglio.annulla-richiesta-pubblicazione-di-tutti-i-lotti-bozza' | translate }}"
                (onClick)="undoRequestApproveAllLots()">
              </dx-button>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <div class="upload-excel-template-lots"
              *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
              <dx-button icon="upload" hint="{{ 'dettaglio.carica-excel' | translate }}"
                (onClick)="selezionaExcelLotti()">
              </dx-button>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <div class="download-excel-template-lots"
              *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
              <dx-button icon="download" hint="{{ 'dettaglio.scarica-template-excel' | translate }}"
                (onClick)="downloadTemplateExcelLotti()">
              </dx-button>
            </div>
          </div>
        </dxi-item>
      </dxo-toolbar>

      <div *dxTemplate="let statusTemplate of 'statusTemplate'">
        <div class="{{statusTemplate.data.statoLotto}} lot-status">{{statusTemplate.data.statoLotto}}</div>
      </div>

      <div *dxTemplate="let priceTemplate of 'priceTemplate'">
        <div>{{priceTemplate.data.prezzoBase}} &euro;</div>
      </div>

      <div *dxTemplate="let quantityTemplate of 'quantityTemplate'">
        <div>{{quantityTemplate.data.quantitaTon}} t</div>
      </div>

      <dxo-master-detail [enabled]="true" template="detailLot"></dxo-master-detail>

      <div *dxTemplate="let detailLot of 'detailLot'">
        <div class="lots-list new-lots-list won">
          <app-dettaglio-lotto [lotDetail]="detailLot.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
        </div>
      </div>

    </dx-data-grid>

  </div>
</div>

<input #inputFile type="file" (change)="UploadExcelLottiFile($event)" class="hidden">

<!-- grid for excel export-->
<dx-data-grid id="gridContainerForExport" [visible]="false" #dataGridLotsForExport [dataSource]="lot_list_for_export"
  keyExpr="id" [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true"
  [columnHidingEnabled]="false" [columnAutoWidth]="true" (onExporting)="onExportingHideColumn($event)"
  (onExported)="onExportedHideColumn($event)" (onStartEditing)="onStartEditing($event)"
  (onExporting)="onExporting($event)">

  <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
    <dxo-search [enabled]="true">
    </dxo-search>
  </dxo-column-chooser>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-scrolling mode="default"></dxo-scrolling>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" placeholder="{{'search'|translate}}">
  </dxo-search-panel>
  <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="datagrid_list"
    [formats]="['xlsx', 'csv', 'pdf']"></dxo-export>

  
  <dxi-column dataField="id" [allowEditing]="false" [allowExporting]="true" [width]="100" caption="ID" [visible]="true">
  </dxi-column>
  <dxi-column dataField="idOrganization" [allowEditing]="false" caption="ID_AZIENDA" [visible]="true">
  </dxi-column>
  <dxi-column dataField="nomeOrganization" [allowEditing]="false" caption="NOME_AZIENDA" [visible]="true">
  </dxi-column>
  <dxi-column dataField="codiceAzienda" [allowEditing]="false" caption="COD_AZIENDA"
    [visible]="true">
  </dxi-column>
  <dxi-column dataField="codiceLotto" caption="COD_LOTTO" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="riga" caption="RIGA" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="tipoProdotto" caption="TIPO_PRODOTTO" dataType="string"
    [visible]="true">
    <dxo-lookup [dataSource]="tipoProdotto_list" valueExpr="codice" displayExpr="valore">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="areaGeografica" [width]="170" caption="AREA_GEOGRAFICA" dataType="string" [visible]="true">
    <dxo-lookup [dataSource]="geoArea_list" valueExpr="codice" displayExpr="valore">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="quantitaTon" [width]="170" caption="QUANTITA_TON" dataType="number"
    cellTemplate="quantityTemplate" [visible]="true">
  </dxi-column>
  <dxi-column dataField="prezzoBase" [width]="120" caption="BASE_ASTA" dataType="number" cellTemplate="priceTemplate"
    [visible]="true">
  </dxi-column>
  <dxi-column dataField="prezzoUltimoRilancio" [allowExporting]="true" caption="PREZZO_FINALE" dataType="number"
    [visible]="true">
  </dxi-column>
  <dxi-column dataField="codiceAsta" caption="COD_ASTA" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="dataDisponibilitaLotto" caption="DATA_DISP_LOTTO" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="note" caption="NOTE" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="centroSelezione[0].codiceEsterno" caption="CSS" dataType="string" [visible]="true">
  </dxi-column>
  <dxi-column dataField="centroSelezione[0].quantitaTon" caption="PESO_CSS" dataType="string" [visible]="true">
  </dxi-column>

  <dxo-toolbar>
    <dxi-item location="after" name="exportButton"></dxi-item>
    <dxi-item location="after" name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- export data grid -->

  <div *dxTemplate="let priceTemplate of 'priceTemplate'">
    <div>{{priceTemplate.data.prezzoBase}} &euro;</div>
  </div>

  <div *dxTemplate="let quantityTemplate of 'quantityTemplate'">
    <div>{{quantityTemplate.data.quantitaTon}} t</div>
  </div>

  <dxo-master-detail [enabled]="true" template="detailLot"></dxo-master-detail>

  <div *dxTemplate="let detailLot of 'detailLot'">
    <div class="lots-list new-lots-list won">
      <app-dettaglio-lotto [lotDetail]="detailLot.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
    </div>
  </div>
</dx-data-grid>