import { Location } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuctionBetSetupCreateItem, AuctionBetSetupItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-bet',
  templateUrl: './setup-bet.component.html',
  styleUrls: ['./setup-bet.component.css']
})
export class SetupBetComponent extends APIService implements OnDestroy {

  @ViewChild("dataGridBet", { static: false }) dataGridBet: DxDataGridComponent;
  @Input() bet: AuctionBetSetupItem[] = [];
  @Output() updated = new EventEmitter<boolean>();
  @Input() tipologiaAsta: string;
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);
  }

  onCellPreparedBet(e) {
    e.cellElement.css("text-align", "left");
  }

  onRowInsertBet(e) {
    let auctionBet: AuctionBetSetupItem = e.data;
    auctionBet.taglioRilancio = parseInt(auctionBet.taglioRilancio.toString());
    let betToInsert: AuctionBetSetupCreateItem = new AuctionBetSetupCreateItem();
    betToInsert.slot = auctionBet.taglioRilancio.toString();
    const service = this.auctionService.insertBetSlot(this.tipologiaAsta, betToInsert).subscribe(
      data => {
        this.updated.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_BET_SLOT_MESSAGE"), true);
      }, this.handleError(true, //this.reloadBetData()
      )
    );
    this.subscriptions.push(service);
  }

  onRowDeleteBet(e) {
    console.log(e.data);
    if (!isNaN(e.data.auctionBetSetupId)) {
      const service = this.auctionService.deleteBetSlot(e.data.auctionBetSetupId).subscribe(
        data => {
          this.updated.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_DELETE_AUCTION_BET_SLOT_MESSAGE"), true);
        }, this.handleError(true, //this.reloadBetData()
        )
      );
      this.subscriptions.push(service);
    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("BET_SLOT_NOT_NUMERIC"), false);      
    }
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}