<div id="dashboard" class="content top100 p-4 my-3">

    <app-active-auction-banner *ngIf="isEnabledToAuction()"></app-active-auction-banner>
    <app-next-auction-countdown *ngIf="isEnabledToAuction()"></app-next-auction-countdown>

    <div class="row align-items-start">
        <div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
            <div class="card clip-path {{boxOrgInvalid != undefined ? boxOrgInvalid.classe : undefined}}">
                <div class="card-body p-0">
                    <!-- azienda validata -->
                    <span *ngIf="boxOrgInvalid != undefined" class="">
                        <p class="p-3 mb-0 pb-0 fs16 bold" translate>{{boxOrgInvalid.titolo}}</p>
                        <p class="percentage-zero pt-2 p-3 fs16" translate>{{boxOrgInvalid.testo}}</p>
                    </span>
                    <!-- percentuale box -->
                    <span *ngFor="let item of boxDocumenti; let i = index">
                        <div class="p-4 white m-0" *ngIf="item.documento == 'progress'"
                            [ngClass]="{'bg-blue-light': item.percentageNum == 100, 'bg-negative-red': item.percentageNum < 100}">
                            <h4 class="mb-3 text-white fs16 bold" translate>{{item.titolo}}</h4>
                            <h2 class="text-white mb-5 p-0">{{item.percentage}}</h2>
                            <p class="mb-5"><span>{{item.testo | translate}}</span><span
                                    translate>{{item.testo_add}}</span></p>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-12 mb-5">
            <div class="card border-right p-4 clip-path">
                <div class="card-body">
                    <h1 class="mb-4 p-0 fs27 bold gray-dark inter" translate>dashboardPage.come-funziona-aucircle</h1>
                    <div class="row align-items-start mb-5">
                        <div class="col-lg-6 col-md-12 col-12">
                            <p class="gray-dark  " translate>dashboardPage.qui-puoi-scaricare</p>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12">
                            <ul>
                                <li class="mb-3">
                                    <a href="regolamenti/04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf"
                                        target="_blank" class="download">
                                        <div class="d-flex" data="regolamento"
                                            data-uuid="04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf">
                                            <i class="bi bi-file-earmark-pdf me-3"></i>
                                            <div class="filename-download">
                                                <p class="gray-dark fs14 inter regular" translate>
                                                    dashboardPage.regolamento-e-condizioni-generali-aste-PET-CORIPET
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="regolamenti/CORIPET_portale aste_Informativa Privacy_20180107.pdf"
                                        target="_blank" class="download">
                                        <div class="d-flex" data="regolamento"
                                            data-uuid="CORIPET_portale aste_Informativa Privacy_20180107.pdf">
                                            <i class="bi bi-file-earmark-pdf me-3"></i>
                                            <div class="filename-download">
                                                <p class="gray-dark fs14 inter regular" translate>dashboardPage.informativa-privacy</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="regolamenti/Aucircle-documentazione-generale.pdf" target="_blank"
                                        class="download">
                                        <div class="d-flex" data="regolamento"
                                            data-uuid="Aucircle-documentazione-generale.pdf"><i
                                                class="bi bi-file-earmark-pdf me-3"></i>
                                            <div class="filename-download">
                                                <p class="gray-dark fs14 inter regular" translate>dashboardPage.linee-guida</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5">
            <div class="card border-right p-4 clip-path" *ngFor="let item of boxArchivio; let i = index">
                <div class="card-body">
                    <div class="auction-item flex-grow-1">
                        <article class="card-with-actions">
                            <h2 class="mb-4 p-0" translate>dashboardPage.archivio-aste</h2>
                            <div class="download-description mb-5" translate>
                                dashboardPage.vedi-le-informazioni
                            </div>
                            <ul>
                                <li>
                                    <div class="download-report-big download-file align-items-center"
                                        (click)="downloadFile(item.uuid, item.filename)" (keydown)="handleKeyDown($event)" id="{{item.id}}" data="report"
                                        title="Report asta {{item.codiceAsta}}"><i
                                            class="bi bi-file-earmark-pdf me-3 blue-dark"></i>
                                        <div class="filename-report">
                                            <p translate>dashboardPage.scarica-ultimo-report-dell-asta</p>
                                            {{item.codiceAsta}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </article>
                        <span class="card-action">
                            <span class="arrow">
                                <a [routerLink]="ConstantsModule.REF_AZ_ASTE_CHIUSE_PATH"><i class="far fa-arrow-right"></i></a>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- impianti -->
    <div class="row align-items-start">
        <ng-container *ngFor="let item of boxDocumenti; let i = index">
            <div class="col-xl-3 col-lg-6 col-md-12 col-12 mb-5" *ngIf="item.available">
                <div class="card border-right p-4 clip-path">
                    <div class="card-body">
                        <div class="auction-item" [ngClass]="{'hidden': item.classe == 'success'}">
                            <a routerLink="/azienda-impianti">
                                <article class="card-with-actions">
                                    <h1>Impianti e produttivit&agrave;</h1>
                                    <h2 class="box status {{item.classe}}" translate>{{item.titolo}}</h2>
                                    <div class="number">{{i+5}}</div>
                                    <p translate>{{item.testo}}</p>
                                </article>
                                <span class="card-action">
                                    <span class="arrow">
                                        <i class="far fa-arrow-right"></i>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- documenti -->
    <div class="row align-items-start">
        <ng-container class="auctions-list" *ngFor="let item of boxDocumenti; let i = index">
            <div class="auction-item col-xl-3 col-lg-6 col-md-12 col-12 mb-5" *ngIf="item.documento != 'progress'">
                <div class="card border-right p-4 clip-path">
                    <div class="card-body">
                        <h2 class="text-end p-0">{{i}}</h2>
                        <a [routerLink]="[getRouteForItem(item)]">
                            <article class="card-with-actions">
                                <h1 class="inter gray-dark bold fs27 bold"><span translate>dashboardPage.document</span> {{item.documento}}</h1>
                                <h2 class="inter gray-dark bold fs28 gray-dark box status w-auto pb-4 {{item.classe}}"><span translate>{{item.titolo}}</span></h2>
                                <p class="dashboardPara" translate>{{item.testo}}<span>{{item.testo_add}}</span></p>
                            </article>
                            <span class="card-action">
                                <span class="arrow">
                                    <i class="far fa-arrow-right"></i>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<script>
    $(document).ready(function () {
        $('.auctions-list').masonry({ itemSelector: '.auction-item', columnWidth: 330, gutter: 30 });
    });
</script>