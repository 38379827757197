import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageVisibilityService {

    private visibilityChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private ngZone: NgZone) {
        this.init();
    }

    private init() {
        document.addEventListener('visibilitychange', () => {
            this.ngZone.run(() => {
                this.visibilityChange.next(!document.hidden);
            });
        });
    }

    get visibilityChange$() {
        return this.visibilityChange.asObservable();
    }
}
