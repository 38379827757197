import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-coripet-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class CoripetAziendeComponent extends APIService {

  constructor(public localService: LocalUtilService,
      public authService: AuthService,
      public auctionService: AuctionService,
      public organizationService: OrganizationService,
      private modalService: NgbModal,
      private messageService: MessagesService,
      location: Location, 
      router: Router) {
      super(localService, location, router);
  
    }
  
}