import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {

  @Input() titolo:string;
  @Input() messaggio:string;
  @Input() tipo:string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {
  }

  passBack(ref:any) {
    this.passEntry.emit(ref);
  }

  openModal(titolo: string, messaggio: string, tipo: string) {
    console.log('openModal()');
    this.titolo = titolo;
    this.messaggio = messaggio;
  }

  successDialogClose() {
    console.log('successDialogClose()');
  }

  confirm() {
    this.passBack('confirm');
    this.modalService.dismissAll();
  }

  chiudi() {
    this.modalService.dismissAll();
  }

}
