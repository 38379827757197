<div id="aste-pubbliche" class="content p-4 my-3">           
    <h2 class="mb-5" translate>aste-pubbliche.aste-pubbliche</h2>
    <div id="auctions-list" class="">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="auctions_list"
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"
            (onRowClick)="openDettaglio($event)"
            (onExporting)="onExporting($event)"
            >
            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="auction_published_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>


            
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'aste-pubbliche.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'aste-pubbliche.userId' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-pubbliche.dataCreazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-pubbliche.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-pubbliche.codiceAsta' | translate}}" dataType="string" [visible]="true" [width]="180" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'aste-pubbliche.pubblicazione' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'aste-pubbliche.inizioAsta' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'aste-pubbliche.conclusioneAsta' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'aste-pubbliche.tipologia' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="statoAsta" [width]="130" caption="{{'aste-chiuse.statoAsta' | translate}}" dataType="string" [visible]="true" cellTemplate="statusTemplate">                    
                <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                    <div class="stato-asta status Disponibile">{{statusTemplate.data.statoAsta}}</div>
                </div>
            </dxi-column>
            
            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>


        </dx-data-grid>
    </div>
</div>