import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { IsUserCanActivate } from '../services/IsCanActivate';

/*USER COMPONENTS*/
import { AsteChiuseComponent } from './components/aste/aste-chiuse/aste-chiuse.component';
import { AsteChiuseDettaglioComponent } from './components/aste/aste-chiuse/dettaglio/aste-chiuse-dettaglio.component';
import { AsteInCorsoBoxComponent } from './components/aste/aste-in-corso-box/aste-in-corso-box.component';
import { AsteInCorsoComponent } from './components/aste/aste-in-corso/aste-in-corso.component';
import { AstePubblicheComponent } from './components/aste/aste-pubbliche/aste-pubbliche.component';
import { AstePubblicheDettaglioComponent } from './components/aste/aste-pubbliche/dettaglio/aste-pubbliche-dettaglio.component';
import { AsteVinteComponent } from './components/aste/aste-vinte/aste-vinte.component';
import { AsteVinteDettaglioComponent } from './components/aste/aste-vinte/dettaglio/aste-vinte-dettaglio.component';
import { AziendaAnagraficaComponent } from './components/azienda/anagrafica/azienda-anagrafica.component';
import { AziendaImpiantiComponent } from './components/azienda/impianti/azienda-impianti.component';
import { AziendaOperatoriComponent } from './components/azienda/operatori/azienda-operatori.component';
import { ConsensiComponent } from './components/consensi/consensi.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

/*COMMON COMPONENTS*/
import { UploadFideiussioneComponent } from '../commons/components/upload-fideiussione/upload-fideiussione.component';
import { UploadLegaleRappresentanteComponent } from '../commons/components/upload-legale/upload-legale.component';
import { UploadModOrgComponent } from '../commons/components/upload-mod-org/upload-mod-org.component';
import { UploadVisuraComponent } from '../commons/components/upload-visura/upload-visura.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { UserComponent } from "./user.component";
import { HttpLoaderFactory } from "../app.module";
import { CommonsModule } from "../commons/commons.module";
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxPivotGridModule, DxChartModule, DxPopupModule, DxTemplateHost, NestedOptionHost } from 'devextreme-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FlatpickrModule } from "angularx-flatpickr";


const USER_ROUTES: Routes = [
      {
        path: 'user/dashboard.html', pathMatch: 'full', component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }, canActivate: [IsUserCanActivate],
      },
      {
        path: 'user/aste-pubbliche.html', pathMatch: 'full', component: AstePubblicheComponent,
        data: {
          title: 'Aste pubbliche'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-pubbliche-dettaglio/:codiceAsta', pathMatch: 'full', component: AstePubblicheDettaglioComponent,
        data: {
          title: 'Aste pubbliche dettaglio'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-chiuse.html', pathMatch: 'full', component: AsteChiuseComponent,
        data: {
          title: 'Aste chiuse'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-chiuse-dettaglio/:codiceAsta', pathMatch: 'full', component: AsteChiuseDettaglioComponent,
        data: {
          title: 'Aste chiuse dettaglio'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-in-corso.html', pathMatch: 'full', component: AsteInCorsoComponent,
        data: {
          title: 'Aste in corso'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-in-corso/:codiceAsta', pathMatch: 'full', component: AsteInCorsoComponent,
        data: {
          title: 'Aste in corso'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-in-corso-box/:codiceAsta', pathMatch: 'full', component: AsteInCorsoBoxComponent,
        data: {
          title: 'Aste in corso'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-vinte.html', pathMatch: 'full', component: AsteVinteComponent,
        data: {
          title: 'Aste vinte'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/aste-vinte-dettaglio/:codiceAsta', pathMatch: 'full', component: AsteVinteDettaglioComponent,
        data: {
          title: 'Aste vinte dettaglio'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/azienda-operatori.html', pathMatch: 'full', component: AziendaOperatoriComponent,
        data: {
          title: 'Operatori'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/azienda-impianti.html', pathMatch: 'full', component: AziendaImpiantiComponent,
        data: {
          title: 'Impianti'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/azienda-anagrafica_aziendale.html', pathMatch: 'full', component: AziendaAnagraficaComponent,
        data: {
          title: 'Anagrafica'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/upload-fideiussione.html', pathMatch: 'full', component: UploadFideiussioneComponent,
        data: {
          title: 'Fideiussione bancaria'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/consensi-utente', pathMatch: 'full', component: ConsensiComponent,
        data: {
          title: 'Consensi utente'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/upload-visura.html', pathMatch: 'full', component: UploadVisuraComponent,
        data: {
          title: 'Visura camerale'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/upload-documento-rappresentante.html', pathMatch: 'full', component: UploadLegaleRappresentanteComponent,
        data: {
          title: 'Documento rappresentante legale'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/upload-dichiarazione-231.html', pathMatch: 'full', component: UploadModOrgComponent,
        data: {
          title: 'Dichiarazione 231 - 2001'
        }, canActivate: [IsUserCanActivate]
      },
      {
        path: 'user/upload-fideiussione.html', pathMatch: 'full', component: UploadFideiussioneComponent,
        data: {
          title: 'Fideiussione bancaria'
        }, canActivate: [IsUserCanActivate]
      }
]

@NgModule({
  declarations: [ 
    UserComponent,
    DashboardComponent,
    AstePubblicheComponent,
    AstePubblicheDettaglioComponent,
    AsteChiuseComponent,
    AsteChiuseDettaglioComponent,
    AsteInCorsoComponent,
    AsteVinteComponent,
    AsteVinteDettaglioComponent,
    AziendaOperatoriComponent,
    AziendaImpiantiComponent,
    AziendaAnagraficaComponent,
    AsteInCorsoBoxComponent,
    ConsensiComponent 
  ],
  exports: [
    RouterModule    
  ],
  bootstrap: [UserComponent], 
  imports: [
    CommonsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule, DxBulletModule,
    DxPopupModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    CKEditorModule,
    DxPivotGridModule,
    DxChartModule,
    FlatpickrModule.forRoot(),
    DxDropDownButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    DxButtonModule,
    RouterModule.forChild(USER_ROUTES),    
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ], 
  providers: [provideHttpClient(withInterceptorsFromDi()), DxTemplateHost, NestedOptionHost],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],  
})
export class UserModule { }