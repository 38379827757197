<div id="upload-fideiussione" class="content p-4 my-3">
    
    <section class="step-section focused d-flex pb-4">
        <div class="card flex-fill me-4">
            <h2 class="fs28 inter bold gray-dark pb-4" translate>fideiussione.fideiussione-bancaria</h2>
            <div class="doc-datas doc-form-upload-fideiussione d-flex p-3">
                <app-file-box [uuid]="existsDocument != undefined ? existsDocument.uuid : undefined" [status]="existsDocument != undefined ? existsDocument.status : ''" docname="fideiussione-bancaria.pdf" (file)="getFideiussioneBancariaFile($event)" [showError]="false" [showCoripetButtons]="false"></app-file-box>
                <div class="form flex-fill pt-4">
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="number" id="amount" [(ngModel)]="totaleFidejussione">
                            <label for="amount" translate>fideiussione.importo-fideiussione-in-euro</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="text" 
                                   mwlFlatpickr 
                                   dateFormat="d/m/Y"
                                   [(ngModel)]="dataScadenzaFidejussione" >
                            <label for="expiration" translate>fideiussione.data-di-scadenza</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper lh-1">
                <div class="number fs32 bold gray-dark pb-2">01</div>
                <div class="label">
                    <span translate>fideiussione.fideiussione-bancaria</span>
                </div>
            </div>
        </div>
    </section>
    <div class="main-actions d-flex justify-content-end pt-4">
        <a (click)="back()" class="btn" translate>fideiussione.annulla</a>
        <button class="btn btn-outline-primary button-color" (click)="uploadFideiussione()" id="btn_fileUpload" data-sector="fideiussione"><span translate>fideiussione.invia</span></button>
    </div>
</div>