import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { FileWithContentUpload, NazioneItem, OrganizationPlantItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-azienda-impianti',
  templateUrl: './azienda-impianti.component.html',
  styleUrls: ['./azienda-impianti.component.css']
})
export class AziendaImpiantiComponent extends APIService implements OnInit,OnDestroy {

  plants_list: OrganizationPlantItem[]=[];
  plant: OrganizationPlantItem = new OrganizationPlantItem();
  plantForm: UntypedFormGroup;
  submitted: boolean = false;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  iso9001: FileWithContentUpload;
  iso14001: FileWithContentUpload;
  docAuth: FileWithContentUpload;
  flag9001: boolean;
  flag14001: boolean;
  flagDocAuth: boolean;
  vatnumber: string;
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    public commonService: CommonService,
    private translate: TranslateService) {
    super(localService, location, router);
    
    this.insertDocImpianto = this.insertDocImpianto.bind(this);
    this.initializeForm();
  }

  checkError(prop: string) {
    let output: boolean = !this.plantForm.get(prop).valid && this.submitted;
    return output;
  }

  getIso9001File(file) {
    if (file != undefined) {
      console.log('getIso9001File() loaded file: ' + file.name);
    }else {
      console.log('getIso9001File() unloaded file!');
    }
    this.iso9001 = new FileWithContentUpload();
    this.iso9001.file = file;
    this.plantForm.get('certificazioneIso9001').reset({ value: '' });
  }

  getIso14001File(file) {
    if (file != undefined) {
      console.log('getIso14001File() loaded file: ' + file.name);
    }else {
      console.log('getIso14001File() unloaded file!');
    }
    this.iso14001 = new FileWithContentUpload();
    this.iso14001.file = file;
    this.plantForm.get('certificazioneIso14001').reset({ value: '' });
  }

  getDocAuthFile(file) {
    if (file != undefined) {
      console.log('getDocAuthFile() loaded file: ' + file.name);
    }else {
      console.log('getDocAuthFile() unloaded file!');
    }
    this.docAuth = new FileWithContentUpload();
    this.docAuth.file = file;
    this.plantForm.get('docAutorizzazione').reset({ value: '' });
  }
  

  initializeForm() {
    this.plantForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.plant.id, []),
      createDate: new UntypedFormControl(this.plant.createDate, []),
      nomeImpianto: new UntypedFormControl(this.plant.nomeImpianto, [Validators.required]),
      nazionalita: new UntypedFormControl(this.plant.nazionalita, [Validators.required]),
      provincia: new UntypedFormControl(this.plant.provincia, []),
      citta: new UntypedFormControl(this.plant.citta, [Validators.required]),
      cap: new UntypedFormControl(this.plant.cap, [Validators.required]),
      indirizzo: new UntypedFormControl(this.plant.indirizzo, [Validators.required]),
      capacitaProduttivaAnnua: new UntypedFormControl(this.plant.capacitaProduttivaAnnua, [Validators.required]),
      pec: new UntypedFormControl(this.plant.pec, [Validators.required, Validators.email]),
      certificazioneImpiantoValid: new UntypedFormControl(this.plant.certificazioneImpiantoValid, []),
      certificazioneIso9001: new UntypedFormControl(this.plant.certificazioneIso9001, [Validators.required]),
      certificazioneIso9001Valid: new UntypedFormControl(this.plant.certificazioneIso9001Valid, []),
      certificazioneIso14001: new UntypedFormControl(this.plant.certificazioneIso14001, [Validators.required]),
      certificazioneIso14001Valid: new UntypedFormControl(this.plant.certificazioneIso14001Valid, []),
      docAutorizzazione: new UntypedFormControl(this.plant.docAutorizzazione, [Validators.required]),
      docAutorizzazioneValid: new UntypedFormControl(this.plant.docAutorizzazioneValid, []),
      nomeRifSpedizione: new UntypedFormControl(this.plant.nomeRifSpedizione, []),
      telefonoRifSpedizione: new UntypedFormControl(this.plant.telefonoRifSpedizione, []),
      mobileRifSpedizione: new UntypedFormControl(this.plant.mobileRifSpedizione, []),
      noteTrasportatore: new UntypedFormControl(this.plant.noteTrasportatore, []),
      emailRifSpedizione: new UntypedFormControl(this.plant.emailRifSpedizione, []),
    });
  }

  ngOnInit(): void {
    console.log('init()');
    if (this.isUser()) {
      this.vatnumber = this.localUtil.authParams.organization_detail.id;
      console.log('vatnumber set to ' + this.vatnumber + ' for isUser()');
    }
    this.loadNazioni();
    this.loadProvince();
    this.listFrameworks();
  }

  loadNazioni() {
    const nazioniService = this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      },
    this.handleError(true));
    this.subscriptions.push(nazioniService);
  }

  loadProvince() {
    this.province_list = [];
    const provinceService = this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
    this.handleError(true));
    this.subscriptions.push(provinceService);
  }

  isNewEditing() {
    return (this.plant.newEditing == true);
  }

  listFrameworks() {
    let list_to_add: OrganizationPlantItem[] = [];
    const plantService = this.organizationService.getOrganizationPlants(this.localUtil.authParams.organization_detail.orgname).subscribe(
        (data) => {
            if (data.list) {
                data.list.forEach((itemImpianto) => {
                    this.processPlantItem(itemImpianto);
                    list_to_add.push(itemImpianto);
                });
            }
            this.plants_list = list_to_add;
        },
        this.handleError(true)
    );
    this.subscriptions.push(plantService);
  }

  processPlantItem(itemImpianto: OrganizationPlantItem) {
    this.setPlantStatus(itemImpianto);
    this.setIso9001Status(itemImpianto);
    this.setIso14001Status(itemImpianto);
    this.setDocAuthStatus(itemImpianto);
    this.setOverallStatus(itemImpianto);
  }

setPlantStatus(itemImpianto: OrganizationPlantItem) {
    if (itemImpianto.certificazioneImpiantoValid === "") {
        itemImpianto.statusClass = "pending";
        itemImpianto.status = "In attesa di verifica";
    } else if (itemImpianto.certificazioneImpiantoValid === "N") {
        itemImpianto.statusClass = "disabled";
        itemImpianto.status = "Rifiutato";
    } else if (itemImpianto.certificazioneImpiantoValid === "Y") {
        itemImpianto.statusClass = "active";
        itemImpianto.status = "Attivo";
    }
  }

setIso9001Status(itemImpianto: OrganizationPlantItem) {
    if (itemImpianto.certificazioneIso9001 === "") {
        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
        itemImpianto.fileClass9001 = "ko";
    } else if (itemImpianto.certificazioneIso9001Valid === "Y") {
        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
        itemImpianto.fileClass9001 = "ok";
    } else if (itemImpianto.certificazioneIso9001Valid === "") {
        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
        itemImpianto.fileClass9001 = "pending";
    } else if (itemImpianto.certificazioneIso9001Valid === "N") {
        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
        itemImpianto.fileClass9001 = "ko";
    }
}

setIso14001Status(itemImpianto: OrganizationPlantItem) {
  if (itemImpianto.certificazioneIso14001 === "") {
      itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
      itemImpianto.fileClass14001 = "ko";
  } else if (itemImpianto.certificazioneIso14001Valid === "Y") {
      itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
      itemImpianto.fileClass14001 = "ok";
  } else if (itemImpianto.certificazioneIso14001Valid === "") {
      itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      itemImpianto.fileClass14001 = "pending";
  } else if (itemImpianto.certificazioneIso14001Valid === "N") {
      itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
      itemImpianto.fileClass14001 = "ko";
  }
}

setDocAuthStatus(itemImpianto: OrganizationPlantItem) {
  if (itemImpianto.docAutorizzazione === "") {
      itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
      itemImpianto.fileClassDocAuth = "ko";
  } else if (itemImpianto.docAutorizzazioneValid === "Y") {
      itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
      itemImpianto.fileClassDocAuth = "ok";
  } else if (itemImpianto.docAutorizzazioneValid === "") {
      itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      itemImpianto.fileClassDocAuth = "pending";
  } else if (itemImpianto.docAutorizzazioneValid === "N") {
      itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
      itemImpianto.fileClassDocAuth = "ko";
  }
}

  setOverallStatus(itemImpianto: OrganizationPlantItem) {
      if (itemImpianto.certificazioneIso9001 === "" || itemImpianto.certificazioneIso14001 === "" || itemImpianto.docAutorizzazione === "") {
          itemImpianto.statusDoc = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE") + " o " + this.messageService.getLabelFromCode("DESCR_DOC_MANCANTI");
      } else {
          itemImpianto.statusDoc = "";
      }
  }

  toggleNuovoImpianto() {
    this.annullaImpianto();
    this.plant.newEditing = !this.plant.newEditing;
    if (this.plant.newEditing) {
      this.cleanValidation();
    }
  }

  apriFormImpiantoDettaglio() {
    this.plant.newEditing = true;
    this.plant.editing = true;
  }

  cleanValidation() {
    this.plantForm.markAllAsTouched();
  }

  annullaImpianto() {
    this.plant = new OrganizationPlantItem();
    this.plant.newEditing = false;
    this.submitted = false;
    this.newPlantId = undefined;
    this.initializeForm();
  }
  
  salvaImpianto() {
    
    if (this.validate()) {      
      if (this.plant != undefined && (this.plant.id != undefined && this.plant.id > 0)) {
        this.updateImpianto();
      } else {
        this.insertImpianto();
      }

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  newPlantId: number;

  insertImpianto() {
    
      const plantService = this.organizationService.insertOrganizationPlant(this.localUtil.authParams.organization_detail.orgname, this.plant).subscribe(
        (data) => {
          this.localUtil.showLoader();
          this.newPlantId = data.detail.id;
          setTimeout(this.insertDocImpianto, 500);
      },
      this.handleError(true));
      this.subscriptions.push(plantService);

  }

  insertDocImpianto() {
    let id = this.newPlantId;
    this.flag9001 = true;
    this.flag14001 = true;
    this.flagDocAuth = true;    
    this.plant.id = id;
    //update
    if (this.iso9001 != undefined && this.iso9001.file != undefined) {
      this.flag9001 = false;
      this.iso9001.contentType = 'FWK';
      this.iso9001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso9001.frameworkId = id.toString();
      
      const uploadService = this.commonService.documentUpload(this.iso9001).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.certificazioneIso9001 = data.file_uuid;
          this.flag9001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
      this.subscriptions.push(uploadService);
    }

    if (this.iso14001 != undefined && this.iso14001.file != undefined) {
      this.flag14001 = false;
      this.iso14001.contentType = 'FWK';
      this.iso14001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso14001.frameworkId = id.toString();
      
      const uploadService = this.commonService.documentUpload(this.iso14001).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.certificazioneIso14001 = data.file_uuid;
          this.flag14001 = true;
          this.salvaBeanImpianto();
      }, this.handleError(true));
      this.subscriptions.push(uploadService);
    }

    if (this.docAuth != undefined && this.docAuth.file != undefined) {
      this.flagDocAuth = false;
      this.docAuth.contentType = 'FWK';
      this.docAuth.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.docAuth.frameworkId = id.toString();
      
      const uploadService = this.commonService.documentUpload(this.docAuth).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.docAutorizzazione = data.file_uuid;
          this.flagDocAuth = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
      this.subscriptions.push(uploadService);
    }

    this.salvaBeanImpianto();

  }

  updateImpianto() {
    this.flag9001 = true;
    this.flag14001 = true;
    this.flagDocAuth = true;    
    //update
    if (this.iso9001 != undefined && this.iso9001.file != undefined) {
      this.flag9001 = false;
      this.iso9001.contentType = 'FWK';
      this.iso9001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso9001.frameworkId = this.plant.id.toString();
      
      const uploadService = this.commonService.documentUpload(this.iso9001).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.certificazioneIso9001 = data.file_uuid;
          this.flag9001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
        this.subscriptions.push(uploadService);
    }

    if (this.iso14001 != undefined && this.iso14001.file != undefined) {
      this.flag14001 = false;
      this.iso14001.contentType = 'FWK';
      this.iso14001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso14001.frameworkId = this.plant.id.toString();
      
      const uploadService = this.commonService.documentUpload(this.iso14001).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.certificazioneIso14001 = data.file_uuid;
          this.flag14001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
        this.subscriptions.push(uploadService);
    }

    if (this.docAuth != undefined && this.docAuth.file != undefined) {
      this.flagDocAuth = false;
      this.docAuth.contentType = 'FWK';
      this.docAuth.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.docAuth.frameworkId = this.plant.id.toString();
      
      const uploadService = this.commonService.documentUpload(this.docAuth).subscribe(
        data => {
          data = JSON.parse(data);
          this.plant.docAutorizzazione = data.file_uuid;
          this.flagDocAuth = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
      this.subscriptions.push(uploadService);
    }

    this.salvaBeanImpianto();
  }

  salvaBeanImpianto() {
    
   if (this.flag9001 && this.flag14001 && this.flagDocAuth) {
        const plantService = this.organizationService.updateOrganizationPlant(this.localUtil.authParams.organization_detail.orgname, this.plant).subscribe(
          (data) => {
            this.localUtil.showMessage("", this.checkAndReplaceTranslation(this.translate, ConstantsModule.INFO_TITLE), this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_UPDATE")), true);
            this.annullaImpianto();
            this.listFrameworks();
        },
        this.handleError(true));
        this.subscriptions.push(plantService);
    }

  }

  validate() {
    this.submitted = true;
    let output = true;
    Object.keys(this.plantForm.controls).forEach(key => {
      if (!this.plantForm.get(key).valid) {
        output = false;        
      } else {
        this.plant[key] = this.plantForm.get(key).value;
      }
    });
    /*if (output) {
      this.prepareBean(this.plant, this.plantForm);
    }*/
    return output;
  }

  /*prepareBean(plant: OrganizationPlantItem, plantForm: FormGroup ) {
  }*/

  getControlErrorMessage(name) {
    let output = '';
    let errors = this.plantForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validateField(nome) {
    return this.plantForm.get(nome).valid;
  }

  controlHasError(nome) {
    if (nome != 'provincia') {
      return !this.plantForm.get(nome).valid && this.submitted;
    } else {
      if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value != '' && this.submitted) {
        return false;
      } else if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value == '' && this.submitted) {
        return true;
      } else if (this.plantForm.get('nazionalita').value != 'IT' && this.submitted) {
        return false;
      } else {
        return false;
      }
    }
  }

  toggleDettaglio(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms();
      this.plant = data;
      data.formOpened = true;
    }
  }

  openDettaglio(data) {
    data.formOpened = true;
    this.plant = data;
  }

  closeDettaglio(data) {
    data.formOpened = false;
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  toggleMenu(data) {
    data.menuOpened = !data.menuOpened;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  modificaImpianto(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms();
      data.editing = true;
    }
    this.plant = data;    
    this.initializeForm();
    this.apriFormImpiantoDettaglio();
    this.closeMenu(data);
    this.localService.scrollTop();
  }

  disableAllEditForms() {
    if (this.plants_list != undefined) {
      this.plants_list.forEach( (operator:OrganizationPlantItem) => {
        operator.editing = false;
        operator.formOpened = false;
        operator.menuOpened = false;
      });
    }
  }

  annullaModificaImpianto(data) {
    data.editing = !data.editing;
    this.plant = new OrganizationPlantItem();
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  isEditing(data) {
    return (data.editing == true);
  }

  aggiornaImpianto(data) {
    
    const updateService = this.organizationService.updateUser(data).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );
    this.subscriptions.push(updateService);
  }

  disabilitaImpianto(data) {

    const disableService = this.organizationService.disableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );
    this.subscriptions.push(disableService);

  }

  abilitaImpianto(data) {

    const enableService = this.organizationService.enableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );
    this.subscriptions.push(enableService);

  }

  eliminaImpianto(data) {

    let params:any[] = [data.nomeImpianto];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_MESSAGE", params);
    const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        const plantService = this.organizationService.deleteFramework(this.localUtil.authParams.organization_detail.orgname, data.id).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_DELETE"), true);
            this.listFrameworks();
          },
          this.handleError(true)
        );
        this.subscriptions.push(plantService);
      }
    });
    this.subscriptions.push(modalService);
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      })
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }
  
}

