<html>
  <title>coripet</title>
  <ng-container *ngIf="islogged">
    <app-head></app-head>
    <div id="container">
        <app-spalla></app-spalla>
        <main [attr.id]="pageUrl">
            <app-header></app-header>
            <router-outlet></router-outlet>
        </main>
    </div>
    <app-footer></app-footer>
  </ng-container>

  <ng-container *ngIf="!islogged">
    <app-head></app-head>
    <router-outlet></router-outlet>    
    <app-footer></app-footer>
  </ng-container>

  <app-loader></app-loader>
  
</html>