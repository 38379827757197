<div id="azienda-operatori" class="content bottom80 p-4 my-3"> 
    <div class="add-item-section add-item-section-user">
        <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 class="pb-4 fs24 gray-dark inter bold" translate>azienda-operatori.nuovo-operatore</h3>
            <form id="form_createOperator" [formGroup]="operatorForm" class="form-group">
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('nome')}"
                        (mouseleave)="validateField('nome')">
                        <div class="input-wrapper full">
                            <input required formControlName="nome" type="text" id="nome" maxlength="75"
                                class="form-control">
                            <label for="newOperator--name" translate>azienda-operatori.nome</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('cognome')}">
                        <div class="input-wrapper full">
                            <input required formControlName="cognome" type="text" id="cognome" maxlength="75">
                            <label for="newOperator--surname" translate>azienda-operatori.cognome</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4 half-width pe-1" [ngClass]="{'error': controlHasError('email')}">
                        <div class="input-wrapper full">
                            <input required formControlName="email" type="text" id="email" maxlength="75" email="true">
                            <label for="newOperator--email" translate>azienda-operatori.indirizzo-email</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('password')}">
                        <div class="input-wrapper full">
                            <input required formControlName="password" type="password" class="form-control"
                                id="password" maxlength="75">
                            <label for="newOperator--password" translate>azienda-operatori.password</label>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('password')">{{getControlErrorMessage('password')}}</span>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('confermaPassword')}">
                        <div class="input-wrapper full">
                            <input required formControlName="confermaPassword" type="password" class="form-control"
                                id="confermaPassword" maxlength="75">
                            <label for="newOperator--password-confirm"
                                translate>azienda-operatori.conferma-password</label>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('confermaPassword')">{{getControlErrorMessage('confermaPassword')}}</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('telefono')}">
                        <div class="input-wrapper full">
                            <input required formControlName="telefono" type="text" id="telefono" maxlength="75">
                            <label for="newOperator--phone" translate>azienda-operatori.telefono</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('mobile')}">
                        <div class="input-wrapper full">
                            <input required type="text" formControlName="mobile" id="mobile" maxlength="75">
                            <label for="newOperator--mobile" translate>azienda-operatori.telefono-mobile</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4 half-width pe-1" [ngClass]="{'error': controlHasError('lingua')}">
                        <div class="input-wrapper full">
                            <select required formControlName="lingua" id="lingua">
                                <option value="IT">Italiano</option>
                                <option value="EN">Inglese</option>
                            </select>
                            <label for="newOperator--language" translate>azienda-operatori.lingua</label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="add-operator-actions mt-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-end">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class="btn btn-outline-primary button-color" type="submit" (click)="salvaNuovoOperatore()"
                    id="btn_createOperator"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler d-flex mb-4 blue-dark pointer w-100" (click)="toggleNuovoOperatore()">
            <article class="d-flex align-items-center w-100">
                <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                <div class="text fs18"><span translate>azienda-operatori.aggiungi-operatore</span></div>
            </article>
        </button>

    </div>
    <h2 translate>azienda-operatori.operatori</h2>
    <div id="operators-list" class="mt-5">
        <dx-data-grid id="gridContainer" 
            #gridOperators
            [dataSource]="operators_list" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onRowUpdating)="onRowUpdating($event)"
            (onEditingStart)="onEditingStart($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" caption="Id" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="UserId" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'azienda-operatori-lista.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="cognome" caption="{{'azienda-operatori-lista.cognome' | translate}}" dataType="string"
                [visible]="true">
            </dxi-column>
            <dxi-column dataField="nome" caption="{{'azienda-operatori-lista.nome' | translate}}" dataType="string" [visible]="true"
                cssClass="operator operator-attivo">
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" caption="{{'azienda-operatori-lista.data-creazione' | translate}}" dataType="string" format="dd/MM/yyyy"
                [visible]="true">
            </dxi-column>
            <dxi-column dataField="email" caption="Email" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="lingua" caption="{{'azienda-operatori-lista.lingua' | translate}}" dataType="string" [visible]="false">
                <dxo-lookup [dataSource]="lang_list" valueExpr="codice" displayExpr="valore">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="telefono" caption="{{'azienda-operatori-lista.telefono' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="role" caption="{{'azienda-operatori-lista.codice-ruolo' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="roleDescr" caption="{{'azienda-operatori-lista.ruolo' | translate}}" dataType="string" [visible]="true">
            </dxi-column>
            <dxi-column dataField="stato" caption="{{'azienda-operatori-lista.stato' | translate}}" dataType="string" [visible]="true"
                cellTemplate="statusTemplate">
                <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                    <div class="status {{statusTemplate.data.statusClass}}">{{statusTemplate.data.status}}</div>
                </div>
            </dxi-column>

            <dxo-master-detail [enabled]="true" template="companyOperatorDetail"></dxo-master-detail>

            <div *dxTemplate="let companyOperatorDetail of 'companyOperatorDetail'">
                <dx-form [(formData)]="companyOperatorDetail.data" id="dettaglio-tipologia-asta">
                    <dxi-item itemType="group" caption="{{ 'operatori.dettaglio' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="10" dataField="nome" [label]="{text: 'azienda-operatori-lista.nome' | translate}">                                
                            </dxi-item>
                            <dxi-item [colSpan]="10" dataField="cognome"  [label]="{text: 'azienda-operatori-lista.cognome' | translate}"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="12" dataField="email"></dxi-item>
                            <dxi-item [colSpan]="8" dataField="lingua" [label]="{text: 'azienda-operatori-lista.lingua' | translate}">
                                <dx-select-box [(value)]="companyOperatorDetail.data.lingua" [dataSource]="lang_list"
                                    displayExpr="valore" valueExpr="codice"></dx-select-box>
                            </dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="10" dataField="telefono" [label]="{text: 'azienda-operatori-lista.telefono' | translate}"></dxi-item>
                            <dxi-item [colSpan]="10" dataField="mobile"></dxi-item>
                        </dxi-item>
                    </dxi-item>
                </dx-form>
            </div>


            <dxi-column type="buttons" width="130">
                <dxi-button name="edit"></dxi-button>
                <dxi-button hint="{{'azienda-operatori-lista.elimina' | translate}}" name="delete" [onClick]="eliminaOperatore"></dxi-button>
                <dxi-button hint="{{'azienda-operatori-lista.abilita' | translate}}" icon="check" [visible]="isCompanyOperatorDisabled"
                    [onClick]="enableCompanyOperator"></dxi-button>
                <dxi-button hint="{{'azienda-operatori-lista.disabilita' | translate}}" icon="clearsquare" [visible]="isCompanyOperatorEnabled"
                    [onClick]="disableCompanyOperator"></dxi-button>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>
            </dxo-toolbar>

            <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="true" mode="form">

                <dxo-texts confirmDeleteMessage="Sei sicuro di voler eliminare questo setup ?"></dxo-texts>

                <dxo-form [colCount]="20">
                    <dxi-item itemType="group" caption="{{ 'operatori.dettaglio-modifica' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="10" dataField="nome"></dxi-item>
                            <dxi-item [colSpan]="10" dataField="cognome"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="12" dataField="email"></dxi-item>
                            <dxi-item [colSpan]="8" dataField="lingua"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colCount]="20" cssClass="detail-form-section">
                            <dxi-item [colSpan]="10" dataField="telefono"></dxi-item>
                            <dxi-item [colSpan]="10" dataField="mobile"></dxi-item>
                        </dxi-item>
                    </dxi-item>
                </dxo-form>

            </dxo-editing>

            <div *dxTemplate="let operatorDetail of 'lotRowTemplate'">
                <div class="operator operator-{{operatorDetail.data.statusClass}}">
                    <article id="{{operatorDetail.data.id}}">
                        <ul class="operator-header card-header">
                            <li name="name" class="name">
                                {{operatorDetail.data.nome}}&nbsp;{{operatorDetail.data.cognome}}</li>
                            <li name="createDate" class="creation-date">utente dal
                                {{operatorDetail.data.dataRegistrazione}}</li>
                            <li name="role" class="role">{{operatorDetail.data.roleDescr}}</li>
                            <li name="status" class="status {{operatorDetail.data.statusClass}}">
                                {{operatorDetail.data.status}}</li>
                            <!--li name="organization" class="company"><a href="operatore-aziende-dettaglio.html?id={{idOrganization}}">{{ragioneSociale}}</a></li-->
                            <li class="actions" [ngClass]="{'menu-open': isMenuOpened(operatorDetail.data)}">
                                <div class="actions-icons">
                                    <div class="card-menu-toggler"
                                        [ngClass]="{'open': isMenuOpened(operatorDetail.data)}"
                                        (click)="toggleMenu(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                        <!-- <i class="fal fa-bars"></i> -->
                                    </div>
                                    <div class="card-body-toggler"
                                        [ngClass]="{'open': isFormOpened(operatorDetail.data)}"
                                        (click)="toggleDettaglio(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                        <!-- <i class="fal fa-expand-alt"></i> -->
                                    </div>
                                </div>
                                <div class="operator-menu operator-{{operatorDetail.data.stato}}">
                                    <ul>
                                        <li [ngClass]="{'hidden': !isEditing(operatorDetail.data)}"
                                            (click)="annullaModificaOperatore(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                            <div class="icon"><i class="fal fa-undo"></i></div>
                                            <div class="text operator-btn-cancelmodify">Annulla
                                                modifica</div>
                                        </li>
                                        <li [ngClass]="{'hidden': isEditing(operatorDetail.data)}"
                                            (click)="modificaOperatore(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                            <div class="icon"><i class="fal fa-edit"></i></div>
                                            <div class="text operator-btn-modify">Modifica</div>
                                        </li>
                                        <li class="operator-btn-disable-container"
                                            (click)="disabilitaOperatore(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                            <div class="icon"><i class="fal fa-user-slash"></i></div>
                                            <div class="text operator-btn-disable">Disabilita</div>
                                        </li>
                                        <li class="operator-btn-enable-container"
                                            (click)="abilitaOperatore(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                            <div class="icon"><i class="fal fa-user"></i></div>
                                            <div class="text operator-btn-enable">Abilita</div>
                                        </li>
                                        <li (click)="eliminaOperatore(operatorDetail.data)" (keydown)="handleKeyDown($event)">
                                            <div class="icon"><i class="fal fa-times"></i></div>
                                            <div class="text operator-btn-delete">Elimina</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <form>
                            <div class="operator-body card-body">
                                <div class="body-wrapper"
                                    [ngClass]="{'form-visible': isFormOpened(operatorDetail.data)}">
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.nome" type="text" name="operator-name"
                                                    value="{{operatorDetail.data.nome}}">
                                                <label for="operator-name" translate>azienda-operatori.nome</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.cognome" type="text"
                                                    name="operator-surname" value="{{operatorDetail.data.cognome}}">
                                                <label for="operator-surname"
                                                    translate>azienda-operatori.cognome</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.email" type="email" disabled
                                                    name="operator-email" value="{{operatorDetail.data.email}}">
                                                <label for="operator-email" translate>azienda-operatori.email</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <select [(ngModel)]="operator.lingua" name="operator-language" id="">
                                                    <option value=""></option>
                                                    <option value="IT" [selected]="operatorDetail.data.lingua === 'IT'">
                                                        Italiana</option>
                                                    <option value="EN" [selected]="operatorDetail.data.lingua === 'EN'">
                                                        Inglese</option>
                                                </select>
                                                <label for="operator-language"
                                                    translate>azienda-operatori.lingua</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.telefono" type="text" name="operator-phone"
                                                    value="{{operatorDetail.data.telefono}}">
                                                <label for="operator-phone"
                                                    translate>azienda-operatori.telefono-fisso</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.mobile" type="text" name="operator-mobile"
                                                    value="{{operatorDetail.data.mobile}}">
                                                <label for="operator-mobile"
                                                    translate>azienda-operatori.telefono-cellulare</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" [ngClass]="{'hidden': !isEditing(operatorDetail.data)}">
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <button type="button" class="btn btn-primary update-user"
                                                    (click)="aggiornaOperatore(operatorDetail.data)"><span
                                                        translate>azienda-operatori.salva</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </article>
                </div>
            </div>

        </dx-data-grid>
    </div>
</div>