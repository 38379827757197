<div class="p-4 bottom80">
    <h2 class="fs28 gray-dark inter bold mb-5" translate>centri-di-selezione.centri-di-selezione</h2>
    <div id="newcs">
        <dx-data-grid #dataGridCs id="gridContainer" 
            [dataSource]="cs_list" 
            keyExpr="id" 
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onEditingStart)="onEditingStart($event)" 
            (onInitNewRow)="onInitNewRow($event)"
            (onRowExpanding)="onRowExpanding($event)" 
            (onRowUpdating)="onRowUpdate($event)"
            (onRowInserting)="onRowInsert($event)" 
            (onRowPrepared)="onRowPrepared($event)"
            (onRowRemoving)="onRowDelete($event)"
            (onExporting)="onExporting($event)">

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-scrolling mode="default"></dxo-scrolling>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"
                placeholder="{{'search'|translate}}">
            </dxo-search-panel>

            <dxi-column dataField="id" [allowEditing]="false" caption="{{ 'centri-di-selezione.id' | translate }}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="createDate" caption="{{ 'centri-di-selezione.data-creazione' | translate }}"
                dataType="string" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="modifiedDate" caption="{{ 'centri-di-selezione.data-modifica' | translate }}"
                dataType="string" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codice" caption="{{ 'centri-di-selezione.codice' | translate }}" cssClass="codice" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="nome" caption="{{ 'centri-di-selezione.nome' | translate }}" dataType="string" cssClass="nome WrappedColumnClass" 
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="indirizzo" caption="{{ 'centri-di-selezione.indirizzo' | translate }}"
                dataType="string" [visible]="true" cssClass="indirizzo WrappedColumnClass">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="cap" caption="{{ 'centri-di-selezione.cap' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="citta" caption="{{ 'centri-di-selezione.città' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="nazione" caption="{{ 'centri-di-selezione.nazione' | translate }}"
                [calculateCellValue]="getNazioneDescr" dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="nation_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="nazioneDescr" caption="{{ 'centri-di-selezione.nazioneDescr' | translate }}"
                dataType="string" [visible]="false">
            </dxi-column>

            <dxi-column dataField="provincia" caption="{{ 'centri-di-selezione.provincia' | translate }}"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="province_list" valueExpr="descrizione" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="{{ 'centri-di-selezione.area-geografica' | translate }}"
                dataType="string" [visible]="true" cssClass="area">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="geo_list" valueExpr="area" displayExpr="area">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="CER" caption="{{ 'centri-di-selezione.cer' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="pesoMedioCarico" caption="{{ 'centri-di-selezione.peso-medio' | translate }}"
                dataType="string" [visible]="true" cssClass="peso">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="capacitaTotale"
                caption="{{ 'centri-di-selezione.capacità-totale-impianto' | translate }}" dataType="string"
                [visible]="false" cellTemplate="capacityTemplate">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="note" caption="{{ 'centri-di-selezione.note' | translate }}" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAzienda" caption="{{ 'centri-di-selezione.codice-azienda' | translate }}" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceEsterno" caption="{{ 'centri-di-selezione.codice-esterno' | translate }}" dataType="string"
                [visible]="false">
            </dxi-column>

            <dxi-column type="buttons" [width]="110">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item name="exportButton"></dxi-item>
                <dxi-item name="columnChooserButton"></dxi-item>
                <dxi-item name="addRowButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let capacityTemplate of 'capacityTemplate'">
                <a>{{formatNumber(capacityTemplate.data.capacitaTotale)}} ton</a>
            </div>

            <dxo-master-detail [enabled]="false" template="detailCs"></dxo-master-detail>

            <div *dxTemplate="let detailCs of 'detailCs'">

                <dx-form [(formData)]="detailCs.data">

                    <dxi-item itemType="group"
                        caption="{{ 'centri-di-selezione.dati-centro-di-selezione' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="codice"></dxi-item>
                            <dxi-item dataField="nome"></dxi-item>
                            <dxi-item dataField="indirizzo"></dxi-item>
                            <dxi-item dataField="cap"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="citta"></dxi-item>
                            <dxi-item dataField="nazioneDescr" [label]="{text: 'Nazione'}"></dxi-item>
                            <dxi-item dataField="provincia"></dxi-item>
                            <dxi-item dataField="codiceEsterno"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="5" cssClass="detail-form-section">
                            <dxi-item dataField="areaGeografica"></dxi-item>
                            <dxi-item dataField="pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="CER"></dxi-item>
                            <dxi-item dataField="capacitaTotale"></dxi-item>
                            <dxi-item dataField="codiceAzienda"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dx-form>
            </div>

            <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="form">

                <dxo-texts
                    confirmDeleteMessage=""></dxo-texts>

                <dxo-form [colCount]="20">

                    <dxi-item itemType="group"
                        caption="{{ 'centri-di-selezione.dati-centro-di-selezione' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="codice"></dxi-item>
                            <dxi-item dataField="nome"></dxi-item>
                            <dxi-item dataField="indirizzo"></dxi-item>
                            <dxi-item dataField="cap"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="citta"></dxi-item>
                            <dxi-item dataField="nazione"></dxi-item>
                            <dxi-item dataField="provincia"></dxi-item>
                            <dxi-item dataField="codiceEsterno"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="5" cssClass="detail-form-section">
                            <dxi-item dataField="areaGeografica"></dxi-item>
                            <dxi-item dataField="pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="CER"></dxi-item>
                            <dxi-item dataField="capacitaTotale"></dxi-item>
                            <dxi-item dataField="codiceAzienda"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dxo-form>

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>