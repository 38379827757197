import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { DocumentData, NazioneItem, OrganizationItem, OrganizationOperatorUpdateItem, OrganizationPlantItem, OrganizationUpdateItem, ProvinciaItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class AziendeComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("datagridAziende", { static: false }) datagridAziende: DxDataGridComponent;
  tabPanelOptions: any;

  fondiFilter: any;
  capacitaFilter: any;
  dataFilter: any;
  showFilterRow: boolean;
  showHeaderFilter: boolean;
  applyFilterTypes: any;
  currentFilter: any;
  customOperations: any;

  organizations_list: OrganizationItem[] = [];

  organization: OrganizationItem;
  userOrganization: UserItem;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];

  visuraCamerale: DocumentData;
  rappresentanteLegale: DocumentData;
  fidejussioneBancaria: DocumentData;
  dichiarazione231_2001: DocumentData;

  certificazioneIso9001: DocumentData;
  certificazioneIso14001: DocumentData;
  docAutorizzazione: DocumentData;
  activeTab: string;
  detail: OrganizationItem;

  filterActive: boolean = true;
  filterToVerify: boolean = true;
  filterDisabled: boolean = true;

  idAzienda: string;

  full_organizations: OrganizationItem[] = [];
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService) {
    super(localService, location, router);

    this.showFilterRow = true;
    this.showHeaderFilter = true;
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];

    this.fondiFilter = [{
      text: 'Meno di € 50.000',
      value: ['totaleFidejussione', '<', 50000],
    }, {
      text: '€ 50.000 -  € 100.000',
      value: [
        ['totaleFidejussione', '>=', 50000],
        ['totaleFidejussione', '<', 100000],
      ],
    }, {
      text: '€ 100.000 - € 150.000',
      value: [
        ['totaleFidejussione', '>=', 100000],
        ['totaleFidejussione', '<', 150000],
      ],
    }, {
      text: '€ 150.000 - € 200.000',
      value: [
        ['totaleFidejussione', '>=', 150000],
        ['totaleFidejussione', '<', 200000],
      ],
    }, {
      text: 'Più di € 200.000',
      value: ['totaleFidejussione', '>=', 200000],
    }];

    this.capacitaFilter = [{
      text: 'Meno di 50.000 ton',
      value: ['capacitaProduttiva', '<', 50000],
    }, {
      text: '50.000 - 100.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 50000],
        ['capacitaProduttiva', '<', 100000],
      ],
    }, {
      text: '100.000 - 150.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 100000],
        ['capacitaProduttiva', '<', 150000],
      ],
    }, {
      text: '150.000 - 200.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 150000],
        ['capacitaProduttiva', '<', 200000],
      ],
    }, {
      text: 'Più di 200.000 ton',
      value: ['capacitaProduttiva', '>=', 200000],
    }];

    this.dataFilter = [{}];
    this.currentFilter = this.applyFilterTypes[0].key;

    this.reloadCompaniesList = this.reloadCompaniesList.bind(this);
    this.tabChanged = this.tabChanged.bind(this);
    console.log('currentLang:' + translateService.currentLang);
  }

  loadNazioni() {
    this.nation_list = [];
    const nazioniService = this.commonService.getNationsList().subscribe(
      {next:data => {
        let nazioneVuota = new NazioneItem();
        nazioneVuota.codice = "";
        nazioneVuota.descrizione = " - ";
        this.nation_list.push(nazioneVuota);
        if (data?.list) {          
          this.nation_list.push(...data.list);
        }
      },
      error: this.handleError(true)
    });
    this.subscriptions.push(nazioniService);
  }

  loadProvince() {
    this.province_list = [];
    const provinceService = this.commonService.getProvinceItalianeList().subscribe(
      {next:data => {
        let provinciaVuota = new ProvinciaItem();
        provinciaVuota.codice = "";
        provinciaVuota.descrizione = " - ";
        this.province_list.push(provinciaVuota);
        if (data?.list) {
          this.province_list.push(...data.list);
        }
      },
      error: this.handleError(true)
    });
    this.subscriptions.push(provinceService);
  }


  loadOrganizationUser(data: any, vatnumber: string) {
    console.log('loadOrganizationUser() ' + data.userId);
    const userService = this.organizationService.getUserDetail(data.userId).subscribe(
      (res: any) => {
        console.log(res);
        let user: UserItem = res.detail;
        data.referente = user.nome + ' ' + user.cognome;
        data.userOrganization = res.detail;
      }, this.handleError(false)
    );
    this.subscriptions.push(userService);
  }

  loadOrganizationPlants(dataRow: any, vatnumber: string) {
    const plantService = this.organizationService.getOrganizationPlants(vatnumber).subscribe(
        data => {
            dataRow.plants = [];

            if (data?.list) {
                data.list.forEach((itemImpianto: OrganizationPlantItem) => {
                    this.setPlantStatus(itemImpianto);
                    this.setIso9001Status(itemImpianto);
                    this.setIso14001Status(itemImpianto);
                    this.setDocAuthStatus(itemImpianto);
                    this.setStatusDoc(itemImpianto);

                    dataRow.plants.push(itemImpianto);
                });

                if (vatnumber === this.lastOrgname) {
                    this.lastOrgnamePlantLoaded = true;
                }
            }
        },
        this.handleError(true)
    );
    this.subscriptions.push(plantService);
}

  setPlantStatus(itemImpianto: OrganizationPlantItem) {
      switch (itemImpianto.certificazioneImpiantoValid) {
          case "":
              itemImpianto.statusClass = "pending";
              itemImpianto.status = "In attesa di verifica";
              break;
          case "N":
              itemImpianto.statusClass = "disabled";
              itemImpianto.status = "Rifiutato";
              break;
          case "Y":
              itemImpianto.statusClass = "active";
              itemImpianto.status = "Attivo";
              break;
      }
  }
    
  setIso9001Status(itemImpianto: OrganizationPlantItem) {
      if (itemImpianto.certificazioneIso9001 === "") {
          itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
          itemImpianto.certificazioneIso9001CssClass = "ko";
      } else {
          switch (itemImpianto.certificazioneIso9001Valid) {
              case "Y":
                  itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  itemImpianto.certificazioneIso9001CssClass = "ok";
                  break;
              case "":
                  itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  itemImpianto.certificazioneIso9001CssClass = "pending";
                  break;
              case "N":
                  itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  itemImpianto.certificazioneIso9001CssClass = "ko";
                  break;
          }
      }
  }
    
  setIso14001Status(itemImpianto: OrganizationPlantItem) {
      if (itemImpianto.certificazioneIso14001 === "") {
          itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
          itemImpianto.certificazioneIso14001CssClass = "ko";
      } else {
          switch (itemImpianto.certificazioneIso14001Valid) {
              case "Y":
                  itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  itemImpianto.certificazioneIso14001CssClass = "ok";
                  break;
              case "":
                  itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  itemImpianto.certificazioneIso14001CssClass = "pending";
                  break;
              case "N":
                  itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  itemImpianto.certificazioneIso14001CssClass = "ko";
                  break;
          }
      }
  }
    
  setDocAuthStatus(itemImpianto: OrganizationPlantItem) {
      if (itemImpianto.docAutorizzazione === "") {
          itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
          itemImpianto.docAutorizzazioneCssClass = "ko";
      } else {
          switch (itemImpianto.docAutorizzazioneValid) {
              case "Y":
                  itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  itemImpianto.docAutorizzazioneCssClass = "ok";
                  break;
              case "":
                  itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  itemImpianto.docAutorizzazioneCssClass = "pending";
                  break;
              case "N":
                  itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  itemImpianto.docAutorizzazioneCssClass = "ko";
                  break;
          }
      }
  }
    
  setStatusDoc(itemImpianto: OrganizationPlantItem) {
      const iso9001 = itemImpianto.certificazioneIso9001 !== "";
      const iso14001 = itemImpianto.certificazioneIso14001 !== "";
      const docAuth = itemImpianto.docAutorizzazione !== "";
  
      if (!iso9001 || !iso14001 || !docAuth) {
          itemImpianto.statusDoc = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE") + " o " + this.messageService.getLabelFromCode("DESCR_DOC_MANCANTI");
      } else {
          itemImpianto.statusDoc = "";
      }
  }

  loadOrganizationOperators(dataRow: any, vatnumber: string) {
    const opService = this.organizationService.getOrganizationOperators(vatnumber).subscribe(
      data => {
        dataRow.operators = [];

        if (data?.list) {
          let operators: UserOperatorItem[] = data.list;
          operators.forEach((itemOp, index) => {

            //if (itemOp.deleted != "Y") {
            dataRow.operators.push(itemOp);
            //}
          });
          if (vatnumber == this.lastOrgname) {
            this.lastOrgnameOperLoaded = true;
            this.datagridAziende.instance.repaint();
          }


        }

      }, this.handleError(true));
      this.subscriptions.push(opService);
  }

  onEditingStart(e) {
    this.datagridAziende.instance.cancelEditData();
    this.datagridAziende.instance.collapseAll(-1);
    this.activeTab = ConstantsModule.ORG_TAB;
    console.log('onEditingStart() activeTab: ' + this.activeTab);
    console.log('onEditing start');
    this.detail = e.data;
    this.idAzienda = undefined;
  }

  onRowUpdate(e) {
    console.log('onRowUpdate()');
    if (e && e.newData && e.newData.userOrganization != undefined) {
      //if (this.activeTab && this.activeTab == ConstantsModule.ORG_USER_TAB) {
      console.log('saveUser()');
      this.idAzienda = e.key;
      let userOrg: OrganizationOperatorUpdateItem = new OrganizationOperatorUpdateItem();
      userOrg = $.extend({}, e.oldData.userOrganization, e.newData.userOrganization);
      const userService = this.organizationService.updateUser(userOrg).subscribe(
        (res: any) => {
        },
        this.handleError(true)
      );
      this.subscriptions.push(userService);
    }
    console.log('saveCompany()');
    this.idAzienda = e.key;
    let orgname = e.oldData.orgname;
    let org: OrganizationUpdateItem = new OrganizationUpdateItem();
    org = $.extend({}, e.oldData, e.newData);

    const orgService = this.organizationService.updateOrganization(orgname, org).subscribe(
      (res: any) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.dettaglioLoaded = false;
        this.apriEditAzienda();
      },
      this.handleError(true)
    );
    this.subscriptions.push(orgService);

  }

  plantsCallback(e) {
    console.log(e);
    this.reloadOrganizationPlants();
  }

  reloadOrganizationPlants() {
    if (this.organizations_list != undefined && this.detail != undefined) {
      this.organizations_list.forEach(org => {
        if (org.orgname == this.detail.orgname) {
          this.loadOrganizationPlants(org, org.orgname);
        }
      });
    }
  }

  tabChanged(e) {
    console.log(e.itemData.title);
    this.activeTab = e.itemData.title;
    //console.log('tabChanged() activeTab: ' + this.activeTab);
  }

  getDocumentStatusClass(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = "ko";
    } else {
      if (validated == "Y") {
        output = "ok";
      } else if (validated == "N") {
        output = "ko";
      } else if (validated == "") {
        output = "pending";
      }
    }
    return output;
  }

  getDocumentStatus(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
    } else {
      if (validated == "Y") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
      } else if (validated == "N") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
      } else if (validated == "") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      }
    }
    return output;
  }

  ngOnInit(): void {
    console.log('init()');
    this.loadNazioni();
    this.loadProvince();
    this.listOrganizations();
    this.idAzienda = this.route.snapshot.paramMap.get('id');
  }

  lastOrgname: string;
  lastOrgnameUserLoaded: boolean = false;
  lastOrgnamePlantLoaded: boolean = false;
  lastOrgnameOperLoaded: boolean = false;

  listOrganizations(orgname ? : string, isInEditMode ? : boolean) {
    let list_org: OrganizationItem[] = [];
    const listService = this.organizationService.getOrganizationList().subscribe(
        data => {
            if (data?.list) {
                this.full_organizations = data.list;
                this.lastOrgname = data.list[data.list.length - 1].orgname;
                data.list.forEach(org => {
                    if (!orgname || orgname === org.orgname) {
                        this.processOrganization(org);
                        list_org.push(org);
                    }
                });
            }
            this.organizations_list = list_org;
        },
        this.handleError(true)
    );
    this.subscriptions.push(listService);
}

processOrganization(org: OrganizationItem) {
    try {
        this.setOrganizationTab(org);
        this.formatCreationDate(org);
        this.setStatus(org);
        this.setDocumentStatus(org, 'visuraCamerale', 'visuraCameraleValidated', 'visuraCameraleStatus', 'visuraCameraleCssClass');
        this.setDocumentStatus(org, 'rappresentanteLegale', 'rappresentanteLegaleValidated', 'rappresentanteLegaleStatus', 'rappresentanteLegaleCssClass');
        this.setDocumentStatus(org, 'fidejussioneBancaria', 'fidejussioneBancariaValidated', 'fidejussioneBancariaStatus', 'fidejussioneBancariaCssClass');
        this.setDocumentStatus(org, 'dichiarazione231_2001', 'dichiarazione231_2001Validated', 'dichiarazione231_2001Status', 'dichiarazione231_2001CssClass');
        this.loadUserDetails(org);
        this.loadOrganizationDetails(org);
        this.full_organizations.push(org);
    } catch (err) {
        this.handleOrganizationError(org);
    }
}

setOrganizationTab(org: OrganizationItem) {
    org.tab = 'ORG_TAB';
}

formatCreationDate(org: OrganizationItem) {
    let dataSplitted = org.dataCreazione.split('/');
    if (dataSplitted) {
        org.dataCreazione = `${dataSplitted[2]}/${dataSplitted[1]}/${dataSplitted[0]}`;
    }
}

setStatus(org: OrganizationItem) {
    if (org.disabled === "Y") {
        org.statusClass = "disabled";
        org.status = "Disabilitata";
    } else if (org.validated === "N") {
        org.statusClass = "disabled";
        org.status = "Non abilitata";
    } else if (org.validated === "") {
        org.statusClass = "pending";
        org.status = "In attesa di verifica";
    } else if (org.validated === "Y") {
        org.statusClass = "active";
        org.status = "Abilitata";
    }
}

setDocumentStatus(org: OrganizationItem, docField: string, validField: string, statusField: string, classField: string) {
  if (org[docField] === "") {
      org[statusField] = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
      org[classField] = "ko";
  } else if (org[validField] === "Y") {
      org[statusField] = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
      org[classField] = "ok";
  } else if (org[validField] === "N") {
      org[statusField] = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
      org[classField] = "ko";
  } else if (org[validField] === "") {
      org[statusField] = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      org[classField] = "pending";
  }
}

loadUserDetails(org: OrganizationItem) {
    const userService = this.organizationService.getUserDetail(org.userId).subscribe(
        (res: any) => {
            let user: UserItem = res.detail;
            user.tab = 'ORG_USER_TAB';
            org.userOrganization = user;
            org.referente = `${user.nome} ${user.cognome}`;
            if (org.orgname === this.lastOrgname) {
                this.lastOrgnameUserLoaded = true;
            }
        },
        this.handleError(true)
    );
    this.subscriptions.push(userService);
  }

  loadOrganizationDetails(org: OrganizationItem) {
      org.plants = [];
      this.loadOrganizationPlants(org, org.orgname);
      org.operators = [];
      this.loadOrganizationOperators(org, org.orgname);
  }

  handleOrganizationError(org: OrganizationItem) {
      let params: any[] = [org.userId];
      this.localUtil.showError("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("USER_NOT_FOUND", params));
  }

  apriEditAzienda() {
    if (!this.dettaglioLoaded) {
      console.log('apro dettaglio idAzienda: ' + this.idAzienda);
      let rowIndex = this.datagridAziende.instance.getRowIndexByKey(parseInt(this.idAzienda));
      if (rowIndex > 1) {
        this.datagridAziende.instance.editRow(rowIndex);
        this.dettaglioLoaded = true;
      }
    }
  }

  onRowExpanding(e) {
    this.idAzienda = undefined;
    this.datagridAziende.instance.collapseAll(-1);
    this.datagridAziende.instance.cancelEditData();
  }

  formatNumber(num: number) {
    return this.localUtil.formatNumber2(num);
  }

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  dettaglioLoaded: boolean = false;

  onContentReady(event) {
    if (this.idAzienda != undefined && this.lastOrgnameUserLoaded && this.lastOrgnamePlantLoaded && this.lastOrgnameOperLoaded) {
      this.apriEditAzienda();
    } else if (this.idAzienda != undefined) {
      setTimeout(() =>{
        this.checkAndOpenAziendaEdit();
      }, 3000);
    }
  }

  checkAndOpenAziendaEdit() {
    if (this.idAzienda != undefined && this.lastOrgnameUserLoaded && this.lastOrgnamePlantLoaded && this.lastOrgnameOperLoaded) {
      this.apriEditAzienda();
    }
  }

  onContentReadyPlants(event) {
  }

  getNazionalitaDescr(val) {
    let output = '';
    this.nation_list.forEach(item => {
      if (item.codice == val || item.descrizione == val) {
        output = item.descrizione;
      }
    });
    return output;
  }

  caricaDocumento(document: string) {

    console.log('caricaDocumento() document: ' + document);
    let params: any[] = [document];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.checkAndReplaceTranslation(this.translateService, this.messageService.getLabelFromCode("LOAD_ORG_DOCUMENT", params));
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'loadOrgDocument';
    modalRef.componentInstance.orgname = this.detail.orgname;
    modalRef.componentInstance.docname = document;
    const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {
        modalRef.close();
        console.log(receivedEntry);
        this.updateDocumentUUID(document, this.detail.orgname, receivedEntry.file_uuid);
        //this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        console.log('carica da popup return');
      }
    }, this.handleError(true));
    this.subscriptions.push(modalService);

  }

  updateDocumentUUID(document: string, orgname: string, uuid: any) {
    console.log('updateDocumentUUID() document: ' + document + ' uuid: ' + uuid);
    console.log();

    if (document == ConstantsModule.DOC_VISURA) {
      this.detail.visuraCameraleValidated = "";
      this.detail.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.visuraCameraleCssClass = "pending";
      this.detail.visuraCamerale = uuid;
    } else if (document == ConstantsModule.DOC_LEGALE) {
      console.log('updateDocumentUUID() updating doc legale');
      this.detail.rappresentanteLegaleValidated = "";
      this.detail.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.rappresentanteLegaleCssClass = "pending";
      this.detail.rappresentanteLegale = uuid;
    } else if (document == ConstantsModule.DOC_FIDEIUSSIONE) {
      this.detail.fidejussioneBancariaValidated = "";
      this.detail.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.fidejussioneBancariaCssClass = "pending";
      this.detail.fidejussioneBancaria = uuid;
    } else if (document == ConstantsModule.DOC_231) {
      this.detail.dichiarazione231_2001Validated = "";
      this.detail.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.dichiarazione231_2001CssClass = "pending";
      this.detail.dichiarazione231_2001 = uuid;
    }
  }

  approveOrganization() {

    const orgService = this.organizationService.approveOrganization(this.detail.orgname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateOrgStatus("Y");
        });
        this.subscriptions.push(modalService);

      }, this.handleError(true));
      this.subscriptions.push(orgService);
  }

  rejectOrganization() {

    const orgService = this.organizationService.rejectOrganization(this.detail.orgname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateOrgStatus("N");
        });
        this.subscriptions.push(modalService);
        
      }, this.handleError(true));
      this.subscriptions.push(orgService);
  }

  updateOrgStatus(status: string) {
    if (status == "Y") {
      this.detail.statusClass = "active";
      this.detail.status = "Abilitata";
    } else if (status == "N") {
      this.detail.statusClass = "disabled";
      this.detail.status = "Non abilitata";
    }
  }

  approveDocument(docname: string) {
    console.log('approveDocument() ' + docname);
    const docService = this.organizationService.approveDocument(this.detail.orgname, docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateDocStatus(docname, "Y");
        });
        this.subscriptions.push(modalService);

      }, this.handleError(true)
    );
    this.subscriptions.push(docService);
  }

  rejectDocument(docname: string) {
    console.log('rejectDocument() ' + docname);
    const docService = this.organizationService.rejectDocument(this.detail.orgname, docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        const modalService = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateDocStatus(docname, "N");
        });
        this.subscriptions.push(modalService);

      }, this.handleError(true)
    );
    this.subscriptions.push(docService);
  }

  updateDocStatus(document: string, status: string) {
    console.log('updateDocStatus() document: ' + document);
    if (document == ConstantsModule.DOC_VISURA) {
      this.detail.visuraCameraleValidated = status;
    } else if (document == ConstantsModule.DOC_LEGALE) {
      this.detail.rappresentanteLegaleValidated = status;
    } else if (document == ConstantsModule.DOC_FIDEIUSSIONE) {
      this.detail.fidejussioneBancariaValidated = status;
    } else if (document == ConstantsModule.DOC_231) {
      this.detail.dichiarazione231_2001Validated = status;
    }
  }

  //vecchia pagina sostituita da <questa pagina>/idAzienda
  /*onRowClick(e) {
    console.log(e.data);
    this.router.navigate(['operatore-aziende-dettaglio.html/', e.data.id]);
  }*/

  onRowClick(e) {
    console.log('onRowClick()');
    console.log(e.data);
    let id = this.route.snapshot.paramMap.get('id');
    if (id != undefined && id == e.data.id) {
      this.dettaglioLoaded = false;
      this.idAzienda = e.data.id;
      this.apriEditAzienda();
    } else {
      if (this.isCoripet()) {
        this.router.navigate(['operatore-aziende.html/', e.data.id]);
      } else {
        this.router.navigate(['admin-aziende.html/', e.data.id]);
      }
    }
  }

  reloadCompaniesList(filterName: string) {
    console.log('reloadCompaniesList()');
    console.log(filterName);

    // Toggle the filter states
    if (filterName == 'filterActive') this.filterActive = !this.filterActive;
    if (filterName == 'filterToVerify') this.filterToVerify = !this.filterToVerify;
    if (filterName == 'filterDisabled') this.filterDisabled = !this.filterDisabled;

    // Filter organizations
    this.filterOrganizations();
  }

  filterOrganizations() {
    // Clear the organizations list
    this.organizations_list = [];

    if (this.full_organizations != undefined) {
      console.log('this.filterActive: ' + this.filterActive);
      console.log('this.filterToVerify: ' + this.filterToVerify);
      console.log('this.filterDisabled: ' + this.filterDisabled);

      // Use a Set to track unique organizations with explicit type
      let uniqueOrgs: Set<OrganizationItem> = new Set();

      this.full_organizations.forEach((org: OrganizationItem) => {  // Ensure org is typed
        console.log(org);

        // Check each condition and add to the Set if unique
        if (this.filterDisabled && org.disabled == "Y") {
          uniqueOrgs.add(org);
        }

        if (this.filterActive && org.validated == "Y") {
          uniqueOrgs.add(org);
        }

        if (this.filterToVerify && org.validated == "") {
          uniqueOrgs.add(org);
        }

        // If this.filterDisabled is true, we may also want to include those that are not validated.
        if (this.filterDisabled && org.validated == "N") {
          uniqueOrgs.add(org);
        }
      });

      // Convert Set to array and assign to organizations_list
      this.organizations_list = Array.from(uniqueOrgs);
    }
  }


  onEditCanceled(e) {
    /*console.log('onEditCanceled()');
    if (this.idAzienda != undefined && this.dettaglioLoaded) {
      this.router.navigate([ConstantsModule.CORIPET_AZIENDE, {}]);
    }*/
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }
  
}
