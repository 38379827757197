import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pdf-resolver',
  standalone: true,
  templateUrl: './pdf-resolver.component.html',
  styleUrl: './pdf-resolver.component.css'
})
export class PdfResolverComponent {

  constructor(private router: Router,
    private route: ActivatedRoute
  ) {
    const fileName = this.route.snapshot.paramMap.get('fileName');
    if (fileName) {
      window.location.href = `/assets/regolamenti/${fileName}`;
    } else {
      this.router.navigate(['/page-not-found']);
    }
  }

}
