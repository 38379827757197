import { ConstantsModule } from './constants.module';
import { LocalUtilService } from './local-util.service';
import { Location } from '@angular/common';
import { Router, Routes } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import moment from 'moment';
import { AuctionBoxTimer, AuctionItem, AuctionLotItem, OrganizationItem } from '../models/dataModels';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { PageVisibilityService } from './PageVisibility.service';

@Injectable()
export class APIService {

  routes: Routes;
  private refreshAuctionLotsSubject = new Subject<void>();

  refreshAuctionLots:Observable<void> = this.refreshAuctionLotsSubject.asObservable();
  
  constructor(public localUtil: LocalUtilService,
    public location: Location,
    public router: Router    
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.router.url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO) < 0) {
      this.localUtil.activeAuctionCode = undefined;
    }
  }

  resizeTabbedForm() {
    $('.dx-tab-text').parent().parent().parent().parent().parent().parent().parent().parent().parent().parent().addClass('tabbed-form-with-full');
  }

  printLog(data) {
    console.log(data);
  }

  handleError<T>(showPopup: any, callback?) {
    this.localUtil.hideLoader();

    return (res: any) => {
      console.error(res);
      this.localUtil.printLog('res.error.code: ' + res.error.code);
      this.localUtil.printLog('res.error.message: ' + res.error.message);
      try {
        res.error = JSON.parse(res.error);
        this.localUtil.printLog('res.error.code1: ' + res.error.code);
        this.localUtil.printLog('res.error.message1: ' + res.error.message);
      } catch (errore) {
        //
      }

      //this.localUtil.printLog('lastErrorCode: ' +res.error.code );
      if (showPopup != undefined) {
        if (!showPopup) {
          if (callback) {
            callback();
          }
          return;
        }
      }

      let message = res.error.message;

      if (res.error.fields != undefined) {
        if (res.error.fields.length > 0) {
          message = 'Validation failed for:<br/>';
          res.error.fields.forEach((element: any) => {
            message += '<br/>' + element.field + " - " + element.message;
          });
        }
      }

      this.handleErrorCodes(res, callback, message);
    };
  }

  handleErrorCodes(res, callback, message) {
    if (res.error.code == 'E:LOGIN_ERR') {
      this.localUtil.showMessage(res.error.code, ConstantsModule.ERROR_TITLE, message, true);
    } else if (!this.localUtil.isAuthorizationFailed) {
      //console.log('this.isAuthorizationFailed: ' + this.localUtil.isAuthorizationFailed);
      this.localUtil.showMessage(res.error.code, ConstantsModule.ERROR_TITLE, message, true);
      if (callback) {
        callback();
      }
    }

    if (res.error.code === 'ERR_AUTHENTICATION' || res.error.code === 'E:AUTHENTICATION' || res.error.code === 'ERR:AUTHENTICATION' || res.error.code == 'E:AUTHORIZATION_FAIL') {
      if (!this.localUtil.isAuthorizationFailed) {
        this.localUtil.setAuthorizationFailed('true');
        console.log('E:AUTHORIZATION_FAIL, sessione scaduta, rimuovo params login e redirect a /login path');
        this.localUtil.removeAuthParams();
        this.router.navigate([ConstantsModule.LOGIN_PATH, {}]);
      }
    }

    if (res.error.code === 'E:AUC_OFFER_REFRESH_ERR') {
      this.refreshAuctionLotsSubject.next();
    }
  }

  isPopupVisible() {
    return $('#modal-dialog').hasClass('show');
  }

  isAdmin() {
    ////this.localUtil.printLog('isAdmin() ' + this.localUtil.isAdmin());
    return this.localUtil.isAdmin();
  }

  isCoripet() {
    return this.localUtil.isCoripet();
  }

  isUser() {
    ////this.localUtil.printLog('isAdmin() ' + this.localUtil.isAdmin());
    return this.localUtil.isUser();
  }

  isAnagAdminOrCoripet(anagAzienda: OrganizationItem) {
    //console.log('isAnagAdminOrCoripet() anagAzienda: ');
    //console.log(anagAzienda);
    if (anagAzienda != undefined) {
      return anagAzienda.orgname == this.localUtil._adminOrgname || anagAzienda.orgname == this.localUtil._coripetOrgname;
    } else {
      return false;
    }
  }

  downloadFile(uuid: string, filename: string) {
    this.localUtil.downloadFile(uuid, filename);
  }

  downloadFromS3Url(url: string) {
    this.localUtil.downloadFileFromS3(url);
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  ruleNumberZeroPositive(e) {
    if (e != undefined && e.value != undefined) {
      if (e.value > -1) {
        return true;
      }
    }
    return false;
  }

  ruleNumberPositive(e) {
    try {
      if (e != undefined && e.value != undefined) {
        if (e.value < 0) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  getDescrizioneRuolo(role: string) {
    let output = '';
    if (role == "OPERATORE") {
      output = "Operatore Coripet";
    } else if (role == "OPER_PLASTICFINDER") {
      output = "Admin";
    } else if (role == "OPER_AZIENDALE") {
      output = "Operatore Aziendale";
    } else if (role == "REF_AZIENDALE") {
      output = "Referente Aziendale";
    }
    return output;
  }

  get ConstantsModule() {
    return ConstantsModule;
  }

  get LocalUtil() {
    return this.localUtil;
  }

  getRouterLinkCompanyDetail(lotDetail: AuctionLotItem) {
    if (lotDetail != null && lotDetail.idOrganization != null && lotDetail.idOrganization != '') {
      let outputLink = '';
      if (this.isCoripet()) {
        outputLink = '/' + ConstantsModule.CORIPET_AZIENDE + "/" + lotDetail.idOrganization;
      } else if (this.isAdmin()) {
        outputLink = '/' + ConstantsModule.ADMIN_AZIENDE_DETTAGLIO_NEW + lotDetail.idOrganization;
      } else {
        outputLink = '';
      }
      return outputLink;
    } else {
      return '';
    }
  }

  getRouterLinkAuctionBox(codiceAsta: string) {
    return '/' + ConstantsModule.USER_ASTE_IN_CORSO_BOX + codiceAsta;
  }

  getRouterLinkAuctionList(codiceAsta: string) {
    return '/' + ConstantsModule.USER_ASTE_IN_CORSO + codiceAsta;
  }

  getDataFineAstaEffettiva(data: string) {
    let output = '';
    if (data != undefined) {
      let dataSplitted = data.split(" ");
      output = dataSplitted[0];
    }
    return output;
  }

  getOraFineAstaEffettiva(data: string) {
    let output = '';
    if (data != undefined) {
      let dataSplitted = data.split(" ");
      output = dataSplitted[1];
    }
    return output;
  }

  getMomentDate(data: string, serverTime) {
    let dateSplitted = data.split(" ")[0].split("/");
    let timeNotSplitted = data.split(" ")[1];
    let nowDate: moment.Moment = moment(serverTime, 'DD/MM/YYYY HH:mm:ss');
    let dateTimeEnd = dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0] + 'T' + timeNotSplitted + ":00";
    let momentDate: moment.Moment = moment(dateTimeEnd);
    return momentDate;
  }

  getNowMomentDate(serverTime) {
    let momentDate: moment.Moment = moment(serverTime, 'DD/MM/YYYY HH:mm:ss');
    return momentDate;
  }

  isInExtraTime(timer: AuctionBoxTimer) {
    let output = false;
    //console.log(timer);
    if (timer) {
      //console.log('timer.dataOraFineAsta: ' + timer.dataOraFineAsta + ' timer.dataOraFineAstaEffettiva: ' + timer.dataOraFineAstaEffettiva);
      if (timer.dataOraFineAsta != timer.dataOraFineAstaEffettiva) {
        output = true;
      }
    }
    return output;
  }

  getItalianDate(data: string) {
    console.log('getItalianDate() input params : ' + data);
    let output = undefined;
    if (data) {
      moment.locale('IT');
      //////this.localUtil.printLog('onRowInsert() if : ' + this.modelEdit.dataOraInizioAnalisi);
      let datas = new Date(data);
      console.log('getItalianDate() dt : ' + datas);
      let month = datas.getMonth() + 1 + ""; //months from 1-12
      console.log('getItalianDate() data : ' + data);
      if (month.toString().length < 2) month = "0" + month;
      console.log('getItalianDate() month : ' + month);
      let day = datas.getDate() + "";
      if (day.toString().length < 2) day = "0" + day;
      console.log('getItalianDate() day : ' + day);
      let year = datas.getFullYear();
      output = day + "/" + month + "/" + year;
      console.log('getItalianDate() output : ' + output);
    }
    console.log(output);
    return output.toLocaleString();
  }

  getMMDDAAAADate(data: string) {
    console.log('getMMDDAAAADate() input params : ' + data);
    let output = undefined;
    if (data) {
      let datas = data.split("/");
      let day = datas[0] + "";
      if (day.toString().length < 2) day = "0" + day;
      console.log('getMMDDAAAADate() day : ' + day);
      let month = (datas[1]) + ""; //months from 1-12
      console.log('getMMDDAAAADate() data : ' + data);
      if (month.toString().length < 2) month = "0" + month;
      console.log('getMMDDAAAADate() month : ' + month);
      let year = datas[2];
      output = month + "/" + day + "/" + year;
      console.log('getMMDDAAAADate() output : ' + output);
    }
    console.log(output);
    return new Date(output);
  }

  checkAndReplaceTranslation(translate: TranslateService, message: string) {
    let output: string = message != undefined ? message : '';
    try {
      let splitted: string[] = output.split(' ');
      if (splitted != undefined && splitted.length > 0) {
        let toBeTranslated = splitted[0];
        let translatedVal = translate.instant(toBeTranslated);
        if (toBeTranslated === translatedVal) {
          //niente da tradurre            
        } else {
          output = '';
          for (let splitItem of splitted) {
            output += translate.instant(splitItem) + ' ';
          }
        }
      }
    } catch (err) {
      console.error('Error on translation: ' + err);
    }
    return output;
  }

  getPrefixUrl() {
    let output = '';
    if (this.localUtil.isUser()) {
      output = ConstantsModule.MENU_USER_PREFIX;
    } else if (this.localUtil.isCoripet()) {
      output = ConstantsModule.MENU_CORIPET_PREFIX;
    } else if (this.localUtil.isUser()) {
      output = ConstantsModule.MENU_ADMIN_PREFIX;
    }
    return output;
  }

  getTimeOnly(dataOraFineAsta: string) {
    let output = dataOraFineAsta;
    try {
      if (dataOraFineAsta) {
        output = dataOraFineAsta.split(' ')[1];
      }
    } catch (err) {
      console.error(err);
    }
    return output;
  }

  isBoxView() {
    return this.localUtil.isBoxView();
  }

  isListView() {
    return this.localUtil.isListView();
  }

  getClosedStatus(dataOraFineAstaEffettiva, serverTime) {
    let output: boolean = false;
    let nowDate: moment.Moment = moment(serverTime, 'DD/MM/YYYY HH:mm:ss');
    let dateEndCountdown = this.getDataFineAstaEffettiva(dataOraFineAstaEffettiva);
    let dateSplitted = dateEndCountdown.split("/");
    let hourEndCountdown = this.getOraFineAstaEffettiva(dataOraFineAstaEffettiva);
    let dateTimeEndCountdown = dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0] + 'T' + hourEndCountdown + ":00";
    let dateTimeEndCountdownMoment: moment.Moment = moment(dateTimeEndCountdown);
    //console.log('lot.dateEnd: ' + dateTimeEndCountdownMoment + ' serverTime: ' + nowDate);
    if (dateTimeEndCountdownMoment > nowDate) {
      output = false;
    } else {
      output = true;
    }
    return output;
  }

}
