<div id="container" class="loginPage mt-5 mb-5">
    <main id="home">
        <h1>404</h1>
        <div class="info mb-4">
            <h2 translate>common.pagina-non-trovata</h2>
            <p class="mt-2" translate>common.pagina-non-trovata-desc</p>
            <a href="/">Home</a>
        </div>
    </main>
</div>
