import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class WindowFocusService {
    private focusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private ngZone: NgZone) {
        this.init();
    }

    private init() {
        window.addEventListener('focus', () => {
            this.ngZone.run(() => {
                this.focusChange.next(true);
            });
        });

        window.addEventListener('blur', () => {
            this.ngZone.run(() => {
                this.focusChange.next(false);
            });
        });
    }

    get focusChange$() {
        return this.focusChange.asObservable();
    }
}
