import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionItem, AuctionLotGeoArea, AuctionLotItem, AuctionLotStatus, AuctionLotTipoProdotto, CentroSelezioneItem, NazioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-asta-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})
export class AdminAstaDettaglioComponent extends APIService implements OnInit, OnDestroy {

  constructor(
    localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private translate: TranslateService) {
    super(localUtil, location, router);
    console.log('AdminAstaDettaglioComponent()');
    this.lot_statuses = this.localUtil.getStatoLottoList();
    this.geoArea_list = this.localUtil.getGeoAreaList();
    this.tipoProdotto_list = this.localUtil.getTipoProdottoList();
  }

  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];
  lot_statuses: AuctionLotStatus[] = [];
  geoArea_list: AuctionLotGeoArea[] = [];
  tipoProdotto_list: AuctionLotTipoProdotto[] = [];
  private subscriptions: Subscription[] = [];

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  ngOnInit(): void {
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' + this.codiceAsta);
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, false);
  }

  loadNazioni() {
    const service = this.commonService.getNationsList().subscribe(
      data => {
        this.nation_list = data.list;
    });
    this.subscriptions.push(service);
  }

  loadCentriSelezione() {
    const service = this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
    });
    this.subscriptions.push(service);
  }

  getLotByCode(codiceLotto: string) {
    let output = undefined;
    if (this.lot_list != undefined) {
      this.lot_list.forEach((lotElem: any) => {
        if (lotElem.codiceLotto == codiceLotto) {
          output = lotElem;
        }
      });
    }
    return output;
  }

  gotoBack() {
    this.location.back();
  }

  loadAuctionDetails(textFilter, onlywon) {

    let qparam = '';

    const service = this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
      (data: any) => {
        this.auction = data.auctionDetail;
        
        if (data.lotList != undefined) {
          this.auction.numLotti = data.lotList.length;
          

          console.log(this.auction.statoAsta);
          this.manageAllOtherAuction(data.lotList);
          console.log('lot_list: ' + this.lot_list.length);
        }
    });
    this.subscriptions.push(service);

  }

  manageAllOtherAuction(lotList) {
    this.lot_list = [];
    console.log('manageAllOtherAuction()');
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });

      if (this.auction.statoAsta == 'Disponibile') {
        console.log("Disponibile");
        lotList.forEach(data => {
          if ((this.inPubblicazione && data.statoLotto == 'In Pubblicazione') ||
            (this.pubblicato && data.statoLotto == 'Pubblicato') ||
            (this.rifiutato && data.statoLotto == 'Rifiutato')) {
            this.lot_list.push(data);
          }
        });
      } else if (this.auction.statoAsta == 'Chiusa') {
        console.log("Chiusa");
        lotList.forEach(data => {
          this.lot_list.push(data);
          /*if (this.pagati && !this.spediti && data.pagato == 1 && data.spedito == 0) {
            this.lot_list.push(data);
          } else if (this.spediti && !this.pagati && data.spedito == 1 && data.pagato == 0) {
            this.lot_list.push(data);
          } else if (this.pagati && this.spediti && data.pagato == 1 && data.spedito == 1) {
            this.lot_list.push(data);
          } else if (!this.pagati && !this.spediti && data.pagato == 0 && data.spedito == 0) {
            this.lot_list.push(data);
          }*/
        });
      }
    }

  }

  pagati: boolean = true;
  spediti: boolean = true;
  inPubblicazione: boolean = true;
  pubblicato: boolean = true;
  rifiutato: boolean = true;
  filterCheckBox() {
    this.loadAuctionDetails(undefined, false);
  }

  gotoListaAsteChiuse() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_CHIUSE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
    return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note: string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
      lotElem.centroSelezione.forEach(csLottoElem => {
        let item = new CentroSelezioneItem();
        item.quantitaTon = lotElem.quantitaTon;
        item.indirizzo = '';
        item.pesoMedioCarico = '';

        let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
        if (cs != undefined) {

          let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
          console.log('nazione: ' + JSON.stringify(nazione));
          let nazioneDescr = '';
          if (nazione != null && nazione != undefined && nazione != '') {
            nazioneDescr = nazione.descrizione;
            if (nazione.codice == 'IT') {
              nazioneDescr = 'Italia';
            }
            item.nazioneDescr = nazioneDescr;
          }
        }
        item.CER = cs.CER;
        item.cap = cs.cap;
        item.citta = cs.citta;
        item.provincia = cs.provincia;
        item.pesoMedioCarico = cs.pesoMedioCarico;
        item.nome = cs.nome;
        item.indirizzo = cs.indirizzo;
        csListPopup.push(item);
      });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  annullaAsta() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("CANCEL_AUCTION_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.cancelAuction(this.auction.codiceAsta).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("SUCCESS_CANCEL_AUCTION_MESSAGE")), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  approvaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("AUCTION_APPROVE_ALL_LOTS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        console.log('TODO create lotList');
        let lotList: string[] = [];
        this.lot_list.forEach(lot => {
          if (lot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
            lotList.push(lot.codiceLotto);
          }
        });
        const service = this.auctionService.approveLotList(this.auction.codiceAsta, lotList).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  annullaApprovaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("AUCTION_UNDO_APPROVE_ALL_LOTS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.undoApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  rigettaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("AUCTION_REJECT_ALL_LOTS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.rejectLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
