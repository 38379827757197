
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityItem, AuctionItem, OrganizationItem, UserItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coripet-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class CoripetDashboardComponent extends APIService implements OnInit, OnDestroy {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router) {
    super(localService, location, router);

  }

  auctions_list: AuctionItem[] = [];
  organizations_list: OrganizationItem[] = [];
  activities_list: ActivityItem[] = [];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    console.log('init()');
    this.setDashboard();
    this.openDettaglioOrganization = this.openDettaglioOrganization.bind(this);
  }

  setDashboard() {
    this.listOrganizations();
    this.listAuctionNotClosed();
    this.listActivities();
  }

  onRowUpdateOrganization(e) {
    console.log('onRowUpdateOrganization() ' + e.data.orgname);
    e.cancel = true;
    this.openDettaglioOrganizationByOrgname(e.data.orgname)
  }

  openDettaglioOrganizationByOrgname(orgname: string) {
    this.router.navigate([ConstantsModule.CORIPET_AZIENDE_DETTAGLIO + orgname, {}]);
  }

  listOrganizations() {

    const service = this.organizationService.getOrganizationList().subscribe(
      data => {

        data.list.forEach((org: OrganizationItem) => {
          //console.log('org.status: ' + org.status);
          if (org.status != undefined && org.status == ConstantsModule.AZIENDA_REGISTRATA) {
            try {

              //console.log(org.ragioneSociale + ' -> userId: ' + org.userId);
              const service = this.organizationService.getUserDetail(org.userId).subscribe(
                (res: any) => {
                  //console.log(res);
                  let user: UserItem = res.detail;
                  org.referente = user.nome + ' ' + user.cognome;
                }, this.handleError(true)
              );
              this.subscriptions.push(service);

              this.organizations_list.push(org);
            }
            catch (err) {
              let params: any[] = [org.userId];
              this.localUtil.showError("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("USER_NOT_FOUND", params));
            }
          }
        });
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  listAuctionNotClosed() {

    const service = this.auctionService.listaAste(undefined).subscribe(
      (data) => {
               
        data.list.forEach((auction: AuctionItem) => {
          if (auction.statoAsta != 'Chiusa') {
            auction.cssClass = auction.statoAsta.replace(' ', '');
            this.auctions_list.push(auction);
          }
        });
      },
      this.handleError(true));
      this.subscriptions.push(service);
  }

  listActivities() {

    const service = this.organizationService.getActivities().subscribe(
      (data: any) => {
        let conta = 0;
        data.list.forEach(item => {
          conta++;
          if (item.operation == 'INSERT') {
            item.operazione = "aggiunto";
            item.desinenza = "alla";
          } else if (item.operation == 'DELETE') {
            item.operazione = "rimosso";
            item.desinenza = "dalla";
          } else if (item.operation == 'UPDATE') {
            item.operazione = "aggiornato";
            item.desinenza = "nella";
          }

          if (conta < 10) {
            this.activities_list.push(item);
          }
        });

      }, this.handleError(true));
      this.subscriptions.push(service);
  }

  openDettaglioAuction(e) {
    console.log(e.data);
    //console.log('event.target: ' + e.event.target.className);
    let className = e.event.target.className;
    if (e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.CORIPET_ASTE_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }

  openDettaglioOrganization(e) {
    //console.log(e);
    let id = e.data.id;
    if (id == undefined && e.row.data != undefined) {
      id = e.row.data.id;
    }
    this.router.navigate([ConstantsModule.CORIPET_AZIENDE + "/" + id, {}]);
  }

  formatNumber(num: number) {
    return this.localUtil.formatNumber2(num);
  }

  onCellPrepared(e) {
    if (e.rowType === 'header') {
      //console.log(e.column.cssClass);  
      if (e.cellElement.classList != undefined) {
        e.cellElement.classList.add(e.column.cssClass);
      }
    }
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      info.rowElement.addClass('auction');
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      })
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}