<div id="aziende-list">
    <dx-data-grid #datagridAziende 
        id="gridContainer" 
        [dataSource]="organizations_list" 
        keyExpr="id"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true" 
        [showBorders]="true" 
        [columnHidingEnabled]="false" 
        [columnAutoWidth]="true"
        (onContentReady)="onContentReady($event)" 
        (onRowExpanding)="onRowExpanding($event)" 
        (onEditingStart)="onEditingStart($event)"
        (onRowUpdating)="onRowUpdate($event)" 
        (onEditCanceled)="onEditCanceled($event)"
        (onRowClick)="onRowClick($event)"
        (onExporting)="onExporting($event)">

        <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
          <dxo-search
          [enabled]="true">
          </dxo-search>
        </dxo-column-chooser>
        <dxo-export [enabled]="true" 
                    [allowExportSelectedData]="false" 
                    fileName="datagrid_list"
                    [formats]="['xlsx', 'csv', 'pdf']"
        ></dxo-export>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="default"></dxo-scrolling>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false"
            placeholder="{{'search'|translate}}">
        </dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        
        <dxi-column dataField="id" [allowEditing]="false" caption="{{ 'aziende.id' | translate}}" alignment="left"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="userId" caption="{{ 'aziende.userId' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="orgname" caption="{{ 'aziende.orgName' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="ragioneSociale" caption="{{ 'aziende.ragione-sociale' | translate}}" dataType="string"
            [visible]="true">
        </dxi-column>
        <dxi-column dataField="dataCreazione" caption="{{ 'aziende.data-registre' | translate}}"
            dataType="date" format="d/M/yyyy" [visible]="true">
        </dxi-column>
        <dxi-column dataField="referente" caption="{{ 'aziende.referente' | translate}}" dataType="string"
            [visible]="true" cellTemplate="referentTemplate">
        </dxi-column>
        <dxi-column dataField="status" caption="{{ 'aziende.stato' | translate}}" dataType="string" [visible]="true"
             cellTemplate="statusTemplate">
        </dxi-column>
        <dxi-column dataField="totaleFidejussione" caption="{{ 'aziende.fondi' | translate}}" dataType="string"
            [visible]="true" cellTemplate="fidTemplate"
            [format]="{ style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 }">
            <dxo-header-filter [dataSource]="fondiFilter"></dxo-header-filter>
        </dxi-column>
        <dxi-column dataField="capacitaProduttiva" caption="{{ 'aziende.capacità' | translate}}" dataType="string"
            [visible]="true" cellTemplate="capacityTemplate">
            <dxo-header-filter [dataSource]="capacitaFilter"></dxo-header-filter>
        </dxi-column>


        <dxi-column dataField="userOrganization.nome" caption="{{ 'aziende.nome' | translate}}" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="userOrganization.cognome" caption="{{ 'aziende.cognome' | translate}}" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="userOrganization.email" caption="{{ 'aziende.email' | translate}}" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="userOrganization.telefono" caption="{{ 'aziende.telefono' | translate}}"
            dataType="string" [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="userOrganization.mobile" caption="{{ 'aziende.mobile' | translate}}" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="userOrganization.lingua" caption="{{ 'aziende.lingua' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="userOrganization.role" caption="{{ 'aziende.ruolo' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="tab" caption="{{ 'aziende.tab' | translate}}" [allowEditing]="false" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="partitaIva" caption="{{ 'aziende.partita-Iva' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="codiceFiscale" caption="{{ 'aziende.codice-Fiscale' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="nazionalita" caption="{{ 'aziende.nazionalità' | translate}}" dataType="string"
            [visible]="false">
            <dxo-lookup [dataSource]="nation_list" valueExpr="codice" displayExpr="descrizione">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="provincia" caption="{{ 'aziende.provincia' | translate}}" dataType="string"
            [visible]="false">
            <dxo-lookup [dataSource]="province_list" valueExpr="descrizione" displayExpr="descrizione">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="citta" caption="{{ 'aziende.città' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="indirizzo" caption="{{ 'aziende.indirizzo' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="cap" caption="{{ 'aziende.cap' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="telefono" caption="{{ 'aziende.telefono' | translate}}" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="fax" caption="{{ 'aziende.fax' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="pec" caption="{{ 'aziende.pec' | translate}}" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="importoFidejussione" caption="{{ 'aziende.importo-fideiussione-in-euro' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="dataScadenzaFidejussione" caption="{{ 'aziende.data-di-scadenza' | translate}}"
            dataType="date" [visible]="false">
        </dxi-column>


        <dxi-column dataField="visuraCamerale" caption="{{ 'aziende.visura-camerale' | translate}}" [visible]="false"
            cellTemplate="docTemplate">
        </dxi-column>
        <dxi-column dataField="visuraCameraleStatus" caption="{{ 'aziende.visura-camerale-status' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="visuraCameraleCssClass" caption="{{ 'aziende.visura-camerale-css-class' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="fidejussioneBancaria" caption="{{ 'aziende.fideiussione-bancaria' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="fidejussioneBancariaStatus"
            caption="{{ 'aziende.fidejussione-bancariaStatus' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="fidejussioneBancariaCssClass"
            caption="{{ 'aziende.fidejussione-bancaria-css-class' | translate}}" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="dichiarazione231_2001" caption="{{ 'aziende.dichiarazione231-2001' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="dichiarazione231_2001Status"
            caption="{{ 'aziende.dichiarazione231_2001-status' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="dichiarazione231_2001CssClass"
            caption="{{ 'aziende.dichiarazione231_2001-css-class' | translate}}" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="rappresentanteLegale" caption="{{ 'aziende.rappresentante-legale' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="rappresentanteLegaleStatus"
            caption="{{ 'aziende.rappresentante-legale-status' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="rappresentanteLegaleCssClass"
            caption="{{ 'aziende.rappresentante-legale-css-class' | translate}}" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="rappresentanteLegaleValidated"
            caption="{{ 'aziende.rappresentante-legale-validated' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="visuraCameraleValidated" caption="{{ 'aziende.visura-camerale-validated' | translate}}"
            dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="fidejussioneBancariaValidated"
            caption="{{ 'aziende.fide-jussione-bancaria-validated' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="dichiarazione231_2001Validated"
            caption="{{ 'aziende.dichiarazione231_2001-validated' | translate}}" dataType="string" [visible]="false">
        </dxi-column>
        <dxi-column dataField="codiceAzienda"
            caption="{{ 'aziende.codiceAzienda' | translate}}" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column type="buttons" [visible]="false">
            <dxi-button name="edit"></dxi-button>
        </dxi-column>

        <dxo-toolbar>
            <dxi-item location="after" name="searchPanel"></dxi-item>
            <dxi-item location="after" name="exportButton"></dxi-item>
            <dxi-item location="after" name="columnChooserButton"></dxi-item>
            <dxi-item location="after" name="addRowButton"></dxi-item>
        </dxo-toolbar>


        <div *dxTemplate="let docTemplate of 'docTemplate'">
            <a>{{docTemplate.data.visuraCamerale}}</a>
        </div>

        <div *dxTemplate="let referentTemplate of 'referentTemplate'">
            <a>{{referentTemplate.data.referente}}</a>
        </div>

        <div *dxTemplate="let fidTemplate of 'fidTemplate'">
            <a>{{formatNumber(fidTemplate.data.totaleFidejussione)}} &euro;</a>
        </div>

        <div *dxTemplate="let capacityTemplate of 'capacityTemplate'">
            <a>{{formatNumber(capacityTemplate.data.capacitaProduttiva)}} ton</a>
        </div>

        <div *dxTemplate="let statusTemplate of 'statusTemplate'">
            <span class="status" class="{{statusTemplate.data.statusClass}}">
                {{statusTemplate.data.status}}
            </span>
        </div>

        <dxo-master-detail [enabled]="false" template="detail"></dxo-master-detail>

        <div *dxTemplate="let detail of 'detail'">

            <dx-form [(formData)]="detail.data">

                <dxi-item itemType="tabbed">
                    <dxi-tab title="Informazioni azienda" translate>
                        <dxi-item itemType="group" caption="Informazioni azienda" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="ragioneSociale" [colSpan]="20" [label]="{text: 'aziende.ragione-sociale' | translate}"></dxi-item>
                                <dxi-item dataField="codiceAzienda" [colSpan]="4" [label]="{text: 'aziende.codiceAzienda' | translate}"></dxi-item>                                
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="partitaIva" [label]="{text: 'Partita IVA'}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="codiceFiscale" [label]="{text: 'Codice fiscale'}"
                                    [colSpan]="10"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="nazionalita" [label]="{text: 'Nazionalità'}"
                                    [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="provincia" [label]="{text: 'Provincia'}" [colSpan]="10"></dxi-item>
                                <dxi-item dataField="citta" [label]="{text: 'Città'}" [colSpan]="10"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="indirizzo" [label]="{text: 'Indirizzo'}" [colSpan]="17"></dxi-item>
                                <dxi-item dataField="cap" [label]="{text: 'CAP'}" [colSpan]="3"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="pec" [label]="{text: 'PEC'}" [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="telefono" [label]="{text: 'Telefono fisso'}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="fax" [label]="{text: 'Fax'}" [colSpan]="10"></dxi-item>
                            </dxi-item>

                        </dxi-item>

                    </dxi-tab>

                    <dxi-tab title="Dati personali referente">
                        <dxi-item itemType="group" caption="Dati personali referente" [colSpan]="20"
                            cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.nome" [label]="{text: 'Nome'}"></dxi-item>
                                <dxi-item dataField="userOrganization.cognome" [label]="{text: 'Cognome'}"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.email" [label]="{text: 'Email'}"
                                    [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.telefono" [label]="{text: 'Telefono fisso'}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="userOrganization.mobile" [label]="{text: 'Telefono mobile'}"
                                    [colSpan]="10"></dxi-item>
                            </dxi-item>

                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="{{'aziende.Documenti azienda' | translate}}">
                        <dxi-item itemType="group" caption="{{'aziende.Documenti azienda' | translate}}" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                                <dxi-item dataField="detail.data.visuraCamerale" caption="{{'azienda.visura-camerale' | translate}}"
                                    [colSpan]="1" itemTemplate="templateDoc">
                                    <div *dxTemplate="let templateDoc of 'templateDoc'">
                                        <span class="status-org-doc-download"
                                            *ngIf="detail.data.visuraCamerale != undefined">
                                            <button class="btn btn-primary"
                                                (click)="downloadFile(detail.data.visuraCamerale, 'visura-camerale.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <br />
                                            <span
                                                class="status {{getDocumentStatusClass(detail.data.visuraCamerale, detail.data.visuraCameraleValidated)}}" tranlate>aziende.{{getDocumentStatus(detail.data.visuraCamerale,
                                                detail.data.visuraCameraleValidated)}}</span>
                                        </span>
                                        <span class="status-org-doc-not-present"
                                            *ngIf="detail.data.visuraCamerale == undefined">
                                            <span translate>aziende.nessun-documento-caricato</span>
                                        </span>
                                    </div>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="rappresentanteLegale" [label]="{text: 'Rappresentante legale'}"
                                        [colSpan]="1" itemTemplate="templateDoc">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.data.rappresentanteLegale != undefined">
                                                <button class="btn btn-primary"
                                                    (click)="downloadFile(detail.data.rappresentanteLegale, 'rappresentante-legale.pdf')">
                                                    <i class="fas fa-download "></i>Download
                                                </button>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.data.rappresentanteLegale, detail.data.rappresentanteLegaleValidated)}}">{{getDocumentStatus(detail.data.rappresentanteLegale,
                                                    detail.data.rappresentanteLegaleValidated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.data.rappresentanteLegale == undefined">
                                                <span translate>aziende.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="fidejussioneBancaria" [label]="{text: 'Fideiussione bancaria'}"
                                        [colSpan]="1" itemTemplate="templateFideiussione">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.data.fidejussioneBancaria != undefined">
                                                <button class="btn btn-primary"
                                                    (click)="downloadFile(detail.data.fidejussioneBancaria, 'fideiussione-bancaria.pdf')">
                                                    <i class="fas fa-download "></i>Download
                                                </button>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.data.fidejussioneBancaria, detail.data.fidejussioneBancariaValidated)}}">{{getDocumentStatus(detail.data.fidejussioneBancaria,
                                                    detail.data.fidejussioneBancariaValidated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.data.fidejussioneBancaria == undefined">
                                                <span translate>aziende.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="dichiarazione231_2001"
                                        [label]="{text: 'Dichiarazione231-2001'}" [colSpan]="1"
                                        itemTemplate="templateDichiarazione231">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.data.dichiarazione231_2001 != undefined">
                                                <button class="btn btn-primary"
                                                    (click)="downloadFile(detail.data.dichiarazione231_2001, 'dichiarazione-231-2001.pdf')">
                                                    <i class="fas fa-download "></i>Download
                                                </button>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.data.dichiarazione231_2001, detail.data.dichiarazione231_2001Validated)}}">{{getDocumentStatus(detail.data.dichiarazione231_2001,
                                                    detail.data.dichiarazione231_2001Validated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.data.dichiarazione231_2001 == undefined">
                                                <span translate>aziende.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="Impianti">
                        <dxi-item itemType="group" caption="Impianti" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item dataField="" [label]="{text: ''}" [colSpan]="1" itemTemplate="templateImpianto">
                                <div *dxTemplate="let templateImpianto of 'templateImpianto'" class="plants-list">

                                    <app-elenco-impianti [plants]="detail.data.plants"></app-elenco-impianti>

                                </div>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="Operatori">
                        <dxi-item itemType="group" caption="Operatori" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item dataField="" [label]="{text: ''}" [colSpan]="1" itemTemplate="templateOperatore">
                                <div *dxTemplate="let templateOperatore of 'templateOperatore'" class="operators-list">

                                    <app-elenco-operatori-full
                                        [operators]="detail.data.operators"></app-elenco-operatori-full>

                                </div>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="Stato">
                        <dxi-item itemType="group" caption="Stato azienda" [colSpan]="20" cssClass="top20 detail-form">
                            <dxi-item dataField="status" cssClass="detail-form-section" [label]="{text: ''}"
                                itemTemplate="templateStatusOrg">

                                <div *dxTemplate="let templateStatusOrg of 'templateStatusOrg'">
                                    <span class="{{detail.data.statusClass}} status">
                                        {{detail.data.status}}
                                    </span>
                                </div>

                            </dxi-item>
                        </dxi-item>

                    </dxi-tab>

                </dxi-item>
            </dx-form>

        </div>

        <dxo-editing [allowUpdating]="isCoripet()" mode="form">

            <dxo-form [colCount]="20">

                <dxi-item itemType="tabbed" [tabPanelOptions]="{onTitleClick: tabChanged}" [colSpan]="20">
                    <!--{ [tabPanelOptions]=-->

                    <dxi-tab title="{{'operatore-azienda.Informazioni azienda' | translate}}" [colCount]="20">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.Informazioni azienda' | translate}}" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="ragioneSociale" [colSpan]="15" [label]="{text: 'operatore-azienda.ragione-sociale' | translate}"></dxi-item>
                                <dxi-item dataField="codiceAzienda" [colSpan]="5" [label]="{text: 'aziende.codiceAzienda' | translate}"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="partitaIva" [label]="{text: 'operatore-azienda.partita-iva' | translate}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="codiceFiscale" [label]="{text: 'operatore-azienda.codice-fiscale' | translate}"
                                    [colSpan]="10"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="nazionalita" [label]="{text: 'operatore-azienda.nazionalità' | translate}"
                                    [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="provincia" [label]="{text: 'operatore-azienda.provincia' | translate}" [colSpan]="10"></dxi-item>
                                <dxi-item dataField="citta" [label]="{text: 'aziende.città' | translate}" [colSpan]="10"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="indirizzo" [label]="{text: 'operatore-azienda.indirizzo' | translate}" [colSpan]="17"></dxi-item>
                                <dxi-item dataField="cap" [label]="{text: 'aziende.cap' | translate}" [colSpan]="3"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="pec" [label]="{text: 'operatore-azienda.pec' | translate}" [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="telefono" [label]="{text: 'operatore-azienda.telefono-fisso' | translate}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="fax" [label]="{text: 'operatore-azienda.fax' | translate}" [colSpan]="10"></dxi-item>
                            </dxi-item>

                        </dxi-item>

                    </dxi-tab>

                    <dxi-tab title="{{'operatore-azienda.Dati personali referente' | translate}}">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.Dati personali referente' | translate}}" cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.nome" [label]="{text: 'operatore-azienda.nome' | translate}"></dxi-item>
                                <dxi-item dataField="userOrganization.cognome" [label]="{text: 'operatore-azienda.cognome' | translate}"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.email" [label]="{text: 'Email'}"
                                    [colSpan]="20"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                                <dxi-item dataField="userOrganization.telefono" [label]="{text: 'operatore-azienda.telefono-fisso' | translate}"
                                    [colSpan]="10"></dxi-item>
                                <dxi-item dataField="userOrganization.mobile" [label]="{text: 'operatore-azienda.telefono-mobile' | translate}"
                                    [colSpan]="10"></dxi-item>
                            </dxi-item>

                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="{{'operatore-azienda.Documenti azienda' | translate}}">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.Documenti azienda' | translate}}" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                                <dxi-item dataField="detail.visuraCamerale" caption="{{'aziende.visura-camerale' | translate}}"
                                    [colSpan]="1" itemTemplate="templateDoc">
                                    <div *dxTemplate="let templateDoc of 'templateDoc'">
                                        <span class="status-org-doc-download"
                                            *ngIf="detail.visuraCamerale != undefined">
                                            <div class="grid">
                                                <button class="btn btn-primary w100"
                                                    (click)="downloadFile(detail.visuraCamerale, 'visura-camerale.pdf')">
                                                    <i class="fas fa-download "></i>Download
                                                </button>
                                                <button class="btn btn-primary w100 top10"
                                                    (click)="caricaDocumento(ConstantsModule.DOC_VISURA)">
                                                    <i class="fas fa-upload "></i>{{'operatore-azienda.Carica documento' | translate}}
                                                </button>
                                                <button class="btn success btn-approve-document w100 top10"
                                                    (click)="approveDocument(ConstantsModule.DOC_VISURA)"><i
                                                        class="fal fa-check"></i>
                                                    {{'operatore-azienda.Approva' | translate}}
                                                </button>
                                                <button class="btn error btn-reject-document w100 top10"
                                                    (click)="rejectDocument(ConstantsModule.DOC_VISURA)"><i
                                                        class="fal fa-times"></i>
                                                    {{'operatore-azienda.Rifiuta' | translate}}
                                                </button>
                                            </div>
                                            <br />
                                            <span
                                                class="status {{getDocumentStatusClass(detail.visuraCamerale, detail.visuraCameraleValidated)}}" translate>aziende.{{getDocumentStatus(detail.visuraCamerale,
                                                detail.visuraCameraleValidated)}}</span>
                                        </span>
                                        <span class="status-org-doc-not-present"
                                            *ngIf="detail.visuraCamerale == undefined">
                                            <span translate>operatore-azienda.nessun-documento-caricato</span>
                                        </span>
                                    </div>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="rappresentanteLegale" caption="{{'operatore-azienda.Rappresentante legale' | translate}}"
                                        [colSpan]="1" itemTemplate="templateDoc">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.rappresentanteLegale != undefined">
                                                <div class="grid">
                                                    <button class="btn btn-primary w100"
                                                        (click)="downloadFile(detail.rappresentanteLegale, 'rappresentante-legale.pdf')">
                                                        <i class="fas fa-download "></i>Download
                                                    </button>
                                                    <button class="btn btn-primary w100 top10"
                                                        (click)="caricaDocumento(ConstantsModule.DOC_LEGALE)">
                                                        <i class="fas fa-upload "></i>{{'operatore-azienda.Carica documento' | translate}}
                                                    </button>
                                                    <button class="btn success btn-approve-document w100 top10"
                                                        (click)="approveDocument(ConstantsModule.DOC_LEGALE)"><i
                                                            class="fal fa-check"></i>
                                                        {{'operatore-azienda.Approva' | translate}}
                                                    </button>
                                                    <button class="btn error btn-reject-document w100 top10"
                                                        (click)="rejectDocument(ConstantsModule.DOC_LEGALE)"><i
                                                            class="fal fa-times"></i>
                                                        {{'operatore-azienda.Rifiuta' | translate}}
                                                    </button>
                                                </div>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.rappresentanteLegale, detail.rappresentanteLegaleValidated)}}" translate>aziende.{{getDocumentStatus(detail.rappresentanteLegale,
                                                    detail.rappresentanteLegaleValidated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.rappresentanteLegale == undefined">
                                                <span translate>operatore-azienda.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="fidejussioneBancaria" caption="{{'fideiussione.fideiussione-bancaria' | translate}}"
                                        [colSpan]="1" itemTemplate="templateFideiussione">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.fidejussioneBancaria != undefined">
                                                <div class="grid">
                                                    <button class="btn btn-primary w100"
                                                        (click)="downloadFile(detail.fidejussioneBancaria, 'fideiussione-bancaria.pdf')">
                                                        <i class="fas fa-download "></i>Download
                                                    </button>
                                                    <button class="btn btn-primary w100 top10"
                                                        (click)="caricaDocumento(ConstantsModule.DOC_FIDEIUSSIONE)">
                                                        <i class="fas fa-upload "></i>{{'operatore-azienda.Carica documento' | translate}}
                                                    </button>
                                                    <button class="btn success btn-approve-document w100 top10"
                                                        (click)="approveDocument(ConstantsModule.DOC_FIDEIUSSIONE)"><i
                                                            class="fal fa-check"></i>
                                                        {{'operatore-azienda.Approva' | translate}}
                                                    </button>
                                                    <button class="btn error btn-reject-document w100 top10"
                                                        (click)="rejectDocument(ConstantsModule.DOC_FIDEIUSSIONE)"><i
                                                            class="fal fa-times"></i>
                                                        {{'operatore-azienda.Rifiuta' | translate}}
                                                    </button>
                                                </div>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.fidejussioneBancaria, detail.fidejussioneBancariaValidated)}}" translate>aziende.{{getDocumentStatus(detail.fidejussioneBancaria,
                                                    detail.fidejussioneBancariaValidated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.fidejussioneBancaria == undefined">
                                                <span translate>operatore-azienda.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>

                                <dxi-item itemType="group" cssClass="">
                                    <dxi-item dataField="dichiarazione231_2001"
                                        [label]="{text: 'Dichiarazione231-2001'}" [colSpan]="1"
                                        itemTemplate="templateDichiarazione231">
                                        <div *dxTemplate="let templateDoc of 'templateDoc'">
                                            <span class="status-org-doc-download"
                                                *ngIf="detail.dichiarazione231_2001 != undefined">
                                                <div class="grid">
                                                    <button class="btn btn-primary w100"
                                                        (click)="downloadFile(detail.dichiarazione231_2001, 'dichiarazione-231-2001.pdf')">
                                                        <i class="fas fa-download "></i>Download
                                                    </button>
                                                    <button class="btn btn-primary w100 top10"
                                                        (click)="caricaDocumento(ConstantsModule.DOC_231)">
                                                        <i class="fas fa-upload "></i>{{'operatore-azienda.Carica documento' | translate}}
                                                    </button>
                                                    <button class="btn success btn-approve-document w100 top10"
                                                        (click)="approveDocument(ConstantsModule.DOC_231)"><i
                                                            class="fal fa-check"></i>
                                                            {{'operatore-azienda.Approva' | translate}}
                                                    </button>
                                                    <button class="btn error btn-reject-document w100 top10"
                                                        (click)="rejectDocument(ConstantsModule.DOC_231)"><i
                                                            class="fal fa-times"></i>
                                                        {{'operatore-azienda.Rifiuta' | translate}}
                                                    </button>
                                                </div>
                                                <br />
                                                <span
                                                    class="status {{getDocumentStatusClass(detail.dichiarazione231_2001, detail.dichiarazione231_2001Validated)}}" translate>aziende.{{getDocumentStatus(detail.dichiarazione231_2001,
                                                    detail.dichiarazione231_2001Validated)}}</span>
                                            </span>
                                            <span class="status-org-doc-not-present"
                                                *ngIf="detail.dichiarazione231_2001 == undefined">
                                                <span translate>operatore-azienda.nessun-documento-caricato</span>
                                            </span>
                                        </div>
                                    </dxi-item>
                                </dxi-item>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="{{'operatore-azienda.Impianti' | translate}}">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.Impianti' | translate}}" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item dataField="" [label]="{text: ''}" [colSpan]="1" itemTemplate="templateImpianto">
                                <div *dxTemplate="let templateImpianto of 'templateImpianto'" class="plants-list">

                                    <app-elenco-impianti [plants]="detail.plants" [orgname]="detail.orgname"
                                        (monitorEvent)="plantsCallback($event)"></app-elenco-impianti>

                                </div>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="{{'operatore-azienda.Operatori' | translate}}">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.Operatori' | translate}}" [colSpan]="20" [colCount]="1"
                            cssClass="top20 detail-form">

                            <dxi-item dataField="" [label]="{text: ''}" [colSpan]="1" itemTemplate="templateOperatore">
                                <div *dxTemplate="let templateOperatore of 'templateOperatore'" class="operators-list">

                                    <app-elenco-operatori-full
                                        [operators]="detail.operators"></app-elenco-operatori-full>

                                </div>
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>

                    <dxi-tab title="{{'operatore-azienda.Stato' | translate}}">
                        <dxi-item itemType="group" caption="{{'operatore-azienda.stato-azienda' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                            <dxi-item dataField="status" cssClass="detail-form-section" [label]="{text: ''}"
                                itemTemplate="templateStatusOrg">

                                <div *dxTemplate="let templateStatusOrg of 'templateStatusOrg'">
                                    <span class="{{detail.statusClass}} status">
                                        <span translate>operatore-azienda.{{detail.status}}</span>
                                    </span>
                                    <div class="actions operator-actions " [ngClass]="{'top20': isCoripet()}">
                                        <div>
                                            <button class="btn success btn-approve-document"
                                                (click)="approveOrganization()"><i class="fal fa-check"></i>
                                                {{'operatore-azienda.Approva azienda' | translate}}
                                            </button>
                                            <button class="btn error btn-reject-document"
                                                (click)="rejectOrganization()"><i class="fal fa-times"></i>
                                                {{'operatore-azienda.Rifiuta azienda' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </dxi-item>
                        </dxi-item>

                    </dxi-tab>

                </dxi-item>

            </dxo-form>

        </dxo-editing>

    </dx-data-grid>
</div>