import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { BoxArchivio, BoxItem, HeaderDetail } from 'src/app/models/dataModels';
import { MessagesService } from 'src/app/services/messages.service';
import { AuctionService } from 'src/app/services/auction.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends APIService implements AfterViewInit, OnDestroy {

  constructor(localUtil: LocalUtilService,
    location: Location,
    router: Router,
    public dashboardService: DashboardService,
    public auctionService: AuctionService,
    public messageService: MessagesService,
    public translateService: TranslateService) {
    super(localUtil, location, router);
  }

  @ViewChild('masonry', { static: true }) masonry: ElementRef;

  vatnumber: string;
  perc_progress: number = 0;
  status: string;
  checker: any;
  titolo_box: string;
  classe_titolo: string;
  percentuale_box: string;
  testo_box: string;
  testo_add_box: string;
  aziendaValidata: boolean = false;

  boxFideiussione: string;
  boxOrgInvalid: BoxItem = undefined;
  boxDocumenti: BoxItem[] = [];
  boxImpianti: BoxItem[] = [];
  boxArchivio: BoxArchivio[] = [];
  boxClassName: any;
  private subscriptions: Subscription[] = [];
  
  ngAfterViewInit() {
    this.setDashboard();
  }

  translate(key) {
    if (key) {
      return this.translateService.instant(key);
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  setDashboard() {
    this.initDashboardHeader();
    this.vatnumber = this.localUtil.authParams.organization_detail.orgname;
    this.checkStatusDoc('FIDEJUSSIONE', 'Fideiussione', false, '/azienda-anagrafica', '/azienda-fideiussione');
    this.checkStatusDoc('VISURA', 'Visura camerale', false, '/azienda-anagrafica', '/upload-visura');
    this.checkStatusDoc('LEGALE', 'Rappresentante legale', false, '/azienda-anagrafica', '/upload-legale');
    this.checkStatusDoc('DICHIARAZIONE231', 'dichiarazione 231-2001', false, '/azienda-anagrafica', '/upload-dichiarazione-231');
    this.archivioUltimaAsta();
    this.checkFramework();
    this.makeOrUpdatePercBox();

    setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 1000);
  }

  initDashboardHeader() {
    let headerDetail: HeaderDetail = new HeaderDetail();
    headerDetail.dashboardHeader = true;
    headerDetail.stickyHeader = false;
    headerDetail.capacityHeader = false;
    this.localUtil.setHeaderMonitor(headerDetail);
  }


  getRouteForItem(item) {
    const routeMap = {
      'Fideiussione': '/upload-fideiussione.html',
      'Visura camerale': '/upload-visura.html',
      'Rappresentante legale': '/upload-documento-rappresentante.html',
      'dichiarazione 231-2001': '/upload-dichiarazione-231.html',
    };
    return routeMap[item.documento] || '/dashboard.html';
  }


  archivioUltimaAsta() {

    const dashboardService = this.dashboardService.listaAste("Chiusa").subscribe(
      data => {

        setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);

        if (data.list.length > 0) {
          let item = data.list[0];
          const reportNewService = this.dashboardService.getAuctionReportNew(item.codiceAsta).subscribe(
            reportRes => {

              let boxItem: BoxArchivio = new BoxArchivio();
              boxItem.id = item.id;
              boxItem.codiceAsta = item.codiceAsta;
              boxItem.uuid = reportRes.uuid;
              boxItem.filename = 'report-asta-' + item.codiceAsta + '.pdf';
              this.boxArchivio.push(boxItem);

              setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);

            });
            this.subscriptions.push(reportNewService);
        }
      });
      this.subscriptions.push(dashboardService);
  }

  makeOrUpdatePercBox() {

    let boxItem: BoxItem = new BoxItem();
    this.titolo_box = "dashboardPage.profilo-azienda-completato-al";//"Profilo azienda completato al";
    boxItem.testo = "dashboardPage.per-poter-operare";//'Per poter operare devi caricare i documenti mancanti oppure attendere la validazione';
    if (this.perc_progress == 5) {
      console.log('set profilo-completo');
      boxItem.testo = 'dashboardPage.profilo-completo';//'Il tuo profilo è completo';
    }
    if (this.perc_progress > 0) {
      this.classe_titolo = "info";
    }
    boxItem.documento = 'progress';

    boxItem.classe = this.classe_titolo;
    boxItem.titolo = this.titolo_box;
    let calc_progr = 20 * this.perc_progress;
    boxItem.percentage = calc_progr + '%';
    boxItem.percentageNum = calc_progr;

    let found: boolean = false;
    this.boxDocumenti.forEach(box => {
      if (box.testo == 'dashboardPage.per-poter-operare') {
        if (box.percentageNum == 100) {
          this.boxClassName = 'profile-progress-blue';
        } else if (box.percentageNum < 100) {
          this.boxClassName = 'profile-error-red';
        }
      }
      if (box.documento == 'progress') {
        box.percentage = boxItem.percentage;
        box.percentageNum = boxItem.percentageNum;
        box.titolo = boxItem.titolo;
        box.classe = boxItem.classe;
        box.testo = boxItem.testo;
        found = true;
      }
    });
    if (!found) {
      this.boxDocumenti.push(boxItem);
    }

    setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);

  }

  checkStatusDoc(docName: string, description: string, isLast: boolean, pathOk: string, pathKo: string) {

    const checkDocsService = this.dashboardService.checkDocumentExists(docName, this.vatnumber).subscribe(
      data => {

        let boxItem: BoxItem = new BoxItem();
        this.status = data.status;
        this.checker = data;
        this.testo_box = "dashboardPage.alert-doc-mancante"; //"Per poter partecipare alle Aste devi caricare il documento di " + description;
        this.testo_add_box = description;

        if (this.checker.exists == false) {

          this.titolo_box = "dashboardPage.non-caricato"
          this.classe_titolo = "error";
          boxItem.path = pathKo;

        }

        if (this.checker.exists == true) {

          if (this.checker.validated == undefined) {

            this.titolo_box = "dashboardPage.in-revisione";
            this.classe_titolo = "pending";
            boxItem.path = pathOk;
          }

          if (this.checker.validated == false) {

            this.titolo_box = "dashboardPage.rifiutato";
            this.classe_titolo = "error";
            boxItem.path = pathKo;
          }

          if (this.checker.validated == true) {

            this.titolo_box = "dashboardPage.caricato";//"Caricato";
            this.classe_titolo = "success";
            this.testo_box = "";
            this.testo_add_box = "";
            boxItem.path = pathOk;

            this.perc_progress = this.perc_progress + 1;

          }

        }


        boxItem.classe = this.classe_titolo;
        boxItem.titolo = this.titolo_box;
        boxItem.testo = this.testo_box;
        boxItem.testo_add = this.testo_add_box;
        boxItem.documento = description;
        if (this.localUtil.isOrganizationReference()) {
          this.boxDocumenti.push(boxItem);
        }

        this.makeOrUpdatePercBox();
        setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);

      });
      this.subscriptions.push(checkDocsService);
  }

  checkFramework() {

    const checkPlantService = this.dashboardService.checkFrameworkExists(this.vatnumber).subscribe(
      data => {
        this.status = data.status;

        //if (this.status == "error") { return false; }
        this.checker = data;
        this.testo_box = "<p>Per poter partecipare alle Aste devi registrare almeno un impianto.</p>";

        if (this.checker.exists == false) {

          this.titolo_box = "dashboardPage.non-caricato";//"Non caricato"
          this.classe_titolo = "error";

        }

        if (this.checker.exists == true) {

          if (this.checker.validated == undefined || this.checker.validated == "") {

            this.titolo_box = "In revisione";
            this.classe_titolo = "pending";

          }

          if (this.checker.validated == 'N') {

            this.titolo_box = "Rifiutato";
            this.classe_titolo = "error";

          }

          if (this.checker.validated == 'Y') {

            this.titolo_box = "Caricato";
            this.classe_titolo = "success";
            this.testo_box = "";

            this.perc_progress = this.perc_progress + 1;

          }

          if (this.checker.organizationValidated == 'Y') {
            this.aziendaValidata = true;
          }
          this.boxAziendaNonValidata();


        }

        let boxItem: BoxItem = new BoxItem();
        boxItem.classe = this.classe_titolo;
        boxItem.titolo = this.titolo_box;
        boxItem.testo = this.testo_box;
        if (this.localUtil.isOrganizationReference()) {
          this.boxImpianti.push(boxItem);
        }
        this.makeOrUpdatePercBox();

        setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 500);

      });
      this.subscriptions.push(checkPlantService);
  }

  boxAziendaNonValidata() {
    if (!this.aziendaValidata) {
      let boxItem: BoxItem = new BoxItem();
      boxItem.classe = "bg-white-red";
      boxItem.titolo = this.messageService.getLabelFromCode("ORG_NOT_VALID_TITLE");
      boxItem.testo = this.messageService.getLabelFromCode("ORG_NOT_VALID_MESSAGE");
      this.boxOrgInvalid = boxItem;

    }

  }

  isEnabledToAuction() {
    return this.localUtil.isEnabledToAuction();
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
