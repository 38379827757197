import { Location } from '@angular/common';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuctionSetupPropItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-setup-properties',
  templateUrl: './setup-properties.component.html',
  styleUrls: ['./setup-properties.component.css']
})
export class SetupPropertiesComponent extends APIService {

  @ViewChild("dataGridProperties", { static: false }) dataGridProperties: DxDataGridComponent;
  @Input() properties: AuctionSetupPropItem[] = [];
  @Output() updated = new EventEmitter<boolean>();

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);
    //this.onSelectionChangedTab = this.onSelectionChangedTab.bind(this);
    //this.resizeTabbedForm = this.resizeTabbedForm.bind(this);
  }

  onRowClickProperties(e) {
    console.log(e);
    e.component.editRow(e.rowIndex);
  }

  onRowUpdateProperties(e) {
    console.log(e);
    this.updated.emit(true);

    /*let rows = this.dataGridSetup.instance.getVisibleRows();
    if (rows) {
      rows.forEach(row => {
        if (row.isEditing) {
          this.dataGridSetup.instance.cellValue(row.rowIndex, 'dataModifica', new Date()); 
        }
      });
    }

    this.dataGridSetup.instance.cellValue(e.rowIndex, "dataModifica", new Date()); */
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

}