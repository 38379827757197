import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { AreaGeograficaItem, AuctionLotCreateItem, AuctionLotItem, AuctionLotUpdateItem, CentroDiSelezioneItem, CentroDiSelezioneLottoItem, CentroSelezioneItem, MaterialTypeItem, NazioneItem, ProvinciaItem, SchedaTecnicaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { PopupComponent } from '../popup/popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-elenco-lotti',
  templateUrl: './elenco-lotti.component.html',
  styleUrls: ['./elenco-lotti.component.css']
})
export class ElencoLottiComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("dataGridLots", { static: false }) dataGridLots: DxDataGridComponent;
  @Input() lots: AuctionLotItem[] = [];
  @Input() codiceAsta: string;
  @Output() monitorEvent: EventEmitter<boolean> = new EventEmitter();
  modelEdit: AuctionLotItem;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  area_list: AreaGeograficaItem[] = [];
  material_list: MaterialTypeItem[] = [];
  scheda_list: SchedaTecnicaItem[] = [];
  cs_list: CentroDiSelezioneItem[] = [];
  activeTab: string;
  defaultVisible: boolean = false;
  isEditMode: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private translate: TranslateService) {
    super(localService, location, router);

    this.requestApproveLot = this.requestApproveLot.bind(this);
    this.undoRequestApproveLot = this.undoRequestApproveLot.bind(this);
    this.resizeTabbedForm = this.resizeTabbedForm.bind(this);
    this.tabChanged = this.tabChanged.bind(this);
  }

  ngOnInit(): void {
    this.loadAreaGeo();
    this.loadMaterialType();
    this.loadSchedaTecnicaList();
    this.loadNazioni();
    this.loadProvince();
    this.loadCentriSelezione();
  }

  loadSchedaTecnicaList() {
    let scheda: SchedaTecnicaItem = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Azzurrato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Colorato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Trasparente.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Misto.pdf';
    this.scheda_list.push(scheda);
  }

  toggleDefault() {
    console.log('toggleDefault()');
    this.defaultVisible = !this.defaultVisible;
  }

  updateCs(list: CentroDiSelezioneLottoItem[]) {
    console.log('updateCs()');
    console.log(list);
    this.updateCsList(list);
  }

  updateCsList(list: CentroDiSelezioneLottoItem[]) {
    if (this.lots != undefined && list != undefined && list.length > 0) {
      let codiceLotto = list[0].codiceLotto;
      this.lots.forEach((lot: AuctionLotItem) => {
        if (lot.codiceLotto == codiceLotto) {
          lot.centroSelezione = this.convertCsItems(list);
          this.dataGridLots.instance.cellValue(this.dataGridLots.instance.getRowIndexByKey(this.modelEdit.id), 'centroSelezione', lot.centroSelezione);
          console.log('cs udated, press save button');
        }
      });
    }
  }

  convertCsItems(list: CentroDiSelezioneLottoItem[]): CentroSelezioneItem[] {
    let output: CentroSelezioneItem[] = [];
    if (list != undefined) {
      list.forEach(cs => {
        let outputItem: CentroSelezioneItem = new CentroSelezioneItem();
        console.log(cs);
        outputItem.codiceAsta = cs.codiceAsta;
        outputItem.codiceLotto = cs.codiceLotto;
        outputItem.quantitaTon = cs.quantitaTon;
        outputItem.codiceCentroSelezione = cs.codiceCentroSelezione;
        if (cs.centroSelezione != undefined) {
          outputItem.CER = cs.centroSelezione.CER;
          outputItem.cap = cs.centroSelezione.cap;
          outputItem.citta = cs.centroSelezione.citta;
          outputItem.codiceCentroSelezione = cs.centroSelezione.codice;
          outputItem.indirizzo = cs.centroSelezione.indirizzo;
          outputItem.nazioneDescr = cs.centroSelezione.nazione;
          outputItem.nome = cs.centroSelezione.nome;
          outputItem.pesoMedioCarico = cs.centroSelezione.pesoMedioCarico;
          outputItem.provincia = cs.centroSelezione.provincia;
        } else {
          let cs = this.localUtil.getSelectionCenterByCodice(outputItem.codiceCentroSelezione, this.cs_list);
        }
        output.push(outputItem);
      });
    }
    return output;
  }

  loadCentriSelezione() {
    const service = this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
      });
      this.subscriptions.push(service);
  }

  requestApproveAllLots() {

    let params: any[] = [this.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.requestApproveLotList(this.codiceAsta, this.lots).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            console.log('emit callback');
            this.monitorEvent.emit(true);
          },
          this.handleError(true, this.monitorEvent.emit(true))
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  undoRequestApproveAllLots() {

    let params: any[] = [this.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params));
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        const service = this.auctionService.undoRequestApproveLotList(this.codiceAsta, this.lots).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
            
            console.log('emit callback');
            this.monitorEvent.emit(true);
          },
          this.handleError(true, this.monitorEvent.emit(true))
        );
        this.subscriptions.push(service);
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  loadExcelLotti() {

    console.log('caricaExcelLotti()');
    let params: any[] = [this.codiceAsta];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("EXCEL_LOTS_TITLE");
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'excelLotti';
    modalRef.componentInstance.codiceAsta = this.codiceAsta;
    const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {
        
        modalRef.close();
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        console.log('carica da popup return');
      }
    }, this.handleError(true));
    this.subscriptions.push(service);

  }

  loadAreaGeo() {
    const service = this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.area_list = [];
        this.area_list = data.list;
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  loadMaterialType() {
    const service = this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.material_list = [];
        this.material_list = data.list;
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  isLotDraft(e) {
    
    if (e.row.data.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_DRAFT || e.row.data.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_REJECTED) {
      return true;
    } else {
      return false;
    }
  }

  isLotReqApprove(e) {
    
    if (e.row.data.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
      return true;
    } else {
      return false;
    }
  }

  requestApproveLot(e) {
    let row: AuctionLotItem = e.row.data;
    console.log('requestApproveLot() ' + row.codiceLotto);
    const service = this.auctionService.requestApproveLot(row.codiceAsta, row.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  undoRequestApproveLot(e) {
    let row: AuctionLotItem = e.row.data;
    console.log('undoRequestApproveLot() ' + row.codiceLotto);
    const service = this.auctionService.undoRequestApproveLot(row.codiceAsta, row.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  deleteLot(codiceAsta: string, codiceLotto: string) {

    if (codiceAsta && codiceLotto) {

      let params: any[] = [codiceAsta, codiceLotto];
      const modalRef = this.modalService.open(ConfirmComponent);
      modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
      modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_DELETE_LOT_MESSAGE", params);
      const service = modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry != undefined) {

          const service = this.auctionService.deleteLot(codiceAsta, codiceLotto).subscribe(
            res => {
              this.monitorEvent.emit(true);
              this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
              this.reloadComponent();
            },
            this.handleError(true)
          );
          this.subscriptions.push(service);
        }
      }, this.handleError(true));
      this.subscriptions.push(service);

    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("E:GENERIC")), true);
    }

  }

  loadNazioni() {
    const service = this.commonService.getNationsList().subscribe(
      data => {
        let nazioneVuota = new NazioneItem();
        nazioneVuota.codice = "";
        nazioneVuota.descrizione = " - ";
        this.nation_list.push(nazioneVuota);
        this.nation_list.push.apply(this.nation_list, data.list);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  loadProvince() {
    this.province_list = [];
    const service = this.commonService.getProvinceItalianeList().subscribe(
      data => {
        let provinciaVuota = new ProvinciaItem();
        provinciaVuota.codice = "";
        provinciaVuota.descrizione = " - ";
        this.province_list.push(provinciaVuota);
        this.province_list.push.apply(this.province_list, data.list);
      },
      this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  addNewLot() {
    this.dataGridLots.instance.addRow();
  }

  onRowClick(e) {
    console.log(e);
    if (this.dataGridLots.instance.isRowExpanded(e.key)) {
      this.dataGridLots.instance.collapseRow(e.key);
    } else {
      this.dataGridLots.instance.cancelEditData();
      this.dataGridLots.instance.collapseAll(-1);
      this.dataGridLots.instance.expandRow(e.key);
    }
  }

  onRowRemoving(e) {
    const service = this.auctionService.deleteLot(e.data.codiceAsta, e.data.codiceLotto).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("SUCCESS_DELETE_LOT_MESSAGE")), true);
        this.monitorEvent.emit(true);
      }, this.handleError(true, this.monitorEvent.emit(true))
    );
    this.subscriptions.push(service);
  }

  onRowExpanding(e) {
    this.dataGridLots.instance.cancelEditData();
    this.dataGridLots.instance.collapseAll(-1);
    console.log(e);
    this.isEditMode = false;
  }

  onInitNewRow(e) {
    this.modelEdit = new AuctionLotItem();
    this.modelEdit.centroSelezioneLottoItem = [];
    this.isEditMode = true;
  }

  onEditingStart(e) {
    this.dataGridLots.instance.cancelEditData();
    this.dataGridLots.instance.collapseAll(-1);
    this.isEditMode = true;
    this.modelEdit = e.data;
    this.modelEdit.centroSelezioneLottoItem = this.getCentriSelezioneLottoItem(e.data.centroSelezione);
    console.log(this.modelEdit.centroSelezioneLottoItem);
  }

  getCentriSelezioneLottoItem(centroSelezione: CentroSelezioneItem[]) {
    let outputList: CentroDiSelezioneLottoItem[] = [];
    if (centroSelezione != undefined) {
      centroSelezione.forEach((csItem: CentroSelezioneItem) => {
        let csLottoItem: CentroDiSelezioneLottoItem = new CentroDiSelezioneLottoItem();
        csLottoItem.codiceAsta = csItem.codiceAsta;
        csLottoItem.codiceLotto = csItem.codiceLotto;
        csLottoItem.codiceCentroSelezione = csItem.codiceCentroSelezione;
        csLottoItem.quantitaTon = csItem.quantitaTon;
        outputList.push(csLottoItem);
      });
    }
    return outputList;
  }

  onRowUpdate(e) {
    console.log('onRowUpdate()');
    console.log(e);
    let codiceLotto = e.oldData.codiceLotto;
    let lotItem: AuctionLotUpdateItem = $.extend({}, e.oldData, e.newData);
    lotItem.codiceLotto = codiceLotto;
    lotItem.centroSelezione = this.convertCsItems(lotItem.centroSelezioneLottoItem);
    const service = this.auctionService.updateLot(lotItem).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("SUCCESS_UPDATE_LOT_MESSAGE")), true);
        this.monitorEvent.emit(true);
      }, this.handleError(true, this.monitorEvent.emit(true))
    );
    this.subscriptions.push(service);
  }

  onRowInsert(e) {
    console.log('onRowInsert()');
    console.log(e);
    let lotItem: AuctionLotCreateItem = e.data;
    lotItem.codiceAsta = this.codiceAsta;
    lotItem.centroSelezione = this.convertCsItems(this.modelEdit.centroSelezioneLottoItem);
    console.log(lotItem.centroSelezione);
    const service = this.auctionService.insertLot(lotItem).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getLabelFromCode("SUCCESS_CREATE_LOT_MESSAGE")), true);
        this.monitorEvent.emit(true);
      }, this.handleError(true, this.monitorEvent.emit(true))
    );
    this.subscriptions.push(service);
  }

  tabChanged(e) {
    console.log('tabChanged()');
    console.log(e.itemData.title);
    this.activeTab = e.itemData.title;
    this.resizeTabbedForm();
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      info.rowElement.addClass('auction');
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
