import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionItem, AuctionLotGeoArea, AuctionLotItem, AuctionLotStatus, AuctionLotTipoProdotto, CentroSelezioneItem, NazioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aste-pubbliche-dettaglio',
  templateUrl: './aste-pubbliche-dettaglio.component.html',
  styleUrls: ['./aste-pubbliche-dettaglio.component.css']
})
export class AstePubblicheDettaglioComponent extends APIService implements OnInit, OnDestroy {

  @ViewChild("dataGridLots", { static: false }) dataGridLots: DxDataGridComponent;

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('AstePubblicheDettaglioComponent()');
    this.lot_statuses = this.localUtil.getStatoLottoList();
    this.geoArea_list = this.localUtil.getGeoAreaList();
    this.tipoProdotto_list = this.localUtil.getTipoProdottoList();
  }

  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];
  lot_statuses: AuctionLotStatus[] = [];
  geoArea_list: AuctionLotGeoArea[] = [];
  tipoProdotto_list: AuctionLotTipoProdotto[] = [];

  totaleMisto: number = 0;
  tonMisto: number = 0;
  totaleAzzurrato: number = 0;
  tonAzzurrato: number = 0;
  totaleColorato: number = 0;
  tonColorato: number = 0;
  totaleTrasparente: number = 0;
  tonTrasparente: number = 0;
  totaleBiennale: number = 0;
  tonBiennale: number = 0;
  totaleSemestrale: number = 0;
  tonSemestrale: number = 0;
  totaleTon: number = 0;
  exportComplete: boolean = false;
  private subscriptions: Subscription[] = [];

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  ngOnInit(): void {
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' +  this.codiceAsta);
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined);
  }

  loadNazioni() {
    const nationListService = this.commonService.getNationsList().subscribe(
        data => {
          this.nation_list = data.list;
    });
    this.subscriptions.push(nationListService);
  }

  loadCentriSelezione() {
    const csService = this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
    });
    this.subscriptions.push(csService);
  }

  getLotByCode(codiceLotto:string) {
    let output = undefined;
    if (this.lot_list != undefined) {
        this.lot_list.forEach((lotElem:any) => {
            if (lotElem.codiceLotto == codiceLotto) {
                output = lotElem;
            }
        });
    }
    return output;
  }

  loadAuctionDetails(textFilter) {
    console.log('loadAuctionDetails()');

    const auctionLotsService = this.auctionService.getAuctionLots(this.codiceAsta, '').subscribe(
        {next: data => {
            this.auction = data.auctionDetail;

            if (data.lotList !== undefined) {
                this.auction.numLotti = data.lotList.length;

                data.lotList.forEach(item => {
                    this.processLotItem(item, textFilter);
                });

                this.lot_list = data.lotList;
                console.log('lot_list: ' + this.lot_list.length);
                this.setLotStats();
            }
        },
        error: this.handleError(true)
    });
    this.subscriptions.push(auctionLotsService);
  }

  processLotItem(item: any, textFilter: string) {
    item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);
    item.classAdmin = 'hidden';

    if (this.shouldInsertItem(item, textFilter)) {
        this.setItemProperties(item);
    }
  }

  shouldInsertItem(item: any, textFilter: string): boolean {
    if (textFilter !== undefined && textFilter !== null && textFilter !== '') {
        return JSON.stringify(item).indexOf(textFilter) > -1;
    }
    return true;
  }

  setItemProperties(item: any) {
    item.toCssClass = function() {
        return function(text, render) {
            return render(text).toLowerCase().split(' ').join('-');
        }
    };

    this.setPaymentStatus(item);
    this.setShippingStatus(item);
    this.setAdditionalProperties(item);
  }

  setPaymentStatus(item: any) {
      if (item.pagato === 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
      } else if (item.pagato === 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
      }
  }

  setShippingStatus(item: any) {
    if (item.spedito === 0) {
        item.retired = "Non ritirato";
        item.retiredClass = "ko";
    } else if (item.spedito === 1) {
        item.retired = "Ritirato";
        item.retiredClass = "ok";
    }
  }

  setAdditionalProperties(item: any) {
    item.existsCS = function() {
        return this.centroSelezione !== undefined && this.centroSelezione.length > 0;
    };
    item.existsScheda = function() {
        return this.schedaTecnica !== '' && this.schedaTecnica !== undefined;
    };
    item.notExistsScheda = function() {
        return this.schedaTecnica === '' || this.schedaTecnica === undefined;
    };
    item.isOperator = this.isOperator.bind(this);
    item.isNotOperator = this.isNotOperator.bind(this);

    item.paidChecked = item.pagato === 1;
    item.retiredChecked = item.spedito === 1;
    item.notPaidChecked = item.pagato === 0;
    item.notRetiredChecked = item.spedito === 0;

    item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

    if (item.nomeOrganization === undefined) {
        item.nomeOrganization = '-';
    }
  }

  isOperator() {
      let user = this.localUtil;
      return user.ruolo === ConstantsModule.OPER_PLASTICFINDER || user.ruolo === ConstantsModule.OPERATORE;
  }

  isNotOperator() {
    let user = JSON.parse(JSON.parse(sessionStorage.getItem("user")).user_detail);
    return user.ruolo !== ConstantsModule.OPER_PLASTICFINDER && user.ruolo !== ConstantsModule.OPERATORE;
  }

  setLotStats() {
          
    this.lot_list.forEach(item => {
        if (item.tipoProdotto ==='PET-Azzurrato') {
            this.totaleAzzurrato++;
            this.tonAzzurrato += item.quantitaTon;
        } else if (item.tipoProdotto ==='PET-Colorato') {
            this.totaleColorato++;
            this.tonColorato += item.quantitaTon;
        } else if (item.tipoProdotto ==='PET-Trasparente') {
            this.totaleTrasparente++;
            this.tonTrasparente += item.quantitaTon;
        } else if (item.tipoProdotto ==='PET-Misto') {
            this.totaleMisto++;
            this.tonMisto += item.quantitaTon;
        } else if (item.tipoProdotto ==='MCPLPET-BIENNALE') {
            this.totaleBiennale++;
            this.tonBiennale += item.quantitaTon;
        } else if (item.tipoProdotto ==='MCPLPET-SEMESTRALE') {
            this.totaleSemestrale++;
            this.tonSemestrale += item.quantitaTon;
        }
        this.totaleTon = this.tonAzzurrato+this.tonColorato+this.tonTrasparente+this.tonMisto+this.tonBiennale+this.tonSemestrale;
    });

  }

  gotoListaAstePubbliche() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_PUBBLICHE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
      return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note:string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;    
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
        lotElem.centroSelezione.forEach(csLottoElem => {
            let item = new CentroSelezioneItem();
            item.quantitaTon = lotElem.quantitaTon;
            item.indirizzo = '';
            item.pesoMedioCarico = '';

            let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
            if (cs != undefined) {
                
                let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
                console.log('nazione: ' + JSON.stringify(nazione));
                let nazioneDescr = '';
                if (nazione != null && nazione != undefined && nazione != '') {
                    nazioneDescr = nazione.descrizione;
                    if (nazione.codice == 'IT') {
                        nazioneDescr = 'Italia';
                    }
                    item.nazioneDescr = nazioneDescr;
                }
            }
            item.CER = cs.CER;
            item.cap = cs.cap;
            item.citta = cs.citta;
            item.provincia = cs.provincia;
            item.pesoMedioCarico = cs.pesoMedioCarico;
            item.nome = cs.nome;
            item.indirizzo = cs.indirizzo;
            csListPopup.push(item);
        });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      })
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }
  
}
