<div id="azienda-impianti" class="content bottom80 p-4 my-3">           
    <div class="add-item-section border-0 mb-auto">
        <div class="add-plant-panel pb-4" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <form id="form_createFramework" [formGroup]="plantForm" class="form-group">
                <section class="step-section focused d-flex pb-4">
                    <div class="card flex-fill me-4">
                        <h2 class="fs28 inter bold gray-dark pb-4" translate>azienda-impianti.dati-impianto</h2>
                        <input type="hidden" id="plant-id" formControlName="id" class="form-control">
                        <div class="row">
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nomeImpianto')}" (mouseleave)="validateField('nomeImpianto')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-name" maxlength="255" required formControlName="nomeImpianto" class="form-control">
                                    <label for="new-plant-name" translate>azienda-impianti.nome-impianto</label>
                                </div>                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nazionalita')}" (mouseleave)="validateField('nazionalita')">
                                <div class="input-wrapper full">
                                    <select name="" id="new-plant-nationality" required formControlName="nazionalita" class="form-control">
                                        <option *ngFor="let nation of nation_list; let i = index" [value]="nation.codice">{{nation.descrizione}}</option>
                                    </select>
                                    <label for="new-plant-nationality" translate>azienda-impianti.nazionalit <span>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill mb-4" id="field-provincia" [ngClass]="{'error': controlHasError('provincia')}" (mouseleave)="validateField('provincia')">
                                <div class="input-wrapper full">
                                    <select name="" id="new-plant-province" formControlName="provincia" class="form-control">
                                        <option *ngFor="let provincia of province_list; let i = index" [value]="provincia.descrizione">{{provincia.descrizione}}</option>
                                    </select>
                                    <label for="new-plant-province" translate>azienda-impianti.provincia</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4 ms-2" [ngClass]="{'error': controlHasError('citta')}" (mouseleave)="validateField('citta')">
                                <div class="input-wrapper full">
                                    <input type="text" name="" id="new-plant-city" maxlength="255" required formControlName="citta" class="form-control">
                                    <label for="new-plant-city" translate>azienda-impianti.citt &nbsp;<span>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('indirizzo')}" (mouseleave)="validateField('indirizzo')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-address" required formControlName="indirizzo" class="form-control" maxlength="255">
                                    <label for="new-plant-address" translate>azienda-impianti.indirizzo</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4 cap" [ngClass]="{'error': controlHasError('cap')}" (mouseleave)="validateField('cap')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-cap" required formControlName="cap" class="form-control" maxlength="5" name="zipcode">
                                    <label for="new-plant-cap" translate>azienda-impianti.cap</label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('pec')}" (mouseleave)="validateField('pec')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-pec" required formControlName="pec" class="form-control" maxlength="255">
                                    <label for="new-plant-address" translate>azienda-impianti.pec</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('capacitaProduttivaAnnua')}" (mouseleave)="validateField('capacitaProduttivaAnnua')">
                                <div class="input-wrapper full">
                                    <input type="number" id="new-plant-production" maxlength="15" required formControlName="capacitaProduttivaAnnua" class="form-control">
                                    <label for="new-plant-production" translate>azienda-impianti.capacit &nbsp;<span translate>azienda-impianti.produttiva-annua-in-tonnellate</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark pb-2">01</div>
                            <div class="label">
                                <span translate>azienda-impianti.dati-impianto</span>
                            </div>
                        </div>
                    </div>
                </section>
            
                <section class="step-section d-flex pb-4">
                    <div class="card flex-fill me-3">
                        <h2 class="fs28 inter bold gray-dark" translate>azienda-impianti.documenti-di-certificazione-impianto</h2>
                        <div class="doc-datas d-flex p-3">
                            <app-file-box [status]="plant.certificazioneIso9001Valid" docname="certificazione-iso-9001.pdf" (file)="getIso9001File($event)" [showError]="checkError('certificazioneIso9001')" [uuid]="plant.certificazioneIso9001"></app-file-box>
                            <app-file-box [status]="plant.certificazioneIso14001Valid" docname="certificazione-iso-14001.pdf" (file)="getIso14001File($event)" [showError]="checkError('certificazioneIso14001')" [uuid]="plant.certificazioneIso14001"></app-file-box>
                            <app-file-box [status]="plant.docAutorizzazioneValid"  docname="documento-autorizzazione.pdf" (file)="getDocAuthFile($event)" [showError]="checkError('docAutorizzazione')" [uuid]="plant.docAutorizzazione"></app-file-box>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark inter">02</div>
                            <div class="label">
                                <span translate>azienda-impianti.documenti-di-certificazione-impianto</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="step-section d-flex pb-4">
                    <div class="card flex-fill me-3">
                        <h2 class="fs28 inter bold gray-dark pb-4" translate>azienda-impianti.informazioni-utili-per-la-spedizione</h2>
                        <div class="row">
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nomeRifSpedizione')}" (mouseleave)="validateField('nomeRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-name" maxlength="255" formControlName="nomeRifSpedizione">
                                    <label for="new-plant-delivery-name" translate>azienda-impianti.nome-e-riferimento-per-la-spedizione</label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('telefonoRifSpedizione')}" (mouseleave)="validateField('telefonoRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-phone" maxlength="75" formControlName="telefonoRifSpedizione">
                                    <label for="new-plant-delivery-phone" translate>azienda-impianti.telefono-di-riferimento</label>
                                </div>
                            </div>
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('mobileRifSpedizione')}" (mouseleave)="validateField('mobileRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-mobile-phone" maxlength="75" formControlName="mobileRifSpedizione">
                                    <label for="new-plant-delivery-mobile-phone" translate>azienda-impianti.telefono-mobile-di-riferimento</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('emailRifSpedizione')}" (mouseleave)="validateField('emailRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="email" id="new-plant-delivery-email" maxlength="255" formControlName="emailRifSpedizione">
                                    <label for="new-plant-delivery-email" translate>azienda-impianti.email-di-riferimento</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('noteTrasportatore')}" (mouseleave)="validateField('noteTrasportatore')">
                                <div class="input-wrapper full">
                                    <textarea name="" id="new-plant-delivery-notes" cols="30" rows="10" maxlength="2000" formControlName="noteTrasportatore"></textarea>
                                    <label for="new-plant-delivery-notes" translate>azienda-impianti.note-per-il-trasportatore</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper lh-1">
                            <div class="number fs32 bold gray-dark inter">03</div>
                            <div class="label">
                                <span translate>azienda-impianti.informazioni-utili-per-la-spedizione</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
                <div class="pb-5 text-lg-end text-center">
                    <button class="btn " id="btn_cancelCreate" (click)="annullaImpianto()"><span translate>azienda-impianti.annulla</span></button>
                    <button class="btn btn-primary primary" type="submit" (click)="salvaImpianto()" id="btn_createPlant"><i class="fal fa-save"></i> <span translate>salva</span></button>
                </div>
        </div>
        <button class="add add-operator new-operator-toggler d-flex mb-4 blue-dark pointer w-100" (click)="toggleNuovoImpianto()">
            <article class="d-flex align-items-center w-100">
                <div class="icon fs40 me-3"><i class="fal fa-plus-circle"></i></div>
                <div class="text fs18"><span translate>azienda-impianti.aggiungi-impianto-di-riciclo</span></div>
            </article>
        </button>
        
    </div>
    <h2 translate>azienda-impianti.impianti-di-riciclo</h2>
    <div id="plants-list" class="mt-5">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="plants_list"
            keyExpr="id"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true" 
            [showBorders]="true" 
            [columnHidingEnabled]="false" 
            [columnAutoWidth]="true"
            (onExporting)="onExporting($event)"
            >

            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
            </dxo-column-chooser>
            <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
            ></dxo-export>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-scrolling mode="default"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="UserId" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'azienda-impianti-lista.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nomeImpianto" caption="{{'azienda-impianti-lista.nome-impianto' | translate}}" dataType="string" [visible]="true" >
            </dxi-column>
            <dxi-column dataField="createDate" caption="{{'azienda-impianti-lista.data-creazione' | translate}}" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="true">
            </dxi-column>
            <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="lingua" caption="{{'azienda-impianti-lista.lingua' | translate}}" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="telefono" caption="{{'azienda-impianti-lista.telefono' | translate}}" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="stato" caption="{{'azienda-impianti-lista.stato' | translate}}" dataType="string" [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" caption="{{'azienda-impianti-lista.data-registrazione' | translate}}" dataType="string" [visible]="false">
            </dxi-column>

            <dxo-master-detail [enabled]="true" template="plantTemplate"></dxo-master-detail>

            <dxo-toolbar>
                <dxi-item location="after" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
                <dxi-item location="after" name="columnChooserButton"></dxi-item>                
            </dxo-toolbar>

            <div *dxTemplate="let plantTemplate of 'plantTemplate'">
                <app-dettaglio-azienda-impianto [plantDetail]="plantTemplate.data" [showDetailForm]="true" [vatnumber]="vatnumber"></app-dettaglio-azienda-impianto>
            </div>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.statusClass}}">{{statusTemplate.data.status}}</div>
            </div>
            
        </dx-data-grid>
    </div>
</div>