import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { AuctionLotTimerItem } from 'src/app/models/dataModels';
import { ITimer } from './itimer';
import { Subscription } from 'rxjs';
import { LocalUtilService } from 'src/app/services/local-util.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {


    timeInSeconds: number;
    serverTimeMonitor: Subscription;
    @Input() name: string;
    @Input() dateOfficialEnd: string;
    @Input() dateEndCountdown: string;
    @Input() hourEndCountdown: string;
    @Input() autoStart: boolean = true;
    @Input() showSign: boolean = false;
    @Input() visible: boolean = true;
    @Input() serverTime: string;
    @Output() monitorEvent: EventEmitter<AuctionLotTimerItem> = new EventEmitter();

    public timer: ITimer;
    

    constructor(private localUtil: LocalUtilService) {

    }

    isVisible() {
        return this.visible;
    }

    public ngOnInit() {
       this.initTimer();
    }
 
    hasFinished() {
        return this.timer.hasFinished;
    }

    hasStarted() {
        return this.timer.hasStarted;
    }

    isInExtraTime() {
        let output = false;
        let extendedLotDateTime = this.dateEndCountdown + ' ' + this.hourEndCountdown;
        if (extendedLotDateTime != this.dateOfficialEnd) {
            output = true;
        }
        return output;
    }
 
    initTimer() {
        //this.destroyTimer();
        //console.log('TimerComponent() initTimer');
        if(!this.timeInSeconds) { this.timeInSeconds = 0; }
        if (this.dateEndCountdown) {
            let dateSplitted = this.dateEndCountdown.split("/");
            //console.log('serverTime: ' + this.serverTime);
            let nowDate: moment.Moment = moment(this.serverTime, 'DD/MM/YYYY HH:mm:ss');
            let dateTimeEndCountdown = dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0] + 'T' + this.hourEndCountdown + ":00";
            let dateTimeEndCountdownMoment: moment.Moment = moment(dateTimeEndCountdown);
            
            if (dateTimeEndCountdownMoment > nowDate ) {
            let seconds = (dateTimeEndCountdownMoment.toDate().getTime() - nowDate.toDate().getTime()) / 1000;
            this.timeInSeconds = seconds;
            }

            let firstStart = this.timer == undefined ? true: false
            
            this.timer = <ITimer>{
                seconds: this.timeInSeconds,
                runTimer: false,
                hasStarted: false,
                hasFinished: false,
                secondsRemaining: this.timeInSeconds,
                extraTimeNotified: false
            };
    
            this.timer.displayTime = this.getSecondsWithUnitMeasure(this.timer.secondsRemaining);
            
            if (!this.timer.hasStarted && this.autoStart) {
                this.startTimer();
            }
        }
    }

    startTimer() {
        if (!this.timer.hasStarted) {
            //console.log('TimerComponent() startTimer');
            this.timer.hasStarted = true;
            this.timer.runTimer = true;
            this.timerTick();
        }
    }

    stopTimer() {
        //console.log('TimerComponent() stopTimer');
        if (this.timer) {
            this.timer.hasStarted = false;
            this.timer.runTimer = false;
        }
    }
 
    pauseTimer() {
        this.timer.runTimer = false;
    }
 
    resumeTimer() {
        this.startTimer();
    }
 
    timerTick() {
        //console.log('TimerComponent() timerTick');
        setTimeout(() => {
            //console.log('TimerComponent() timerTick setTimeout ' + this.name + ' this.timer.secondsRemaining: ' + this.timer.secondsRemaining);
            if (!this.timer.runTimer) { return; }
            this.timer.secondsRemaining--;
            this.timer.displayTime = this.getSecondsWithUnitMeasure(this.timer.secondsRemaining);
            if (this.timer.secondsRemaining > 0) {
                this.timerTick();
                if (this.isInExtraTime() && !this.timer.extraTimeNotified) {                    
                    let outputTimerEmit: AuctionLotTimerItem = new AuctionLotTimerItem();
                    outputTimerEmit.codiceLotto = this.name;
                    outputTimerEmit.isClosed = false;
                    outputTimerEmit.isExtratime = true;
                    this.monitorEvent.emit(outputTimerEmit);
                    this.timer.extraTimeNotified = true;
                }
            } else {
                //console.log(this.name + ' timerHashFinished');
                this.timer.hasFinished = true;
                let outputTimerEmit: AuctionLotTimerItem = new AuctionLotTimerItem();
                outputTimerEmit.codiceLotto = this.name;
                outputTimerEmit.isClosed = true;
                outputTimerEmit.isExtratime = false;
                this.monitorEvent.emit(outputTimerEmit);
                this.timer.displayTime = '';

            }
        }, 1000);

        setTimeout(() => {
            try {
                if (this.timer.secondsRemaining > 0) { 
                    if (this.isInExtraTime() && !this.timer.extraTimeNotified) {                    
                        let outputTimerEmit: AuctionLotTimerItem = new AuctionLotTimerItem();
                        outputTimerEmit.codiceLotto = this.name;
                        outputTimerEmit.isClosed = false;
                        outputTimerEmit.isExtratime = true;
                        this.monitorEvent.emit(outputTimerEmit);
                        this.timer.extraTimeNotified = true;
                    }
                } else {
                    this.timer.hasFinished = true;
                    let outputTimerEmit: AuctionLotTimerItem = new AuctionLotTimerItem();
                    outputTimerEmit.codiceLotto = this.name;
                    outputTimerEmit.isClosed = true;
                    outputTimerEmit.isExtratime = false;
                    this.monitorEvent.emit(outputTimerEmit);
                    this.timer.displayTime = '';
                }
            } catch (err) {}
        }, 0);

    }
    

    getSecondsWithUnitMeasure(inputSeconds: number) {
      let sec_num = parseInt(inputSeconds.toString(), 10);
      let hours   = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);
      let hoursString = '';
      let minutesString = '';
      let secondsString = '';
      hoursString = ((hours < 10) ? "0" + hours : hours.toString()) + "h";
      minutesString = ((minutes < 10) ? "0" + minutes : minutes.toString()) + "m";
      secondsString = ((seconds < 10) ? "0" + seconds : seconds.toString()) + "s";
      return hoursString + ' : ' + minutesString + ' : ' + secondsString;
  }
    
}
