import { DatePipe, Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuctionSetupCreateItem, AuctionSetupItem, AuctionSetupPropItem, AuctionSetupUpdateItem, AuctionTipologyCreateItem, AuctionTipologyItem, CodiceDescrizioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class AdminSetupComponent extends APIService {

  tipologia: AuctionTipologyCreateItem = new AuctionTipologyCreateItem();
  tipologiaForm: UntypedFormGroup;
  submitted: boolean = false;

  /* Master variables */
  addAuction: AuctionSetupCreateItem = new AuctionSetupCreateItem();
  addAuctionForm: UntypedFormGroup;
  saveButtonOptions: any;

  @ViewChild("dataGridTipology", { static: false }) dataGridTipology: DxDataGridComponent;
  @ViewChild("dataGridSetup", { static: false }) dataGridSetup: DxDataGridComponent;
  @ViewChild("dataGridBet", { static: false }) dataGridBet: DxDataGridComponent;

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router,
    private translate: TranslateService) {
    super(localService, location, router);
    this.onSelectionChangedTab = this.onSelectionChangedTab.bind(this);
    this.resizeTabbedForm = this.resizeTabbedForm.bind(this);

    const that = this;
    this.saveButtonOptions = {
      text: 'chiudi',
      stylingMode: 'contained',
      width: '200',
      type: 'default',
      onClick() {
        that.chiudi();
      }
    },

      this.tipologiaForm = new UntypedFormGroup({
        codice: new UntypedFormControl(this.tipologia.codice, [Validators.required]),
        descrizione: new UntypedFormControl(this.tipologia.descrizione, [Validators.required]),
        motore: new UntypedFormControl(this.tipologia.motore, [Validators.required]),
      });

    this.addAuctionForm = new UntypedFormGroup({
      dataInizio: new UntypedFormControl(this.addAuction.dataInizio, [Validators.required]),
      dataFine: new UntypedFormControl(this.addAuction.dataFine, [Validators.required]),
      numeroAsteAnno: new UntypedFormControl(this.addAuction.numeroAsteAnno, [Validators.required]),
      percRule2: new UntypedFormControl(this.addAuction.percRule2, [Validators.required]),
      percRule3: new UntypedFormControl(this.addAuction.percRule3, [Validators.required]),
      tipologiaAsta: new UntypedFormControl(this.addAuction.tipologiaAsta, [Validators.required])
    });
  }

  tipology_list: AuctionTipologyItem[] = [];

  motore_list: CodiceDescrizioneItem[] = [{ codice: 'SINGOLA', descrizione: 'Asta singola' }, { codice: 'MULTIPLA', descrizione: 'Asta multipla' }];

  modelTipology: AuctionTipologyItem;
  modelSetup: AuctionSetupItem;

  ngOnInit(): void {
    console.log('init()');
    this.listAuctionTipology();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();  // Prevents default scroll on space
    }
  }

  logTemplate(data) {
    console.log(data);
    return data;
  }

  onSelectionChangedTab(e) {
    this.resizeTabbedForm();
  }

  motore: any[] = [];
  listAuctionTipology() {
    this.motore = [];
    this.auctionService.listAuctionTipology().subscribe(
      (data) => {
        this.tipology_list = data.list.map((tipology: AuctionTipologyItem) => {
          console.log('tipology:', tipology.codice);
          console.log('tipology:', tipology);

          this.getAuctionSetupList(tipology);
          this.getBetSlotList(tipology);
          this.motore.push(tipology);
          return tipology;
        });

        console.log(this.tipology_list);
      },
      this.handleError(true)
    );
  }

  getAuctionSetupList(tipology: AuctionTipologyItem): void {
    tipology.setup = [];
    this.auctionService.getAuctionSetupListByTipology(tipology.codice).subscribe(
      (data) => {
        tipology.setup = data.list.map((item: any) => {
          const setupItem = new AuctionSetupItem();

          // Directly assign properties
          setupItem.id = item.id;
          setupItem.numeroAsteAnno = item.numeroAsteAnno;
          setupItem.percRule2 = item.percRule2;
          setupItem.percRule3 = item.percRule3;
          setupItem.tipologiaAsta = item.tipologiaAsta;
          setupItem.properties = item.properties;
          setupItem.active = item.active;
          setupItem.motore = item.motore;

          setupItem.dataInizio = item.dataInizio ? this.convertToDate(item.dataInizio) : undefined;
          setupItem.dataFine = item.dataFine ? this.convertToDate(item.dataFine) : undefined;

          return setupItem;
        });

        this.cleanProperties(tipology.setup);
      },
    );
  }


  private convertToDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(Date.UTC(+year, +month - 1, +day));
  }






  getBetSlotList(tipology: AuctionTipologyItem) {
    tipology.bet = [];
    this.auctionService.getAuctionBetSetupByTipology(tipology.codice).subscribe(
      (data) => {
        tipology.bet = data.list;
      }, this.handleError(true));
  }

  cleanProperties(setups: AuctionSetupItem[]) {
    if (setups && setups.length > 0) {
      setups.forEach((setup: AuctionSetupItem) => {
        let propertiesOutput: AuctionSetupPropItem[] = [];
        setup.properties.forEach((prop: AuctionSetupPropItem) => {
          if (prop.code != '') {
            propertiesOutput.push(prop);
          }
        });
        setup.properties = propertiesOutput;
      });
    }
  }

  onInitNewRowSetup(e, tipology: string) {
    console.log('onInitNewRowSetup()');
    let setup: AuctionSetupItem = e.data;
    setup.percRule2 = 0;
    setup.percRule3 = 0;
    setup.numeroAsteAnno = 1;
    setup.tipologiaAsta = tipology;
    setTimeout(this.resizeTabbedForm, 100);
  }

  onRowExpanding(e) {
    this.dataGridTipology.instance.cancelEditData();
    this.dataGridTipology.instance.collapseAll(-1);
    this.modelTipology = this.getTipologyByCodice(e.key);
    //console.log('onRowExpanding() tipo: ' + e.key);
  }

  getTipologyByCodice(tipologiaAsta: string) {
    let output: AuctionTipologyItem;
    if (this.tipology_list && this.tipology_list.length > 0) {
      this.tipology_list.forEach(tipology => {
        if (tipology.codice == tipologiaAsta) {
          output = tipology;
        }
      });
    }
    return output;
  }

  onRowInsert(e) {
    let tipology: AuctionTipologyCreateItem = e.data;
    this.auctionService.createAuctionTipology(tipology).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true)
    );
  }

  saveTipologia() {
    if (this.validate()) {
      let request: AuctionTipologyCreateItem = new AuctionTipologyCreateItem();
      request = {
        codice: this.tipologiaForm.get('codice').value,
        descrizione: this.tipologiaForm.get('descrizione').value,
        motore: this.tipologiaForm.get('motore').value
      }
      this.auctionService.createAuctionTipology(request).subscribe(
        data => {
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
          this.listAuctionTipology();
        }, this.handleError(true)
      );
    }
  }

  isNewEditing() {
    return (this.tipologia.newEditing == true);
  }

  toggleNuovoOperatore() {
    this.tipologia.newEditing = !this.tipologia.newEditing;
    if (this.tipologia.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.tipologiaForm.markAllAsTouched();
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.tipologiaForm);
    Object.keys(this.tipologiaForm.controls).forEach(key => {
      if (!this.tipologiaForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  annullaNuovoOperatore() {
    this.tipologia = new AuctionTipologyCreateItem();
    this.tipologia.newEditing = false;
  }

  controlHasError(nome) {
    return !this.tipologiaForm.get(nome).valid && this.submitted;
  }

  onRowDelete(e) {
    console.log('onRowDelete() ' + e.data.id);
    this.auctionService.deleteAuctionTipology(e.data.id).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true)
    );
  }

  isNewEditingSetup() {
    return (this.addAuction.newEditing == true);
  }

  toggleNuovoSetup() {
    this.addAuction.newEditing = !this.addAuction.newEditing;
    if (this.addAuction.newEditing) {
      this.cleanValidation2();
    }
  }

  cleanValidation2() {
    this.addAuctionForm.markAllAsTouched();
  }

  validate2() {
    this.submitted = true;
    let output = true;
    console.log(this.addAuctionForm);
    Object.keys(this.addAuctionForm.controls).forEach(key => {
      if (!this.addAuctionForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  annullaNuovoSetup() {
    this.addAuction = new AuctionSetupCreateItem();
    this.addAuction.newEditing = false;
  }

  controlHasError2(nome) {
    return !this.addAuctionForm.get(nome).valid && this.submitted;
  }

  date = new Date();
  pipe = new DatePipe('en-US');
  saveAddAuction() {
    console.log('saveAddAuction()');
    let tipologiaAsta = this.getAuctionTipologySelected();
    this.addAuctionForm.controls['tipologiaAsta'].setValue(tipologiaAsta);
    if (this.validate2()) {
      let request: AuctionSetupCreateItem = new AuctionSetupCreateItem();
      console.log('saveAddAuction');
      let dataInizio = this.pipe.transform(this.addAuctionForm.get('dataInizio').value, 'dd/MM/yyyy');
      let dataFine = this.pipe.transform(this.addAuctionForm.get('dataFine').value, 'dd/MM/yyyy');
      request = {
        dataInizio: dataInizio,
        dataFine: dataFine,
        numeroAsteAnno: this.addAuctionForm.get('numeroAsteAnno').value,
        percRule2: this.addAuctionForm.get('percRule2').value,
        percRule3: this.addAuctionForm.get('percRule3').value,
        tipologiaAsta: tipologiaAsta
      }
      console.log(request);
      this.auctionService.createAuctionSetup(request).subscribe(
        data => {
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
          this.listAuctionTipology();
          this.cleanValidation2();
          this.tipologia.newEditing = false;
        }, this.handleError(true, this.listAuctionTipology())
      );
    }
  }

  getAuctionTipologySelected() {
    let output: string = undefined;
    if (this.tipology_list != undefined) {
      for (let tipology of this.tipology_list) {
        if (tipology.formOpened == true) {
          return tipology.codice;
        }
      }
    }
    return output;
  }

  onRowInsertSetup(e) {
    console.log('onRowInsertSetup');
    console.log(e.data);
    let auctionSetup: AuctionSetupCreateItem = e.data;
    console.log('PRE: ' + JSON.stringify(auctionSetup));
    console.log();
    /*auctionSetup.dataInizio = this.getUTCFromDevextremeDate(auctionSetup.dataInizio);
    auctionSetup.dataFine = this.getUTCFromDevextremeDate(auctionSetup.dataFine, '23:59');*/

    auctionSetup.dataInizio = this.pipe.transform(auctionSetup.dataInizio, 'dd/MM/yyyy');//this.getItalianDate(auctionSetup.dataInizio);
    auctionSetup.dataFine = this.pipe.transform(auctionSetup.dataFine, 'dd/MM/yyyy');//this.getItalianDate(auctionSetup.dataFine);
    console.log('POST: ' + JSON.stringify(auctionSetup));

    this.auctionService.createAuctionSetup(auctionSetup).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true, this.listAuctionTipology())
    );
  }

  onRowDeleteSetup(e) {
    console.log('onRowDeleteSetup()');
    this.auctionService.deleteAuctionSetup(e.data.id).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true, this.listAuctionTipology())
    );
  }

  onRowUpdateSetup(e) {
    console.log('onRowUpdateSetup');
    console.log(e);
    let auctionSetup: AuctionSetupUpdateItem = $.extend({}, e.oldData, e.newData);
    console.log('PRE: ' + JSON.stringify(auctionSetup));

    /*auctionSetup.dataInizio = this.getUTCFromDevextremeDate(auctionSetup.dataInizio);
    auctionSetup.dataFine = this.getUTCFromDevextremeDate(auctionSetup.dataFine, '23:59');*/
    auctionSetup.dataInizio = this.pipe.transform(auctionSetup.dataInizio, 'dd/MM/yyyy');//this.getItalianDate(auctionSetup.dataInizio);
    auctionSetup.dataFine = this.pipe.transform(auctionSetup.dataFine, 'dd/MM/yyyy');//this.getItalianDate(auctionSetup.dataFine);
    console.log(auctionSetup.dataInizio);
    console.log(auctionSetup.dataFine);

    console.log('POST: ' + JSON.stringify(auctionSetup));

    this.auctionService.updateAuctionSetup(auctionSetup).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true, this.listAuctionTipology())
    );
  }

  updatedProperties(e) {
    console.log('updatedProperties()');
    let rows = this.dataGridSetup.instance.getVisibleRows();
    if (rows) {
      rows.forEach(row => {
        if (row.isEditing) {
          this.dataGridSetup.instance.cellValue(row.rowIndex, 'dataModifica', new Date());
        }
      });
    }
  }

  updatedBet(e) {
    console.log('updatedBet()');
    let rows = this.dataGridSetup.instance.getVisibleRows();
    if (rows) {
      rows.forEach(row => {
        if (row.isEditing) {
          this.dataGridSetup.instance.cellValue(row.rowIndex, 'dataModifica', new Date());
        }
      });
    }
  }

  dataInizioUpdate: any;
  dataFineUpdate: any;
  numeroAsteAnnoUpdate: any;
  percRule2Update: any;
  percRule3Update: any;
  properties: any = [];
  id: any;

  grid: boolean = true;
  updateForm: boolean = false;

  tipologiaAsta: any;
  onEditingStart(e) {
    this.grid = false;
    this.updateForm = true;
    console.log('onEditingStart');
    let dataInizioUpdate = e.data.dataInizio;//this.getMMDDAAAADate(e.data.dataInizio);//this.formatDate(e.data.dataInizio);
    let dataFineUpdate = e.data.dataFine;//this.getMMDDAAAADate(e.data.dataFine);//this.formatDate(e.data.dataFine);

    this.id = e.data.id;
    this.dataInizioUpdate = dataInizioUpdate;
    this.dataFineUpdate = dataFineUpdate;
    this.numeroAsteAnnoUpdate = e.data.numeroAsteAnno;
    this.percRule2Update = e.data.percRule2;
    this.percRule3Update = e.data.percRule3;
    this.properties = e.data.properties;

    this.propertiesValues = this.properties.map(item => item.value);

    this.tipologiaAsta = e.data.tipologiaAsta;

    this.modelSetup = e.data;
    this.dataGridSetup.instance.cancelEditData();
    this.dataGridSetup.instance.collapseAll(-1);
    setTimeout(this.resizeTabbedForm, 100);
  }

  propertiesValues: string[] = [];
  tagli: any = [];
  saveUpdateAuction() {
    if (this.tagli.length === 0) {
      this.auctionService.getAuctionBetSetupByTipology(this.tipologiaAsta).subscribe(dt => {
        this.betList = dt.list;
        this.betList.forEach(data => {
          this.tagli.push(data.taglioRilancio.toString());
        });
      });
    }
    // Map properties array values to their corresponding item.code
    const propertiesMapped = this.properties.map((item, index) => {
      return {
        code: item.code,
        value: this.propertiesValues[index]
      };
    });
    console.log(this.dataInizioUpdate)
    console.log(this.dataFineUpdate)

    let dataInizioAnnoAste = this.pipe.transform(this.dataInizioUpdate, 'dd/MM/yyyy');
    let dataFineAnnoAste = this.pipe.transform(this.dataFineUpdate, 'dd/MM/yyyy');
    let request = {
      "id": this.id,
      "dataInizioAnnoAste": dataInizioAnnoAste,
      "dataFineAnnoAste": dataFineAnnoAste,
      "numeroAsteAnno": this.numeroAsteAnnoUpdate,
      "percRule2": this.percRule2Update,
      "percRule3": this.percRule3Update,
      "properties": propertiesMapped, // Use the mapped properties array
      "tagli": this.tagli
    };
    console.log(request)

    this.auctionService.updateAuctionSetup(request).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.checkAndReplaceTranslation(this.translate, this.messageService.getMessageFromCode("OK_OPERATION")), true);
        this.listAuctionTipology();
      }, this.handleError(true, this.listAuctionTipology())
    );
  }

  showPopup: boolean = false;
  betList: any[] = [];
  nuovoTaglio: any;
  gestione() {
    this.showPopup = true;
    this.auctionService.getAuctionBetSetupByTipology(this.tipologiaAsta).subscribe(dt => {
      this.betList = dt.list;
    });
  }

  remove(index) {
    this.tagli = [];
    if (index >= 0 && index < this.betList.length) {
      // Get the removed item's taglioRilancio value
      const removedTaglio = this.betList[index].taglioRilancio;

      // Use splice to remove the item at the specified index
      this.betList.splice(index, 1);

      // Remove the corresponding value from the tagli array
      const taglioIndex = this.tagli.indexOf(removedTaglio.toString());
      if (taglioIndex !== -1) {
        this.tagli.splice(taglioIndex, 1);
      }
      this.betList.forEach(data => {
        this.tagli.push(data.taglioRilancio.toString());
      });
      // Optionally, you can perform any other operations needed after removal.
    }
  }

  errorMsg: boolean = false;
  add() {
    this.tagli = [];
    if (this.nuovoTaglio != null && this.nuovoTaglio != '') {
      this.errorMsg = false;
      if (this.nuovoTaglio !== undefined && this.nuovoTaglio !== null) {
        const newItem = { taglioRilancio: this.nuovoTaglio };
        this.betList.push(newItem);
        this.betList.forEach(data => {
          this.tagli.push(data.taglioRilancio.toString());
        });
        this.nuovoTaglio = null; // Clear the input field
      }
    } else {
      this.errorMsg = true;
    }
  }

  chiudi() {
    if (this.tagli.length === 0) {
      this.auctionService.getAuctionBetSetupByTipology(this.tipologiaAsta).subscribe(dt => {
        this.betList = dt.list;
        this.betList.forEach(data => {
          this.tagli.push(data.taglioRilancio.toString());
        });
      });
    }
    this.showPopup = false;
  }

  formatDate(apiDate: string): string {
    const parts = apiDate.split('/');
    console.log('formatDate');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return apiDate;
  }

  reloadBetData() {
    this.listAuctionTipology();
    //this.getBetSlotList(this.modelTipology);
  }

  openDettaglioAuction(e) {
    //console.log('event.target: ' + e.event.target.className);
    let className = e.event.target.className;
    if (e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.ADMIN_ASTE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }

  isEditing(data) {
    return (data.editing == true);
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  toggleDettaglio(data) {
    this.grid = true;
    this.updateForm = false;
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      data.formOpened = true;
      this.closeOtherDetailTipology(data.codice);
      this.annullaNuovoSetup();
    }
    console.log('toggleDettaglio() data:');
    console.log(data);
  }

  closeOtherDetailTipology(codiceAsta: string) {
    //console.log('closeOtherDetailTipology() close all except ' + codiceAsta);
    if (codiceAsta != undefined && this.tipology_list != undefined) {
      for (let tipology of this.tipology_list) {
        if (tipology.codice != codiceAsta) {
          //console.log('closeOtherDetailTipology() closing ' + tipology.codice);
          tipology.formOpened = false;
        }
      }
    }
  }

  closeDettaglio(data) {
    console.log(data);
    data.formOpened = false;
  }

  toggleMenu(data) {
    console.log(data);
    data.menuOpened = !data.menuOpened;
  }

  annullaModificaOperatore(data) {
    data.editing = !data.editing;
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  openDettaglio(data) {
    data.formOpened = true;
    //this.operator = data;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  modificaOperatore(data) {
    data.editing = !data.editing;
    if (data.editing) {
      //this.disableAllEditForms();
      data.editing = true;
    }
    this.openDettaglio(data);
    this.closeMenu(data);
  }

  disabilitaOperatore(data) {
    console.log(data);
  }

  abilitaOperatore(data) {
    console.log(data);
  }

  eliminaOperatore(data) {
    console.log(data);
  }

  onEditingStartSetup(e) {
    console.log('onEditingStartSetup');
  }


  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
        });
      });
    } else if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component
      }).then(() => {
        doc.save(e.fileName + '.pdf');
      });
    }
  }

}

