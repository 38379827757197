import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { IsCoripetCanActivate } from '../services/IsCanActivate';

/*CORIPET COMPONENTS*/
import { CoripetAsteComponent } from './components/aste/aste.component';
import { CoripetAstaDettaglioComponent } from './components/aste/dettaglio/dettaglio.component';
import { CoripetAziendeComponent } from './components/aziende/aziende.component';
import { CoripetDashboardComponent } from './components/dashboard/dashboard.component';
import { CoripetOperatoriComponent } from './components/operatori/operatori.component';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CoripetComponent } from "./coripet.component";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

/*COMMON COMPONENTS*/
import { UploadFideiussioneComponent } from '../commons/components/upload-fideiussione/upload-fideiussione.component';
import { UploadLegaleRappresentanteComponent } from '../commons/components/upload-legale/upload-legale.component';
import { UploadModOrgComponent } from '../commons/components/upload-mod-org/upload-mod-org.component';
import { UploadVisuraComponent } from '../commons/components/upload-visura/upload-visura.component';
import { HttpLoaderFactory } from "../app.module";
import { CommonsModule } from "../commons/commons.module";
import { CoripetCentriDiSelezioneComponent } from "./components/centri-di-selezione/centri-di-selezione.component";
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule, DxPivotGridModule, DxChartModule, DxTemplateHost, DxPopupModule, NestedOptionHost } from 'devextreme-angular';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FlatpickrModule } from "angularx-flatpickr";


const CORIPET_ROUTES: Routes = [
  {
    path: 'coripet/operatore-dashboard.html', pathMatch: 'full', component: CoripetDashboardComponent,
    data: {
      title: 'Dashboard'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-operatori.html', pathMatch: 'full', component: CoripetOperatoriComponent,
    data: {
      title: 'Operatori'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-aziende.html', pathMatch: 'full', component: CoripetAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-aziende.html/:id', pathMatch: 'full', component: CoripetAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-aste.html', pathMatch: 'full', component: CoripetAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-aste/:codiceAsta', pathMatch: 'full', component: CoripetAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/operatore-aste-dettaglio.html/:codiceAsta', pathMatch: 'full', component: CoripetAstaDettaglioComponent,
    data: {
      title: 'Dettaglio asta'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/coripet-upload-visura/:vatnumber', pathMatch: 'full', component: UploadVisuraComponent,
    data: {
      title: 'Visura camerale'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/coripet-upload-legale/:vatnumber', pathMatch: 'full', component: UploadLegaleRappresentanteComponent,
    data: {
      title: 'Documento rappresentante legale'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/coripet-azienda-fideiussione/:vatnumber', pathMatch: 'full', component: UploadFideiussioneComponent,
    data: {
      title: 'Fideiussione bancaria'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/coripet-upload-dichiarazione-231/:vatnumber', pathMatch: 'full', component: UploadModOrgComponent,
    data: {
      title: 'Dichiarazione 231 - 2001'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet/admin-centriselezione.html', pathMatch: 'full', component: CoripetCentriDiSelezioneComponent,
    data: {
      title: 'Centri di selezione'
    }, canActivate: [IsCoripetCanActivate]
  },
]

@NgModule({
  declarations: [   
    CoripetComponent,
    CoripetDashboardComponent, CoripetOperatoriComponent, CoripetAziendeComponent,
    CoripetAsteComponent, CoripetAstaDettaglioComponent,
    CoripetCentriDiSelezioneComponent
  ],
  exports: [
    RouterModule
  ],
  bootstrap: [CoripetComponent], 
  imports: [
    CommonsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule, DxBulletModule,
    DxPopupModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    CKEditorModule,
    DxPivotGridModule,
    DxChartModule,
    FlatpickrModule.forRoot(),
    DxDropDownButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    DxButtonModule,
    RouterModule.forChild(CORIPET_ROUTES),    
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ], 
  providers: [provideHttpClient(withInterceptorsFromDi()), DxTemplateHost, NestedOptionHost],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],  
})
export class CoripetModule { }