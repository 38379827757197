<div class="plant-body card-body" [ngClass]="{'hidden': !showDetail(), 'show': showDetail()}">
    <form id="form_createFramework" [formGroup]="plantForm" class="form-group">
        <div class="body-wrapper" class='form-visible'>
            <section>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nomeImpianto')}" (mouseleave)="validateField('nomeImpianto')">
                        <div class="input-wrapper full">
                            <input type="text" id="plant-name-{{plantDetail.id}}" formControlName="nomeImpianto" value="{{plantDetail.nomeImpianto}}">
                            <label for="plant-name-{{plantDetail.id}}" translate>azienda-impianti.nome-impianto</label>
                        </div>
                    </div>
                </div> 
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nazionalita')}" (mouseleave)="validateField('nazionalita')">
                        <div class="input-wrapper full">
                            <select class="plant-nationality" [(ngModel)]="plantDetail.nazionalita" formControlName="nazionalita" name="plant-nationality" id="plant-nationality-{{plantDetail.id}}">
                                <option *ngFor="let nation of nation_list; let i = index" [value]="nation.codice">{{nation.descrizione}}</option>
                            </select>
                            <label for="plant-nationality-{{plantDetail.id}}" translate>azienda-impianti.nazionalit&nbsp;<span>*</span></label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2" id="field-provincia" [ngClass]="{'error': controlHasError('provincia')}" (mouseleave)="validateField('provincia')">
                        <div class="input-wrapper full">
                            <select id="plant-province-{{plantDetail.id}}" [(ngModel)]="plantDetail.provincia" formControlName="provincia" >
                                <option *ngFor="let provincia of province_list; let i = index" [value]="provincia.descrizione">{{provincia.descrizione}}</option>
                            </select>
                            <label for="plant-province-{{plantDetail.id}}" translate>azienda-impianti.provincia</label>
                        </div>
                    </div>
                    
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('citta')}" (mouseleave)="validateField('citta')">
                        <div class="input-wrapper full">
                            <input type="text" id="plant-city-{{plantDetail.id}}" value="{{plantDetail.citta}}" formControlName="citta" maxlength="75">
                            <label for="plant-city-{{plantDetail.id}}" translate>azienda-impianti.citt &nbsp;<span>*</span></label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2" [ngClass]="{'error': controlHasError('indirizzo')}" (mouseleave)="validateField('indirizzo')">
                        <div class="input-wrapper full">
                            <input type="text" id="plant-address-{{plantDetail.id}}" value="{{plantDetail.indirizzo}}" formControlName="indirizzo">
                            <label for="plant-address-{{plantDetail.id}}" translate>azienda-impianti.indirizzo</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4 cap" [ngClass]="{'error': controlHasError('cap')}" (mouseleave)="validateField('cap')">
                        <div class="input-wrapper full">
                            <input type="text" id="plant-cap-{{plantDetail.id}}" value="{{plantDetail.cap}}" maxlength="5" formControlName="cap">
                            <label for="plant-cap-{{plantDetail.id}}" translate>azienda-impianti.cap</label>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('pec')}" (mouseleave)="validateField('pec')">
                        <div class="input-wrapper full">
                            <input type="text" id="new-plant-pec" value="{{plantDetail.pec}}" class="form-control" maxlength="255" formControlName="pec">
                            <label for="new-plant-address" translate>azienda-impianti.pec</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="text" id="plant-production-{{plantDetail.id}}" value="{{plantDetail.capacitaProduttivaAnnua}}" formControlName="capacitaProduttivaAnnua">
                            <label for="plant-production-{{plantDetail.id}}" translate>azienda-impianti.capacit &nbsp;<span translate>azienda-impianti.produttiva-annua-in-tonnellate</span></label>
                        </div>
                    </div>
                </div>
            </section>
            <section class="certification pending">
                <h2 class="pb-3" translate>azienda-impianti.documenti</h2>
                <div class="status">{{plantDetail.statusDoc}}</div>
                <div class="doc-datas d-flex p-3">
                    <app-file-box docname="certificazione-iso-9001.pdf" (file)="getIso9001File($event)" [showError]="checkError('certificazioneIso9001')" [uuid]="plantDetail.certificazioneIso9001" [status]="plantDetail.certificazioneIso9001Valid" type="framework" [frameworkId]="plantDetail.id" [vatnumber]="vatnumber" [showCoripetButtons]="!isAdmin()"></app-file-box>
                    <app-file-box docname="certificazione-iso-14001.pdf" (file)="getIso14001File($event)" [showError]="checkError('certificazioneIso14001')" [uuid]="plantDetail.certificazioneIso14001" [status]="plantDetail.certificazioneIso14001Valid" type="framework" [frameworkId]="plantDetail.id" [vatnumber]="vatnumber" [showCoripetButtons]="!isAdmin()"></app-file-box>
                    <app-file-box docname="documento-autorizzazione.pdf" (file)="getDocAuthFile($event)" [showError]="checkError('docAutorizzazione')" [uuid]="plantDetail.docAutorizzazione" [status]="plantDetail.docAutorizzazioneValid" type="framework" [frameworkId]="plantDetail.id" [vatnumber]="vatnumber" [showCoripetButtons]="!isAdmin()"></app-file-box>
                </div>
            </section>
            <section class="delivery">
                <h2 translate>azienda-impianti.informazioni-utili-per-la-spedizione</h2>
                <div class="row">
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="text" name="plant-delivery-name" value="{{plantDetail.nomeRifSpedizione}}" formControlName="nomeRifSpedizione">
                            <label for="plant-delivery-name" translate>azienda-impianti.nome-e-riferimento-per-la-spedizione</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input flex-fill mb-4 me-2">
                        <div class="input-wrapper full">
                            <input type="text" name="plant-delivery-phone" value="{{plantDetail.telefonoRifSpedizione}}" formControlName="telefonoRifSpedizione">
                            <label for="plant-delivery-phone" translate>azienda-impianti.telefono-di-riferimento</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="text" name="plant-delivery-mobile-phone" value="{{plantDetail.mobileRifSpedizione}}" formControlName="mobileRifSpedizione">
                            <label for="plant-delivery-mobile-phone" translate>azienda-impianti.telefono-mobile-di-riferimento</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <input type="email" name="plant-delivery-email" value="{{plantDetail.emailRifSpedizione}}" formControlName="emailRifSpedizione">
                            <label for="plant-delivery-email" translate>azienda-impianti.email-di-riferimento</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4">
                        <div class="input-wrapper full">
                            <textarea name="" name="plant-delivery-notes" cols="30" rows="10" formControlName="noteTrasportatore">{{plantDetail.noteTrasportatore}}</textarea>
                            <label for="plant-delivery-notes" translate>azienda-impianti.note-per-il-trasportatore</label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isCoripet() && showCoripetButtons">
                    <div class="actions operator-actions plant-actions">
                        <button class="btn success btn-approve-plant" (click)="approveFramework()"><i class="fal fa-check"></i>
                            <span translate>azienda-impianti.approva-impianto</span></button>
                        <button class="btn error btn-reject-plant" (click)="rejectFramework()"><i class="fal fa-times"></i>
                            <span translate>azienda-impianti.rifiuta-impianto</span></button>
                    </div>
                </div>
            </section>
        </div>
    </form>
    <div class="add-operator-actions">
        <div class="main-actions full" *ngIf="showCoripetButtons">
            <button class="btn btn-primary primary" type="submit" (click)="salvaImpianto()" id="btn_createPlant"><i class="fal fa-save"></i> <span translate>azienda-impianti.salva</span></button>
        </div>
    </div>
</div>