import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import moment from 'moment';
import { AsteItem, AuctionCreateItem, AuctionItem, AuctionLotItem, AuctionStatusItem, AuctionTipologyItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-aste',
  templateUrl: './aste.component.html',
  styleUrls: ['./aste.component.css']
})
export class AdminAsteComponent extends APIService implements OnInit, OnDestroy { //extends APIService manda in errore il componente

  @ViewChild("dataGridAste", { static: false }) dataGridAste: DxDataGridComponent;
  asteForm: UntypedFormGroup;
  aste: AsteItem = new AsteItem();
  submitted: boolean = false;
  status_list: AuctionStatusItem[] = this.localUtil.getStatoAstaList();
  private subscriptions: Subscription[] = [];

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);

    this.asteForm = new UntypedFormGroup({
      dataDisponibilita: new UntypedFormControl(this.aste.dataDisponibilita, [Validators.required]),
      dataPubblicazione: new UntypedFormControl(this.aste.dataPubblicazione, [Validators.required]),
      dataOraInizioAsta: new UntypedFormControl(this.aste.dataOraInizioAsta, [Validators.required]),
      dataOraFineAsta: new UntypedFormControl(this.aste.dataOraFineAsta, [Validators.required]),
      tipologiaAsta: new UntypedFormControl(this.aste.tipologiaAsta, [Validators.required]),
    })
  }

  auctions_list: AuctionItem[] = [];
  tipology_list: AuctionTipologyItem[] = [];

  ngOnInit(): void {
    console.log('init()');
    this.listAuctions();
    this.listAuctionTipology();
  }

  isNewEditing() {
    return (this.aste.newEditing == true);
  }

  toggleNuovaAsta() {
    this.aste.newEditing = !this.aste.newEditing;
    if (this.aste.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.asteForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.aste = new AsteItem();
    this.aste.newEditing = false;
  }

  controlHasError(nome) {
    return !this.asteForm.get(nome).valid && this.submitted;
  }

  date = new Date();
  pipe = new DatePipe('en-US');
  salvaAuctionDettaglio() {
    if (this.validate()) {
      let request: AsteItem = new AsteItem();
      let dataDisponibilita = this.pipe.transform(this.asteForm.get('dataDisponibilita').value, 'dd/MM/yyyy HH:mm');
      let dataPubblicazione = this.pipe.transform(this.asteForm.get('dataPubblicazione').value, 'dd/MM/yyyy HH:mm');
      let dataOraInizioAsta = this.pipe.transform(this.asteForm.get('dataOraInizioAsta').value, 'dd/MM/yyyy HH:mm');
      let dataOraFineAsta = this.pipe.transform(this.asteForm.get('dataOraFineAsta').value, 'dd/MM/yyyy HH:mm');
      request = {
        dataDisponibilita: dataDisponibilita,
        dataPubblicazione: dataPubblicazione,
        dataOraInizioAsta: dataOraInizioAsta,
        dataOraFineAsta: dataOraFineAsta,
        tipologiaAsta: this.asteForm.get('tipologiaAsta').value,
      }
      console.log(request);
      const service = this.auctionService.createAuction(request).subscribe(dt => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_MESSAGE"), true);
        this.toggleNuovaAsta();
        this.listAuctions();
      }, this.handleError(true));
      this.subscriptions.push(service);
    }
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.asteForm);
    Object.keys(this.asteForm.controls).forEach(key => {
      if (!this.asteForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  listAuctionTipology() {

    const service = this.auctionService.listAuctionTipology().subscribe(
      (data) => {
        
        this.tipology_list = data.list;
    });
    this.subscriptions.push(service);

  }

  attive: boolean = true;
  concluse: boolean = true;
  pubbliche: boolean = true;
  bozza: boolean = true;
  annullate: boolean = true;

  listAuctions() {
    this.auctions_list = [];
    const service = this.auctionService.listaAste(undefined).subscribe(
      (data) => {
        
        data.list.forEach((auction: AuctionItem) => {
          //preimpostazione data con momentjs sembra l'unico modo per visualizzare la data e ora in modo corretto
          //su devextreme
          auction.dataDisponibilita = moment(auction.dataDisponibilita, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataPubblicazione = moment(auction.dataPubblicazione, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataOraInizioAsta = moment(auction.dataOraInizioAsta, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataOraFineAsta = moment(auction.dataOraFineAsta, 'DD/MM/YYYY HH:mm', true).format();
        });
        data.list.forEach(data => {          
            this.auctions_list.push(data);
        });
        this.loadToPublishLots();
      });
      this.subscriptions.push(service);
  }

  filterCheckBox() {
    this.listAuctions();
  }

  loadToPublishLots() {
    if (this.auctions_list) {
      this.auctions_list.forEach(auction => {
        auction.cssClass = auction.statoAsta.replace(' ', '');
        const service = this.auctionService.getAuctionLots(auction.codiceAsta, '').subscribe(
          data => {
            let somma = 0;
            if (data.lotList != undefined) {
              data.lotList.forEach(itemLot => {
                if (itemLot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                  somma++;
                }
              });
            }
            auction.lots = data.lotList;
            auction.sum_lots_to_publish = somma;
            auction.daValidare = somma + ' lotti';
            // set fields
            this.setLotsFields(auction);
          }
        );
        this.subscriptions.push(service);
      });
    }
  }

  setLotsFields(auction) {
    let lotList: AuctionLotItem[] = auction.lots;
    
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });
      
      
    }

  }

  onRowClick(e) {
    console.log(e);
    this.router.navigate(['admin-aste-dettaglio.html/', e.data.codiceAsta]);
    if (this.dataGridAste.instance.isRowExpanded(e.key)) {
      this.dataGridAste.instance.collapseRow(e.key);
    } else {
      this.dataGridAste.instance.cancelEditData();
      this.dataGridAste.instance.collapseAll(-1);
      this.dataGridAste.instance.expandRow(e.key);
    }
  }

  onRowExpanding(e) {
    this.dataGridAste.instance.cancelEditData();
    this.dataGridAste.instance.collapseAll(-1);
  }

  onRowPreparedAuction(info) {
    if (info.rowType === 'data') {
      info.rowElement.addClass('auction');
    }
  }

  onRowInsert(e) {
    let auction: AuctionCreateItem = e.data;
    const service = this.auctionService.createAuction(auction).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_MESSAGE"), true);
        this.listAuctions();
      }, this.handleError(true)
    );
    this.subscriptions.push(service);
  }

  onContentReady(event) {

  }

  openDettaglioAuction(e) {
    let className = e.event.target.className;
    if (e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.ADMIN_ASTE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      });
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}

