<div *ngIf="headerVisible">
    <div *ngIf="headerDetail">
        <!-- topHeader -->
        <div class="bg-white shadow-sm border-bottom border-dark-gray border-1" *ngIf="headerDetail.topHeader"
            [ngClass]="{'fixed-header': stickyHeaderAuction || stickyHeaderAuctionBox}">
            <div class="row m-0 align-items-center">
                <div id="timer1" class="col-xl-5 col-lg-4 col-md-6 col-12 border-end border-dark-gray border-1">
                    <div class="p-xl-3 p-2" *ngIf="getShowAuctionHeaders()">
                        <div class="{{motoreAsta}} pb-3" *ngIf="headerDetail.view == ConstantsModule.AUC_VIEW_BOX">
                            <div *ngIf="timers && timers.length>0"
                                [ngClass]="{'rosso-inline': isInExtraTime(timers[0])}">
                                <span translate>{{timers[0].label}}</span>&nbsp;
                                <span id="server_time">
                                    <app-timer class="fw-bold" #timer1 [name]="timers[0].name"
                                        (monitorEvent)="timerLotEndRaised($event, timers[0])"
                                        [dateOfficialEnd]="timers[0].dataOraFineAsta"
                                        [dateEndCountdown]="getDataFineAstaEffettiva(timers[0].dataOraFineAstaEffettiva)"
                                        [hourEndCountdown]="getOraFineAstaEffettiva(timers[0].dataOraFineAstaEffettiva)"
                                        [serverTime]="timers[0].serverTime"
                                        [showSign]="false"></app-timer></span>
                            </div>
                        </div>
                        <div *ngIf="getShowAuctionHeaders()">
                            <p translate>header.orario-di-sistema&nbsp;<span>{{serverTime}}</span></p>
                        </div>
                    </div>
                </div>
                <div id="timer2" class="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div class="p-xl-3 p-2" *ngIf="getShowAuctionHeaders()">
                        <div class="pb-3" *ngIf="headerDetail.view== ConstantsModule.AUC_VIEW_BOX">
                            <div *ngIf="timers && timers.length == 2"
                                [ngClass]="{'rosso-inline': isInExtraTime(timers[1])}">
                                <span translate>{{timers[1].label}}</span>&nbsp;
                                <span id="server_time">
                                    <app-timer class="fw-bold" #timer2 [name]="timers[1].name"
                                        (monitorEvent)="timerLotEndRaised($event, timers[1])"
                                        [dateOfficialEnd]="timers[1].dataOraFineAsta"
                                        [dateEndCountdown]="getDataFineAstaEffettiva(timers[1].dataOraFineAstaEffettiva)"
                                        [hourEndCountdown]="getOraFineAstaEffettiva(timers[1].dataOraFineAstaEffettiva)"
                                        [serverTime]="timers[1].serverTime"
                                        [showSign]="false"></app-timer></span>
                            </div>
                        </div>
                        <div *ngIf="getShowAuctionHeaders()">
                            <p translate>header.spesa-attuale&nbsp;<span>{{currentCost}} &#8364;</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-12 text-end">
                    <div id="logged-info" class="d-flex align-items-center justify-content-xl-end justify-content-lg-center justify-content-md-center justify-content-center gap-3 p-3">
                        <i class="bi bi-person fs30 light blue-dark"></i>
                        <p class="m-0 fs14 inter gray-dark border-end border-dark border-2 pe-3">{{nome}}</p>
                        <p class="m-0 fs14 middle gray-dark inter">{{qualifica}}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- dashboardHeader -->
        <ul class="overview-tool d-xl-flex d-grid bg-white overflow-auto dashboardHeader" *ngIf="routerPath">
            <li class="production-capacity p-4 d-flex flex-column justify-content-center position-relative">
                <div class="label d-flex justify-content-between align-items-center">
                    <span class="fs14" translate>header.capacita-produttiva-annua</span>
                    <div class="symbol fs21 gray-light bold" id="frameworksCapacity">{{frameworksCapacity}} t</div>
                </div>
            </li>
            <li class="last-section p-4 d-flex flex-column justify-content-center position-relative">
                <div class="label d-flex justify-content-between align-items-center">
                    <div class="label gray-dark">
                        <span class="fs14" translate>header.la-tua-fideiussione-scade-il</span>
                    </div>
                    <div class="label gray-dark">
                        <span class="fs14" translate>header.valore-fideiussione</span>
                    </div>
                </div>
                <div class="label d-flex justify-content-between align-items-center">
                    <div class="expiration-date fs21 bold gray-light mt-2" id="dataScadenzaFidejussione">
                        {{dataScadenzaFidejussione}}
                    </div>
                    <div class="expiration-date fs21 bold gray-light mt-2" id="totaleFidejussione">
                        {{totaleFidejussione}} &euro;
                    </div>
                </div>
            </li>
        </ul>
        <!-- capacityHeader -->
        <ul class="{{motoreAsta}} {{getView()}} overview-tool d-xl-flex d-lg-flex d-md-flex d-grid bg-white overflow-auto capacityHeader"
            *ngIf="isUser() && headerDetail.capacityHeader"
            [ngClass]="{'fixed-parameters': headerDetail.capacityHeader && (stickyHeaderAuction || stickyHeaderAuctionBox)}">
            <li class="capacity-1 p-xl-3 p-2 d-flex flex-column justify-content-center position-relative flex-50"
                *ngIf="sliderValueCapacity">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile</span>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueCapacity.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueCapacity.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueCapacity.resValue}} t)
                        </div>
                        <div class="max">{{sliderValueCapacity.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Azzurrato p-xl-3 p-2 d-flex flex-column justify-content-center position-relative flex-50"
                *ngIf="sliderValueAzzurrato">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTA-M</span>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueAzzurrato.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueAzzurrato.curValue}} ton</div>
                        <div class="mid"> (<span translate>header.q.ta-residua</span> {{sliderValueAzzurrato.resValue}}
                            t)
                        </div>
                        <div class="max">{{sliderValueAzzurrato.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Colorato p-xl-3 p-2 d-flex flex-column justify-content-center position-relative flex-50"
                *ngIf="sliderValueColorato">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTC-M</span>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueColorato.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueColorato.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueColorato.resValue}} t)
                        </div>
                        <div class="max">{{sliderValueColorato.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Trasparente p-xl-3 p-2 d-flex flex-column justify-content-center position-relative flex-50"
                *ngIf="sliderValueTrasparente">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTL-M</span>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueTrasparente.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueTrasparente.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueTrasparente.resValue}}
                            t)
                        </div>
                        <div class="max">{{sliderValueTrasparente.maxValue}} ton </div>
                    </div>
                </div>
            </li>
        </ul>
        <!-- stickyHeader -->
        <table class="cards-lots-list-header {{motoreAsta}} stickyHeader" id="cards-lots-list-header"
            data-toggle="table" *ngIf="headerDetail.stickyHeader">
            <thead class="header" id="myHeader">
                <tr>
                    <th id="lotto" translate>aste-incorso.lotto</th>
                    <th id="tipologia" translate>aste-incorso.tipologia</th>
                    <th id="area" translate>aste-incorso.area</th>
                    <th id="quantita" translate>aste-incorso.q.ta</th>
                    <th id="rilanci" translate>aste-incorso.rilanci</th>
                    <th id="prezzo" translate>aste-incorso.prezzo</th>
                    <th id="tempo" translate>aste-incorso.tempo-rimanente</th>
                    <th id="stato" translate>aste-incorso.stato</th>
                    <th id="azione1" translate>aste-incorso.azione</th>
                    <th id="azione2"><i class="fal fa-sync blu fright mr20 pointer" (click)="reloadLots()" (keydown)="handleKeyDown($event)"
                            [ngClass]="{'disabled-reload-header': getDisabledReloadLots() || loaderLotsEnabled}"
                            title="{{'aste-incorso.ricarica' | translate}}"></i></th>
                </tr>
            </thead>
            <tbody id="rowSizing">
            </tbody>
        </table>
    </div>
</div>