import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { AuctionItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import jsPDF from 'jspdf';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aste-pubbliche',
  templateUrl: './aste-pubbliche.component.html',
  styleUrls: ['./aste-pubbliche.component.css']
})
export class AstePubblicheComponent extends APIService implements OnInit, OnDestroy {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);

  }
  
  auction: AuctionItem;
  auctions_list: any[]=[];  
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.listAuctionPubbliche();
  }

  listAuctionPubbliche() {
      
    const listaService = this.auctionService.listaAste("Pubblica").subscribe(
      (data:any) => { 
        this.auctions_list = data.list;
    });
    this.subscriptions.push(listaService);
  }

  onRowPrepared(info) {
    if(info.rowType === 'data') {  
      info.rowElement.addClass('auction');  
    }
  }

  onContentReady(event) {
    //$('table.dx-datagrid-table').find('div.dx-data-row').addClass('lot');
    //$('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

  openDettaglio(e) {
    let className = e.event.target.className;
    if(e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.REF_AZ_ASTE_PUBBLICHE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx');
        });
      });
    } else if (e.format === 'csv') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(e.fileName);
      exportDataGrid({
          component: e.component,
          worksheet: worksheet
      }).then(function() {
          workbook.csv.writeBuffer().then(function(buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.csv');
          });
      });
    } else  if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component
      }).then(() => {
          doc.save(e.fileName + '.pdf');
      })
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }
  
}
