<div *ngFor="let item of auctionsPublished">
    <div class="countdown_dashboard" *ngIf="item.visible">
        <div class="auctions-focus auctions-focus-dashboard">
            <h2><span translate>dashboardPage.aste</span> {{item.codiceAsta}}</h2>
            <h1 translate>dashboardPage.tempo-rimanente-prossima-asta</h1>
            <div class="countdown" *ngIf="item.dataInizioAsta && item.oraInizioAsta && serverTime">
                <app-timer [dateOfficialEnd]="item.dataInizioAsta + ' ' + item.oraInizioAsta" 
                [dateEndCountdown]="item.dataInizioAsta" 
                [hourEndCountdown]="item.oraInizioAsta" 
                (monitorEvent)="reloadCurrentPage($event)"
                [serverTime]="serverTime"
                [showSign]="false"></app-timer>
            </div>
            <div class="infos">
                <span translate>dashboardPage.L'asta-inizier&nbsp;</span> <b>{{item.dataInizioAsta}}</b><span translate>&nbsp;dashboardPage.alle-ore&nbsp;</span><b>{{item.oraInizioAsta}}</b><span translate>&nbsp;dashboardPage.e-si-concluder&nbsp;</span><b>{{item.dataFineAsta}}</b><span translate>&nbsp;dashboardPage.alle-ore&nbsp;</span><b>{{item.oraFineAsta}}<span translate>&nbsp;dashboardPage.ora-italiana</span></b>
            </div>
        </div>
        <div id="getting-started"></div>                    
    </div>
</div>