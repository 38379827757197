<div id="auctions-list" class="">
    <dx-data-grid #dataGridBet 
        id="gridContainer" 
        [dataSource]="bet" 
        keyExpr="auctionBetSetupId"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true" 
        [showBorders]="true" 
        [columnHidingEnabled]="false" 
        [columnAutoWidth]="true"
        (onRowInserting)="onRowInsertBet($event)" 
        (onRowRemoving)="onRowDeleteBet($event)"
        (onCellPrepared)="onCellPreparedBet($event)" 
        (onRowPrepared)="onRowPrepared($event)"
        (onExporting)="onExporting($event)">

        <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                <dxo-search
                [enabled]="true">
                </dxo-search>
        </dxo-column-chooser>
        <dxo-export [enabled]="true" 
                        [allowExportSelectedData]="false" 
                        fileName="datagrid_list"
                        [formats]="['xlsx', 'csv', 'pdf']"
        ></dxo-export>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="default"></dxo-scrolling>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
        </dxo-search-panel>

        <dxi-column dataField="auctionBetSetupId" caption="Id" [allowEditing]="false" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="taglioRilancio" caption="Valore taglio" [allowEditing]="true" dataType="number"
            [visible]="true">
            <dxi-validation-rule type="custom" [validationCallback]="ruleNumberPositive"
                message="Il valore deve essere maggiore di zero"></dxi-validation-rule>
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="row">
            <dxo-texts confirmDeleteMessage="Sei sicuro di voler eliminare questo taglio di rilancio ?"></dxo-texts>
        </dxo-editing>

        <dxo-toolbar>
            <dxi-item location="after" name="searchPanel"></dxi-item>
            <dxi-item location="after" name="exportButton"></dxi-item>
            <dxi-item location="after" name="columnChooserButton"></dxi-item>
            <dxi-item location="after" name="addRowButton"></dxi-item>
        </dxo-toolbar>
        
    </dx-data-grid>
</div>