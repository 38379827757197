import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalUtilService } from 'src/app/services/local-util.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {

  constructor(public localUtil: LocalUtilService) { }

  loaderVisible: boolean = false;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {

    const service = this.localUtil.loaderMonitor.subscribe(
      data => {
        //console.log('loadercomponent loaderMonitor data: ' + data);
        if (data && data == true) {
          this.showLoader();
        } else {
          this.hideLoader();
        }
      });
    this.subscriptions.push(service);

  }
  
  showLoader() {
    this.loaderVisible = true;
  }

  hideLoader() {
    this.loaderVisible = false;
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach(sub => {
                
        sub.unsubscribe()
      });
    } catch (err) {
      //
    }
  }

}
