<div class="mobile-menu menu-toggler">
    <i class="fal fa-bars"></i>
</div>
<aside id="sidebar">
    <div class="logo" id="logo">
        Coripet
    </div>
    <nav id="main-menu">
        <ul id="main-menu-items">
            <li class="active">
                <a translate>register-page.registrazione</a>
            </li>
        </ul>
    </nav>
</aside>
<main id="register" class="register">
    <form id="registerForm_step2" [formGroup]="step2" class="content form-group p-4 my-3">
        
        <section class="step-section d-flex pb-4 focused">
            <div class="card flex-fill mb-3">
                <h2 class="mb-4" translate>registrazione.dati-personali</h2>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nome')}"
                        (mouseleave)="validateField('nome')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--name" maxlength="255" formControlName="nome">
                            <label for="registration--name" translate>registrazione.nome</label>
                            <div class="note"></div>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('cognome')}"
                        (mouseleave)="validateField('cognome')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--surname" maxlength="255" formControlName="cognome">
                            <label for="registration--surname" translate>registrazione.cognome</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('email')}"
                        (mouseleave)="validateField('email')">
                        <div class="input-wrapper full">
                            <input type="email" id="registration--email" maxlength="255" formControlName="email">
                            <label for="registration--email" translate>registrazione.email</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('password')}"
                        (mouseleave)="validateField('password')">
                        <div class="input-wrapper full">
                            <input type="password" id="registration--password" maxlength="75"
                                formControlName="password">
                            <label for="registration--password" translate>registrazione.password</label>
                            <div class="note"></div>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('password')">{{getControlErrorMessage('password')}}</span>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('confermaPassword')}"
                        (mouseleave)="validateField('confermaPassword')">
                        <div class="input-wrapper full">
                            <input type="password" id="registration--confirm-password" maxlength="75"
                                formControlName="confermaPassword">
                            <label for="registration--confirm-password"
                                translate>registrazione.conferma-password</label>
                            <div class="note"></div>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('confermaPassword')">{{getControlErrorMessage('confermaPassword')}}</span>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('telefono')}"
                        (mouseleave)="validateField('telefono')">
                        <div class="input-wrapper full">
                            <input type="number" id="registration--user-phone" maxlength="75"
                                formControlName="telefono">
                            <label for="registration--user-phone" translate>registrazione.telefono-fisso</label>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4 " [ngClass]="{'error': controlHasError('mobile')}"
                        (mouseleave)="validateField('mobile')">
                        <div class="input-wrapper full">
                            <input type="number" id="registration--user-mobile" maxlength="75" formControlName="mobile">
                            <label for="registration--user-mobile" translate>registrazione.telefono-mobile</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="infos">
                <div class="number-wrapper lh-1 px-3">
                    <div class="number fs32 bold gray-dark inter">02</div>
                    <div class="label" translate>
                        registrazione.dati-personali
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section d-flex pb-4">
            <div class="card flex-fill mb-3">
                <h2 class="mb-4" translate>registrazione.informazioni-azienda</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('ragioneSociale')}"
                        (mouseleave)="validateField('ragioneSociale')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-business-name" maxlength="255"
                                formControlName="ragioneSociale">
                            <label for="registration--company-business-name"
                                translate>registrazione.ragione-sociale</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('partitaIva')}"
                        (mouseleave)="validateField('partitaIva')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-p-iva" style="text-transform: uppercase;"
                                readonly maxlength="75" formControlName="partitaIva">
                            <label for="registration--company-p-iva" translate>registrazione.partita-iva</label>
                            <div class="note"></div>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('codiceFiscale')}"
                        (mouseleave)="validateField('codiceFiscale')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-fiscal-code" maxlength="75"
                                formControlName="codiceFiscale">
                            <label for="registration--company-fiscal-code"
                                translate>registrazione.codice-fiscale</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('nazionalita')}"
                        (mouseleave)="validateField('nazionalita')">
                        <div class="input-wrapper full">
                            <select id="registration--company-nationality" disabled>
                                <option value="AF">Afghanistan</option>
                                <option value="AX">Åland Islands</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AS">American Samoa</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua and Barbuda</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarus</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermuda</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia, Plurinational State of</option>
                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                <option value="BA">Bosnia and Herzegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BV">Bouvet Island</option>
                                <option value="BR">Brazil</option>
                                <option value="IO">British Indian Ocean Territory</option>
                                <option value="BN">Brunei Darussalam</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodia</option>
                                <option value="CM">Cameroon</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Cape Verde</option>
                                <option value="KY">Cayman Islands</option>
                                <option value="CF">Central African Republic</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CX">Christmas Island</option>
                                <option value="CC">Cocos (Keeling) Islands</option>
                                <option value="CO">Colombia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="CD">Congo, the Democratic Republic of the</option>
                                <option value="CK">Cook Islands</option>
                                <option value="CR">Costa Rica</option>
                                <option value="CI">Côte d'Ivoire</option>
                                <option value="HR">Croatia</option>
                                <option value="CU">Cuba</option>
                                <option value="CW">Curaçao</option>
                                <option value="CY">Cyprus</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="DK">Denmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominican Republic</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypt</option>
                                <option value="SV">El Salvador</option>
                                <option value="GQ">Equatorial Guinea</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Ethiopia</option>
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                <option value="FO">Faroe Islands</option>
                                <option value="FJ">Fiji</option>
                                <option value="FI">Finland</option>
                                <option value="FR">France</option>
                                <option value="GF">French Guiana</option>
                                <option value="PF">French Polynesia</option>
                                <option value="TF">French Southern Territories</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="DE">Germany</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Greece</option>
                                <option value="GL">Greenland</option>
                                <option value="GD">Grenada</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HM">Heard Island and McDonald Islands</option>
                                <option value="VA">Holy See (Vatican City State)</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="HU">Hungary</option>
                                <option value="IS">Iceland</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="IR">Iran, Islamic Republic of</option>
                                <option value="IQ">Iraq</option>
                                <option value="IE">Ireland</option>
                                <option value="IM">Isle of Man</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italy</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JE">Jersey</option>
                                <option value="JO">Jordan</option>
                                <option value="KZ">Kazakhstan</option>
                                <option value="KE">Kenya</option>
                                <option value="KI">Kiribati</option>
                                <option value="KP">Korea, Democratic People's Republic of</option>
                                <option value="KR">Korea, Republic of</option>
                                <option value="KW">Kuwait</option>
                                <option value="KG">Kyrgyzstan</option>
                                <option value="LA">Lao People's Democratic Republic</option>
                                <option value="LV">Latvia</option>
                                <option value="LB">Lebanon</option>
                                <option value="LS">Lesotho</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libya</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Lithuania</option>
                                <option value="LU">Luxembourg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                <option value="MG">Madagascar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldives</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MQ">Martinique</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Micronesia, Federated States of</option>
                                <option value="MD">Moldova, Republic of</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="ME">Montenegro</option>
                                <option value="MS">Montserrat</option>
                                <option value="MA">Morocco</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NL">Netherlands</option>
                                <option value="NC">New Caledonia</option>
                                <option value="NZ">New Zealand</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NF">Norfolk Island</option>
                                <option value="MP">Northern Mariana Islands</option>
                                <option value="NO">Norway</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PS">Palestinian Territory, Occupied</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PH">Philippines</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PL">Poland</option>
                                <option value="PT">Portugal</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="QA">Qatar</option>
                                <option value="RE">Réunion</option>
                                <option value="RO">Romania</option>
                                <option value="RU">Russian Federation</option>
                                <option value="RW">Rwanda</option>
                                <option value="BL">Saint Barthélemy</option>
                                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                <option value="KN">Saint Kitts and Nevis</option>
                                <option value="LC">Saint Lucia</option>
                                <option value="MF">Saint Martin (French part)</option>
                                <option value="PM">Saint Pierre and Miquelon</option>
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome and Principe</option>
                                <option value="SA">Saudi Arabia</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SX">Sint Maarten (Dutch part)</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="ZA">South Africa</option>
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                <option value="SS">South Sudan</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="SD">Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="SE">Sweden</option>
                                <option value="CH">Switzerland</option>
                                <option value="SY">Syrian Arab Republic</option>
                                <option value="TW">Taiwan, Province of China</option>
                                <option value="TJ">Tajikistan</option>
                                <option value="TZ">Tanzania, United Republic of</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad and Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TC">Turks and Caicos Islands</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UG">Uganda</option>
                                <option value="UA">Ukraine</option>
                                <option value="AE">United Arab Emirates</option>
                                <option value="GB">United Kingdom</option>
                                <option value="US">United States</option>
                                <option value="UM">United States Minor Outlying Islands</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VE">Venezuela, Bolivarian Republic of</option>
                                <option value="VN">Viet Nam</option>
                                <option value="VG">Virgin Islands, British</option>
                                <option value="VI">Virgin Islands, U.S.</option>
                                <option value="WF">Wallis and Futuna</option>
                                <option value="EH">Western Sahara</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option>
                            </select>
                            <label for="registration--company-nationality" translate>registrazione.nazionalità</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" id="field-provincia"
                        *ngIf="getControlValue('nazionalita') == 'IT'"
                        [ngClass]="{'error': controlHasError('provincia')}" (mouseleave)="validateField('provincia')">
                        <div class="input-wrapper full">
                            <select name="" id="registration--company-province" formControlName="provincia">
                                <option *ngFor="let provincia of province_list; let i = index"
                                    [value]="provincia.descrizione">{{provincia.descrizione}}</option>
                            </select>
                            <label for="registration--company-province" translate>registrazione.provincia</label>
                            <div class="note"></div>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('citta')}"
                        (mouseleave)="validateField('citta')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-city" maxlength="255" formControlName="citta">
                            <label for="registration--company-city" translate>registrazione.città</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('indirizzo')}"
                        (mouseleave)="validateField('indirizzo')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-address" maxlength="255"
                                formControlName="indirizzo">
                            <label for="registration--company-address" translate>registrazione.indirizzo</label>
                            <div class="note"></div>
                        </div>
                    </div>
                    <div class="input cap flex-fill mb-4" [ngClass]="{'error': controlHasError('cap')}"
                        (mouseleave)="validateField('cap')">
                        <div class="input-wrapper full">
                            <input type="text" id="registration--company-cap" maxlength="5" name="zipCode"
                                formControlName="cap">
                            <label for="registration--company-cap" translate>registrazione.cap</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('pec')}"
                        (mouseleave)="validateField('pec')">
                        <div class="input-wrapper full">
                            <input type="email" id="registration--company-pec-email-address" maxlength="255"
                                formControlName="pec">
                            <label for="registration--company-pec-email-address" translate>registrazione.pec</label>
                            <div class="note"></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('org_telefono')}"
                        (mouseleave)="validateField('org_telefono')">
                        <div class="input-wrapper full">
                            <input type="number" id="registration--company-phone" maxlength="75"
                                formControlName="org_telefono">
                            <label for="registration--company-phone" translate>registrazione.telefono-fisso</label>
                            <div class="note"></div>
                        </div>
                    </div>
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('fax')}"
                        (mouseleave)="validateField('fax')">
                        <div class="input-wrapper full">
                            <input type="number" id="registration--company-fax" maxlength="75" formControlName="fax">
                            <label for="registration--company-fax" translate>registrazione.fax</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="infos">
                <div class="number-wrapper lh-1 px-3">
                    <div class="number fs32 bold gray-dark inter ">03</div>
                    <div class="label" translate>
                        registrazione.informazioni-azienda
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section d-flex pb-4 terms">
            <div class="card flex-fill mb-3">
                <h2 class="mb-4" translate>registrazione.informativa-privacy</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkPrivacy')}"
                        (mouseleave)="validateField('checkPrivacy')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-personal-datas" class="big" formControlName="checkPrivacy">
                            <label for="check-personal-datas"><span translate>registrazione.cliccando-qui</span>&nbsp;<a
                                    href="regolamenti/CORIPET_portale aste_Informativa Privacy_20180107.pdf" target="_blank"><span
                                        translate>registrazione.privacy-policy</span></a>&nbsp;<span
                                    translate>registrazione.e-la-nostra</span>&nbsp;<a href="regolamenti/cookie-policy.pdf"
                                    target="_blank" translate>registrazione.cookie-policy</a>.</label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1 px-3">
                    <div class="number fs32 bold gray-dark inter">04</div>
                    <div class="label" translate>
                        registrazione.trattamento-dati
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms d-flex pb-4">
            <div class="card flex-fill mb-3">
                <h2 class="mb-4" translate>registrazione.regolamento-aste-tematiche</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkRules')}"
                        (mouseleave)="validateField('checkRules')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-general-conditions" class="big"
                                formControlName="checkRules">
                            <label for="check-general-conditions">
                                <span translate>registrazione.clicca-qui-regolamento-1</span>&nbsp;<a
                                    href="regolamenti/04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf"
                                    target="_blank"><span
                                        translate>registrazione.modulo-regolamento</span></a>&nbsp;<span
                                    translate>registrazione.clicca-qui-regolamento-2</span>
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1 px-3">
                    <div class="number fs32 bold gray-dark inter">05</div>
                    <div class="label" translate>
                        registrazione.condizioni-generali-vendita
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms d-flex pb-4">
            <div class="card flex-fill mb-3">
                <h2 class="mb-4" translate>registrazione.accettazione-espressa</h2>
                <div class="row">
                    <div class="input flex-fill mb-4" [ngClass]="{'error': controlHasError('checkAcceptance')}"
                        (mouseleave)="validateField('checkAcceptance')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-auction-rules" class="big"
                                formControlName="checkAcceptance">
                            <label for="check-auction-rules" translate>
                                registrazione.testo-accettazione-espressa
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper lh-1 px-3">
                    <div class="number fs32 bold gray-dark inter">06</div>
                    <div class="label" translate>
                        registrazione.regolamento-aste
                    </div>
                </div>
            </div>
        </section>
        <div class="main-actions text-lg-end text-center">
            <a routerLink="/registrazione/step1" class="btn" translate>register-page.Indietro</a>
            <button class="btn primary" id="btn_registrationStep2" (click)="saveOrganization()" translate>register-page.Invia</button>
        </div>
    </form>
</main>