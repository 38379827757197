<dx-data-grid id="gridContainerPlant" [dataSource]="plants" #dataGridPlants
    keyExpr="id" 
    [allowColumnReordering]="true"
    [allowColumnResizing]="true" 
    [showBorders]="true" 
    [columnHidingEnabled]="false"
    [columnAutoWidth]="true"
    (onRowExpanding)="onRowExpanding($event)"
    (onEditingStart)="onEditingStart($event)"
    (onRowUpdating)="onRowUpdate($event)"
    (onRowPrepared)="onRowPrepared($event)"
    (onCellPrepared)="onCellPrepared($event)"
    >

    
    <dxi-column dataField="id" [allowEditing]="false" caption="Id" alignment="left"
        [visible]="false">
    </dxi-column>
    <dxi-column dataField="userId" caption="UserId" [allowEditing]="false" dataType="string"
        [visible]="false">
    </dxi-column>
    <dxi-column dataField="nomeImpianto" caption="{{'azienda-impianti-lista.nome-impianto' | translate}}" [allowEditing]="true" dataType="string" [visible]="true">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="createDate" caption="{{'azienda-impianti-lista.data-registrazione' | translate}}" [allowEditing]="false" dataType="date"
        format="dd/MM/yyyy" [visible]="true">
    </dxi-column>

    <dxi-column dataField="nazionalita" caption="{{'azienda-impianti-lista.nazionalità' | translate}}" [allowEditing]="true" dataType="string" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-lookup
            [dataSource]="nation_list"
            valueExpr="codice"
            displayExpr="descrizione">
        </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="provincia" caption="{{'azienda-impianti-lista.provincia' | translate}}" [allowEditing]="true" dataType="string" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxo-lookup
            [dataSource]="province_list"
            valueExpr="descrizione"
            displayExpr="descrizione">
        </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="indirizzo" caption="{{'azienda-impianti-lista.indirizzo' | translate}}" [allowEditing]="true" dataType="string" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="citta" caption="{{'azienda-impianti-lista.città' | translate}}" [allowEditing]="true" dataType="string" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    
    <dxi-column dataField="cap" caption="{{'azienda-impianti-lista.cap' | translate}}" [allowEditing]="true" dataType="string" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="capacitaProduttivaAnnua" caption="{{'azienda-impianti-lista.capacità' | translate}} (ton)" [allowEditing]="true" dataType="number" [visible]="false">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="status" caption="{{'azienda-impianti-lista.stato' | translate}}" dataType="string" [visible]="true" cellTemplate="templateStatusPlant">
        <div *dxTemplate="let templateStatusPlant of 'templateStatusPlant'">
            <span class="{{templateStatusPlant.data.statusClass}}">
                {{templateStatusPlant.data.status}}
            </span>
        </div>
    </dxi-column>

    <dxi-column dataField="statusDoc" caption="" [allowEditing]="false" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="statusClass" caption="" [allowEditing]="false" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="certificazioneIso9001" [allowEditing]="false" caption="Certificazione ISO 9001" dataType="string" [visible]="false" cssClass="">
    </dxi-column>
    <dxi-column dataField="certificazioneIso9001Valid" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>


    <dxi-column dataField="certificazioneIso9001Status" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="certificazioneIso9001CssClass" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="certificazioneIso14001" [allowEditing]="false" caption="Certificazione ISO 14001" dataType="string" [visible]="false" cssClass="">
    </dxi-column>
    <dxi-column dataField="certificazioneIso14001Valid" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>


    <dxi-column dataField="certificazioneIso14001Status" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="certificazioneIso14001CssClass" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="docAutorizzazione" [allowEditing]="false" caption="Documento autorizzazione" dataType="string" [visible]="false" cssClass="">
    </dxi-column>
    <dxi-column dataField="docAutorizzazioneValid" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="docAutorizzazioneStatus" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="docAutorizzazioneCssClass" [allowEditing]="false" caption="" dataType="string" [visible]="false" cssClass="">
    </dxi-column>
                                            

    <dxi-column dataField="nomeRifSpedizione" [allowEditing]="true" caption="Nome e riferimento per la spedizione" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="telefonoRifSpedizione" [allowEditing]="true" caption="Telefono di riferimento" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="mobileRifSpedizione" [allowEditing]="true" caption="Telefono mobile di riferimento" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="emailRifSpedizione" [allowEditing]="true" caption="Email di riferimento" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column dataField="noteTrasportatore" [allowEditing]="true" caption="Note per il trasportatore" dataType="string" [visible]="false" cssClass="">
    </dxi-column>

    <dxi-column type="buttons">
        <dxi-button name="edit" [visible]="isCoripet()"></dxi-button>
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detailPlant"></dxo-master-detail>

    <div *dxTemplate="let detailPlant of 'detailPlant'">
        
        <dx-form [(formData)]="detailPlant.data">
                
            <dxi-item itemType="tabbed">
                <dxi-tab title="{{'azienda-impianti-lista.dati-impianto' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.dati-impianto' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="nomeImpianto" [label]="{text: 'azienda-impianti-lista.nome-impianto' | translate}"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="nazionalita" [label]="{text: 'azienda-impianti-lista.nazionalità' | translate}" [colSpan]="10">
                                <div *dxTemplate="let nazionalita of 'item'">
                                    <dx-select-box *ngIf="nazionalita && nazionalita.editorOptions" [width]="auto" [height]="auto"
                                        [value]="nazionalita.editorOptions.value"
                                        [items]="nation_list" 
                                        displayExpr="descrizione"
                                        valueExpr="codice">
                                    </dx-select-box>
                                </div>
                            </dxi-item>
                            <dxi-item dataField="provincia" [label]="{text: 'azienda-impianti-lista.provincia' | translate}" [colSpan]="10">
                                <div *dxTemplate="let provincia of 'item'">
                                    <dx-select-box *ngIf="provincia && provincia.editorOptions" [width]="auto" [height]="auto"
                                        [value]="provincia.editorOptions.value"
                                        [items]="province_list" 
                                        displayExpr="descrizione"
                                        valueExpr="descrizione">
                                    </dx-select-box>
                                </div>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="indirizzo" [label]="{text: 'azienda-impianti-lista.indirizzo' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="citta" [label]="{text: 'azienda-impianti-lista.città' | translate}" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="cap" [label]="{text: 'azienda-impianti-lista.cap' | translate}" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="capacitaProduttivaAnnua"  [label]="{text: 'azienda-impianti-lista.capacità' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                    </dxi-item>
                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.documenti-impianto' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.documenti-impianto' | translate}}" [colSpan]="20" [colCount]="1" cssClass="top20 detail-form">

                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            
                            <dxi-item dataField="certificazioneIso9001" [label]="{text: 'Certificazione ISO 9001'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant.data.certificazioneIso9001 != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.data.certificazioneIso9001, 'certificazione-iso-9001.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button *ngIf="isCoripet()"  class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_9001, detailPlant.data.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button *ngIf="isCoripet()" class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_9001, detailPlant.data.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span translate class="status {{getDocumentStatusClass(detailPlant.data.certificazioneIso9001, detailPlant.data.certificazioneIso9001Valid)}}">azienda-impianti-lista.{{getDocumentStatus(detailPlant.data.certificazioneIso9001, detailPlant.data.certificazioneIso9001Valid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.data.certificazioneIso9001 == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>

                            <dxi-item dataField="certificazioneIso14001" [label]="{text: 'Certificazione ISO 14001'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant.data.certificazioneIso14001 != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.data.certificazioneIso14001, 'certificazione-iso-14001.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button *ngIf="isCoripet()"  class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_14001, detailPlant.data.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button *ngIf="isCoripet()" class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_14001, detailPlant.data.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span translate class="status {{getDocumentStatusClass(detailPlant.data.certificazioneIso14001, detailPlant.data.certificazioneIso14001Valid)}}">azienda-impianti-lista.{{getDocumentStatus(detailPlant.data.certificazioneIso14001, detailPlant.data.certificazioneIso14001Valid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.data.certificazioneIso14001 == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>

                            <dxi-item dataField="docAutorizzazione" [label]="{text: 'Documento autorizzazione'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant.data.docAutorizzazione != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.data.docAutorizzazione, 'documento-autorizzazione.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button *ngIf="isCoripet()" class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_DOCAUTH, detailPlant.data.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button *ngIf="isCoripet()" class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_DOCAUTH, detailPlant.data.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span translate class="status {{getDocumentStatusClass(detailPlant.data.docAutorizzazione, detailPlant.data.docAutorizzazioneValid)}}">azienda-impianti-lista.{{getDocumentStatus(detailPlant.data.docAutorizzazione, detailPlant.data.docAutorizzazioneValid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.data.docAutorizzazione == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>
                            
                        </dxi-item>

                    </dxi-item>
                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.dati-spedizione' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.info-utili-spedizione' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="nomeRifSpedizione" [label]="{text: 'azienda-impianti-lista.nome-rif-spedizione' | translate}"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="telefonoRifSpedizione" [label]="{text: 'azienda-impianti-lista.telefono-rif-spedizione' | translate}" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="mobileRifSpedizione" [label]="{text: 'azienda-impianti-lista.mobile-rif-spedizione' | translate}" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="emailRifSpedizione" [label]="{text: 'azienda-impianti-lista.email-rif-spedizione' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="noteTrasportatore" [label]="{text: 'azienda-impianti-lista.note-trasportatore' | translate}" editorType="dxTextArea" [colSpan]="20"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.stato-impianto' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.stato-impianto' | translate}}" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item dataField="status" cssClass="detail-form-section" [label]="{text: ''}" itemTemplate="templateStatusPlantView">

                            <div *dxTemplate="let templateStatusPlantView of 'templateStatusPlantView'">
                                <span class="{{detailPlant.data.statusClass}} status">
                                    <span translate>azienda-impianti-lista.{{detailPlant.data.status}}</span>
                                </span>
                                <div class="actions operator-actions " *ngIf="isCoripet()" [ngClass]="{'top20': isCoripet()}">
                                    <div>
                                        <button  class="btn success btn-approve-document" (click)="approveFramework(detailPlant.data.id)"><i class="fal fa-check"></i>
                                            <span translate>azienda-impianti-lista.approva-impianto</span>
                                        </button>
                                        <button class="btn error btn-reject-document" (click)="rejectFramework(detailPlant.data.id)"><i class="fal fa-times"></i>
                                            <span translate>azienda-impianti-lista.rifiuta-impianto</span>
                                        </button>
                                    </div>
                                </div>
                            </div>                            

                        </dxi-item>
                    </dxi-item>

                </dxi-tab>
            </dxi-item>
        </dx-form>
    </div>
    
    <dxo-editing
        [allowUpdating]="true"
        [allowAdding]="false"
        [allowDeleting]="false"
        mode="form"
        >

        <dxo-form [colCount]="20">
                
            <dxi-item itemType="tabbed" [colSpan]="20">
                <dxi-tab title="{{'azienda-impianti-lista.dati-impianto' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.dati-impianto' | translate}}" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="nomeImpianto" [label]="{text: 'azienda-impianti-lista.nome-impianto' | translate}"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="nazionalita" [label]="{text: 'azienda-impianti-lista.nazionalità' | translate}" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="provincia"  [label]="{text: 'azienda-impianti-lista.provincia' | translate}" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="indirizzo"  [label]="{text: 'azienda-impianti-lista.indirizzo' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="citta"  [label]="{text: 'azienda-impianti-lista.città' | translate}" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="cap"  [label]="{text: 'azienda-impianti-lista.cap' | translate}" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="capacitaProduttivaAnnua"  [label]="{text: 'azienda-impianti-lista.capacità' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                    </dxi-item>
                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.documenti-impianto' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.documenti-impianto' | translate}}" [colSpan]="20" [colCount]="1" cssClass="top20 detail-form">

                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            
                            <dxi-item dataField="certificazioneIso9001" [label]="{text: 'Certificazione ISO 9001'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant && detailPlant.certificazioneIso9001 != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.certificazioneIso9001, 'certificazione-iso-9001.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button  class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_9001, detailPlant.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_9001, detailPlant.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span class="status {{getDocumentStatusClass(detailPlant.certificazioneIso9001, detailPlant.certificazioneIso9001Valid)}}" translate>azienda-impianti-lista.{{getDocumentStatus(detailPlant.certificazioneIso9001, detailPlant.certificazioneIso9001Valid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.certificazioneIso9001 == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>

                            <dxi-item dataField="certificazioneIso14001" [label]="{text: 'Certificazione ISO 14001'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant.certificazioneIso14001 != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.certificazioneIso14001, 'certificazione-iso-14001.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button  class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_14001, detailPlant.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_14001, detailPlant.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span class="status {{getDocumentStatusClass(detailPlant.certificazioneIso14001, detailPlant.certificazioneIso14001Valid)}}" translate>azienda-impianti-lista.{{getDocumentStatus(detailPlant.certificazioneIso14001, detailPlant.certificazioneIso14001Valid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.certificazioneIso14001 == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>

                            <dxi-item dataField="docAutorizzazione" [label]="{text: 'Documento autorizzazione'}" [colSpan]="1" itemTemplate="templateDoc">
                                <div *dxTemplate="let templateDoc of 'templateDoc'">
                                    <span class="status-org-doc-download" *ngIf="detailPlant.docAutorizzazione != undefined">
                                        <div class="grid">
                                            <button class="btn btn-primary w100" (click)="downloadFile(detailPlant.docAutorizzazione, 'documento-autorizzazione.pdf')">
                                                <i class="fas fa-download "></i>Download
                                            </button>
                                            <button  class="btn success btn-approve-document w100 top10" (click)="approveDocumentFramework(ConstantsModule.DOC_FRAMEWORK_DOCAUTH, detailPlant.id)"><i class="fal fa-check"></i>
                                                <span translate>azienda-impianti-lista.approva</span>
                                            </button>
                                            <button class="btn error btn-reject-document w100 top10" (click)="rejectDocumentFramework(ConstantsModule.DOC_FRAMEWORK_DOCAUTH, detailPlant.id)"><i class="fal fa-times"></i>
                                                <span translate>azienda-impianti-lista.rifiuta</span>
                                            </button>
                                        </div>
                                        <br/>
                                        <span class="status {{getDocumentStatusClass(detailPlant.docAutorizzazione, detailPlant.docAutorizzazioneValid)}}" translate>azienda-impianti-lista.{{getDocumentStatus(detailPlant.docAutorizzazione, detailPlant.docAutorizzazioneValid)}}</span>
                                    </span>
                                    <span class="status-org-doc-not-present" *ngIf="detailPlant.docAutorizzazione == undefined">
                                        <span translate>azienda-impianti-lista.nessun-documento-caricato</span>
                                    </span>
                                </div>
                            </dxi-item>
                            
                        </dxi-item>

                    </dxi-item>
                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.dati-spedizione' | translate}}">
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.info-utili-spedizione' | translate}}" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="nomeRifSpedizione" [label]="{text: 'azienda-impianti-lista.nome-rif-spedizione' | translate}"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="telefonoRifSpedizione" [label]="{text: 'azienda-impianti-lista.telefono-rif-spedizione' | translate}" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="mobileRifSpedizione" [label]="{text: 'azienda-impianti-lista.mobile-rif-spedizione' | translate}" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="emailRifSpedizione" [label]="{text: 'azienda-impianti-lista.email-rif-spedizione' | translate}" [colSpan]="20"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="noteTrasportatore" [label]="{text: 'azienda-impianti-lista.note-trasportatore' | translate}" editorType="dxTextArea" [colSpan]="20"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dxi-tab>

                <dxi-tab title="{{'azienda-impianti-lista.stato-impianto' | translate}}" >
                    <dxi-item itemType="group" caption="{{'azienda-impianti-lista.stato-impianto' | translate}}" cssClass="top20 detail-form">
                        <dxi-item dataField="status" cssClass="detail-form-section" [label]="{text: ''}" itemTemplate="templateStatusPlantView">

                            <div *dxTemplate="let templateStatusPlantView of 'templateStatusPlantView'">
                                <span class="{{detailPlant.statusClass}} status">
                                    <span translate>azienda-impianti-lista.{{detailPlant.status}}</span>
                                </span>
                                <div class="actions operator-actions " [ngClass]="{'top20': isCoripet()}">
                                    <div>
                                        <button  class="btn success btn-approve-document" (click)="approveFramework(detailPlant.id)"><i class="fal fa-check"></i>
                                            <span translate>azienda-impianti-lista.approva-impianto</span>
                                        </button>
                                        <button class="btn error btn-reject-document" (click)="rejectFramework(detailPlant.id)"><i class="fal fa-times"></i>
                                            <span translate>azienda-impianti-lista.rifiuta-impianto</span>
                                        </button>
                                    </div>
                                </div>
                            </div>                            

                        </dxi-item>
                    </dxi-item>

                </dxi-tab>
            </dxi-item>
        </dxo-form>

    </dxo-editing>
    
</dx-data-grid>